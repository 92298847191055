export const CURATED_LISTS = [
  {
    "id": "978959195907129345",
    "title": "Black Holes",
    "color": "GRAY",
    "credit": "https://www.go-astronomy.com/black-holes.php",
    "imgURL": "/list_icons/black-hole.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "944242499278143489",
        "name": "M 15",
        "names": [
          "C 2127+119",
          "GCl 120",
          "M 15",
          "NGC 7078",
          "[KPS2012] MWSC 3518"
        ],
        "searchKey": "gcl120|m15|kps2012mwsc3518|ngc7078|c2127119",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.49953611111111,
        "dec": 12.167,
        "fluxV": null,
        "sizeMajor": 12.3,
        "sizeMinor": 12.3,
        "sizeAngle": 90.0,
        "simbadName": "M 15",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242728855666689",
        "name": "M 87",
        "names": [
          "PCCS2 143 G283.76+74.50",
          "RFC J1230+1223",
          "FIRST J123049.3+122323",
          "TGSSADR J123049.6+122321",
          "NGVS J123049.42+122328.0",
          "QSO B1228+126",
          "NAME M 87*",
          "IGR J12310+1221",
          "ACSVCS 2",
          "1A 1228+12",
          "1H 1226+128",
          "1Jy 1228+12",
          "1Jy 1228+126",
          "1M 1228+127",
          "2A 1228+125",
          "2E 2744",
          "2U 1228+12",
          "3A 1228+125",
          "3C 274.0",
          "3C 274",
          "3CR 274",
          "3U 1228+12",
          "4C 12.45",
          "4U 1228+12",
          "APG 152",
          "CTA 54",
          "Cul 1228+126",
          "Cul 1228+12",
          "DA 325",
          "DB 85",
          "GIN 800",
          "H 1228+127",
          "H 1227+12",
          "IERS B1228+126",
          "IRAS F12282+1240",
          "IRAS 12282+1240",
          "LEDA 41361",
          "M 87",
          "MCG+02-32-105",
          "MRC 1228+126",
          "Mills 12+1A",
          "NAME SMOKING GUN",
          "NAME Vir A",
          "NAME Virgo A",
          "NGC 4486",
          "NRAO 401",
          "NRL 8",
          "NVSS B122817+124004",
          "PKS J1230+1223",
          "PKS 1228+12",
          "PKS 1228+126",
          "PKS 1228+127",
          "RORF 1228+126",
          "SIM 1228+06.0",
          "UGC 7654",
          "VCC 1316",
          "VPC 771",
          "VSOP J1230+1223",
          "X Vir X-1",
          "XSS J12309+1223",
          "Z 70-139",
          "[DGW65] 57",
          "[DLB87] V12",
          "[DML87] 747",
          "[DSB94] 254",
          "[PT56] 24",
          "[TH2002] 1",
          "[VDD93] 163",
          "2MASX J12304942+1223279",
          "[FWB89] Galaxy 270",
          "X Vir XR-1",
          "[GW2008] CoNFIG 139",
          "1ES 1228+12.6",
          "2E 1228.2+1240",
          "2EUVE J1230+12.3",
          "EUVE J1230+12.3",
          "ICRF J123049.4+122328",
          "RX J1230.8+1223",
          "RX J1230.1+1223",
          "UZC J123049.3+122327",
          "Z 1228.3+1240",
          "[CHM2007] HDC 720 J123049.42+1223279  [CHM2007] LDC 904 J123049.42+1223279  [VV2000c] J123049.5+122328",
          "[VV2003c] J123049.5+122328",
          "[VV2006c] J123049.5+122328",
          "[VV98c] J123049.5+122328",
          "1FGL J1230.8+1223",
          "[BEC2010] HRS 183",
          "WMAP J1230+1223",
          "WMAP 165",
          "GB6 J1230+1223",
          "[WCO2009] J123049+122256",
          "NVSS J123049+122323",
          "SDSS J123049.41+122328.1",
          "[VV2010c] J123049.5+122328",
          "NEWPS5 J1230+1223",
          "GALEX J123049.4+122328",
          "2FGL J1230.8+1224",
          "1FHL J1230.8+1224",
          "87GB 122819.0+124029",
          "BWE 1228+1240",
          "GB6 B1228+1240",
          "NVSS J123049+122321",
          "RGB J1230+123",
          "[CAB95] IRAS F12282+1240",
          "[DC78] UGC 7654",
          "PCCS1 857 G283.74+74.48",
          "PCCS1 545 G283.76+74.47",
          "NAME Virgo Galaxy",
          "3FGL J1230.9+1224",
          "SDSS J123047.55+122318.0",
          "EVCC 786",
          "VER J1230+123",
          "2FHL J1230.8+1225",
          "TeV J1230+123",
          "3FHL J1230.8+1223",
          "4FGL J1230.8+1223",
          "Gaia DR2 3907709439453756032",
          "9Y-MST J1230+1221",
          "HIDDEN NAME M87*"
        ],
        "searchKey": "2masxj123049421223279|3cr274|virgogalaxy|vira|3a1228125|gb6b12281240|smokinggun|87gb1228190124029|firstj1230493122323|gb6j12301223|gin800|2e122821240|2euvej1230123|evcc786|2u122812|z70139|xvirxr1|rxj123081223|mrc1228126|vv2010cj1230495122328|m87|2a1228125|2fglj123081224|bwe12281240|vcc1316|mcg0232105|rxj123011223|rorf1228126|virgoa|4c1245|sdssj123049411223281|nvssb122817124004|leda41361|pks122812|z122831240|m87|ngvsj123049421223280|cul122812|verj1230123|wco2009j123049122256|cab95irasf122821240|uzcj1230493122327|1es1228126|cta54|pccs2143g283767450|ugc7654|nvssj123049122323|pccs1545g283767447|euvej1230123|2e2744|bec2010hrs183|dc78ugc7654|9ymstj12301221|da325|1m1228127|gaiadr23907709439453756032|pt5624|xvirx1|vpc771|nrl8|nrao401|pks1228127|3fhlj123081223|sim1228060|dgw6557|galexj1230494122328|acsvcs2|dml87747|apg152|rgbj1230123|3fglj123091224|vv98cj1230495122328|fwb89galaxy270|vv2006cj1230495122328|igrj123101221|3c2740|irasf122821240|cul1228126|iras122821240|vdd93163|ngc4486|1jy122812|h1228127|xssj123091223|vsopj12301223|mills121a|3c274|chm2007hdc720j123049421223279chm2007ldc904j123049421223279vv2000cj1230495122328|db85|vv2003cj1230495122328|hiddennamem87|1fhlj123081224|1jy1228126|h122712|2fhlj123081225|icrfj1230494122328|tevj1230123|nvssj123049122321|th20021|tgssadrj1230496122321|3u122812|wmapj12301223|1h1226128|pccs1857g283747448|pksj12301223|qsob1228126|1fglj123081223|wmap165|gw2008config139|dsb94254|dlb87v12|4fglj123081223|4u122812|iersb1228126|pks1228126|newps5j12301223|1a122812|rfcj12301223|sdssj123047551223180",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.51372871781667,
        "dec": 12.39112324608333,
        "fluxV": 8.63,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 87",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079447824564226",
        "name": "SNR G039.7-02.0",
        "names": [
          "SNR G039.7-02.0",
          "AJG 93",
          "CTB 69",
          "LMH 40",
          "MM 43",
          "Zel 1909+049",
          "[DGW65] 121",
          "[HC69] 28",
          "1E 1909.3+0453",
          "GRS G039.70 -02.00",
          "[ADD2012] SNR 18",
          "W 50"
        ],
        "searchKey": "dgw65121|lmh40|ajg93|w50|mm43|zel1909049|ctb69|grsg039700200|1e190930453|snrg0397020|add2012snr18|hc6928",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.20555555555556,
        "dec": 4.916666666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "SNR G039.7-02.0",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079717720064002",
        "name": "W 49b",
        "names": [
          "SNR G043.3-00.2",
          "1ES 1908+09.0",
          "2E 4203",
          "3C 398",
          "3C 398.0",
          "4C 09.63",
          "AJG 95",
          "CTA 84",
          "Cul 1908+09",
          "GAL 043.3-00.2",
          "NEK 43.3-00.2",
          "NRAO 599",
          "2E 1908.7+0901",
          "GRS G043.30 -00.20",
          "[ADD2012] SNR 23",
          "2FGL J1911.0+0905",
          "0FGL J1911.0+0905",
          "1FGL J1910.9+0906c",
          "1FHL J1911.0+0905",
          "W 49b",
          "3FGL J1910.9+0906",
          "2FHL J1911.0+0905",
          "TeV J1911+090",
          "3FHL J1911.0+0905",
          "HESS J1911+090",
          "4FGL J1911.0+0905"
        ],
        "searchKey": "4c0963|2fglj191100905|1es1908090|nek433002|grsg043300020|3c3980|snrg0433002|4fglj191100905|gal0433002|1fhlj191100905|1fglj191090906c|hessj1911090|cul190809|ajg95|3fhlj191100905|3c398|0fglj191100905|nrao599|w49b|2e190870901|tevj1911090|2fhlj191100905|3fglj191090906|2e4203|cta84|add2012snr23",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.18583333333333,
        "dec": 9.106666666666666,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "W 49b",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079810603515906",
        "name": "ESO 243-49",
        "names": [
          "Gaia DR3 4933568807668380160",
          "2MFGC 863",
          "APMBGC 243-112+069",
          "ESO 243-49",
          "ESO-LV 243-0490",
          "LEDA 4181",
          "[D80] ACO 2877 16",
          "[D80] DRCG 0107-46 16",
          "[MKD92] ACO 2877 87",
          "2MASX J01102774-4604274",
          "6dFGS gJ011027.8-460427",
          "PDF J011027.7-460427",
          "PDF J011027.6-460428",
          "[CHM2007] HDC 50 J011027.74-4604274  [CHM2007] LDC 62 J011027.74-4604274"
        ],
        "searchKey": "gaiadr34933568807668380160|chm2007hdc50j011027744604274chm2007ldc62j011027744604274|mkd92aco287787|2masxj011027744604274|esolv2430490|apmbgc243112069|leda4181|d80aco287716|eso24349|d80drcg01074616|6dfgsgj0110278460427|pdfj0110276460428|2mfgc863|pdfj0110277460427",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 1.174376406632,
        "dec": -46.07426564174001,
        "fluxV": null,
        "sizeMajor": 0.777,
        "sizeMinor": 0.233,
        "sizeAngle": 65.0,
        "simbadName": "ESO 243-49",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079810792095746",
        "name": "[KRL2007b] 222",
        "names": [
          "[OKM2018] SWIFT J1709.8-3627A",
          "[KRL2007b] 222",
          "IGR J17091-3624",
          "INTEGRAL1 44",
          "SAX J1709.1-3624",
          "PBC J1709.4-3624",
          "CXOU J170907.6-362425",
          "SWIFT J1709.8-3626",
          "SWIFT J1709.8-3627B"
        ],
        "searchKey": "saxj170913624|okm2018swiftj170983627a|swiftj170983627b|pbcj170943624|swiftj170983626|integral144|cxouj1709076362425|krl2007b222|igrj170913624",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.15211388888889,
        "dec": -36.40713888888889,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "[KRL2007b] 222",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811040116738",
        "name": "SN 1997D",
        "names": [
          "SN 1997D"
        ],
        "searchKey": "sn1997d",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 4.183611111111111,
        "dec": -56.49888888888889,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "SN 1997D",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811147038722",
        "name": "V* IL Lup",
        "names": [
          "Gaia DR3 5986117923045619072",
          "TIC 254732447",
          "1A 1543-47",
          "1M 1543-475",
          "3U 1543-47",
          "4U 1543-47",
          "4U 1543-475",
          "INTREF 657",
          "V* IL Lup",
          "[BM83] X1543-475",
          "2MASS J15470829-4740103",
          "Gaia DR2 5986117923045619072"
        ],
        "searchKey": "4u1543475|gaiadr25986117923045619072|gaiadr35986117923045619072|tic254732447|bm83x1543475|1m1543475|1a154347|intref657|4u154347|2massj154708294740103|villup|3u154347",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 15.785632463132,
        "dec": -47.6695234966,
        "fluxV": 11.96,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* IL Lup",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811164602370",
        "name": "V* V404 Cyg",
        "names": [
          "Gaia DR2 2056188624872569088",
          "TIC 136874944",
          "AAVSO 2020+33",
          "AN 100.1938",
          "Ginga 2023+338",
          "Ginga 2023+33",
          "INTREF 1021",
          "NOVA Cyg 1938",
          "NOVA Cyg 1989",
          "V* V404 Cyg",
          "2MASS J20240382+3352021",
          "1RXS J202405.3+335157"
        ],
        "searchKey": "tic136874944|ginga2023338|vv404cyg|ginga202333|an1001938|1rxsj2024053335157|aavso202033|novacyg1938|novacyg1989|2massj202403823352021|intref1021|gaiadr22056188624872569088",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.40106262385489,
        "dec": 33.86721171825972,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* V404 Cyg",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811164635138",
        "name": "V* V4641 Sgr",
        "names": [
          "[OKM2018] SWIFT J1818.7-2553",
          "Gaia DR3 4053096388919082368",
          "TIC 128269687",
          "2MASS J18192163-2524258",
          "AAVSO 1813-25B",
          "GSC 06848-03786",
          "INTREF 898",
          "V* V4641 Sgr",
          "XTE J1819-254",
          "SAX J1819.3-2525",
          "Gaia DR2 4053096388919082368",
          "OGLE BLG261.2 109821",
          "OGLE BLG-ECL-408340"
        ],
        "searchKey": "vv4641sgr|2massj181921632524258|okm2018swiftj181872553|aavso181325b|gaiadr34053096388919082368|saxj181932525|ogleblg2612109821|ogleblgecl408340|tic128269687|intref898|xtej1819254|gaiadr24053096388919082368|gsc0684803786",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.322676201646,
        "dec": -25.40718044332,
        "fluxV": 13.654,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* V4641 Sgr",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811166142466",
        "name": "ASM 2000+25",
        "names": [
          "Ginga 2000+251",
          "Ginga 2000+25",
          "INTREF 1007",
          "NAME ASM 2000+25",
          "NOVA Vul 1988",
          "V* QZ Vul"
        ],
        "searchKey": "ginga2000251|vqzvul|intref1007|asm200025|asm200025|ginga200025|novavul1988",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.04710555555556,
        "dec": 25.23647222222222,
        "fluxV": 18.2,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* QZ Vul",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811199467522",
        "name": "Sgr A IRS 13E",
        "names": [
          "[VES2006] 44",
          "GCIRS 13E",
          "NAME Sgr A IRS 13E",
          "WR 101f",
          "[BSD96] 52",
          "[CRG2001b] 26",
          "[CRG2001b] 26a",
          "[GPE2000] 3.37",
          "NAME Gal Center IRS 13E"
        ],
        "searchKey": "crg2001b26a|gpe2000337|crg2001b26|sgrairs13e|galcenterirs13e|wr101f|ves200644|sgrairs13e|gcirs13e|bsd9652",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.76103611111111,
        "dec": -29.00825,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "GCIRS 13E",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811199631362",
        "name": "M33 X-7",
        "names": [
          "[MPH2006] 150",
          "IFM-B 576",
          "CXOU J013334.1+303210",
          "[TFP88] X-7",
          "[SB95] 12",
          "[PMH2004] 171",
          "[LDC81] 7",
          "[LCB96] 21",
          "[HP2001] 71",
          "XMMU J013334.0+303211",
          "2E 408",
          "1AXG J013335+3032",
          "[MBH96] 128",
          "ChASeM33 137",
          "2E 0130.7+3016",
          "RX J0133.5+3032",
          "NAME M33 X-7",
          "ChASeM33 J013334.13+303211.3",
          "[SSA2010] 1272",
          "[I92a] 47",
          "IFM-B 581",
          "2XMM J013334.0+303212",
          "3XMM J013334.0+303211"
        ],
        "searchKey": "1axgj0133353032|chasem33137|rxj013353032|ssa20101272|chasem33j013334133032113|hp200171|mbh96128|sb9512|ifmb576|2xmmj0133340303212|cxouj0133341303210|m33x7|mph2006150|2e408|3xmmj0133340303211|m33x7|i92a47|tfp88x7|2e013073016|xmmuj0133340303211|ifmb581|ldc817|pmh2004171|lcb9621",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 1.559480555555556,
        "dec": 30.53647222222222,
        "fluxV": 18.7,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME M33 X-7",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811232301058",
        "name": "V* KV UMa",
        "names": [
          "Gaia DR3 789430249033567744",
          "TIC 297983610",
          "AAVSO 1112+48",
          "INTREF 446",
          "USNO-A1.0 1350-08089912",
          "USNO-A2.0 1350-07924726",
          "V* KV UMa",
          "XSS J11178+4758",
          "XTE J1118+480",
          "2MASS J11181079+4802126",
          "EQ J111810.7+480214",
          "PBC J1117.9+4803",
          "Gaia DR2 789430244729073664"
        ],
        "searchKey": "xssj111784758|eqj1118107480214|usnoa10135008089912|gaiadr2789430244729073664|intref446|aavso111248|gaiadr3789430249033567744|vkvuma|2massj111810794802126|xtej1118480|tic297983610|usnoa20135007924726|pbcj111794803",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 11.302998067236,
        "dec": 48.0367540917,
        "fluxV": 12.25,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* KV UMa",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811251732482",
        "name": "[GHJ2008] 3",
        "names": [
          "[GHJ2008] 3",
          "EQ J165001.0-495745",
          "INTREF 720",
          "XTE J1650-500"
        ],
        "searchKey": "intref720|ghj20083|eqj1650010495745|xtej1650500",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.83360555555555,
        "dec": -49.96211111111111,
        "fluxV": 11.89,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "[GHJ2008] 3",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811251798018",
        "name": "Great Annihilator",
        "names": [
          "[KRL2007b] 265",
          "2E 3938",
          "INTEGRAL1 64",
          "INTREF 816",
          "IRAS 17408-2942",
          "NAME Great Annihilator",
          "[HPG94] 1",
          "[SKM2002] 23",
          "1E 1740.7-2942",
          "1E 1740.7-2943",
          "2E 1740.7-2943",
          "AX J1743.9-2945",
          "PBC J1744.0-2942",
          "SWIFT J1743.9-2944",
          "2XMM J174354.8-294442",
          "SWIFT J1743.7-2946"
        ],
        "searchKey": "greatannihilator|2e3938|axj174392945|swiftj174372946|1e174072942|iras174082942|integral164|intref816|greatannihilator|skm200223|pbcj174402942|krl2007b265|swiftj174392944|hpg941|1e174072943|2xmmj1743548294442|2e174072943",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "CYAN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.73189722222222,
        "dec": -29.74516666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Great Annihilator",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811269853186",
        "name": "HD 167128",
        "names": [
          "HIP 89605",
          "Gaia DR3 6649357561810851328",
          "[JE82] 955",
          "CD-56 7256",
          "CPC 19 7226",
          "CPD-56 8706",
          "EM* CDS 966",
          "FK5 1474",
          "GC 24906",
          "GCRV 10712",
          "GEN# +1.00167128",
          "GSC 08748-02276",
          "HD 167128",
          "HIC 89605",
          "HR 6819",
          "IRAS 18129-5602",
          "JP11 2950",
          "N30 4051",
          "PPM 346742",
          "SAO 245369",
          "SKY# 33319",
          "TD1 21938",
          "UBV 15495",
          "UBV M 22748",
          "V* QV Tel",
          "[B10] 4611",
          "uvby98 100167128",
          "2MASS J18170753-5601240",
          "ALS 15056",
          "TYC 8748-2276-1",
          "WEB 15261",
          "Gaia DR2 6649357561810851328",
          "TIC 118842700"
        ],
        "searchKey": "hd167128|ppm346742|uvby98100167128|gsc0874802276|cd567256|hic89605|hip89605|gc24906|tyc874822761|hr6819|jp112950|gaiadr36649357561810851328|als15056|web15261|emcds966|ubvm22748|td121938|cpc197226|2massj181707535601240|ubv15495|sao245369|gen100167128|iras181295602|gcrv10712|fk51474|n304051|je82955|tic118842700|sky33319|vqvtel|b104611|cpd568706|gaiadr26649357561810851328",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.28542567371006,
        "dec": -56.02334932875639,
        "fluxV": 5.36,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "HD 167128",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811270410242",
        "name": "V* GU Mus",
        "names": [
          "Gaia DR3 5234956524083372544",
          "2RE J112617-684044",
          "2RE J1126-684",
          "AAVSO 1120-68",
          "Ginga 1124-68",
          "Ginga 1124-684",
          "Ginga 1124-683",
          "Granat 1124-68",
          "Granat 1121-68",
          "Granat 1124-684",
          "INTREF 452",
          "NOVA Mus 1991",
          "V* GU Mus",
          "1RXS J112623.5-684040",
          "Gaia DR2 5234956524083372544"
        ],
        "searchKey": "gaiadr25234956524083372544|vgumus|1rxsj1126235684040|ginga1124684|2rej1126684|2rej112617684044|granat112468|granat112168|granat1124684|novamus1991|ginga112468|intref452|aavso112068|ginga1124683|gaiadr35234956524083372544",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 11.44072115443333,
        "dec": -68.67579937582,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* GU Mus",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811287121922",
        "name": "V* V381 Nor",
        "names": [
          "Gaia DR3 5884099221246440064",
          "[KRL2007b] 174",
          "INTEGRAL1 17",
          "INTREF 660",
          "V* V381 Nor",
          "XTE J1550-564",
          "Gaia DR2 5884099221246440064"
        ],
        "searchKey": "vv381nor|integral117|gaiadr35884099221246440064|gaiadr25884099221246440064|krl2007b174|xtej1550564|intref660",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 15.84962883113067,
        "dec": -56.4764606625,
        "fluxV": 16.6,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* V381 Nor",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811288170498",
        "name": "M82 X-1",
        "names": [
          "2CXO J095550.1+694046",
          "[SGT2004] J095550.01+694046.0",
          "[CHP2004] J095550.0+694046",
          "[PCV2006] ULX 13",
          "[SST2011] J095550.01+694046.0",
          "NAME M82 X-1",
          "[CRS94] 1",
          "[KCF2005] M82 D",
          "[MTK2001b] 7",
          "NAME M82 ULX-1",
          "[LM2005] NGC 3034 ULX5",
          "CXOM82 J095550.3+694046",
          "CXOM82 J095550.2+694047",
          "CXOU J095550.2+694047",
          "[BWC2008] U10",
          "[KPZ2001] X41.4+60",
          "CXOU J095550.1+694046"
        ],
        "searchKey": "sgt2004j095550016940460|crs941|mtk2001b7|kcf2005m82d|sst2011j095550016940460|cxouj0955501694046|2cxoj0955501694046|chp2004j0955500694046|m82ulx1|cxom82j0955503694046|pcv2006ulx13|kpz2001x41460|lm2005ngc3034ulx5|bwc2008u10|m82x1|cxom82j0955502694047|cxouj0955502694047|m82x1",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 9.930558333333332,
        "dec": 69.67944444444444,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME M82 ULX-1",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811302817794",
        "name": "1A 0620-00",
        "names": [
          "Gaia DR3 3118721026600835328",
          "SBC9 396",
          "1A 0620-00",
          "1XRS 06201-003",
          "2S 0620-003",
          "3A 0620-003",
          "AAVSO 0617-00",
          "INTREF 297",
          "NOVA Mon 1917",
          "NOVA Mon 1975",
          "V* V616 Mon",
          "X Mon X-1",
          "[BM83] X0620-003",
          "[RLC2006] XB1",
          "WEB 6033",
          "Gaia DR2 3118721026600835328",
          "AO 0621-00",
          "[ZGH2005] XS00095B7-004",
          "CXOPS J062244.5-002044"
        ],
        "searchKey": "bm83x0620003|novamon1975|intref297|xmonx1|aavso061700|2s0620003|gaiadr23118721026600835328|sbc9396|novamon1917|zgh2005xs00095b7004|gaiadr33118721026600835328|rlc2006xb1|1a062000|vv616mon|1xrs06201003|cxopsj0622445002044|web6033|ao062100|3a0620003",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 6.379039551591999,
        "dec": -0.34563623904,
        "fluxV": 11.2,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "1A 0620-00",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811374776322",
        "name": "ICRF J130533.0-103319",
        "names": [
          "WISEA J130533.01-103319.3",
          "CRATES J130532-103315",
          "VLSSr J130533.2-103322",
          "RFC J1305-1033",
          "GLEAM J130533-103317",
          "SPASS J130532-103328",
          "TGSSADR J130533.1-103318",
          "Gaia DR3 3622979843899961472",
          "TIC 134875922",
          "6dFGS gJ130533.0-103319",
          "2E 2966",
          "1Jy 1302-102",
          "2MASS J13053301-1033193",
          "2MASSI J1305330-103319",
          "HE 1302-1017",
          "ICRF J130533.0-103319",
          "IERS B1302-102",
          "NVSS J130533-103320",
          "OHIO P -106",
          "PG 1302-103",
          "PG 1302-10",
          "PG 1302-102",
          "PKS 1302-102",
          "PKS J1305-1033",
          "PKS 1302-103",
          "PMN J1305-1033",
          "QSO J1305-1033",
          "QSO B1302-1017",
          "QSO B1302-102",
          "QSO B1302-103",
          "RBS 1212",
          "RORF 1302-102",
          "TXS 1302-102",
          "UVQS J130533.01-103319.3",
          "Gaia DR1 3622979839604521984",
          "VSOP J1305-1033",
          "[BDW2002] q1302-1017",
          "[HB91] 1304-107",
          "[HB93] 1302-102",
          "[VV2000] J130533.0-103320",
          "[VV2003] J130533.0-103320",
          "[VV2006] J130533.0-103320",
          "[VV96] J130533.0-103320",
          "[VV98] J130533.0-103320",
          "[WPW2003] J1305-1033",
          "[WTW94] 1302-102",
          "[KB2007] J130533.0-103320.4",
          "1RXS J130533.2-103315",
          "2E 1302.9-1017",
          "WISH B1302.9-1017",
          "[SZK2001] J130532.98-103320.1",
          "CGRaBS J1305-1033",
          "[MGL2009] BZQ J1305-1033",
          "[VV2010] J130533.0-103320",
          "IVS B1302-102",
          "WISE J130533.02-103319.3",
          "AT20G J130532-103318",
          "[MML2015] 5BZQ J1305-1033",
          "WMAP J1305-1031",
          "[P82] 1302-102",
          "SWIFT J1305.4-1034",
          "WISE J130533.01-103319.3",
          "Gaia DR2 3622979843899961472"
        ],
        "searchKey": "at20gj130532103318|vv2006j1305330103320|wisej130533021033193|vv2003j1305330103320|nvssj130533103320|vv96j1305330103320|spassj130532103328|pks1302102|wpw2003j13051033|qsoj13051033|qsob1302102|pksj13051033|vsopj13051033|2massij1305330103319|gaiadr23622979843899961472|1rxsj1305332103315|vv2010j1305330103320|ohiop106|cratesj130532103315|swiftj130541034|mgl2009bzqj13051033|wmapj13051031|wtw941302102|gaiadr33622979843899961472|bdw2002q13021017|tgssadrj1305331103318|2massj130533011033193|wiseaj130533011033193|hb911304107|vlssrj1305332103322|1jy1302102|tic134875922|rorf1302102|mml20155bzqj13051033|cgrabsj13051033|pg1302103|icrfj1305330103319|wishb130291017|szk2001j130532981033201|txs1302102|he13021017|p821302102|wisej130533011033193|ivsb1302102|pg130210|qsob1302103|rbs1212|uvqsj130533011033193|gaiadr13622979839604521984|qsob13021017|vv98j1305330103320|hb931302102|kb2007j13053301033204|rfcj13051033|2e130291017|gleamj130533103317|iersb1302102|pmnj13051033|pks1302103|6dfgsgj1305330103319|2e2966|pg1302102|vv2000j1305330103320",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 13.09250418154667,
        "dec": -10.5553973288,
        "fluxV": 15.23,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "ICRF J130533.0-103319",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811482845186",
        "name": "V* V1033 Sco",
        "names": [
          "[KRL2007b] 208",
          "AAVSO 1647-39",
          "GRO J1655-40",
          "GRO J1655-44",
          "GRO J1655-41",
          "INTREF 727",
          "NOVA Sco 1994",
          "V* V1033 Sco",
          "PBC J1653.9-3950",
          "ChOPS J165400.14-395045.1",
          "SWIFT J1653.8-3952",
          "SWIFT J1654.0-3950",
          "2XMM J165400.1-395044"
        ],
        "searchKey": "swiftj165403950|groj165540|intref727|novasco1994|vv1033sco|aavso164739|chopsj165400143950451|krl2007b208|groj165541|pbcj165393950|2xmmj1654001395044|swiftj165383952|groj165544",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.90003805555556,
        "dec": -39.84580555555556,
        "fluxV": 14.2,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* V1033 Sco",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811497525250",
        "name": "Sgr A*",
        "names": [
          "NAME Sgr A*",
          "CXOGC J174540.0-290027",
          "NAME SgrA*/IR",
          "NAME Sgr A*/L'",
          "NAME Sgr A* IR",
          "NAME Sgr A *",
          "[BGM2005] J174540.14-290028.40",
          "[BGM2005] J174540.08-290028.48",
          "AX J1745.6-2900",
          "[SKM2002] 28",
          "NAME Sgr A",
          "SWIFT J1746.3-2850A",
          "NAME Sagittarius A",
          "NAME Sagittarius A*"
        ],
        "searchKey": "sagittariusa|sgrair|swiftj174632850a|sgra|sgra|axj174562900|bgm2005j1745400829002848|skm200228|cxogcj1745400290027|sagittariusa|sgrair|sgra|sgral|bgm2005j1745401429002840|sgra",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.76112110833333,
        "dec": -29.00782497222222,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Sgr A*",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811499327490",
        "name": "V* V821 Ara",
        "names": [
          "Gaia DR3 5938658156448950528",
          "TIC 212205879",
          "[KRL2007b] 215",
          "1H 1659-487",
          "1M 1658-487",
          "2E 3803",
          "2E 1659.0-4842",
          "3A 1659-487",
          "3U 1658-48",
          "4U 1658-48",
          "4U 1659-487",
          "4U 1659-48",
          "AAVSO 1655-48",
          "GPS 1658-486",
          "GX 339-04",
          "INTEGRAL1 38",
          "INTREF 737",
          "V* V821 Ara",
          "[BM83] X1659-487",
          "2MASS J17024936-4847228",
          "1RXS J170248.5-484719",
          "PBC J1702.8-4847",
          "SWIFT J1702.8-4849",
          "SWIFT J1702.8-4847",
          "Gaia DR2 5938658152134474752"
        ],
        "searchKey": "gx33904|4u165948|gps1658486|3u165848|2e3803|2e165904842|vv821ara|pbcj170284847|tic212205879|2massj170249364847228|gaiadr35938658156448950528|integral138|intref737|3a1659487|4u165848|4u1659487|krl2007b215|aavso165548|swiftj170284849|1h1659487|1m1658487|swiftj170284847|1rxsj1702485484719|bm83x1659487|gaiadr25938658152134474752",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.04705212198,
        "dec": -48.78974665403999,
        "fluxV": 15.5,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* V821 Ara",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811546808322",
        "name": "V* V1521 Cyg",
        "names": [
          "TIC 17032547",
          "[KRL2007b] 376",
          "18P 57",
          "19P 17",
          "1H 2030+407",
          "1M 2030+407",
          "1XRS 20305+407",
          "2E 4376",
          "2U 2030+40",
          "3A 2030+407",
          "3U 2030+40",
          "4U 2030+40",
          "87GB 203037.0+404726",
          "ECX6-23",
          "GB6 B2030+4047",
          "GPS 2030+407",
          "GT 2030+407",
          "H 2030+407",
          "INTEGRAL1 118",
          "RGB J2032+409",
          "V* V1521 Cyg",
          "WR 145a",
          "X Cyg X-3",
          "[BM83] X2030+407",
          "[FS2003] 1076",
          "2MASS J20322578+4057279",
          "GPSR 079.846+0.700",
          "1ES 2030+40.7",
          "1RXS J203226.2+405725",
          "2E 2030.5+4047",
          "RX J2032.3+4057",
          "[BSF97] J203225.77+405728.0",
          "1FGL J2032.4+4057",
          "PBC J2032.4+4057",
          "SWIFT J2032.5+4055",
          "SWIFT J2032.4+4057",
          "2FGL J2032.1+4049",
          "[WBC2013] J203225.6+405726",
          "[AAA2018] FGL J2032.1+4058",
          "2AGL J2033+4100",
          "4FGL J2032.6+4053"
        ],
        "searchKey": "18p57|swiftj203244057|rxj203234057|gt2030407|aaa2018fglj203214058|vv1521cyg|gb6b20304047|integral1118|wr145a|krl2007b376|87gb2030370404726|4fglj203264053|ecx623|2aglj20334100|3a2030407|2e4376|pbcj203244057|3u203040|2massj203225784057279|swiftj203254055|1es2030407|1fglj203244057|rgbj2032409|2fglj203214049|wbc2013j2032256405726|1m2030407|19p17|xcygx3|2e203054047|h2030407|1xrs20305407|2u203040|gpsr0798460700|1rxsj2032262405725|4u203040|bm83x2030407|fs20031076|tic17032547|gps2030407|bsf97j203225774057280|1h2030407",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.54049444444444,
        "dec": 40.95775,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* V1521 Cyg",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811578822658",
        "name": "SS 433",
        "names": [
          "WISE J191149.56+045857.8",
          "UCAC4 475-089770",
          "Gaia DR3 4293406612283985024",
          "TIC 181547773",
          "[DPS86] B1909+048",
          "[KRL2007b] 358",
          "SBC9 1129",
          "1A 1909+04",
          "1H 1908+047",
          "2E 4204",
          "3A 1909+048",
          "4C 04.66",
          "4U 1908+05",
          "AAVSO 1906+04",
          "BWE 1909+0453",
          "GAL 039.7-02.0",
          "GPS 1909+049",
          "HBHA 204-02",
          "INTEGRAL1 110",
          "INTREF 969",
          "NEK 40.1-02.1",
          "RGB J1911+049",
          "SS 433",
          "TXS 1909+048",
          "USNO 659",
          "V* V1343 Aql",
          "[BM83] X1909+048",
          "[KW97] 36-17",
          "2MASS J19114957+0458578",
          "PLX 4470.01",
          "1ES 1909+04.8",
          "1RXS J191149.7+045857",
          "2E 1909.3+0453",
          "87GB 190920.8+045332",
          "RX J1911.7+0459",
          "[BSF97] J191149.56+045857.4",
          "PBC J1911.8+0459",
          "SWIFT J1912.0+0500",
          "SWIFT J1911.7+0500",
          "GRS G039.60 -01.80",
          "NVSS J191149+045858",
          "2XMM J191149.5+045858",
          "F3R 1368",
          "GB6 B1909+0453",
          "WEB 16429",
          "Gaia DR2 4293406612283985024"
        ],
        "searchKey": "plx447001|swiftj191200500|sbc91129|1h1908047|bwe19090453|web16429|f3r1368|2e4204|integral1110|grsg039600180|krl2007b358|tic181547773|nek401021|1rxsj1911497045857|4c0466|1es1909048|2e190930453|3a1909048|hbha20402|rxj191170459|gaiadr34293406612283985024|ucac4475089770|ss433|bm83x1909048|rgbj1911049|4u190805|aavso190604|gaiadr24293406612283985024|txs1909048|gal0397020|gps1909049|pbcj191180459|kw973617|1a190904|swiftj191170500|87gb1909208045332|intref969|2xmmj1911495045858|dps86b1909048|gb6b19090453|2massj191149570458578|nvssj191149045858|vv1343aql|wisej191149560458578|usno659|bsf97j191149560458574",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.19710132493,
        "dec": 4.98272975768,
        "fluxV": 14.643,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "SS 433",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811674079234",
        "name": "Granat 1915+105",
        "names": [
          "WISEA J191511.57+105645.2",
          "TIC 209140669",
          "[KRL2007b] 360",
          "Granat 1915+105",
          "Granat 1915+10",
          "2MASS J19151155+1056449",
          "WISE J191511.54+105644.7",
          "INTEGRAL1 112",
          "NOVA Aql 1992",
          "V* V1487 Aql",
          "PBC J1915.1+1056",
          "SWIFT J1915.3+1057",
          "SWIFT J1915.1+1056"
        ],
        "searchKey": "wiseaj191511571056452|vv1487aql|2massj191511551056449|pbcj191511056|tic209140669|integral1112|swiftj191531057|krl2007b360|wisej191511541056447|granat1915105|swiftj191511056|novaaql1992|granat191510",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.25320993333333,
        "dec": 10.945807,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "Granat 1915+105",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079811854958594",
        "name": "HD 226868",
        "names": [
          "HIP 98298",
          "Gaia DR3 2059383668236814720",
          "TIC 102604645",
          "[KRL2007b] 370",
          "SBC9 1193",
          "2E 4306",
          "1H 1956+350",
          "1M 1956+350",
          "1XRS 19564+350",
          "2U 1956+35",
          "3A 1956+350",
          "3U 1956+35",
          "4U 1956+35",
          "AAVSO 1954+34",
          "AG+35 1910",
          "ALS 10678",
          "BD+34 3815",
          "CGO 548",
          "GCRV 12319",
          "GEN# +1.00226868",
          "GOS G071.34+03.07 01",
          "GSC 02678-00791",
          "H 1956+350",
          "H 1957+35",
          "HD 226868",
          "HIC 98298",
          "Hilt 849",
          "INTEGRAL1 116",
          "INTREF 1001",
          "LS II +35 8",
          "MCW 770",
          "PPM 83929",
          "SAO 69181",
          "SBC7 776",
          "TYC 2678-791-1",
          "UBV 17047",
          "UBV M 27507",
          "V* V1357 Cyg",
          "X Cyg X-1",
          "[BM83] X1956+350",
          "2MASS J19582166+3512057",
          "SV* SVS 1808",
          "1RXS J195821.9+351156",
          "2E 1956.4+3503",
          "[MJD95] J195821.72+351205.9",
          "PBC J1958.3+3512",
          "SWIFT J1958.4+3510",
          "SWIFT J1958.3+3512",
          "1FGL J1958.9+3459",
          "uvby98 100226868",
          "WEB 17338",
          "Gaia DR2 2059383668236814720",
          "[AAA2018] FGL J1958.6+3510"
        ],
        "searchKey": "integral1116|hd226868|1xrs19564350|ppm83929|vv1357cyg|gaiadr32059383668236814720|xcygx1|h195735|sbc91193|swiftj195843510|krl2007b370|bd343815|als10678|hic98298|3a1956350|gcrv12319|1fglj195893459|ag351910|1m1956350|aaa2018fglj195863510|uvby98100226868|lsii358|4u195635|pbcj195833512|ubv17047|swiftj195833512|gsc0267800791|cgo548|ubvm27507|bm83x1956350|mcw770|web17338|2u195635|tyc26787911|1rxsj1958219351156|1h1956350|aavso195434|tic102604645|svsvs1808|2e195643503|3u195635|sbc7776|sao69181|gaiadr22059383668236814720|hip98298|gen100226868|mjd95j195821723512059|2e4306|h1956350|hilt849|gosg07134030701|2massj195821663512057|intref1001",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ROSE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.972687704332,
        "dec": 35.20160680908,
        "fluxV": 8.91,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "HD 226868",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959181486653441",
    "title": "Brightest Stars",
    "color": "YELLOW",
    "credit": "https://www.britannica.com/topic/list-of-brightest-stars-2231574",
    "imgURL": "/list_icons/bright.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "944241942959718401",
        "name": "Sun",
        "names": [
          "NAME Sun",
          "NAME Sol",
          "NAME Helios"
        ],
        "searchKey": "helios|sun|sol|sun",
        "solarSystemKey": "sun",
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/The_Sun_in_white_light.jpg/450px-The_Sun_in_white_light.jpg",
        "ra": null,
        "dec": null,
        "fluxV": -26.76,
        "sizeMajor": 32.2,
        "sizeMinor": 32.2,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "Mat\u00fa\u0161 Motlo",
        "description": "The Sun is the star at the center of the Solar System. It is a massive, hot ball of plasma, inflated and heated by energy produced by nuclear fusion reactions at its core. Part of this energy is emitted from its surface as light, ultraviolet, and infrared radiation, providing most of the energy for life on Earth. The Sun has been an object of veneration in many cultures. It has been a central subject for astronomical research since ancient times.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Sun"
      },
      {
        "id": "948636180193935361",
        "name": "Rigel",
        "names": [
          "HIP 24436",
          "TIC 231308237",
          "2MASS J05143226-0812060",
          "PLX 1191",
          "* bet Ori",
          "* 19 Ori",
          "* bet Ori A",
          "2E 1238",
          "AAVSO 0509-08",
          "ADS 3823 A",
          "BD-08 1063",
          "CCDM J05145-0812A",
          "CEL 579",
          "CSI-08 1063 1",
          "CSV 100463",
          "EM* MWC 487",
          "FK5 194",
          "GC 6410",
          "GCRV 3110",
          "GSC 05331-01752",
          "HD 34085",
          "HGAM 376",
          "HIC 24436",
          "HR 1713",
          "IDS 05097-0819 A",
          "IRAS 05121-0815",
          "IRC -10085",
          "JP11 1014",
          "MCW 291",
          "N30 1120",
          "NSV 1882",
          "PMC 90-93 140",
          "PPM 187839",
          "RAFGL 710",
          "ROT 749",
          "SACS 113",
          "SAO 131907",
          "SKY# 8263",
          "SV* ZI 362",
          "TD1 4253",
          "UBV 5016",
          "UBV M 10750",
          "V* bet Ori",
          "VDB 36",
          "[SC93b] 101",
          "ALS 14772",
          "PLX 1191.00",
          "1RXS J051431.5-081112",
          "2E 0512.1-0814",
          "EUVE J0514-08.1",
          "WDS J05145-0812A",
          "TYC 5331-1752-1",
          "PMSC 05097-0819A",
          "uvby98 100034085",
          "GN 05.04.0",
          "NAME Rigel",
          "WEB 4731"
        ],
        "searchKey": "iras051210815|gcrv3110|uvby98100034085|2e1238|emmwc487|fk5194|tyc533117521|rigel|19ori|sao131907|hd34085|td14253|vdb36|hgam376|irc10085|1rxsj0514315081112|ads3823a|wdsj051450812a|pmsc050970819a|2e051210814|ppm187839|hic24436|ids050970819a|euvej0514081|gsc0533101752|als14772|bd081063|ccdmj051450812a|gn05040|gc6410|ubv5016|hr1713|rot749|nsv1882|ubvm10750|web4731|csv100463|cel579|plx1191|n301120|sacs113|sky8263|svzi362|csi0810631|aavso050908|tic231308237|plx119100|rigel|jp111014|betori|vbetori|mcw291|sc93b101|rafgl710|2massj051432260812060|betoria|pmc9093140|hip24436",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.242297805555555,
        "dec": -8.20163836111111,
        "fluxV": 0.13,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* bet Ori",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "948744223158206465",
        "name": "Antares",
        "names": [
          "HIP 80763",
          "TIC 175934060",
          "PLX 3741",
          "* alf Sco",
          "* 21 Sco",
          "CD-26 11359",
          "CMC 184415",
          "CPD-26 5648",
          "CSI-26 11359 21",
          "Elia 2-74",
          "FK5 616",
          "GC 22157",
          "GCRV 9479",
          "GEN# +1.00148478",
          "GSC 06803-02158",
          "HIC 80763",
          "HR 6134",
          "IRAS 16262-2619",
          "IRC -30265",
          "N30 3686",
          "NAME Antares",
          "PMC 90-93 439",
          "PPM 265579",
          "RAFGL 1863",
          "ROT 2345",
          "SAO 184415",
          "SKY# 29714",
          "SRS 30616",
          "UBV M 21449",
          "CSI-26 11359 23",
          "TD1 19208",
          "UBV 21661",
          "2MASS J16292443-2625549",
          "Ced 132",
          "DG 141",
          "LBN 1108",
          "LBN 351.88+15.11",
          "GN 16.26.3",
          "VDB 107",
          "GEN# +1.00148479",
          "TYC 6803-2158-1",
          "ADS 10074 A",
          "CCDM J16294-2626A",
          "WDS J16294-2626A",
          "* alf Sco A",
          "V* alf Sco",
          "AAVSO 1623-26",
          "HD 148478",
          "WEB 13658",
          "IDS 16233-2613 A",
          "** GNT 1A",
          "WISEA J162924.49-262554.8"
        ],
        "searchKey": "n303686|pmc9093439|wdsj162942626a|tic175934060|21sco|sao184415|srs30616|iras162622619|irc30265|cpd265648|gcrv9479|tyc680321581|hic80763|alfscoa|hr6134|dg141|cmc184415|rafgl1863|sky29714|cd2611359|gsc0680302158|hip80763|fk5616|gen100148479|ads10074a|gen100148478|alfsco|rot2345|wiseaj162924492625548|antares|2massj162924432625549|ids162332613a|vdb107|ccdmj162942626a|gc22157|lbn351881511|web13658|lbn1108|ppm265579|csi261135923|antares|plx3741|td119208|valfsco|ubv21661|aavso162326|elia274|ubvm21449|ced132|hd148478|gnt1a|csi261135921|gn16263",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.49012769444445,
        "dec": -26.43200261111111,
        "fluxV": 0.91,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Sco",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "949096934846889985",
        "name": "Procyon",
        "names": [
          "GJ 280.0",
          "GJ 280 A",
          "GJ 280",
          "GES J07391749+0513163",
          "HIP 37279",
          "GALAH 141101003801339",
          "AP J07391805+0513298",
          "TIC 280310048",
          "PLX 1805",
          "SBC9 467",
          "LSPM J0739+0513",
          "ASCC 1030621",
          "USNO-B1.0 0952-00146563",
          "* 10 CMi",
          "* alf CMi",
          "1AXG J073918+0514",
          "1C 12.01",
          "2E 1849",
          "2RE J0739+051",
          "2RE J073918+051351",
          "AG+05 1072",
          "BD+05 1739",
          "CSI+05 1739 1",
          "CSI+05 1739 5",
          "CSV 100884",
          "Ci 20 432",
          "FAUST 1387",
          "FK5 291",
          "GC 10277",
          "GCRV 5099",
          "HD 61421",
          "HIC 37279",
          "HR 2943",
          "IRAS 07366+0520",
          "IRC +10170",
          "JP11 1576",
          "LFT 541",
          "LHS 233",
          "LTT 12053",
          "N30 1726",
          "NAME Procyon",
          "NLTT 18229",
          "NSV 3672",
          "PM 07367+0521",
          "PMC 90-93 212",
          "PPM 153068",
          "RAFGL 1161",
          "RE J0739+051",
          "RE J073917+051339",
          "ROT 1234",
          "SAO 115756",
          "SBC7 317",
          "SKY# 14100",
          "SPOCS 352",
          "SV* ZI 646",
          "TD1 10647",
          "UBV 7352",
          "USNO 825",
          "Zkh 106",
          "[HFE83] 530",
          "2MASS J07391805+0513298",
          "1ES 0736+05.3",
          "1RXS J073918.3+051332",
          "2E 0736.6+0520",
          "2EUVE J0739+05.2",
          "EUVE J0739+05.2",
          "RX J0739.3+0513",
          "[TSA98] J073919.85+051356.36",
          "TYC 187-2184-1",
          "AKARI-FIS-V1 J0739178+051322",
          "uvby98 100061421",
          "WEB 7381",
          "GEN# +1.00061421",
          "8pc 285.93A",
          "* alf CMi A",
          "** SHB 1A",
          "IDS 07341+0529 A",
          "WDS J07393+0514A",
          "CCDM J07393+0514A",
          "NAME Procyon A"
        ],
        "searchKey": "ascc1030621|uvby98100061421|sky14100|gesj073917490513163|hr2943|ltt12053|euvej0739052|td110647|rafgl1161|plx1805|2e073660520|sao115756|1rxsj0739183051332|nltt18229|web7381|sbc9467|alfcmi|gj280a|10cmi|gc10277|gen100061421|fk5291|faust1387|spocs352|wdsj073930514a|apj073918050513298|bd051739|gj2800|procyona|pm073670521|rxj073930513|ubv7352|sbc7317|1es0736053|2euvej0739052|procyon|2e1849|1c1201|akarifisv1j0739178051322|svzi646|gcrv5099|1axgj0739180514|csi0517391|lspmj07390513|lhs233|csv100884|iras073660520|hip37279|rej073917051339|ag051072|pmc9093212|rot1234|tic280310048|csi0517395|8pc28593a|ccdmj073930514a|ids073410529a|hic37279|alfcmia|galah141101003801339|irc10170|hfe83530|hd61421|tyc18721841|2rej0739051|shb1a|gj280|nsv3672|2rej073918051351|usnob10095200146563|lft541|n301726|procyon|jp111576|ci20432|2massj073918050513298|rej0739051|usno825|ppm153068|tsa98j0739198505135636|zkh106",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.655033194444445,
        "dec": 5.224987555555556,
        "fluxV": 0.37,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf CMi",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603335802882",
        "name": "Gacrux",
        "names": [
          "GJ 470",
          "HIP 61084",
          "TIC 272314138",
          "* gam Cru",
          "CCDM J12312-5706A",
          "CD-56 4504",
          "CPC 20 3840",
          "CPD-56 5272",
          "CSV 101285",
          "FK5 468",
          "GC 17052",
          "GCRV 7528",
          "GEN# +1.00108903",
          "HD 108903",
          "HIC 61084",
          "HR 4763",
          "IDS 12256-5633 A",
          "IRAS 12283-5650",
          "JP11 2226",
          "LTT 4752",
          "N30 2892",
          "NLTT 30973",
          "NSV 5672",
          "PPM 341058",
          "RAFGL 4150",
          "SAO 240019",
          "SKY# 23406",
          "SV* BV 839",
          "SV* ZI 944",
          "UBV 11239",
          "UBV M 18297",
          "[DS98] 174",
          "[L91b] 208",
          "2MASS J12310993-5706474",
          "WDS J12312-5707A",
          "NAME Gacrux",
          "TYC 8654-3422-1",
          "WEB 10875"
        ],
        "searchKey": "sky23406|iras122835650|ppm341058|cpc203840|ids122565633a|rafgl4150|n302892|gacrux|nltt30973|nsv5672|ubv11239|jp112226|svzi944|tic272314138|ubvm18297|gacrux|ltt4752|2massj123109935706474|hip61084|tyc865434221|ds98174|ccdmj123125706a|csv101285|web10875|l91b208|gc17052|fk5468|cd564504|svbv839|gamcru|gen100108903|sao240019|wdsj123125707a|gj470|hic61084|cpd565272|hr4763|gcrv7528|hd108903",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.519433225,
        "dec": -57.11321344444445,
        "fluxV": 1.64,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* gam Cru",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603617607682",
        "name": "Aldebaran",
        "names": [
          "GJ 9159 A",
          "GJ 171.1 A",
          "GES J04355530+1630309",
          "HIP 21421",
          "GALAH 141102003801353",
          "AP J04355524+1630331",
          "TIC 245873777",
          "2MASS J04355524+1630331",
          "PLX 1014",
          "LSPM J0435+1630",
          "ASCC 838711",
          "USNO-B1.0 1065-00048822",
          "* alf Tau",
          "* 87 Tau",
          "ADS 3321 A",
          "AG+16 400",
          "BD+16 629",
          "CCDM J04359+1631A",
          "CEL 436",
          "CSI+16 629 1",
          "CSV 6116",
          "FK5 168",
          "GC 5605",
          "GCRV 2689",
          "GEN# +1.00029139",
          "HD 29139",
          "HIC 21421",
          "HR 1457",
          "IDS 04302+1619 A",
          "IRAS 04330+1624",
          "IRC +20087",
          "JP11 898",
          "LTT 11462",
          "N30 962",
          "NAME Aldebaran",
          "NLTT 13584",
          "PMC 90-93 119",
          "PPM 120061",
          "RAFGL 601",
          "ROT 655",
          "SAO 94027",
          "SKY# 7128",
          "SRS 30168",
          "TD1 3349",
          "UBV 4426",
          "UBV M 10223",
          "V* alf Tau",
          "YZ 16 1233",
          "[B10] 1077",
          "TYC 1266-1416-1",
          "GSC 01266-01416",
          "WDS J04359+1631A",
          "GAT 1331",
          "** BU 550A",
          "** BU 1031A",
          "** STF 4002A",
          "uvby98 100029139",
          "WEB 4111"
        ],
        "searchKey": "gcrv2689|ids043021619a|ltt11462|n30962|gesj043555301630309|87tau|wdsj043591631a|hip21421|fk5168|b101077|lspmj04351630|aldebaran|cel436|sao94027|gsc0126601416|irc20087|csv6116|hr1457|rafgl601|bu1031a|plx1014|csi166291|gj1711a|apj043555241630331|bd16629|iras043301624|ubvm10223|bu550a|pmc9093119|gc5605|tyc126614161|ag16400|galah141102003801353|2massj043555241630331|srs30168|uvby98100029139|gat1331|hic21421|td13349|ads3321a|ccdmj043591631a|nltt13584|gj9159a|stf4002a|sky7128|ppm120061|rot655|ascc838711|tic245873777|ubv4426|gen100029139|aldebaran|yz161233|hd29139|alftau|jp11898|web4111|valftau|usnob10106500048822",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 4.598677519444444,
        "dec": 16.50930236111111,
        "fluxV": 0.86,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Tau",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603617705986",
        "name": "Arcturus",
        "names": [
          "GJ 541",
          "HIP 69673",
          "GALAH 150210005801171",
          "AP J14153968+1910558",
          "TIC 459832522",
          "PLX 3242",
          "LSPM J1415+1910",
          "NLTT 36756",
          "TYC 1472-1436-1",
          "ASCC 870215",
          "USNO-B1.0 1091-00221874",
          "* alf Boo",
          "* 16 Boo",
          "AAVSO 1411+19",
          "AG+19 1335",
          "BD+19 2777",
          "CSV 101433",
          "Ci 20 843",
          "FK5 526",
          "GC 19242",
          "GCRV 8341",
          "GEN# +1.00124897",
          "HD 124897",
          "HIC 69673",
          "HR 5340",
          "IRAS 14133+1925",
          "IRC +20270",
          "JP11 2486",
          "LFT 1084",
          "LHS 48",
          "LTT 14184",
          "N30 3229",
          "NAME Arcturus",
          "NSV 6603",
          "PM 14134+1927",
          "PM 14134-1927",
          "PMC 90-93 376",
          "PPM 130442",
          "RAFGL 1693",
          "ROT 2044",
          "SAO 100944",
          "SKY# 26028",
          "SRS 30526",
          "SV* ZI 1054",
          "TD1 17351",
          "UBV 12551",
          "UBV M 20076",
          "USNO 850",
          "YPAC 52",
          "[HFE83] 1018",
          "uvby98 100124897",
          "2MASS J14153968+1910558",
          "PLX 3242.00",
          "GAT 1341",
          "** HDS 2003A",
          "WDS J14157+1911A",
          "CCDM J14157+1911A",
          "WEB 12132"
        ],
        "searchKey": "16boo|bd192777|lhs48|td117351|tic459832522|hip69673|hr5340|ypac52|uvby98100124897|gat1341|rafgl1693|usnob10109100221874|irc20270|2massj141539681910558|hfe831018|jp112486|galah150210005801171|gen100124897|srs30526|tyc147214361|ltt14184|plx3242|rot2044|svzi1054|n303229|arcturus|ccdmj141571911a|aavso141119|pm141341927|sky26028|gj541|ppm130442|nltt36756|iras141331925|sao100944|pm141341927|gc19242|pmc9093376|arcturus|ag191335|hds2003a|alfboo|hd124897|web12132|lft1084|apj141539681910558|ascc870215|csv101433|hic69673|plx324200|wdsj141571911a|ci20843|ubvm20076|nsv6603|fk5526|lspmj14151910|usno850|ubv12551|gcrv8341",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 14.26102001944444,
        "dec": 19.18240916666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Boo",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603618525186",
        "name": "Betelgeuse",
        "names": [
          "HIP 27989",
          "GEN# +1.00039801",
          "TIC 269273552",
          "2MASS J05551028+0724255",
          "PLX 1362",
          "* 58 Ori",
          "* alf Ori",
          "AAVSO 0549+07",
          "ADS 4506 AP",
          "AG+07 681",
          "BD+07 1055",
          "CCDM J05552+0724AP",
          "CSI+07 1055 1",
          "EIC 108",
          "FK5 224",
          "GC 7451",
          "GCRV 3679",
          "GSC 00129-01873",
          "HD 39801",
          "HIC 27989",
          "HR 2061",
          "IRAS 05524+0723",
          "IRC +10100",
          "JP11 1282",
          "N30 1266",
          "NAME Betelgeuse",
          "PMC 90-93 162",
          "PPM 149643",
          "RAFGL 836",
          "SAO 113271",
          "SKY# 9804",
          "TD1 5587",
          "TYC 129-1873-1",
          "UBV 21314",
          "V* alf Ori",
          "YZ 7 2503",
          "[LFO93] 0552+07",
          "JCMTSE J055510.1+072426",
          "PLX 1362.00",
          "JCMTSF J055510.1+072426",
          "WDS J05552+0724Aa,Ab",
          "** H 639A",
          "WDS J05552+0724Aa,Ac",
          "WDS J05552+0724A",
          "** KAR 1",
          "WEB 5485"
        ],
        "searchKey": "plx136200|sky9804|gc7451|alfori|csi0710551|irc10100|wdsj055520724aaac|hd39801|bd071055|sao113271|td15587|iras055240723|betelgeuse|hic27989|hip27989|pmc9093162|valfori|tyc12918731|ads4506ap|lfo93055207|wdsj055520724a|jp111282|kar1|ppm149643|web5485|rafgl836|n301266|yz72503|gcrv3679|wdsj055520724aaab|betelgeuse|jcmtsfj0555101072426|2massj055510280724255|hr2061|h639a|ccdmj055520724ap|gsc0012901873|tic269273552|58ori|jcmtsej0555101072426|plx1362|ubv21314|eic108|ag07681|aavso054907|gen100039801|fk5224",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.919529266666667,
        "dec": 7.407064,
        "fluxV": 0.42,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Ori",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "951942641195417601",
        "name": "Capella",
        "names": [
          "GJ 194 B",
          "GJ 194 A",
          "GJ 194",
          "HIP 24608",
          "TIC 368326352",
          "CABS 51",
          "PLX 1187",
          "SBC9 306",
          "LSPM J0516+4559",
          "ASCC 317037",
          "2MASS J05164138+4559525",
          "* alf Aur",
          "* 13 Aur",
          "2E 0513.0+4556",
          "2E 1239",
          "2RE J051640+455955",
          "2RE J0516+455",
          "AAVSO 0509+45",
          "ADS 3841 AP",
          "AG+45 512",
          "BD+45 1077",
          "CCABS 42",
          "CCDM J05168+4559AP",
          "CEL 586",
          "CSI+45 1077 1",
          "CSV 100460",
          "Ci 20 321",
          "FK5 193",
          "GC 6427",
          "GCRV 3121",
          "H 0513+45",
          "HD 34029",
          "HIC 24608",
          "HR 1708",
          "IDS 05093+4554 AP",
          "IRAS 05130+4556",
          "IRC +50139",
          "JP11 1010",
          "LTT 11619",
          "N30 1121",
          "NLTT 14766",
          "NSV 1897",
          "PMC 90-93 139",
          "PPM 47925",
          "RAFGL 713",
          "RE J051640+455929",
          "RE J0516+455",
          "SAO 40186",
          "SBC7 214",
          "SKY# 8333",
          "SV* ZI 360",
          "TD1 4244",
          "UBV 5009",
          "VVO 236",
          "YPAC 23",
          "[HFE83] 366",
          "USNO-B1.0 1359-00140245",
          "1E 051259+4556.7",
          "1RXS J051642.2+460001",
          "2EUVE J0516+45.9",
          "EUVE J0516+45.9",
          "RX J0516.7+4559",
          "WDS J05167+4600Aa,Ab",
          "TYC 3358-3141-1",
          "PMSC 05093+4553AB",
          "** ANJ 1",
          "** FRH 1A",
          "WDS J05167+4600A",
          "CSI+45-05130",
          "[FS2003] 0255",
          "NAME Capella",
          "uvby98 100034029",
          "WEB 4762",
          "GEN# +1.00034029"
        ],
        "searchKey": "sbc7214|aavso050945|cabs51|lspmj05164559|1rxsj0516422460001|ubv5009|vvo236|13aur|tyc335831411|ccabs42|fs20030255|wdsj051674600a|2e051304556|2rej051640455955|ascc317037|fk5193|gj194|usnob10135900140245|gcrv3121|ads3841ap|2e1239|svzi360|tic368326352|gj194b|ccdmj051684559ap|rej051640455929|euvej0516459|pmsc050934553ab|pmc9093139|gc6427|sky8333|hfe83366|gj194a|uvby98100034029|hic24608|hip24608|alfaur|hd34029|ag45512|hr1708|1e05125945567|rxj051674559|n301121|bd451077|h051345|capella|csi4505130|sbc9306|cel586|frh1a|wdsj051674600aaab|2massj051641384559525|2euvej0516459|capella|anj1|ci20321|iras051304556|td14244|csi4510771|nltt14766|plx1187|rafgl713|web4762|ppm47925|nsv1897|irc50139|sao40186|csv100460|ypac23|gen100034029|2rej0516455|rej0516455|ids050934554ap|jp111010|ltt11619",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.278155197222222,
        "dec": 45.99799147222222,
        "fluxV": 0.08,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Aur",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936235769857",
        "name": "Canopus",
        "names": [
          "HIP 30438",
          "TIC 255559489",
          "2MASS J06235709-5241441",
          "PLX 1497",
          "* alf Car",
          "1AXG J062403-5241",
          "2E 1642",
          "CEL 1228",
          "CPC 19 2125",
          "CPD-52 914",
          "FK5 245",
          "GC 8302",
          "GCRV 4091",
          "GEN# +1.00045348",
          "HD 45348",
          "HIC 30438",
          "HR 2326",
          "IRAS 06228-5240",
          "JP11 1384",
          "N30 1391",
          "NAME Canopus",
          "PPM 335149",
          "ROT 1039",
          "SAO 234480",
          "SKY# 10936",
          "SRS 30245",
          "TD1 7115",
          "UBV 6431",
          "UBV M 12094",
          "uvby98 100045348",
          "PLX 1497.00",
          "1E 0622.8-5239",
          "1ES 0622-52.6",
          "1RXS J062357.2-524139",
          "2E 0622.8-5240",
          "2EUVE J0624-52.6",
          "EUVE J0624-52.6",
          "EXSS 0622.7-5240",
          "TYC 8534-2277-1",
          "WEB 6060"
        ],
        "searchKey": "hd45348|ubv6431|ubvm12094|1es0622526|hr2326|web6060|n301391|1e062285239|1axgj0624035241|td17115|rot1039|iras062285240|hic30438|2e1642|gc8302|plx149700|srs30245|gcrv4091|2euvej0624526|sao234480|gen100045348|fk5245|euvej0624526|2e062285240|cpc192125|cel1228|sky10936|plx1497|uvby98100045348|tyc853422771|canopus|canopus|hip30438|1rxsj0623572524139|jp111384|exss062275240|alfcar|tic255559489|2massj062357095241441|cpd52914|ppm335149",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 6.399197188888889,
        "dec": -52.69566138888889,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Car",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936419827713",
        "name": "Acrux",
        "names": [
          "HIP 60718",
          "CD-62 652",
          "LLNS 8229",
          "TIC 450568754",
          "SBC9 725",
          "* alf Cru",
          "CCDM J12266-6306AB",
          "CEL 4105",
          "CPD-62 2745",
          "CSI-62 2745 43",
          "HIC 60718",
          "IDS 12210-6233 AB",
          "JP11 2217",
          "UBV 11169",
          "AX J1226.6-6305",
          "WDS J12266-6306AB",
          "uvby98 100108248",
          "IRAS 12237-6249",
          "AKARI-IRC-V1 J1226360-630557",
          "GEN# +1.00108248",
          "SBC7 462",
          "EUVE J1226-63.0",
          "2MASS J12263615-6305571",
          "1RXS J122634.8-630554",
          "1ES 1223-62.8",
          "NAME Acrux"
        ],
        "searchKey": "2massj122636156305571|sbc9725|jp112217|cel4105|ubv11169|iras122376249|uvby98100108248|1es1223628|akariircv1j1226360630557|ids122106233ab|axj122666305|ccdmj122666306ab|alfcru|1rxsj1226348630554|gen100108248|acrux|hip60718|csi62274543|wdsj122666306ab|hic60718|cd62652|sbc7462|llns8229|tic450568754|euvej1226630|acrux|cpd622745",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.44330422777778,
        "dec": -63.09909286111111,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Cru",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936439685121",
        "name": "Deneb",
        "names": [
          "TIC 195554360",
          "PLX 4935",
          "* alf Cyg",
          "* 50 Cyg",
          "ADS 14172 A",
          "AG+45 1730",
          "ALS 11488",
          "BD+44 3541",
          "CCDM J20415+4517A",
          "CEL 5144",
          "CSI+44 3541 1",
          "EM* MWC 351",
          "FK5 777",
          "GC 28846",
          "GCRV 12971",
          "GEN# +1.00197345",
          "GSC 03574-03347",
          "HD 197345",
          "HIC 102098",
          "HIP 102098",
          "HR 7924",
          "IDS 20380+4455 A",
          "IRC +50337",
          "JP11 3264",
          "LS III +45 15",
          "MCW 940",
          "N30 4579",
          "PMC 90-93 546",
          "PPM 60323",
          "RAFGL 2633",
          "ROT 3019",
          "SAO 49941",
          "SKY# 39204",
          "SV* ZI 1938",
          "TD1 27159",
          "TYC 3574-3347-1",
          "UBV 17953",
          "UBV M 25099",
          "V* alf Cyg",
          "[HFE83] 1387",
          "[KW97] 48-33",
          "2MASS J20412592+4516491",
          "PLX 4935.00",
          "WDS J20414+4517A",
          "NAME Deneb",
          "uvby98 100197345",
          "WEB 18466"
        ],
        "searchKey": "fk5777|jp113264|als11488|hd197345|hr7924|ccdmj204154517a|ppm60323|sao49941|rafgl2633|csi4435411|td127159|valfcyg|2massj204125924516491|ads14172a|wdsj204144517a|deneb|sky39204|50cyg|tyc357433471|ids203804455a|kw974833|gcrv12971|hic102098|hfe831387|alfcyg|bd443541|cel5144|n304579|ubv17953|tic195554360|lsiii4515|pmc9093546|deneb|gc28846|hip102098|uvby98100197345|rot3019|emmwc351|plx493500|ubvm25099|web18466|svzi1938|gsc0357403347|irc50337|mcw940|ag451730|gen100197345|plx4935",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.69053198333333,
        "dec": 45.28033880555555,
        "fluxV": 1.25,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Cyg",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936459968513",
        "name": "Mimosa",
        "names": [
          "HIP 62434",
          "TIC 405567821",
          "SBC9 2446",
          "* bet Cru",
          "CCDM J12478-5940A",
          "CEL 4154",
          "CPC 20 3932",
          "CPD-59 4451",
          "CSI-59 4451 41",
          "FK5 481",
          "GC 17374",
          "GCRV 7661",
          "GEN# +1.00111123",
          "GSC 08659-03107",
          "HD 111123",
          "HGAM 2011",
          "HIC 62434",
          "HR 4853",
          "IDS 12419-5908 A",
          "JP11 2298",
          "N30 2947",
          "NAME Mimosa",
          "PPM 341305",
          "ROT 1887",
          "SAO 240259",
          "SKY# 23820",
          "TD1 16475",
          "TYC 8659-3107-1",
          "UBV 11480",
          "UBV M 18628",
          "V* bet Cru",
          "[B10] 3328",
          "2MASS J12474326-5941194",
          "ALS 14974",
          "PLX 2941.01",
          "EUVE J1247-59.6",
          "1RXS J124742.5-594125",
          "WDS J12477-5941A",
          "IRAS 12448-5925",
          "AKARI-IRC-V1 J1247432-594119",
          "uvby98 100111123",
          "WEB 11084",
          "[KCS2016] J124743.26-594119.5",
          "CXOU J124743.8-594121"
        ],
        "searchKey": "mimosa|td116475|rot1887|gen100111123|plx294101|betcru|hip62434|tic405567821|1rxsj1247425594125|wdsj124775941a|gc17374|hr4853|ppm341305|sbc92446|gcrv7661|cel4154|akariircv1j1247432594119|cpd594451|n302947|ccdmj124785940a|ubv11480|hic62434|2massj124743265941194|gsc0865903107|sao240259|tyc865931071|hd111123|kcs2016j124743265941195|iras124485925|sky23820|ubvm18628|vbetcru|csi59445141|ids124195908a|mimosa|cxouj1247438594121|uvby98100111123|b103328|als14974|cpc203932|fk5481|jp112298|euvej1247596|web11084|hgam2011",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ROSE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.79535243611111,
        "dec": -59.688772,
        "fluxV": 1.25,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* bet Cru",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936461901825",
        "name": "Agena",
        "names": [
          "HIP 68702",
          "WISEA J140349.33-602223.1",
          "TIC 328329822",
          "PLX 3198",
          "* bet Cen",
          "2E 3163",
          "CD-59 5054",
          "CEL 4298",
          "CPC 20.1 4149",
          "CPD-59 5365",
          "FK5 518",
          "GC 18971",
          "GCRV 8251",
          "HD 122451",
          "HIC 68702",
          "HR 5267",
          "IRAS 14002-6008",
          "N30 3189",
          "NAME Agena",
          "PPM 360515",
          "ROT 2025",
          "SACS 302",
          "SAO 252582",
          "SKY# 25751",
          "TD1 17207",
          "NAME Hadar",
          "UBV 12426",
          "V* bet Cen",
          "[B10] 3615",
          "2MASS J14034938-6022226",
          "ALS 14992",
          "PLX 3198.00",
          "1RXS J140348.7-602242",
          "2E 1400.2-6008",
          "EUVE J1403-60.3",
          "WEB 12025",
          "GEN# +1.00122451",
          "uvby98 100122451",
          "* bet Cen A",
          "TYC 9005-3919-1",
          "** VOU 31A",
          "WDS J14038-6022A",
          "CCDM J14038-6022A",
          "IDS 13568-5953 A",
          "WISE J140349.31-602222.3",
          "** RBT 1",
          "WDS J14038-6022Aa,Ab"
        ],
        "searchKey": "wisej140349316022223|td117207|ppm360515|betcen|hadar|agena|cpc2014149|euvej1403603|betcena|cd595054|1rxsj1403487602242|gen100122451|cel4298|uvby98100122451|vbetcen|ubv12426|sacs302|web12025|ids135685953a|hip68702|tyc900539191|wdsj140386022aaab|rot2025|iras140026008|sky25751|tic328329822|fk5518|sao252582|rbt1|n303189|2e3163|plx3198|gc18971|agena|vou31a|b103615|plx319800|ccdmj140386022a|cpd595365|2massj140349386022226|hic68702|2e140026008|hr5267|als14992|wdsj140386022a|hd122451|wiseaj140349336022231|gcrv8251",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 14.06372370833333,
        "dec": -60.37303516666667,
        "fluxV": 0.58,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* bet Cen",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936511610881",
        "name": "Achernar",
        "names": [
          "HIP 7588",
          "TIC 230981971",
          "TYC 8478-1395-1",
          "2MASS J01374284-5714119",
          "PLX 344",
          "[JE82] 39",
          "CD-57 316",
          "GSC 08478-01395",
          "NSV 15353",
          "* alf Eri",
          "CPC 20 447",
          "CPD-57 334",
          "EM* CDS 176",
          "FK5 54",
          "GC 1979",
          "GCRV 916",
          "GEN# +1.00010144",
          "HD 10144",
          "HIC 7588",
          "HR 472",
          "IRAS 01358-5729",
          "JP11 517",
          "N30 335",
          "PPM 331199",
          "ROT 233",
          "SAO 232481",
          "SKY# 2444",
          "TD1 938",
          "UBV 1700",
          "UBV M 8330",
          "uvby98 100010144",
          "ALS 16724",
          "PLX 344.00",
          "2XMM J013742.5-571413",
          "V* alf Eri",
          "NAME Achernar",
          "WEB 1623"
        ],
        "searchKey": "als16724|plx344|ppm331199|cd57316|hd10144|achernar|ubvm8330|je8239|ubv1700|rot233|iras013585729|jp11517|gsc0847801395|gcrv916|emcds176|cpd57334|fk554|hr472|uvby98100010144|gc1979|valferi|tyc847813951|tic230981971|achernar|2xmmj0137425571413|hic7588|nsv15353|n30335|sao232481|plx34400|2massj013742845714119|td1938|sky2444|alferi|cpc20447|web1623|gen100010144|hip7588",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 1.628568188888889,
        "dec": -57.23675280555555,
        "fluxV": 0.46,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Eri",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936566824961",
        "name": "Shaula",
        "names": [
          "HIP 85927",
          "TIC 465088681",
          "* 35 Sco",
          "* lam Sco",
          "CCDM J17336-3706A",
          "CD-37 11673",
          "CPC 18 8791",
          "CPD-37 7265",
          "CSI-37 11673 21",
          "FK5 652",
          "GC 23769",
          "GCRV 10136",
          "GEN# +1.00158926",
          "GSC 07388-01093",
          "HD 158926",
          "HIC 85927",
          "HR 6527",
          "IDS 17268-3702 A",
          "IRAS 17302-3704",
          "JP11 2862",
          "N30 3905",
          "NAME Shaula",
          "PMC 90-93 462",
          "PPM 296488",
          "ROT 2459",
          "SACS 383",
          "SAO 208954",
          "SKY# 31695",
          "SRS 30652",
          "TD1 20577",
          "TYC 7388-1093-1",
          "UBV M 22240",
          "UBV 14937",
          "V* lam Sco",
          "[B10] 4439",
          "[SC95] ZET 456",
          "[SC96] Mis 521",
          "[SC96] GC 99",
          "2MASS J17333652-3706135",
          "ALS 15082",
          "1E 1730.2-3704",
          "1RXS J173336.7-370604",
          "EUVE J1733-37.1",
          "WDS J17336-3706A",
          "WDS J17336-3706Aa,Ab",
          "** TNG 1",
          "** SEE 334A",
          "** DUN 218A",
          "WD 1730-370",
          "uvby98 100158926",
          "WEB 14501"
        ],
        "searchKey": "35sco|td120577|srs30652|tic465088681|ubv14937|2massj173336523706135|ubvm22240|sacs383|ids172683702a|tyc738810931|sao208954|rot2459|1rxsj1733367370604|uvby98100158926|hip85927|sc95zet456|sc96mis521|1e173023704|web14501|gsc0738801093|ppm296488|tng1|gcrv10136|dun218a|pmc9093462|shaula|iras173023704|wdsj173363706aaab|hic85927|als15082|see334a|hr6527|lamsco|wdsj173363706a|jp112862|cd3711673|sky31695|b104439|ccdmj173363706a|gc23769|cpd377265|csi371167321|shaula|cpc188791|hd158926|n303905|wd1730370|euvej1733371|sc96gc99|gen100158926|fk5652|vlamsco",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.56014447777778,
        "dec": -37.10382355555556,
        "fluxV": 1.63,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* lam Sco",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936571248641",
        "name": "Altair",
        "names": [
          "GJ 768",
          "HIP 97649",
          "TIC 70257116",
          "PLX 4665",
          "LSPM J1950+0852",
          "ASCC 1075038",
          "USNO-B1.0 0988-00511792",
          "* alf Aql",
          "* 53 Aql",
          "2E 4294",
          "8pc 194.44",
          "ADS 13009 A",
          "AG+08 2636",
          "BD+08 4236",
          "CCDM J19508+0852A",
          "CSI+08 4236 1",
          "Ci 20 1169",
          "FK5 745",
          "GAT 549",
          "GC 27470",
          "GCRV 12193",
          "GEN# +1.00187642",
          "HD 187642",
          "HIC 97649",
          "HR 7557",
          "IDS 19459+0836 A",
          "IRAS 19483+0844",
          "IRC +10441",
          "JP11 3142",
          "LFT 1499",
          "LHS 3490",
          "LTT 15795",
          "N30 4388",
          "NAME Altair",
          "NLTT 48314",
          "NSV 24910",
          "PM 19484+0844",
          "PMC 90-93 530",
          "PPM 168779",
          "RAFGL 2463",
          "ROT 2857",
          "SAO 125122",
          "SKY# 37134",
          "TD1 25537",
          "UBV M 24205",
          "UBV 16885",
          "USNO 891",
          "Zkh 297",
          "uvby98 100187642",
          "2MASS J19504698+0852060",
          "PLX 4665.00",
          "1ES 1948+08.7",
          "2E 1948.3+0844",
          "1RXS J195047.0+085159",
          "WDS J19508+0852A",
          "TYC 1058-3399-1",
          "GSC 01058-03399",
          "V* alf Aql",
          "AKARI-FIS-V1 J1950472+085209",
          "2XMMi J195047.1+085208",
          "WEB 17173"
        ],
        "searchKey": "gsc0105803399|1es1948087|53aql|hip97649|lhs3490|ubvm24205|valfaql|usno891|8pc19444|lft1499|uvby98100187642|web17173|nsv24910|pmc9093530|usnob10098800511792|zkh297|nltt48314|ppm168779|gcrv12193|ag082636|ci201169|fk5745|plx4665|gat549|ltt15795|hic97649|td125537|tyc105833991|2xmmij1950471085208|ubv16885|bd084236|2e194830844|ascc1075038|2massj195046980852060|akarifisv1j1950472085209|gj768|gen100187642|rot2857|jp113142|tic70257116|rafgl2463|alfaql|ads13009a|hd187642|plx466500|2e4294|sky37134|hr7557|pm194840844|gc27470|ccdmj195080852a|iras194830844|lspmj19500852|irc10441|csi0842361|n304388|wdsj195080852a|altair|sao125122|altair|ids194590836a|1rxsj1950470085159",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.84638848611111,
        "dec": 8.868321194444444,
        "fluxV": 0.76,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Aql",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936571281409",
        "name": "Adhara",
        "names": [
          "HIP 33579",
          "** CPO 7A",
          "GEN# +1.00052089",
          "TIC 63198307",
          "* eps CMa",
          "* 21 CMa",
          "1AXG J065840-2857",
          "2RE J065836-285810",
          "2RE J0658-285",
          "ADS 5654 A",
          "CCDM J06586-2858A",
          "CD-28 3666",
          "CEL 1473",
          "CPD-28 1586",
          "CSI-28 3666 21",
          "FK5 268",
          "GC 9188",
          "GCRV 4590",
          "GSC 06535-03619",
          "HD 52089",
          "HIC 33579",
          "HR 2618",
          "IDS 06547-2850 A",
          "IRC -30071",
          "JP11 1473",
          "MCW 492",
          "N30 1515",
          "PMC 90-93 192",
          "PPM 251347",
          "RE J065836-285811",
          "RE J0658-285",
          "ROT 1127",
          "SACS 151",
          "SAO 172676",
          "SKY# 12435",
          "SRS 30268",
          "TD1 8751",
          "TYC 6535-3619-1",
          "UBV 6891",
          "UBV M 12697",
          "ALS 14800",
          "1RXS J065837.3-285814",
          "2EUVE J0658-28.9",
          "EUVE J0658-28.9",
          "RX J0658.6-2858",
          "2MASS J06583754-2858197",
          "2XMM J065837.5-285820",
          "WDS J06586-2858A",
          "uvby98 100052089",
          "NAME Adhara",
          "WEB 6753",
          "IRAS 06566-2854",
          "AKARI-IRC-V1 J0658375-285820"
        ],
        "searchKey": "2euvej0658289|uvby98100052089|epscma|tyc653536191|sky12435|cpo7a|rxj065862858|ppm251347|adhara|21cma|ubv6891|srs30268|hip33579|euvej0658289|sao172676|1rxsj0658373285814|gsc0653503619|cel1473|td18751|2xmmj0658375285820|csi28366621|iras065662854|ccdmj065862858a|akariircv1j0658375285820|1axgj0658402857|2massj065837542858197|adhara|tic63198307|irc30071|cpd281586|wdsj065862858a|ads5654a|fk5268|hic33579|web6753|rej0658285|ubvm12697|pmc9093192|gc9188|hd52089|jp111473|ids065472850a|gcrv4590|2rej065836285810|hr2618|als14800|rej065836285811|rot1127|cd283666|mcw492|gen100052089|sacs151|n301515|2rej0658285",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 6.977096877777778,
        "dec": -28.97208616666666,
        "fluxV": 1.5,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* eps CMa",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936577015809",
        "name": "Pollux",
        "names": [
          "GJ 286",
          "HIP 37826",
          "TIC 423088367",
          "PLX 1826",
          "LSPM J0745+2801",
          "ASCC 674757",
          "USNO-B1.0 1180-00191173",
          "* 78 Gem",
          "* bet Gem",
          "2E 1872",
          "ADS 6335 A",
          "AG+28 833",
          "BD+28 1463",
          "CCDM J07454+2802A",
          "CSI+28 1463 1",
          "CSV 100892",
          "CTI 074434.0+280331",
          "Ci 20 437",
          "FK5 295",
          "GC 10438",
          "GCRV 5166",
          "HD 62509",
          "HIC 37826",
          "HR 2990",
          "IDS 07392+2816 A",
          "IRAS 07422+2808",
          "IRC +30194",
          "JP11 1588",
          "LFT 548",
          "LHS 1945",
          "LTT 12065",
          "N30 1758",
          "NLTT 18379",
          "NSV 3712",
          "PM 07423+2809",
          "PMC 90-93 216",
          "PPM 97924",
          "RAFGL 1183",
          "ROT 1249",
          "SAO 79666",
          "SKY# 14351",
          "SRS 30295",
          "SV* ZI 656",
          "TD1 10866",
          "UBV 7418",
          "UBV M 13566",
          "2MASS J07451891+2801340",
          "PLX 1826.00",
          "2E 0742.2+2808",
          "1RXS J074519.3+280123",
          "WDS J07453+2802A",
          "TYC 1920-2194-1",
          "uvby98 100062509",
          "NAME Pollux",
          "WEB 7472",
          "** BU 580A",
          "GEN# +1.00062509"
        ],
        "searchKey": "n301758|2e074222808|rafgl1183|bd281463|csi2814631|jp111588|ads6335a|gen100062509|ppm97924|srs30295|sao79666|usnob10118000191173|hr2990|lft548|uvby98100062509|pollux|ascc674757|gj286|ccdmj074542802a|gcrv5166|ubvm13566|pm074232809|ci20437|hip37826|pollux|web7472|ag28833|2massj074518912801340|lspmj07452801|sky14351|ubv7418|gc10438|hic37826|td110866|tic423088367|78gem|csv100892|ids073922816a|nsv3712|hd62509|plx1826|wdsj074532802a|iras074222808|1rxsj0745193280123|betgem|tyc192021941|bu580a|irc30194|svzi656|2e1872|ltt12065|cti0744340280331|rot1249|nltt18379|pmc9093216|plx182600|lhs1945|fk5295",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.755263852777778,
        "dec": 28.02619888888889,
        "fluxV": 1.14,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* bet Gem",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936720900097",
        "name": "Dog Star",
        "names": [
          "GJ 244 A",
          "HIP 32349",
          "TIC 322899250",
          "* alf CMa",
          "HR 2491",
          "LHS 219",
          "GC 8833",
          "SAO 151881",
          "* 9 CMa",
          "BD-16 1591",
          "HD 48915",
          "NAME Dog Star",
          "NSV 17173",
          "TD1 8027",
          "UBV 6709",
          "IRC -20105",
          "RAFGL 1007",
          "IRAS 06429-1639",
          "2MASS J06450887-1642566",
          "NAME Sirius",
          "HIC 32349",
          "IRAS S06429-1639",
          "IRAS S06430-1639",
          "AKARI-FIS-V1 J0645085-164258",
          "WEB 6525",
          "** AGC 1A",
          "PLX 1577",
          "SBC9 416",
          "* alf CMa A",
          "8pc 379.21A",
          "ADS 5423 A",
          "CCDM J06451-1643A",
          "CEL 1368",
          "CSI-16 1591 1",
          "Ci 20 396",
          "FK5 257",
          "GAT 474",
          "GCRV 4392",
          "GEN# +1.00048915A",
          "HGAM 556",
          "IDS 06408-1635 A",
          "JP11 1425",
          "LFT 486",
          "LPM 243",
          "LTT 2638",
          "N30 1470",
          "NAME Sirius A",
          "NLTT 16953",
          "PM 06430-1639A",
          "PMC 90-93 186",
          "PPM 217626",
          "ROT 1088",
          "SBC7 288",
          "SKY# 11855",
          "UBV M 12413",
          "USNO 816",
          "Zkh 91",
          "uvby98 100048915 A",
          "WDS J06451-1643A",
          "TYC 5949-2777-1",
          "Renson 13090",
          "BD-16 1591A",
          "HD 48915A"
        ],
        "searchKey": "csi1615911|tic322899250|cel1368|ids064081635a|8pc37921a|lhs219|ccdmj064511643a|bd161591a|iras064291639|hic32349|plx1577|9cma|pm064301639a|akarifisv1j0645085164258|gc8833|ubvm12413|rafgl1007|nsv17173|ltt2638|sbc7288|irass064301639|agc1a|web6525|td18027|ads5423a|usno816|wdsj064511643a|hip32349|irass064291639|alfcmaa|hgam556|nltt16953|hd48915|hr2491|uvby98100048915a|rot1088|alfcma|sirius|tyc594927771|gat474|jp111425|fk5257|zkh91|hd48915a|renson13090|irc20105|2massj064508871642566|ci20396|ppm217626|gj244a|sbc9416|gen100048915a|dogstar|lft486|lpm243|sky11855|siriusa|gcrv4392|bd161591|pmc9093186|ubv6709|dogstar|n301470|sao151881",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "CYAN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 6.752477022222222,
        "dec": -16.71611586111111,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf CMa",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936730370049",
        "name": "Regulus",
        "names": [
          "GJ 9316 A",
          "HIP 49669",
          "AP J10082229+1158018",
          "TIC 357348164",
          "PLX 2384",
          "LSPM J1008+1158",
          "ASCC 949713",
          "USNO-B1.0 1019-00203254",
          "* alf Leo",
          "* 32 Leo",
          "2E 2231",
          "ADS 7654 A",
          "AG+12 1199",
          "BD+12 2149",
          "CCDM J10083+1159A",
          "CSI+12 2149 1",
          "CSV 101099",
          "FK5 380",
          "GC 13926",
          "GCRV 6417",
          "GEN# +1.00087901A",
          "HD 87901",
          "HGAM 596",
          "HIC 49669",
          "HR 3982",
          "IRAS 10056+1212",
          "IRC +10226",
          "JP11 1896",
          "LTT 12716",
          "N30 2414",
          "NAME Regulus",
          "NLTT 23490",
          "NSV 4750",
          "PMC 90-93 274",
          "PPM 127140",
          "RAFGL 4771S",
          "ROT 1536",
          "SAO 98967",
          "SKY# 19511",
          "SRS 30380",
          "SV* ZI 793",
          "TD1 14585",
          "UBV 9452",
          "UBV M 15960",
          "YPAC 43",
          "[HFE83] 708",
          "uvby98 100087901 A",
          "2MASS J10082229+1158018",
          "WDS J10084+1158A",
          "2E 1005.5+1213",
          "TYC 833-1381-1",
          "PMSC 10030+1227A",
          "** STF 5006A",
          "WEB 9160"
        ],
        "searchKey": "regulus|2e100551213|2e2231|ag121199|jp111896|ypac43|stf5006a|hip49669|fk5380|svzi793|ads7654a|sky19511|tic357348164|nltt23490|lspmj10081158|web9160|gj9316a|hfe83708|rafgl4771s|hic49669|srs30380|iras100561212|irc10226|sao98967|2massj100822291158018|ascc949713|tyc83313811|pmsc100301227a|gc13926|pmc9093274|rot1536|csv101099|ppm127140|usnob10101900203254|uvby98100087901a|ccdmj100831159a|ubv9452|nsv4750|csi1221491|hd87901|hgam596|ubvm15960|gcrv6417|wdsj100841158a|bd122149|td114585|plx2384|alfleo|n302414|ltt12716|apj100822291158018|hr3982|gen100087901a|regulus|32leo",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 10.13953083055556,
        "dec": 11.96720877777778,
        "fluxV": 1.4,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Leo",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158936869208065",
        "name": "Fomalhaut",
        "names": [
          "GJ 881.0",
          "GJ 881",
          "TIC 47552789",
          "PLX 5565",
          "* alf PsA",
          "* 24 PsA",
          "8pc 130.08",
          "CD-30 19370",
          "CEL 5549",
          "CPC 17 12409",
          "CPD-30 6685",
          "FK5 867",
          "GC 32000",
          "GCRV 14409",
          "GEN# +1.00216956",
          "HD 216956",
          "HIC 113368",
          "HIP 113368",
          "HR 8728",
          "IRAS 22549-2953",
          "IRC -30458",
          "JP11 3556",
          "LTT 9292",
          "N30 5050",
          "NLTT 55380",
          "NSV 14372",
          "PMC 90-93 611",
          "PPM 274426",
          "RAFGL 2995",
          "ROT 3340",
          "SAO 191524",
          "SKY# 43608",
          "SRS 30867",
          "TD1 29476",
          "UBV 19672",
          "UBV M 26732",
          "Zkh 345",
          "[PS72] 89",
          "uvby98 100216956",
          "JCMTSE J225739.4-293726",
          "JCMTSF J225738.9-293714",
          "PLX 5565.00",
          "WDS J22577-2937A",
          "** SHY 106A",
          "2MASS J22573901-2937193",
          "NAME Fomalhaut",
          "** MAM 1A",
          "H-ATLAS J225739.6-293730",
          "TYC 6977-1267-1",
          "[DML87] 545",
          "WEB 20167",
          "* 79 Aqr"
        ],
        "searchKey": "79aqr|plx556500|hatlasj2257396293730|alfpsa|shy106a|gen100216956|hd216956|rafgl2995|gj881|ubv19672|sao191524|ubvm26732|hr8728|gcrv14409|fk5867|cpd306685|fomalhaut|n305050|jcmtsej2257394293726|sky43608|gc32000|cd3019370|mam1a|ltt9292|jp113556|pmc9093611|2massj225739012937193|iras225492953|tyc697712671|tic47552789|fomalhaut|ppm274426|srs30867|gj8810|dml87545|8pc13008|web20167|nsv14372|wdsj225772937a|hip113368|nltt55380|hic113368|plx5565|rot3340|uvby98100216956|zkh345|ps7289|24psa|cel5549|irc30458|jcmtsfj2257389293714|td129476|cpc1712409",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 22.96084618055556,
        "dec": -29.62223702777778,
        "fluxV": 1.16,
        "sizeMajor": 0.212,
        "sizeMinor": null,
        "sizeAngle": 251.0,
        "simbadName": "* alf PsA",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158937208520705",
        "name": "Vega",
        "names": [
          "GJ 721.0",
          "GJ 721",
          "HIP 91262",
          "TIC 157587146",
          "PLX 4293",
          "LSPM J1836+3847",
          "ASCC 507896",
          "2MASS J18365633+3847012",
          "USNO-B1.0 1287-00305764",
          "* alf Lyr",
          "* 3 Lyr",
          "8pc 128.93",
          "ADS 11510 A",
          "AG+38 1711",
          "BD+38 3238",
          "CCDM J18369+3847A",
          "CEL 4636",
          "CSI+38 3238 1",
          "CSV 101745",
          "FK5 699",
          "GC 25466",
          "GCRV 11085",
          "GEN# +1.00172167",
          "HD 172167",
          "HGAM 706",
          "HIC 91262",
          "HR 7001",
          "IDS 18336+3841 A",
          "IRAS 18352+3844",
          "IRC +40322",
          "JP11 2999",
          "LTT 15486",
          "N30 4138",
          "NAME Vega",
          "NLTT 46746",
          "NSV 11128",
          "PMC 90-93 496",
          "PPM 81558",
          "RAFGL 2208",
          "ROT 2633",
          "SAO 67174",
          "SKY# 34103",
          "TD1 22883",
          "UBV 15842",
          "UBV M 23118",
          "USNO 882",
          "V* alf Lyr",
          "Zkh 277",
          "[HFE83] 1223",
          "PLX 4293.00",
          "1E 183515+3844.3",
          "EUVE J1836+38.7",
          "WDS J18369+3846A",
          "TYC 3105-2070-1",
          "GAT 1285",
          "JCMTSF J183656.4+384709",
          "JCMTSE J183656.4+384709",
          "IRAS F18352+3844",
          "AKARI-IRC-V1 J1836564+384703",
          "WISE J183656.49+384703.9",
          "EQ 183456.7+384615.4",
          "uvby98 100172167",
          "WEB 15681"
        ],
        "searchKey": "8pc12893|ag381711|zkh277|ascc507896|jcmtsfj1836564384709|fk5699|1e18351538443|akariircv1j1836564384703|vega|irasf183523844|tic157587146|irc40322|gcrv11085|gj7210|usno882|ubvm23118|lspmj18363847|rot2633|ubv15842|hfe831223|nsv11128|uvby98100172167|web15681|ads11510a|vega|gc25466|bd383238|usnob10128700305764|hr7001|n304138|ccdmj183693847a|gen100172167|sky34103|eq18345673846154|alflyr|ltt15486|jp112999|ids183363841a|euvej1836387|ppm81558|tyc310520701|wdsj183693846a|csv101745|2massj183656333847012|cel4636|gj721|pmc9093496|3lyr|jcmtsej1836564384709|plx4293|wisej183656493847039|csi3832381|td122883|hd172167|hic91262|nltt46746|hgam706|iras183523844|rafgl2208|sao67174|valflyr|hip91262|plx429300|gat1285",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.61564898611111,
        "dec": 38.78368894444444,
        "fluxV": 0.03,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Lyr",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158937382944769",
        "name": "Spica",
        "names": [
          "HIP 65474",
          "TIC 178999156",
          "PLX 3063",
          "SBC9 766",
          "IRAS 13225-1054",
          "AKARI-IRC-V1 J1325115-110940",
          "* alf Vir",
          "* 67 Vir",
          "2E 3039",
          "2RE J132508-111117",
          "2RE J1325-111",
          "BD-10 3672",
          "CCDM J13252-1109A",
          "CSI-10 3672 1",
          "FK5 498",
          "GC 18144",
          "GCRV 7963",
          "GEN# +1.00116658",
          "GSC 05547-01518",
          "HD 116658",
          "HGAM 623",
          "HIC 65474",
          "HR 5056",
          "IDS 13200-1038 A",
          "IRC -10286",
          "JP11 2392",
          "MCW 553",
          "N30 3066",
          "NAME Spica",
          "PMC 90-93 354",
          "PPM 227262",
          "RAFGL 1622",
          "RBS 1263",
          "RE J132512-110856",
          "RE J1325-110",
          "ROT 1963",
          "SACS 290",
          "SAO 157923",
          "SBC7 487",
          "SKY# 24751",
          "TD1 16831",
          "TYC 5547-1518-1",
          "UBV 12103",
          "UBV M 19362",
          "V* alf Vir",
          "2MASS J13251158-1109404",
          "ALS 14813",
          "1ES 1322-10.8",
          "1RXS J132511.6-110932",
          "2E 1322.5-1053",
          "EUVE J1325-11.1",
          "RX J1325.2-1109",
          "WDS J13252-1110A",
          "PMSC 13199-1038Aabcd",
          "uvby98 100116658",
          "WEB 11556"
        ],
        "searchKey": "irc10286|pmc9093354|hic65474|spica|n303066|rxj132521109|pmsc131991038aabcd|hgam623|iras132251054|ccdmj132521109a|rej132512110856|sao157923|gsc0554701518|2e3039|akariircv1j1325115110940|gc18144|rafgl1622|rot1963|td116831|2e132251053|ubv12103|wdsj132521110a|rej1325110|67vir|1es1322108|euvej1325111|sbc7487|hr5056|mcw553|spica|alfvir|tic178999156|valfvir|1rxsj1325116110932|hip65474|ppm227262|2massj132511581109404|2rej1325111|sky24751|tyc554715181|bd103672|jp112392|sbc9766|2rej132508111117|web11556|sacs290|fk5498|gen100116658|rbs1263|als14813|csi1036721|hd116658|gcrv7963|ubvm19362|plx3063|uvby98100116658|ids132001038a",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 13.41988315833333,
        "dec": -11.16131947222222,
        "fluxV": 0.97,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Vir",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158937544491009",
        "name": "* alf Cen",
        "names": [
          "2XMM J143933.0-605008",
          "GES J14392972-6049560",
          "* alf Cen",
          "2E 3308",
          "2RE J143941-605000",
          "2RE J1439-605",
          "CCDM J14396-6050AB",
          "CPD-60 5483",
          "CSI-60 5483 43",
          "Ci 20 875",
          "FK5 538",
          "GC 19728",
          "GCRV 8519",
          "IDS 14328-6025 AB",
          "LPM 534",
          "RE J1439-605",
          "RE J143944-605008",
          "1E 143556-6037.3",
          "1ES 1435-60.6",
          "2E 1435.9-6037",
          "2EUVE J1439-60.8",
          "EUVE J1439-60.8",
          "[TSA98] J143948.42-605021.66",
          "WDS J14396-6050AB",
          "RX J1439.5-6050",
          "IRAS 14359-6037",
          "SAO 252838",
          "1RXS J143940.4-605020",
          "PM 14362-6038",
          "WDS J14403-6051AB",
          "** RHD 1",
          "1E 143555-6037.6",
          "CD-60 5293",
          "** LDS 494AB",
          "uvby98 100128620"
        ],
        "searchKey": "fk5538|rej1439605|2e3308|wdsj144036051ab|iras143596037|gesj143929726049560|2xmmj1439330605008|gc19728|lpm534|rej143944605008|tsa98j1439484260502166|1es1435606|2rej1439605|gcrv8519|ci20875|sao252838|ccdmj143966050ab|uvby98100128620|1e14355560376|cpd605483|rxj143956050|rhd1|ids143286025ab|alfcen|cd605293|wdsj143966050ab|2rej143941605000|1rxsj1439404605020|1e14355660373|euvej1439608|pm143626038|2euvej1439608|lds494ab|csi60548343|2e143596037",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 14.66122222222222,
        "dec": -60.83888888888889,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Cen",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954158938745012225",
        "name": "Castor",
        "names": [
          "GJ 278.0",
          "HIP 36850",
          "TIC 239187687",
          "WDS J07346+3153AB",
          "NLTT 18087",
          "* alf Gem",
          "* 66 Gem",
          "ADS 6175 AB",
          "AG+31 789",
          "BD+32 1581",
          "CCDM J07346+3153AB",
          "CSI+32 1581 1",
          "FK5 287",
          "GC 10120",
          "HIC 36850",
          "IRAS 07314+3159",
          "IRC +30188",
          "LTT 12038",
          "NAME Castor",
          "NAME Castor AB",
          "PMC 90-93 208",
          "PPM 72938",
          "RAFGL 1144",
          "SAO 60198",
          "SRS 30287",
          "TD1 10405",
          "UBV 7277",
          "[HFE83] 521",
          "2MASS J07343598+3153184",
          "** STF 1110AB",
          "2XMM J073435.6+315318",
          "PLX 1785",
          "PLX 1785.00",
          "GEN# +1.00060179"
        ],
        "searchKey": "ads6175ab|ag31789|plx1785|hip36850|td110405|bd321581|sao60198|2xmmj0734356315318|66gem|hic36850|stf1110ab|gen100060179|iras073143159|alfgem|rafgl1144|ubv7277|srs30287|2massj073435983153184|gj2780|ppm72938|castorab|castor|ccdmj073463153ab|gc10120|plx178500|hfe83521|irc30188|csi3215811|tic239187687|castor|wdsj073463153ab|pmc9093208|nltt18087|ltt12038|fk5287",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.576631441666667,
        "dec": 31.88828222222222,
        "fluxV": 1.58,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Gem",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959194120388609",
    "title": "Comets",
    "color": "GREEN",
    "credit": "https://skyandtelescope.org/astronomy-news/the-best-comets-in-2024/",
    "imgURL": "/list_icons/comets.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "950169148805545986",
        "name": "C/2023 A3 (Tsuchinshan-ATLAS)",
        "names": [
          "DESIGNATION C/2023 A3 (Tsuchinshan-ATLAS)",
          "MPEC 2024-E49"
        ],
        "searchKey": "mpec2024e49|designationc2023a3tsuchinshanatlas|c2023a3tsuchinshanatlas",
        "solarSystemKey": null,
        "cometKey": "C/2023 A3 (Tsuchinshan-ATLAS)",
        "celestrakKey": null,
        "color": "GRAY",
        "type": "COMET",
        "imgURL": null,
        "ra": null,
        "dec": null,
        "fluxV": 3.370727378475495,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950169155254648834",
        "name": "12P/Pons-Brooks",
        "names": [
          "DESIGNATION 12P/Pons-Brooks",
          "MPEC 2024-E49"
        ],
        "searchKey": "mpec2024e49|designation12pponsbrooks|12pponsbrooks",
        "solarSystemKey": null,
        "cometKey": "12P/Pons-Brooks",
        "celestrakKey": null,
        "color": "BLUE",
        "type": "COMET",
        "imgURL": null,
        "ra": null,
        "dec": null,
        "fluxV": 4.355155394018391,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950169155359539202",
        "name": "13P/Olbers",
        "names": [
          "DESIGNATION 13P/Olbers",
          "MPEC 2024-DC6"
        ],
        "searchKey": "mpec2024dc6|designation13polbers|13polbers",
        "solarSystemKey": null,
        "cometKey": "13P/Olbers",
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "COMET",
        "imgURL": null,
        "ra": null,
        "dec": null,
        "fluxV": 5.421320283604923,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950169156905271298",
        "name": "29P/Schwassmann-Wachmann",
        "names": [
          "DESIGNATION 29P/Schwassmann-Wachmann",
          "MPEC 2024-E49"
        ],
        "searchKey": "mpec2024e49|29pschwassmannwachmann|designation29pschwassmannwachmann",
        "solarSystemKey": null,
        "cometKey": "29P/Schwassmann-Wachmann",
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "COMET",
        "imgURL": null,
        "ra": null,
        "dec": null,
        "fluxV": 7.049366594325258,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950169160271167490",
        "name": "62P/Tsuchinshan",
        "names": [
          "DESIGNATION 62P/Tsuchinshan",
          "MPEC 2024-E49"
        ],
        "searchKey": "62ptsuchinshan|mpec2024e49|designation62ptsuchinshan",
        "solarSystemKey": null,
        "cometKey": "62P/Tsuchinshan",
        "celestrakKey": null,
        "color": "PINK",
        "type": "COMET",
        "imgURL": null,
        "ra": null,
        "dec": null,
        "fluxV": 9.020747326980782,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950169176392826882",
        "name": "144P/Kushida",
        "names": [
          "DESIGNATION 144P/Kushida",
          "MPEC 2024-E49"
        ],
        "searchKey": "mpec2024e49|designation144pkushida|144pkushida",
        "solarSystemKey": null,
        "cometKey": "144P/Kushida",
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "COMET",
        "imgURL": null,
        "ra": null,
        "dec": null,
        "fluxV": 9.66618159605746,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950169195873206274",
        "name": "333P/LINEAR",
        "names": [
          "DESIGNATION 333P/LINEAR",
          "MPEC 2024-E49"
        ],
        "searchKey": "333plinear|mpec2024e49|designation333plinear",
        "solarSystemKey": null,
        "cometKey": "333P/LINEAR",
        "celestrakKey": null,
        "color": "GRAY",
        "type": "COMET",
        "imgURL": null,
        "ra": null,
        "dec": null,
        "fluxV": 15.18605869592413,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959178739974145",
    "title": "Galaxy Clusters & Groups",
    "color": "BLUE",
    "credit": "https://www.skyatnightmagazine.com/astrophotography/galaxies/galaxy-clusters-groups",
    "imgURL": "/list_icons/galaxy-groups.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "944242361449775105",
        "name": "Leo Triplet",
        "names": [
          "APG 317",
          "NAME Leo Triplet"
        ],
        "searchKey": "leotriplet|leotriplet|apg317",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/LeoTripletHunterWilson.jpg/450px-LeoTripletHunterWilson.jpg",
        "ra": 11.31666666666667,
        "dec": 13.2,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Leo Triplet",
        "imgCredit": "Hewholooks",
        "description": "The Leo Triplet (also known as the M66 Group) is a small group of galaxies about 35 million light-years away in the constellation Leo. This galaxy group consists of the spiral galaxies M65, M66, and NGC 3628.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Leo_Triplet"
      },
      {
        "id": "944242758237945857",
        "name": "M 96",
        "names": [
          "EQ 1044+120",
          "IRAS 10441+1205",
          "IRAS F10441+1205",
          "LEDA 32192",
          "M 96",
          "MCG+02-28-006",
          "NGC 3368",
          "TC 532",
          "UGC 5882",
          "Z 66-13",
          "[FS90] Leo 19",
          "[N93] 50",
          "[VDD93] 122",
          "2MASX J10464574+1149117",
          "JCMTSE J104645.8+114914",
          "JCMTSF J104645.8+114914",
          "UZC J104645.9+114926",
          "Z 1044.1+1205",
          "[CHM2007] HDC 626 J104645.74+1149117  [CHM2007] LDC 778 J104645.74+1149117  [M98c] 104406.9+120505",
          "PSCz Q10441+1205",
          "HIPASS J1046+11",
          "ALFALFA 5-321",
          "SDSS J104645.67+114911.8"
        ],
        "searchKey": "jcmtsfj1046458114914|irasf104411205|m96|psczq104411205|n9350|jcmtsej1046458114914|ugc5882|tc532|z104411205|fs90leo19|leda32192|sdssj104645671149118|mcg0228006|z6613|iras104411205|alfalfa5321|chm2007hdc626j104645741149117chm2007ldc778j104645741149117m98c1044069120505|hipassj104611|2masxj104645741149117|ngc3368|eq1044120|vdd93122|uzcj1046459114926",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 10.77937333333333,
        "dec": 11.81993888888889,
        "fluxV": 9.25,
        "sizeMajor": 7.24,
        "sizeMinor": 4.79,
        "sizeAngle": 5.0,
        "simbadName": "M 96",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950083045008637954",
        "name": "NGC 4216",
        "names": [
          "2MFGC 9663",
          "ADBS J121555+1308",
          "EQ 1213+134",
          "IRAS F12133+1325",
          "IRAS 12133+1325",
          "LEDA 39246",
          "MCG+02-31-072",
          "NGC 4216",
          "NVSS B121321+132538",
          "QDOT B1213204+132506",
          "UGC 7284",
          "VCC 167",
          "Z 69-112",
          "[HDL96] 69 182",
          "[YOF95] 49",
          "2MASX J12155444+1308578",
          "ALFALFA 1-213",
          "1E 1213.3+1324",
          "CAIRNS J121554.39+130858.3",
          "UZC J121554.2+130859",
          "Z 1213.4+1326",
          "[CHM2007] HDC 708 J121554.44+1308578  [CHM2007] LDC 904 J121554.44+1308578  [M98c] 121320.3+132538",
          "[RS2000] 182",
          "[BEC2010] HRS 97",
          "PCCS1 857 G270.46+73.72",
          "PCCS1 545 G270.44+73.74",
          "SDSS J121554.37+130858.0",
          "SDSS J121555.47+130947.4",
          "EVCC 236"
        ],
        "searchKey": "z69112|1e121331324|ugc7284|iras121331325|chm2007hdc708j121554441308578chm2007ldc904j121554441308578m98c1213203132538|ngc4216|hdl9669182|z121341326|yof9549|2mfgc9663|nvssb121321132538|mcg0231072|sdssj121555471309474|cairnsj121554391308583|vcc167|bec2010hrs97|rs2000182|irasf121331325|leda39246|evcc236|pccs1545g270447374|pccs1857g270467372|sdssj121554371308580|qdotb1213204132506|eq1213134|adbsj1215551308|uzcj1215542130859|2masxj121554441308578|alfalfa1213",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "CYAN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.26512333333333,
        "dec": 13.1494,
        "fluxV": 10.01,
        "sizeMajor": 7.94,
        "sizeMinor": 1.86,
        "sizeAngle": 19.0,
        "simbadName": "NGC 4216",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950083124156137474",
        "name": "For Cluster",
        "names": [
          "XCLASS 2074",
          "ACO S 373",
          "AM 0336-353",
          "MCL 52",
          "[HG82] 17",
          "[R88] 13",
          "[FWB89] GrG 31",
          "RXC J0338.4-3526",
          "BAX 054.6289-35.4545",
          "MCXC J0338.4-3526",
          "NAME For Cluster",
          "NAME Fornax Cluster",
          "NAME For Group",
          "NAME Fornax Group",
          "NAME NGC 1399 Group"
        ],
        "searchKey": "hg8217|r8813|mcxcj033843526|forcluster|bax0546289354545|mcl52|fornaxgroup|fwb89grg31|fornaxcluster|forgroup|acos373|am0336353|rxcj033843526|forcluster|xclass2074|ngc1399group",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 3.6415,
        "dec": -35.45222222222223,
        "fluxV": null,
        "sizeMajor": 11.666666985,
        "sizeMinor": 11.666666985,
        "sizeAngle": null,
        "simbadName": "ACO S 373",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950083125129576450",
        "name": "NGC 3189",
        "names": [
          "APG 316",
          "HCG 44a",
          "IRAS 10153+2204",
          "IRAS F10153+2205",
          "ISOSS J10180+2150",
          "LEDA 30083",
          "MCG+04-24-026",
          "NGC 3189",
          "NGC 3190",
          "QDOT B1015212+220451",
          "UGC 5559",
          "VV 307a",
          "Z 123-37",
          "[SLK2004] 581",
          "2MASX J10180564+2149549",
          "UZC J101805.7+214957",
          "Z 1015.4+2205",
          "[CHM2007] HDC 572 J101805.64+2149549",
          "[CHM2007] LDC 724 J101805.64+2149549  [M98c] 101520.2+220501",
          "PSCz Q10153+2204",
          "2XMM J101805.6+214955",
          "SDSS J101805.65+214956.1",
          "SDSS J101805.66+214956.1"
        ],
        "searchKey": "iras101532204|leda30083|ngc3190|z101542205|ngc3189|chm2007hdc572j101805642149549|vv307a|sdssj101805662149561|hcg44a|2masxj101805642149549|z12337|slk2004581|sdssj101805652149561|mcg0424026|chm2007ldc724j101805642149549m98c1015202220501|isossj101802150|uzcj1018057214957|apg316|qdotb1015212220451|ugc5559|2xmmj1018056214955|irasf101532205|psczq101532204",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 10.3015675,
        "dec": 21.83193888888889,
        "fluxV": 11.15,
        "sizeMajor": 3.63,
        "sizeMinor": 1.26,
        "sizeAngle": 123.0,
        "simbadName": "NGC 3189",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950083125129609218",
        "name": "NGC 5982",
        "names": [
          "2E 3483",
          "K79 64B",
          "LEDA 55674",
          "MCG+10-22-029",
          "NGC 5982",
          "UGC 9961",
          "Z 297-24",
          "2MASX J15383977+5921212",
          "[FWB89] Galaxy 384",
          "1RXS J153840.1+592118",
          "2E 1537.6+5931",
          "RX J1538.6+5921",
          "UZC J153839.9+592121",
          "Z 1537.6+5931",
          "[CHM2007] LDC 1141 J153839.77+5921212",
          "[ZEH2003] RX J1538.6+5921 1"
        ],
        "searchKey": "uzcj1538399592121|z29724|2e3483|rxj153865921|z153765931|zeh2003rxj1538659211|2masxj153839775921212|fwb89galaxy384|ngc5982|2e153765931|chm2007ldc1141j153839775921212|1rxsj1538401592118|mcg1022029|k7964b|ugc9961|leda55674",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 15.64438277777778,
        "dec": 59.35589166666666,
        "fluxV": null,
        "sizeMajor": 1.667,
        "sizeMinor": 1.167,
        "sizeAngle": 105.0,
        "simbadName": "NGC 5982",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950083125181054978",
        "name": "ACO 1367",
        "names": [
          "1H 1142+199",
          "1M 1144+197",
          "2A 1141+199",
          "2U 1144+19",
          "3A 1141+198",
          "3U 1144+19",
          "4U 1143+19",
          "ACO 1367",
          "FR 70",
          "HMS 1142+2035",
          "INTREF 460",
          "RASSCALS NRGb155",
          "RBS 1026",
          "WBL 353",
          "XSS J11444+1952",
          "[F81] 215",
          "[N93] 78",
          "[S85] 131",
          "1E 1141.9+2007",
          "1RXS J114452.7+194706",
          "ClG 1141.9+2007",
          "RXC J1144.7+1949",
          "BAX 176.1231+19.8391",
          "PLCKESZ G234.59+73.01",
          "MCXC J1144.6+1945",
          "[SPD2011] 227",
          "2MAXI J1144+198",
          "PSZ1 G234.54+73.03",
          "RXC J1144.6+1945",
          "PSZ2 G234.59+73.01"
        ],
        "searchKey": "1m1144197|xssj114441952|2maxij1144198|plckeszg234597301|spd2011227|s85131|intref460|hms11422035|4u114319|psz1g234547303|fr70|rasscalsnrgb155|2a1141199|n9378|1rxsj1144527194706|1e114192007|1h1142199|rxcj114471949|3a1141198|rbs1026|aco1367|3u114419|rxcj114461945|psz2g234597301|wbl353|f81215|clg114192007|mcxcj114461945|2u114419|bax1761231198391",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 11.74572222222222,
        "dec": 19.69972222222222,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "ACO 1367",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950083125212839938",
        "name": "Her Cluster",
        "names": [
          "2E 3577",
          "ACO 2151",
          "CAN 58",
          "CID 58",
          "ClG 1603.0+1753",
          "FR 98",
          "HMS 1603+1755",
          "RBS 1553",
          "[F81] 340",
          "[S85] 407",
          "1ES 1602+17.8",
          "1RXS J160435.1+174333",
          "2E 1602.3+1751",
          "RX J1604.5+1743",
          "RXC J1604.5+1743",
          "BAX 241.3125+17.7485",
          "MCXC J1604.5+1743",
          "NAME Her Cluster",
          "NAME Hercules Cluster"
        ],
        "searchKey": "rxcj160451743|2e3577|rbs1553|bax2413125177485|hercluster|hercluster|aco2151|hms16031755|s85407|f81340|2e160231751|1rxsj1604351174333|1es1602178|can58|cid58|clg160301753|fr98|rxj160451743|mcxcj160451743|herculescluster",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.0875,
        "dec": 17.74861111111111,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "ACO 2151",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950083125246689282",
        "name": "NGC 3187",
        "names": [
          "Gaia DR3 628994146573251456",
          "2MFGC 7972",
          "2MASXI J1017478+215224",
          "HCG 44d",
          "LEDA 30068",
          "MCG+04-24-025",
          "NGC 3187",
          "UGC 5556",
          "VV 307b",
          "Z 123-36",
          "[M98c] 101502.0+220726",
          "LCSB L407",
          "2MASX J10174784+2152238",
          "UZC J101747.8+215225",
          "Z 1015.1+2207",
          "[CHM2007] HDC 572 J101747.84+2152238  [CHM2007] LDC 724 J101747.84+2152238  HIPASS J1017+21",
          "SDSS J101747.86+215224.0",
          "Gaia DR2 628994146573251456"
        ],
        "searchKey": "vv307b|z12336|gaiadr3628994146573251456|z101512207|hcg44d|m98c1015020220726|lcsbl407|2masxij1017478215224|mcg0424025|ugc5556|leda30068|uzcj1017478215225|chm2007hdc572j101747842152238chm2007ldc724j101747842152238hipassj101721|gaiadr2628994146573251456|2mfgc7972|ngc3187|2masxj101747842152238|sdssj101747862152240",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 10.29663065475733,
        "dec": 21.87332890702,
        "fluxV": 13.44,
        "sizeMajor": 1.263,
        "sizeMinor": 0.379,
        "sizeAngle": 120.0,
        "simbadName": "NGC 3187",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950083125247082498",
        "name": "NGC 7331",
        "names": [
          "Gaia DR3 1903152720991758464",
          "2E 4630",
          "IRAS F22347+3409",
          "ISOSS J22370+3426",
          "LEDA 69327",
          "MCG+06-49-045",
          "NGC 7331",
          "RX J2237.0+3425",
          "TC 402",
          "UGC 12113",
          "Z 514-68",
          "[BTW2003] J2237+3424",
          "[SLK2004] 1798",
          "[SRC98] 1",
          "[VDD93] 245",
          "2MASX J22370410+3424573",
          "1RXS J223705.9+342519",
          "2E 2234.7+3408",
          "UZC J223704.5+342501",
          "Z 2234.8+3410",
          "[M98c] 223447.7+340935",
          "IRAS 22347+3409",
          "PSCz Q22347+3409",
          "SDSS J223704.05+342456.8"
        ],
        "searchKey": "irasf223473409|isossj223703426|slk20041798|2e4630|ugc12113|ngc7331|iras223473409|psczq223473409|btw2003j22373424|tc402|sdssj223704053424568|1rxsj2237059342519|uzcj2237045342501|leda69327|2masxj223704103424573|m98c2234477340935|gaiadr31903152720991758464|vdd93245|mcg0649045|z223483410|z51468|2e223473408|rxj223703425|src981",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 22.61779183439133,
        "dec": 34.41577779903,
        "fluxV": 9.48,
        "sizeMajor": 10.47,
        "sizeMinor": 4.17,
        "sizeAngle": 171.0,
        "simbadName": "NGC 7331",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950083127239409666",
        "name": "Vir Cluster",
        "names": [
          "INTREF 496",
          "[D61] Vir I",
          "BAX 186.6337+12.7233",
          "NAME Vir Cluster",
          "NAME Virgo Cluster",
          "NAME Virgo Group"
        ],
        "searchKey": "vircluster|intref496|vircluster|bax1866337127233|virgogroup|virgocluster|d61viri",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.44225,
        "dec": 12.72333333333333,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Virgo Cluster",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950093545373237250",
        "name": "Stephan's Quartet",
        "names": [
          "XCLASS 22792",
          "HCG 92",
          "NAME Stephan's Quartet",
          "NAME Stephan's Quintet",
          "RSCG 82",
          "RX J2236.0+3358",
          "WBL 683",
          "[FK2002] 279",
          "BAX 338.9896+33.9599",
          "MLCG 1407",
          "V1CG 616"
        ],
        "searchKey": "hcg92|stephansquintet|stephansquartet|wbl683|mlcg1407|fk2002279|xclass22792|stephansquartet|v1cg616|rscg82|bax3389896339599|rxj223603358",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 22.59930555555555,
        "dec": 33.96,
        "fluxV": null,
        "sizeMajor": 3.2,
        "sizeMinor": 3.2,
        "sizeAngle": 90.0,
        "simbadName": "NAME Stephan's Quintet",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959180183961601",
    "title": "Grand Spiral Galaxies",
    "color": "BLUE",
    "credit": "https://en.wikipedia.org/wiki/Grand_design_spiral_galaxy",
    "imgURL": "/list_icons/grand-spiral.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "944242002229231617",
        "name": "Whirlpool Galaxy",
        "names": [
          "ILT J132952.71+471143.3",
          "[NKB95] N5194",
          "[OKM2018] SWIFT J1329.9+4719",
          "PLX 3084",
          "4C 47.36A",
          "APG 85A",
          "APG 85",
          "BD+47 2063",
          "GB1 1327+475",
          "IRAS F13277+4727",
          "IRAS 13277+4727",
          "ISOSS J13299+4714",
          "KHG 1-C 5",
          "KPG 379a",
          "LEDA 47404",
          "M 51",
          "MCG+08-25-012",
          "NAME Whirlpool",
          "NGC 5194",
          "TC 827",
          "UGC 8493",
          "VV 403",
          "VV 1a",
          "VV 1",
          "Z 246-8",
          "[DML87] 671",
          "[H92] 27",
          "[LPS2002] 16",
          "[SLK2004] 853",
          "[T76] 85A",
          "[VDD93] 187A",
          "2MASX J13295269+4711429",
          "PLX 3084.00",
          "1RXS J132953.8+471143",
          "RX J1329.8+4711",
          "XMMU J132952.9+471140",
          "Z 1327.8+4727",
          "[CHM2007] LDC 867 J132952.69+4711429  [M98c] 132746.9+472716",
          "[VV2000c] J132952.4+471141",
          "[VV2003c] J132952.4+471141",
          "[VV2006c] J132952.4+471141",
          "[VV98c] J132952.7+471143",
          "[ZEH2003] RX J1329.8+4711 1",
          "UZC J132952.1+471144",
          "PSCz Q13277+4727",
          "2XMM J132952.5+471144",
          "[VV2010c] J132952.4+471141",
          "B3 1327+474C",
          "GB6 B1327+4727",
          "WB 1327+4727",
          "87GB 132747.8+472723",
          "WN B1327.8+4727",
          "BWE 1327+4727",
          "6C 132748+472801",
          "7C 132735.60+472604.00",
          "7C 132746.79+472722.00",
          "7C 132753.10+473032.00",
          "[CAB95] IRAS F13277+4727",
          "NAME Question Mark Galaxy",
          "NAME Whirlpool Galaxy"
        ],
        "searchKey": "vv403|z132784727|chm2007ldc867j132952694711429m98c1327469472716|leda47404|7c1327467947272200|6c132748472801|7c1327531047303200|isossj132994714|t7685a|b31327474c|apg85a|vv1|4c4736a|lps200216|zeh2003rxj1329847111|z2468|plx3084|questionmarkgalaxy|gb11327475|mcg0825012|rxj132984711|whirlpoolgalaxy|cab95irasf132774727|dml87671|vv98cj1329527471143|7c1327356047260400|whirlpoolgalaxy|wb13274727|bwe13274727|vv1a|2xmmj1329525471144|khg1c5|ngc5194|kpg379a|87gb1327478472723|tc827|slk2004853|xmmuj1329529471140|vv2010cj1329524471141|wnb132784727|vv2003cj1329524471141|vv2006cj1329524471141|iltj132952714711433|apg85|uzcj1329521471144|iras132774727|2masxj132952694711429|h9227|nkb95n5194|bd472063|whirlpool|plx308400|vdd93187a|okm2018swiftj132994719|ugc8493|vv2000cj1329524471141|m51|gb6b13274727|1rxsj1329538471143|psczq132774727|irasf132774727",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Messier51_sRGB.jpg/435px-Messier51_sRGB.jpg",
        "ra": 13.49797166666667,
        "dec": 47.19525833333333,
        "fluxV": 8.36,
        "sizeMajor": 10.0,
        "sizeMinor": 7.59,
        "sizeAngle": 163.0,
        "simbadName": "M 51",
        "imgCredit": "NASA and European Space Agency",
        "description": "The Whirlpool Galaxy, also known as Messier 51a (M51a) or NGC 5194, is an interacting grand-design spiral galaxy with a Seyfert 2 active galactic nucleus. It lies in the constellation Canes Venatici, and was the first galaxy to be classified as a spiral galaxy. It is 7.22 megaparsecs (23.5 million light-years) away and 23.58 kiloparsecs (76,900 ly) in diameter.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Whirlpool_Galaxy"
      },
      {
        "id": "944242005918744577",
        "name": "Bode's Galaxy",
        "names": [
          "RFC J0955+6903",
          "TGSSADR J095533.1+690354",
          "Gaia DR3 1070470609404512512",
          "HD 85534",
          "BD+69 543",
          "[LB2005] NGC 3031 X1",
          "2E 2195",
          "HIJASS J0955+69A",
          "IERS B0951+693",
          "IRAS F09514+6918",
          "IRAS 09514+6918",
          "K79 28A",
          "KPG 218a",
          "LEDA 28630",
          "M 81",
          "MCG+12-10-010",
          "NAME M 81*",
          "NGC 3031",
          "RBS 808",
          "RORF 0951+693",
          "RX J095532+69038",
          "SPB 115",
          "TC 714",
          "Gaia DR1 1070470605109644672",
          "UGC 5318",
          "Z 333-7",
          "[B93] 13",
          "[BAZ94] 3",
          "[DML87] 630",
          "[VDD93] 99",
          "2MASX J09553318+6903549",
          "1ES 0951+69.3",
          "1RXP J095532.8+690354",
          "1RXS J095534.7+690338",
          "2E 0951.4+6918",
          "EXO 0951.3+6917",
          "ICRF J095533.1+690355",
          "RX J0955.5+6903",
          "RX J095533.4+690353",
          "Z 0951.4+6918",
          "[CHM2007] HDC 552 J095533.18+6903549  [CHM2007] LDC 842 J095533.18+6903549",
          "[M98c] 095127.6+691813",
          "[VV2000c] J095533.2+690355",
          "[VV2003c] J095533.2+690355",
          "[VV2006] J095533.2+690355",
          "[VV98c] J095533.2+690355",
          "[ZEH2003] RX J0955.5+6903 1",
          "UZC J095535.5+690352",
          "PSCz Q09514+6918",
          "[VV2010] J095533.2+690355",
          "87GB 095125.0+691806",
          "GB6 B0951+6918",
          "NVSS J095533+690355",
          "WN B0951.4+6918",
          "2XMM J095533.1+690355",
          "SDSS J095533.16+690355.1",
          "[BAG2012] 149.0083+69.0811",
          "XMMSL1 J095534.3+690350",
          "SDSS J095533.14+690355.2",
          "BWE 0951+6918",
          "RGB J0955+690",
          "[CAB95] IRAS F09514+6918",
          "[NKB95] N3031",
          "NAME Bode's Galaxy",
          "SWIFT J0955.5+6907",
          "SWIFT J0955.9+6905",
          "Gaia DR2 1070470609404512512"
        ],
        "searchKey": "rgbj0955690|m81|2masxj095533186903549|mcg1210010|vv2006j0955332690355|2e095146918|iras095146918|2e2195|kpg218a|swiftj095596905|m81|rbs808|z3337|z095146918|vv2003cj0955332690355|psczq095146918|baz943|ugc5318|rorf0951693|wnb095146918|vv2000cj0955332690355|uzcj0955355690352|rfcj09556903|87gb0951250691806|zeh2003rxj0955569031|nvssj095533690355|nkb95n3031|iersb0951693|tgssadrj0955331690354|dml87630|vdd9399|vv98cj0955332690355|gaiadr21070470609404512512|rxj095556903|cab95irasf095146918|vv2010j0955332690355|exo095136917|swiftj095556907|gb6b09516918|leda28630|m98c0951276691813|bwe09516918|bd69543|rxj0955334690353|1rxsj0955347690338|sdssj095533166903551|sdssj095533146903552|k7928a|xmmsl1j0955343690350|gaiadr31070470609404512512|b9313|hijassj095569a|hd85534|rxj09553269038|tc714|bodesgalaxy|gaiadr11070470605109644672|2xmmj0955331690355|chm2007hdc552j095533186903549chm2007ldc842j095533186903549|bag20121490083690811|irasf095146918|lb2005ngc3031x1|ngc3031|spb115|1rxpj0955328690354|1es0951693|icrfj0955331690355|bodesgalaxy",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Messier_81_HST.jpg/450px-Messier_81_HST.jpg",
        "ra": 9.925881293236,
        "dec": 69.06529514038,
        "fluxV": 6.94,
        "sizeMajor": 21.38,
        "sizeMinor": 10.23,
        "sizeAngle": 157.0,
        "simbadName": "M 81",
        "imgCredit": "NASA, ESA and the Hubble Heritage Team",
        "description": "Messier 81 (also known as NGC 3031 or Bode's Galaxy) is a grand design spiral galaxy about 12 million light-years away in the constellation Ursa Major. It has a D25 isophotal diameter of 29.44 kiloparsecs (96,000 light-years). Because of its relative proximity to the Milky Way galaxy, large size, and active galactic nucleus (which harbors a 70 million M\u2609 supermassive black hole), Messier 81 has been studied extensively by professional astronomers. The galaxy's large size and relatively high brightness also makes it a popular target for amateur astronomers. In late February 2022, astronomers reported that M81 may be the source of FRB 20200120E, a repeating fast radio burst.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Messier_81"
      },
      {
        "id": "944242351239495681",
        "name": "Pinwheel Galaxy",
        "names": [
          "APG 26",
          "IRAS F14012+5434",
          "IRAS 14013+5435",
          "K73 610",
          "LEDA 50063",
          "M 101",
          "MCG+09-23-028",
          "NAME Pinwheel",
          "NGC 5457",
          "SPB 243",
          "TC 302",
          "UGC 8981",
          "VV 344a",
          "VV 456",
          "VV 344",
          "Z 272-21",
          "2MASX J14031258+5420555",
          "Z 1401.5+5435",
          "[CHM2007] HDC 853 J140312.58+5420555  [CHM2007] LDC 842 J140312.58+5420555  [M98c] 140126.6+543525",
          "UZC J140312.5+542056",
          "PSCz Q14013+5435",
          "SDSS J140312.52+542056.2"
        ],
        "searchKey": "vv456|iras140135435|pinwheelgalaxy|leda50063|z140155435|irasf140125434|ugc8981|vv344a|uzcj1403125542056|m101|chm2007hdc853j140312585420555chm2007ldc842j140312585420555m98c1401266543525|k73610|spb243|sdssj140312525420562|pinwheel|tc302|z27221|2masxj140312585420555|mcg0923028|psczq140135435|ngc5457|apg26|vv344",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/M101_hires_STScI-PRC2006-10a.jpg/435px-M101_hires_STScI-PRC2006-10a.jpg",
        "ra": 14.05349527777778,
        "dec": 54.34875,
        "fluxV": 7.86,
        "sizeMajor": 21.88,
        "sizeMinor": 20.89,
        "sizeAngle": null,
        "simbadName": "M 101",
        "imgCredit": "European Space Agency & NASA",
        "description": "The Pinwheel Galaxy (also known as Messier 101, M101 or NGC 5457) is a face-on spiral galaxy 21 million light-years (6.4 megaparsecs) from Earth in the constellation Ursa Major. It was discovered by Pierre M\u00e9chain in 1781 and was communicated that year to Charles Messier, who verified its position for inclusion in the Messier Catalogue as one of its final entries.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Pinwheel_Galaxy"
      },
      {
        "id": "944242684275326977",
        "name": "M 74",
        "names": [
          "Gaia DR3 2589380639673817728",
          "[CHP2004] J013641.7+154701",
          "2MASS J01364173+1547008",
          "TIC 405375557",
          "2E 440",
          "2E 0134.0+1531",
          "87GB 013400.0+153119",
          "GB6 B0134+1531",
          "IRAS F01339+1532",
          "IRAS 01340+1532",
          "ISOSS J01366+1547",
          "LEDA 5974",
          "M 74",
          "MCG+03-05-011",
          "NGC 628",
          "TC 18",
          "UGC 1149",
          "Z 0134.0+1532",
          "Z 460-14",
          "[SLK2004] 223",
          "[VDD93] 8",
          "2MASX J01364177+1547004",
          "[M98c] 013400.7+153155",
          "UZC J013641.7+154659",
          "PSCz Q01340+1532",
          "HIPASS J0136+15"
        ],
        "searchKey": "chp2004j0136417154701|vdd938|leda5974|ugc1149|2masxj013641771547004|z46014|2massj013641731547008|z013401532|tic405375557|ngc628|iras013401532|87gb0134000153119|2e440|slk2004223|isossj013661547|m98c0134007153155|irasf013391532|gaiadr32589380639673817728|gb6b01341531|tc18|psczq013401532|mcg0305011|uzcj0136417154659|hipassj013615|m74|2e013401531",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "INDIGO",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 1.611595867684,
        "dec": 15.78364097564,
        "fluxV": 9.46,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 74",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242714419757057",
        "name": "M 83",
        "names": [
          "LVHIS 053",
          "VLSSr J133658.0-295147",
          "TGSSADR J133659.9-295145",
          "[QPD2020] G133659.4-295159",
          "HIPASS J1337-29",
          "SINGG HIPASS J1337-29",
          "HIDEEP J1337-2954",
          "PMN J1336-2951",
          "[LB2005] NGC 5236 X1",
          "NVSS J133700-295155",
          "1RXS J133657.0-295207",
          "2E 3112",
          "ESO-LV 444-0810",
          "ESO 444-81",
          "IRAS 13341-2936",
          "IRAS F13341-2936",
          "LEDA 48082",
          "M 83",
          "MCG-05-32-050",
          "MRC 1334-296",
          "MSH 13-2-05",
          "NAME Southern Pinwheel Galaxy",
          "NGC 5236",
          "PKS 1334-296",
          "PKS J1336-2951",
          "PKS 1334-29",
          "QDOT B1334113-293639",
          "RBS 1293",
          "RX J1337.0-2952",
          "SPB 225",
          "UGCA 366",
          "[DAM86] 103",
          "[DSB94] 62",
          "[M98c] 133411.0-293648",
          "[R84] A1-333",
          "[TFP85] I1",
          "[VDD93] 189",
          "2MASX J13370091-2951567",
          "SGC 133411-2936.8",
          "1ES 1334-29.6",
          "2E 1334.2-2936",
          "6dFGS gJ133658.1-294826",
          "FLASH J133700.23-295204.5",
          "[CHM2007] HDC 813 J133700.91-2951567  [CHM2007] LDC 993 J133700.91-2951567",
          "PSCz Q13341-2936",
          "Cul 1334-296",
          "CD-29 10465",
          "CPD-29 3793",
          "FAUST 3840",
          "HD 118366",
          "[BBW2000] FM 70",
          "TXS 1334-296",
          "[CAB95] IRAS F13341-2936",
          "[NKB95] N5236"
        ],
        "searchKey": "esolv4440810|lvhis053|sgc13341129368|dsb9462|qpd2020g1336594295159|hipassj133729|rxj133702952|southernpinwheelgalaxy|vdd93189|hd118366|txs1334296|faust3840|ugca366|r84a1333|eso44481|rbs1293|m98c1334110293648|nkb95n5236|ngc5236|iras133412936|2e3112|psczq133412936|cab95irasf133412936|irasf133412936|1rxsj1336570295207|cpd293793|pmnj13362951|1es1334296|6dfgsgj1336581294826|mrc1334296|bbw2000fm70|msh13205|mcg0532050|hideepj13372954|cul1334296|tgssadrj1336599295145|pks133429|tfp85i1|vlssrj1336580295147|qdotb1334113293639|cd2910465|2masxj133700912951567|lb2005ngc5236x1|nvssj133700295155|leda48082|m83|pksj13362951|spb225|2e133422936|chm2007hdc813j133700912951567chm2007ldc993j133700912951567|pks1334296|flashj133700232952045|singghipassj133729|dam86103",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 13.616922,
        "dec": -29.86576111111111,
        "fluxV": 7.52,
        "sizeMajor": 13.8,
        "sizeMinor": 12.88,
        "sizeAngle": null,
        "simbadName": "M 83",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950089973310586882",
        "name": "NGC 5364",
        "names": [
          "IRAS F13536+0515",
          "IRAS 13536+0515",
          "LEDA 49555",
          "MCG+01-36-003",
          "NGC 5364",
          "UGC 8853",
          "UZC J135612.0+050053",
          "Z 46-9",
          "2MASX J13561200+0500520",
          "[CHM2007] LDC 1015 J135612.00+0500520  [CHM2007] HDC 836 J135612.00+0500520  Z 1353.7+0516",
          "[M98c] 135341.1+051533",
          "PSCz Q13536+0515",
          "[BEC2010] HRS 307",
          "HIPASS J1356+05",
          "WVFSCC J135608+050203",
          "NGC 5317",
          "SDSS J135611.98+050053.5"
        ],
        "searchKey": "leda49555|ugc8853|uzcj1356120050053|2masxj135612000500520|chm2007ldc1015j135612000500520chm2007hdc836j135612000500520z135370516|psczq135360515|ngc5317|bec2010hrs307|wvfsccj135608050203|iras135360515|ngc5364|irasf135360515|z469|hipassj135605|m98c1353411051533|mcg0136003|sdssj135611980500535",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 13.93666777777778,
        "dec": 5.014461111111111,
        "fluxV": null,
        "sizeMajor": 5.01,
        "sizeMinor": 2.69,
        "sizeAngle": 58.0,
        "simbadName": "NGC 5364",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950089973407252482",
        "name": "NGC 2997",
        "names": [
          "SUMSS J094538-311135",
          "TGSSADR J094538.8-311128",
          "NVSS J094538-311127",
          "Gaia DR3 5440216477189745152",
          "HIPASS J0945-31",
          "AM 0943-305",
          "ESO 434-35",
          "ESO-LV 434-0350",
          "LEDA 27978",
          "MCG-05-23-012",
          "NGC 2997",
          "UGCA 181",
          "[M98c] 094328.0-305736",
          "[VDD93] 97",
          "2MASX J09453879-3111279",
          "6dFGS gJ094538.8-311128",
          "SGC 094328-3057.6",
          "[CHM2007] LDC 683 J094538.79-3111279",
          "Gaia DR2 5440216477189745152"
        ],
        "searchKey": "ugca181|eso43435|sumssj094538311135|ngc2997|m98c0943280305736|6dfgsgj0945388311128|hipassj094531|gaiadr25440216477189745152|nvssj094538311127|chm2007ldc683j094538793111279|esolv4340350|2masxj094538793111279|leda27978|tgssadrj0945388311128|mcg0523012|am0943305|gaiadr35440216477189745152|vdd9397|sgc09432830576",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "INDIGO",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 9.760765062152,
        "dec": -31.19092939172,
        "fluxV": 9.41,
        "sizeMajor": 9.77,
        "sizeMinor": 6.92,
        "sizeAngle": 97.0,
        "simbadName": "NGC 2997",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950089973977251842",
        "name": "NGC 1232",
        "names": [
          "HIPASS J0309-20",
          "SINGG HIPASS J0309-20",
          "ESO-LV 547-0140",
          "6dFGS gJ030945.4-203445",
          "AGC 22364",
          "APG 41",
          "ESO 547-14",
          "IRAS F03075-2045",
          "LEDA 11819",
          "MBG 03075-2046",
          "MBG 03078-2047",
          "MCG-04-08-032",
          "NGC 1232",
          "[M98c] 030730.0-204613",
          "2MASX J03094551-2034454",
          "SGC 030730-2046.1",
          "[CHM2007] LDC 251 J030945.51-2034454"
        ],
        "searchKey": "chm2007ldc251j030945512034454|leda11819|mcg0408032|singghipassj030920|6dfgsgj0309454203445|2masxj030945512034454|hipassj030920|ngc1232|eso54714|apg41|sgc03073020461|mbg030782047|irasf030752045|agc22364|mbg030752046|esolv5470140|m98c0307300204613",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 3.162642777777778,
        "dec": -20.5793,
        "fluxV": 9.87,
        "sizeMajor": 6.61,
        "sizeMinor": 5.75,
        "sizeAngle": 99.0,
        "simbadName": "NGC 1232",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950089973978464258",
        "name": "NGC 7424",
        "names": [
          "Gaia DR3 6543107733125554560",
          "HIPASS J2257-41",
          "SINGG HIPASS J2257-41",
          "APMBGC 346+036+065",
          "ESO 346-19",
          "ESO-LV 346-0190",
          "IRAS 22544-4120",
          "IRAS F22544-4120",
          "LEDA 70096",
          "MCG-07-47-008",
          "NGC 7424",
          "QDOT B2254296-412001",
          "[M98c] 225428.0-412012",
          "[VDD93] 246",
          "2MASX J22571836-4104140",
          "[CHM2007] HDC 1226 J225718.36-4104140  6dFGS gJ225718.4-410414",
          "SGC 225428-4120.2",
          "[CHM2007] LDC 1547 J225718.36-4104140  PSCz Q22544-4120",
          "Gaia DR2 6543107728830799744"
        ],
        "searchKey": "sgc22542841202|leda70096|apmbgc346036065|chm2007hdc1226j2257183641041406dfgsgj2257184410414|esolv3460190|mcg0747008|gaiadr36543107733125554560|hipassj225741|singghipassj225741|qdotb2254296412001|iras225444120|eso34619|gaiadr26543107728830799744|chm2007ldc1547j225718364104140psczq225444120|ngc7424|2masxj225718364104140|vdd93246|m98c2254280412012|irasf225444120",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 22.95510901419667,
        "dec": -41.07059018612,
        "fluxV": 10.38,
        "sizeMajor": 6.92,
        "sizeMinor": 4.68,
        "sizeAngle": 125.0,
        "simbadName": "NGC 7424",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950089974137815042",
        "name": "NGC 3631",
        "names": [
          "Gaia DR3 839766402446198656",
          "APG 27",
          "IRAS 11181+5326",
          "IRAS F11182+5326",
          "LEDA 34767",
          "MCG+09-19-047",
          "NGC 3631",
          "QDOT B1118120+532639",
          "TC 164",
          "UGC 6360",
          "VV 363",
          "Z 268-21",
          "2MASX J11210294+5310099",
          "Z 1118.2+5327",
          "[CHM2007] HDC 651 J112102.94+5310099",
          "[CHM2007] LDC 867 J112102.94+5310099  UZC J112102.9+531010",
          "PSCz Q11181+5326",
          "[BEC2010] HRS 48",
          "SDSS J112102.87+531010.4",
          "87GB 111816.0+532550",
          "BWE 1118+5326",
          "GB6 B1118+5326",
          "NVSS J112103+531011",
          "WN B1118.2+5326",
          "WN B1118.2+5326A",
          "WN B1118.2+5326B",
          "MY 111812.8+531028.1",
          "6C 111817+532717",
          "7C 111814.00+532643.00",
          "[CAB95] IRAS F11182+5326"
        ],
        "searchKey": "wnb111825326a|nvssj112103531011|chm2007ldc867j112102945310099uzcj1121029531010|tc164|qdotb1118120532639|chm2007hdc651j112102945310099|ugc6360|7c1118140053264300|z26821|cab95irasf111825326|2masxj112102945310099|leda34767|wnb111825326|gb6b11185326|sdssj112102875310104|bec2010hrs48|wnb111825326b|6c111817532717|z111825327|bwe11185326|my11181285310281|iras111815326|vv363|ngc3631|psczq111815326|87gb1118160532550|irasf111825326|gaiadr3839766402446198656|apg27|mcg0919047",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 11.350801996254,
        "dec": 53.16955981435,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 3631",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959198960844801",
    "title": "Iconic Hubble",
    "color": "GRAY",
    "credit": "https://esahubble.org/images/archive/top100/",
    "imgURL": "/list_icons/hubble.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "944241943363649537",
        "name": "Jupiter",
        "names": [
          "NAME Jupiter"
        ],
        "searchKey": "jupiter|jupiter",
        "solarSystemKey": "jupiter barycenter",
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Jupiter_New_Horizons.jpg/435px-Jupiter_New_Horizons.jpg",
        "ra": null,
        "dec": null,
        "fluxV": -1.99,
        "sizeMajor": 0.5795,
        "sizeMinor": 0.5795,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "National Aeronautics and Space Administration",
        "description": "Jupiter is the fifth planet from the Sun and the largest in the Solar System. It is a gas giant with a mass more than two and a half times that of all the other planets in the Solar System combined, and slightly less than one one-thousandth the mass of the Sun. Jupiter orbits the Sun at a distance of 5.20 AU (778.5 Gm) with an orbital period of 11.86 years. Jupiter is the third brightest natural object in the Earth's night sky after the Moon and Venus, and it has been observed since prehistoric times. It was named after Jupiter, the chief deity of ancient Roman religion.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Jupiter"
      },
      {
        "id": "944241943455465473",
        "name": "Saturn",
        "names": [
          "NAME Saturn"
        ],
        "searchKey": "saturn|saturn",
        "solarSystemKey": "saturn barycenter",
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Saturn_Storm.jpg/330px-Saturn_Storm.jpg",
        "ra": null,
        "dec": null,
        "fluxV": 1.01,
        "sizeMajor": 0.25016667,
        "sizeMinor": 0.25016667,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "NASA / JPL / Space Science Institute",
        "description": "Saturn is the sixth planet from the Sun and the second-largest in the Solar System, after Jupiter. It is a gas giant with an average radius of about nine-and-a-half times that of Earth. It has only one-eighth the average density of Earth, but is over 95 times more massive. Even though Saturn is nearly the size of Jupiter, Saturn has less than one-third of Jupiter's mass. Saturn orbits the Sun at a distance of 9.59 AU (1,434 million km) with an orbital period of 29.45 years.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Saturn"
      },
      {
        "id": "944241952512442369",
        "name": "Andromeda Galaxy",
        "names": [
          "PLX 124.00",
          "PPM 43228",
          "HD 3969",
          "BD+40 148",
          "AG+40 60",
          "PLX 124",
          "2C 56",
          "DA 21",
          "GIN 801",
          "IRAS F00400+4059",
          "IRAS 00400+4059",
          "IRC +40013",
          "K79 1C",
          "LEDA 2557",
          "M 31",
          "MCG+07-02-016",
          "NAME Andromeda",
          "NAME Andromeda Galaxy",
          "NGC 224",
          "RAFGL 104",
          "UGC 454",
          "XSS J00425+4102",
          "Z 535-17",
          "Z 0040.0+4100",
          "[DGW65] 4",
          "2MASX J00424433+4116074",
          "[M98c] 004000.1+405943",
          "[VV2000c] J004244.3+411610",
          "[VV2003c] J004244.3+411610",
          "[VV2006] J004244.3+411610",
          "[VV98c] J004245.1+411622",
          "UZC J004244.3+411608",
          "[VV2010] J004244.3+411610",
          "NAME And Nebula",
          "NAME Andromeda Nebula",
          "2MAXI J0043+412",
          "3FGL J0042.5+4117",
          "2FGL J0042.5+4114",
          "4FGL J0043.2+4114"
        ],
        "searchKey": "da21|dgw654|bd40148|2maxij0043412|2fglj004254114|hd3969|irasf004004059|z53517|m31|xssj004254102|iras004004059|vv2000cj0042443411610|4fglj004324114|andromeda|vv2006j0042443411610|plx12400|mcg0702016|ag4060|rafgl104|uzcj0042443411608|leda2557|ugc454|plx124|2c56|2masxj004244334116074|m98c0040001405943|irc40013|andromedanebula|andromedagalaxy|vv2010j0042443411610|vv2003cj0042443411610|z004004100|andnebula|3fglj004254117|ppm43228|k791c|andromedagalaxy|gin801|ngc224|vv98cj0042451411622",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/M31_09-01-2011_%28cropped%29.jpg/435px-M31_09-01-2011_%28cropped%29.jpg",
        "ra": 0.7123138888888888,
        "dec": 41.26875,
        "fluxV": 3.44,
        "sizeMajor": 199.53,
        "sizeMinor": 70.79,
        "sizeAngle": 35.0,
        "simbadName": "M 31",
        "imgCredit": "Torben Hansen",
        "description": "The Andromeda Galaxy is a barred spiral galaxy and is the nearest major galaxy to the Milky Way. It was originally named the Andromeda Nebula and is cataloged as Messier 31, M31, and NGC 224. Andromeda has a diameter of about 46.56 kiloparsecs (152,000 light-years) and is approximately 765 kpc (2.5 million light-years) from Earth. The galaxy's name stems from the area of Earth's sky in which it appears, the constellation of Andromeda, which itself is named after the princess who was the wife of Perseus in Greek mythology.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Andromeda_Galaxy"
      },
      {
        "id": "944241955830530049",
        "name": "Orion Nebula",
        "names": [
          "3C 145",
          "4C -05.21",
          "CTA 37",
          "LBN 209.13-19.35",
          "LBN 974",
          "M 42",
          "MSH 05-0-11",
          "Mills 05+0A",
          "NAME Great Orion Nebula",
          "NAME Ori Nebula",
          "NAME Orion Nebula",
          "NGC 1976",
          "NRL 6",
          "PKS 0532-054",
          "PKS 0532-05",
          "XSS J05351-0519",
          "[DGW65] 26",
          "[PT56] 6",
          "[WCO2009] J053517-052326",
          "GAL 209.01-19.4",
          "[KPS2012] MWSC 0582"
        ],
        "searchKey": "msh05011|orionnebula|pt566|4c0521|3c145|pks0532054|pks053205|ngc1976|dgw6526|lbn974|cta37|gal20901194|kps2012mwsc0582|wco2009j053517052326|lbn209131935|greatorionnebula|m42|xssj053510519|orinebula|orionnebula|nrl6|mills050a",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Orion_Nebula_-_Hubble_2006_mosaic_18000.jpg/450px-Orion_Nebula_-_Hubble_2006_mosaic_18000.jpg",
        "ra": 5.588,
        "dec": -5.390000000000001,
        "fluxV": null,
        "sizeMajor": 66.0,
        "sizeMinor": 66.0,
        "sizeAngle": 90.0,
        "simbadName": "M 42",
        "imgCredit": "NASA, ESA, M. Robberto",
        "description": "The Orion Nebula (also known as Messier 42, M42, or NGC 1976) is a diffuse nebula situated in the Milky Way, being south of Orion's Belt in the constellation of Orion, and is known as the middle \"star\" in the \"sword\" of Orion. It is one of the brightest nebulae and is visible to the naked eye in the night sky with apparent magnitude 4.0. It is 1,344 \u00b1 20 light-years (412.1 \u00b1 6.1 pc) away and is the closest region of massive star formation to Earth. The M42 nebula is estimated to be 24 light-years across (so its apparent size from Earth is approximately 1 degree). It has a mass of about 2,000 times that of the Sun. Older texts frequently refer to the Orion Nebula as the Great Nebula in Orion or the Great Orion Nebula.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Orion_Nebula"
      },
      {
        "id": "944241962644766721",
        "name": "Crab Nebula",
        "names": [
          "3HWC J0534+220",
          "2HWC J0534+220",
          "eHWC J0534+220",
          "1RXS J053431.2+220218",
          "2AGL J0534+2205",
          "SNR G184.6-05.8",
          "1H 0531+219",
          "1M 0531+219",
          "2C 481",
          "2E 1309",
          "2U 0531+22",
          "3A 0531+219",
          "3C 144",
          "3C 144.0",
          "3CR 144",
          "3U 0531+21",
          "4C 21.19",
          "4U 0531+21",
          "AJG 1",
          "CTA 36",
          "CTB 18",
          "Cul 0531+21",
          "Cul 0531+219",
          "DA 179",
          "DB 38",
          "H 0534+21",
          "H 0531+219",
          "IRAS 05314+2200",
          "LBN 833",
          "LBN 184.62-05.65",
          "M 1",
          "Mills 05+2A",
          "NAME CRAB NEB",
          "NAME Crab",
          "NAME Crab Nebula",
          "NAME Tau A",
          "NAME Taurus A",
          "NGC 1952",
          "NRAO 214",
          "NRL 2",
          "PKS 0531+219",
          "SH 2-244",
          "SIM 0531+21.0",
          "VRO 21.05.01",
          "X Tau X-1",
          "X Tau XR-1",
          "[BM83] X0531+219",
          "[DGW65] 25",
          "[PT56] 5",
          "1ES 0532+21.5",
          "2E 0531.5+2159",
          "PBC J0534.5+2201",
          "SWIFT J0534.6+2204",
          "SWIFT J0534.5+2200",
          "GRS G184.60 -05.80",
          "W 9",
          "NVSS J053428+220202",
          "ARGO J0535+2203",
          "3FGL J0534.5+2201i",
          "TeV J0534+220",
          "3FHL J0534.5+2201",
          "4FGL J0534.5+2201i"
        ],
        "searchKey": "3u053121|tevj0534220|3cr144|xtaux1|2c481|m1|taua|1m0531219|da179|ngc1952|2u053122|lbn184620565|crabneb|2e053152159|3a0531219|4fglj053452201i|1es0532215|grsg184600580|crabnebula|crabnebula|3hwcj0534220|pt565|ctb18|sh2244|xtauxr1|taurusa|nrao214|ajg1|dgw6525|2e1309|db38|nvssj053428220202|2aglj05342205|4u053121|h0531219|iras053142200|cta36|4c2119|lbn833|snrg1846058|mills052a|cul053121|nrl2|h053421|sim0531210|3fhlj053452201|3c1440|pbcj053452201|ehwcj0534220|1h0531219|swiftj053462204|w9|bm83x0531219|1rxsj0534312220218|crab|argoj05352203|pks0531219|3fglj053452201i|3c144|cul0531219|2hwcj0534220|vro210501|swiftj053452200",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.57525,
        "dec": 22.01472222222222,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 1",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241968814784513",
        "name": "Horsehead Nebula",
        "names": [
          "Barnard 33",
          "M3T 31",
          "[OS98] 52",
          "[DB2002b] G206.95-16.80",
          "NAME Horsehead Nebula"
        ],
        "searchKey": "m3t31|horseheadnebula|barnard33|horseheadnebula|os9852|db2002bg206951680",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.683055555555556,
        "dec": -2.458333333333333,
        "fluxV": null,
        "sizeMajor": 6.0,
        "sizeMinor": 4.0,
        "sizeAngle": 90.0,
        "simbadName": "NAME Horsehead Nebula",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241976047599617",
        "name": "Eagle Nebula",
        "names": [
          "Villafranca O-019",
          "C 1816-138",
          "CTB 51",
          "LBN 016.96+00.78",
          "LBN 67",
          "LMH 22",
          "M 16",
          "MM 21",
          "NGC 6611",
          "NRL 14",
          "OCISM 10",
          "OCl 54",
          "[KPR2004b] 441",
          "IC 4703",
          "GRS G017.00 +00.30",
          "[KPS2012] MWSC 2886",
          "NAME Eagle Nebula",
          "NAME Star Queen"
        ],
        "searchKey": "kpr2004b441|eaglenebula|villafrancao019|kps2012mwsc2886|lmh22|ocism10|ocl54|lbn67|grsg017000030|nrl14|ngc6611|lbn016960078|eaglenebula|mm21|starqueen|c1816138|ctb51|m16|ic4703",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.31252777777778,
        "dec": -13.79194444444444,
        "fluxV": null,
        "sizeMajor": 80.0,
        "sizeMinor": 80.0,
        "sizeAngle": null,
        "simbadName": "M 16",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241985593442305",
        "name": "Carina Nebula",
        "names": [
          "NAME Keyhole",
          "1E 1044.0-5940",
          "BRAN 316A",
          "GUM 33",
          "MHR 11",
          "MSH 10-5-07",
          "NGC 3372",
          "RCW 53",
          "NAME Car Nebula",
          "NAME Carina Nebula",
          "NAME eta Car Nebula",
          "NAME Keyhole Nebula"
        ],
        "searchKey": "carnebula|carinanebula|ngc3372|keyholenebula|mhr11|gum33|carinanebula|rcw53|msh10507|bran316a|1e104405940|keyhole|etacarnebula",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 10.75061944444444,
        "dec": -59.69994444444444,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 3372",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241995678580737",
        "name": "Ring Nebula",
        "names": [
          "Gaia DR3 2090486618786534784",
          "PLX 4377",
          "BD+32 3246",
          "CSI+32-18517",
          "GCRV 11366",
          "GSC2 N0223131306",
          "HD 175353",
          "IRAS 18517+3257",
          "M 57",
          "NGC 6720",
          "PK 063+13 1",
          "PK 063+13",
          "PN G063.1+13.9",
          "PN ARO 9",
          "PN VV 214",
          "PN VV' 466",
          "WD 1851+329",
          "[LFO93] 1851+32",
          "PLX 4377.00",
          "NAME Ring Nebula",
          "[D71] 1851+32",
          "[FA87] 1851+329",
          "[A86] 1851+329",
          "B2.1 1851+32",
          "BWE 1851+3257",
          "GB6 B1851+3257",
          "MITG J185334+3301",
          "MITG J185337+3301",
          "NVSS J185335+330145",
          "WB 1851+3257",
          "87GB 185143.8+325740",
          "WN B1851.7+3257",
          "WEB 16043",
          "Gaia DR2 2090486618786534784"
        ],
        "searchKey": "gaiadr32090486618786534784|pnaro9|d71185132|web16043|bd323246|plx4377|iras185173257|gsc2n0223131306|m57|hd175353|wd1851329|csi3218517|mitgj1853343301|ringnebula|pk063131|87gb1851438325740|pnvv466|b21185132|ringnebula|wb18513257|plx437700|mitgj1853373301|gcrv11366|wnb185173257|png0631139|lfo93185132|gaiadr22090486618786534784|pnvv214|a861851329|bwe18513257|pk06313|fa871851329|nvssj185335330145|gb6b18513257|ngc6720",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.89308243497533,
        "dec": 33.02913424654,
        "fluxV": 15.769,
        "sizeMajor": 1.153,
        "sizeMinor": 1.153,
        "sizeAngle": 90.0,
        "simbadName": "M 57",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242002229231617",
        "name": "Whirlpool Galaxy",
        "names": [
          "ILT J132952.71+471143.3",
          "[NKB95] N5194",
          "[OKM2018] SWIFT J1329.9+4719",
          "PLX 3084",
          "4C 47.36A",
          "APG 85A",
          "APG 85",
          "BD+47 2063",
          "GB1 1327+475",
          "IRAS F13277+4727",
          "IRAS 13277+4727",
          "ISOSS J13299+4714",
          "KHG 1-C 5",
          "KPG 379a",
          "LEDA 47404",
          "M 51",
          "MCG+08-25-012",
          "NAME Whirlpool",
          "NGC 5194",
          "TC 827",
          "UGC 8493",
          "VV 403",
          "VV 1a",
          "VV 1",
          "Z 246-8",
          "[DML87] 671",
          "[H92] 27",
          "[LPS2002] 16",
          "[SLK2004] 853",
          "[T76] 85A",
          "[VDD93] 187A",
          "2MASX J13295269+4711429",
          "PLX 3084.00",
          "1RXS J132953.8+471143",
          "RX J1329.8+4711",
          "XMMU J132952.9+471140",
          "Z 1327.8+4727",
          "[CHM2007] LDC 867 J132952.69+4711429  [M98c] 132746.9+472716",
          "[VV2000c] J132952.4+471141",
          "[VV2003c] J132952.4+471141",
          "[VV2006c] J132952.4+471141",
          "[VV98c] J132952.7+471143",
          "[ZEH2003] RX J1329.8+4711 1",
          "UZC J132952.1+471144",
          "PSCz Q13277+4727",
          "2XMM J132952.5+471144",
          "[VV2010c] J132952.4+471141",
          "B3 1327+474C",
          "GB6 B1327+4727",
          "WB 1327+4727",
          "87GB 132747.8+472723",
          "WN B1327.8+4727",
          "BWE 1327+4727",
          "6C 132748+472801",
          "7C 132735.60+472604.00",
          "7C 132746.79+472722.00",
          "7C 132753.10+473032.00",
          "[CAB95] IRAS F13277+4727",
          "NAME Question Mark Galaxy",
          "NAME Whirlpool Galaxy"
        ],
        "searchKey": "vv403|z132784727|chm2007ldc867j132952694711429m98c1327469472716|leda47404|7c1327467947272200|6c132748472801|7c1327531047303200|isossj132994714|t7685a|b31327474c|apg85a|vv1|4c4736a|lps200216|zeh2003rxj1329847111|z2468|plx3084|questionmarkgalaxy|gb11327475|mcg0825012|rxj132984711|whirlpoolgalaxy|cab95irasf132774727|dml87671|vv98cj1329527471143|7c1327356047260400|whirlpoolgalaxy|wb13274727|bwe13274727|vv1a|2xmmj1329525471144|khg1c5|ngc5194|kpg379a|87gb1327478472723|tc827|slk2004853|xmmuj1329529471140|vv2010cj1329524471141|wnb132784727|vv2003cj1329524471141|vv2006cj1329524471141|iltj132952714711433|apg85|uzcj1329521471144|iras132774727|2masxj132952694711429|h9227|nkb95n5194|bd472063|whirlpool|plx308400|vdd93187a|okm2018swiftj132994719|ugc8493|vv2000cj1329524471141|m51|gb6b13274727|1rxsj1329538471143|psczq132774727|irasf132774727",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Messier51_sRGB.jpg/435px-Messier51_sRGB.jpg",
        "ra": 13.49797166666667,
        "dec": 47.19525833333333,
        "fluxV": 8.36,
        "sizeMajor": 10.0,
        "sizeMinor": 7.59,
        "sizeAngle": 163.0,
        "simbadName": "M 51",
        "imgCredit": "NASA and European Space Agency",
        "description": "The Whirlpool Galaxy, also known as Messier 51a (M51a) or NGC 5194, is an interacting grand-design spiral galaxy with a Seyfert 2 active galactic nucleus. It lies in the constellation Canes Venatici, and was the first galaxy to be classified as a spiral galaxy. It is 7.22 megaparsecs (23.5 million light-years) away and 23.58 kiloparsecs (76,900 ly) in diameter.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Whirlpool_Galaxy"
      },
      {
        "id": "944242354172428289",
        "name": "Sombrero Galaxy",
        "names": [
          "WISEA J123959.44-113723.2",
          "CRATES J123959-113709",
          "TGSSADR J123959.3-113723",
          "Gaia DR3 3530135742021065472",
          "HD 110124",
          "BD-10 3525",
          "PMN J1239-1137",
          "NVSS J123959-113723",
          "VCS4 J1239-1137",
          "2E 2814",
          "EQ 1237-113",
          "IERS B1237-113",
          "IRAS 12373-1121",
          "IRAS F12374-1120",
          "LEDA 42407",
          "M 104",
          "MCG-02-32-020",
          "NAME Sombrero",
          "NAME Sombrero Galaxy",
          "NGC 4594",
          "UGCA 293",
          "[DML87] 660",
          "2MASX J12395949-1137230",
          "JCMTSE J123959.3-113726",
          "JCMTSF J123959.3-113726",
          "1E 1237.3-1120",
          "1RXP J123959.2-113731",
          "1RXS J123959.8-113725",
          "2E 1237.3-1120",
          "6dFGS gJ123959.4-113723",
          "ICRF J123959.4-113722",
          "WISH B1237.3-1121",
          "[CHM2007] LDC 904 J123959.49-1137230",
          "[M98c] 123723.3-112055",
          "[VV2000c] J123959.3-113723",
          "[VV2003c] J123959.3-113723",
          "[VV2006c] J123959.3-113723",
          "[VV98c] J123959.3-113723",
          "[VV2010c] J123959.3-113723",
          "AT20G J123959-113721",
          "[CAB95] IRAS F12374-1120",
          "[NKB95] N4594"
        ],
        "searchKey": "gaiadr33530135742021065472|cab95irasf123741120|bd103525|m104|vv2006cj1239593113723|nvssj123959113723|vcs4j12391137|iras123731121|6dfgsgj1239594113723|at20gj123959113721|irasf123741120|leda42407|eq1237113|wishb123731121|nkb95n4594|dml87660|jcmtsfj1239593113726|jcmtsej1239593113726|1e123731120|vv98cj1239593113723|wiseaj123959441137232|pmnj12391137|mcg0232020|tgssadrj1239593113723|icrfj1239594113722|hd110124|vv2010cj1239593113723|chm2007ldc904j123959491137230|2e123731120|iersb1237113|2masxj123959491137230|1rxsj1239598113725|m98c1237233112055|cratesj123959113709|sombrerogalaxy|2e2814|ugca293|1rxpj1239592113731|vv2000cj1239593113723|sombrerogalaxy|vv2003cj1239593113723|ngc4594|sombrero",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/M104_ngc4594_sombrero_galaxy_hi-res.jpg/435px-M104_ngc4594_sombrero_galaxy_hi-res.jpg",
        "ra": 12.66650884972778,
        "dec": -11.62305449444444,
        "fluxV": 8.0,
        "sizeMajor": 8.51,
        "sizeMinor": 5.01,
        "sizeAngle": 90.0,
        "simbadName": "M 104",
        "imgCredit": "NASA/ESA and The Hubble Heritage Team",
        "description": "The Sombrero Galaxy (also known as Messier Object 104, M104 or NGC 4594) is a peculiar galaxy of unclear classification in the constellation borders of Virgo and Corvus, being about 9.55 megaparsecs (31.1 million light-years) from the Milky Way galaxy. It is a member of the Virgo II Groups, a series of galaxies and galaxy clusters strung out from the southern edge of the Virgo Supercluster. It has an isophotal diameter of approximately 29.09 to 32.32 kiloparsecs (94,900 to 105,000 light-years), making it slightly bigger in size than the Milky Way.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Sombrero_Galaxy"
      },
      {
        "id": "944242794096820225",
        "name": "M 106",
        "names": [
          "WN B1216.4+4735",
          "[ZEH2003] RX J1219.0+4717 1",
          "[KRL2007b] 111",
          "B3 1216+475",
          "GB1 1216+476",
          "LEDA 39600",
          "M 106",
          "MCG+08-22-104",
          "NGC 4258",
          "OHIO N 427",
          "RX J1219.0+4717",
          "UGC 7353",
          "VV 448",
          "XSS J12190+4715",
          "Z 243-67",
          "Z 244-3",
          "[CPF88] 121629.0+473500.0",
          "[VDD93] 154",
          "2MASX J12185761+4718133",
          "1RXP J121856.4+471755",
          "1RXS J121900.4+471747",
          "RX J1218.9+4718",
          "SAX J1219.7+4721",
          "UZC J121857.7+471820",
          "Z 1216.5+4735",
          "[CHM2007] HDC 706 J121857.61+4718133  [CHM2007] LDC 867 J121857.61+4718133",
          "[M98c] 121629.7+473455",
          "[VV2000c] J121857.5+471814",
          "[VV2003c] J121857.5+471814",
          "[VV2006c] J121857.5+471814",
          "[VV98c] J121857.5+471814",
          "PBC J1218.9+4718",
          "SWIFT J1219.4+4720",
          "SWIFT J1219.0+4715",
          "[VV2010c] J121857.5+471814",
          "[BAG2012] 184.7378+47.2874",
          "SDSS J121857.50+471814.3",
          "2XMM J121857.5+471814",
          "87GB 121625.0+473537",
          "GB6 B1216+4735",
          "MY 121625.0+471813.7",
          "WB 1216+4735",
          "7C 121620.39+473545.00",
          "7C 121626.19+473526.00",
          "7C 121635.39+473404.00",
          "VLSS J1218.9+4718",
          "[NKB95] N4258",
          "SWIFT J1218.9+4717"
        ],
        "searchKey": "swiftj121894717|2xmmj1218575471814|ugc7353|1rxsj1219004471747|vdd93154|pbcj121894718|z24367|nkb95n4258|mcg0822104|vv2003cj1218575471814|gb6b12164735|7c1216261947352600|chm2007hdc706j121857614718133chm2007ldc867j121857614718133|vv98cj1218575471814|rxj121894718|swiftj121944720|vv2000cj1218575471814|m106|krl2007b111|wnb121644735|xssj121904715|swiftj121904715|rxj121904717|z2443|z121654735|cpf8812162904735000|7c1216353947340400|b31216475|gb11216476|ohion427|saxj121974721|sdssj121857504718143|leda39600|ngc4258|zeh2003rxj1219047171|wb12164735|1rxpj1218564471755|vlssj121894718|2masxj121857614718133|7c1216203947354500|vv2010cj1218575471814|bag20121847378472874|vv448|vv2006cj1218575471814|my12162504718137|87gb1216250473537|uzcj1218577471820|m98c1216297473455",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.31600555555556,
        "dec": 47.30371944444444,
        "fluxV": 8.41,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 106",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "948211436817252353",
        "name": "NGC 2841",
        "names": [
          "Gaia DR3 1019152003689497600",
          "[PVK2003] 140.50750 +50.97528",
          "2E 2126",
          "EQ 0918+511",
          "IRAS 09185+5111",
          "IRAS F09186+5111",
          "K73 324",
          "LEDA 26512",
          "MCG+09-16-005",
          "NGC 2841",
          "QSO B0918+512",
          "TC 712",
          "UGC 4966",
          "Z 265-6",
          "[VDD93] 91",
          "[VV2000c] J092202.0+505840",
          "[VV2003c] J092202.0+505840",
          "[VV2006c] J092202.0+505840",
          "[VV98c] J092202.7+505836",
          "2MASX J09220265+5058353",
          "2E 0918.5+5111",
          "Z 0918.5+5112",
          "[M98c] 091834.9+511119",
          "UZC J092202.3+505845",
          "PSCz Q09185+5111",
          "[VV2010c] J092202.0+505840",
          "SDSS J092202.67+505835.9",
          "SDSS J092202.66+505835.7"
        ],
        "searchKey": "pvk2003140507505097528|vv2006cj0922020505840|psczq091855111|vv2000cj0922020505840|2masxj092202655058353|k73324|ngc2841|tc712|qsob0918512|eq0918511|uzcj0922023505845|2e091855111|vv2010cj0922020505840|mcg0916005|sdssj092202675058359|iras091855111|2e2126|leda26512|ugc4966|irasf091865111|sdssj092202665058357|vv2003cj0922020505840|vdd9391|m98c0918349511119|z091855112|z2656|gaiadr31019152003689497600|vv98cj0922027505836",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 9.367410519769333,
        "dec": 50.97659363413,
        "fluxV": 9.22,
        "sizeMajor": 8.13,
        "sizeMinor": 3.98,
        "sizeAngle": 147.0,
        "simbadName": "NGC 2841",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "948752825148604417",
        "name": "NGC 4038",
        "names": [
          "[M98c] 115919.0-183512",
          "LEDA 37967",
          "ESO-LV 572-0481",
          "ESO 572-47",
          "MCG-03-31-014",
          "RR95 207a",
          "ESO-LV 572-0470",
          "NGC 4038",
          "RR95 206a",
          "UGCA 264",
          "PKS 1159-185",
          "[DSB94] 80",
          "VV 245b",
          "SGC 115919-1835.2",
          "WISH B1159.3-1835A",
          "NVSS J120152-185205",
          "2MASX J12015300-1852033",
          "6dFGS gJ120153.0-185204",
          "SINGG HIPASS J1201-18",
          "HIPASS J1201-18"
        ],
        "searchKey": "2masxj120153001852033|leda37967|hipassj120118|dsb9480|rr95206a|6dfgsgj1201530185204|eso57247|esolv5720470|wishb115931835a|vv245b|sgc11591918352|pks1159185|m98c1159190183512|nvssj120152185205|ugca264|ngc4038|esolv5720481|mcg0331014|singghipassj120118|rr95207a",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.03138944444444,
        "dec": -18.86758888888889,
        "fluxV": null,
        "sizeMajor": 5.2,
        "sizeMinor": 3.1,
        "sizeAngle": 80.0,
        "simbadName": "NGC 4038",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950093545373237250",
        "name": "Stephan's Quartet",
        "names": [
          "XCLASS 22792",
          "HCG 92",
          "NAME Stephan's Quartet",
          "NAME Stephan's Quintet",
          "RSCG 82",
          "RX J2236.0+3358",
          "WBL 683",
          "[FK2002] 279",
          "BAX 338.9896+33.9599",
          "MLCG 1407",
          "V1CG 616"
        ],
        "searchKey": "hcg92|stephansquintet|stephansquartet|wbl683|mlcg1407|fk2002279|xclass22792|stephansquartet|v1cg616|rscg82|bax3389896339599|rxj223603358",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 22.59930555555555,
        "dec": 33.96,
        "fluxV": null,
        "sizeMajor": 3.2,
        "sizeMinor": 3.2,
        "sizeAngle": 90.0,
        "simbadName": "NAME Stephan's Quintet",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950094427380088834",
        "name": "HDF",
        "names": [
          "NAME HDF",
          "NAME HDF-N",
          "NAME HDFN",
          "NAME NHDF",
          "NAME Hubble Deep Field",
          "NAME Hubble Deep Field North",
          "NAME Northern Hubble Deep Field"
        ],
        "searchKey": "hdf|nhdf|hubbledeepfieldnorth|hdfn|hdf|northernhubbledeepfield|hubbledeepfield|hdfn",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "INDIGO",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.61375,
        "dec": 62.21611111111111,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Hubble Deep Field",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950094427487404034",
        "name": "Bug Nebula",
        "names": [
          "PMN J1713-3706",
          "MGPS J171344-370613",
          "[WBH2005] G349.509+1.056",
          "NVSS J171344-370611",
          "OH 349.509+01.056",
          "WISE J171344.51-370611.3",
          "AKARI-IRC-V1 J1713445-370611",
          "CD-36 11341",
          "CPC 18 8579",
          "ESO 392-5",
          "GCRV 9926",
          "GSC 07373-00818",
          "GUM 60",
          "HD 155520",
          "Hen 2-204",
          "IRAS 17103-3702",
          "MSX5C G349.5082+01.0551",
          "NGC 6302",
          "OHPN 1",
          "PK 349+01 1",
          "PN G349.5+01.0",
          "PN Sa 2-180",
          "PN ARO 502",
          "PN VV' 168",
          "PN VV 94",
          "RCW 124",
          "SCHD 190",
          "SCM 136",
          "SH 1-3",
          "SH 2-6",
          "WRAY 16-259",
          "[KC97c] G349.5+01.1",
          "[LFO93] 1710-37",
          "JCMTSF J171344.6-370608",
          "[CAB2011] G349.50+1.05",
          "NAME Bug Nebula",
          "AGAL G349.508+01.057",
          "WEB 14231"
        ],
        "searchKey": "mgpsj171344370613|schd190|sh13|bugnebula|wbh2005g3495091056|png3495010|cab2011g34950105|sh26|hen2204|nvssj171344370611|gcrv9926|pnsa2180|pmnj17133706|jcmtsfj1713446370608|oh34950901056|gum60|pk349011|iras171033702|cd3611341|wisej171344513706113|ohpn1|agalg34950801057|bugnebula|cpc188579|gsc0737300818|eso3925|pnvv168|hd155520|msx5cg3495082010551|scm136|rcw124|akariircv1j1713445370611|pnvv94|pnaro502|ngc6302|wray16259|web14231|kc97cg3495011|lfo93171037",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.22902777777778,
        "dec": -37.10305555555556,
        "fluxV": null,
        "sizeMajor": 0.47182336,
        "sizeMinor": 0.44727832,
        "sizeAngle": 68.0,
        "simbadName": "NGC 6302",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950094428443279362",
        "name": "UGC 1810",
        "names": [
          "Gaia DR3 332053546678251904",
          "APG 273",
          "KPG 64a",
          "LEDA 8961",
          "MCG+06-06-023",
          "UGC 1810",
          "VV 323",
          "VV 323a",
          "Z 523-28",
          "ZW V 223",
          "2MASX J02212870+3922326",
          "CGPG 0218.4+3909",
          "Z 0218.4+3909",
          "UZC J022128.6+392231",
          "[HVG99b] 02184+3909",
          "Gaia DR2 332053546677806592"
        ],
        "searchKey": "mcg0606023|vv323|ugc1810|zwv223|apg273|gaiadr3332053546678251904|2masxj022128703922326|cgpg021843909|z021843909|hvg99b021843909|vv323a|z52328|uzcj0221286392231|gaiadr2332053546677806592|leda8961|kpg64a",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.357973985155334,
        "dec": 39.37558926756,
        "fluxV": 12.92,
        "sizeMajor": 1.2,
        "sizeMinor": 0.504,
        "sizeAngle": 45.0,
        "simbadName": "UGC 1810",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950094428581920770",
        "name": "HELICAL NEB",
        "names": [
          "GJ 9785",
          "WISH B2226.9-2105",
          "GLEAM J222942-205017",
          "NVSS J222941-205025",
          "Gaia DR3 6628874205642084224",
          "TIC 69813909",
          "PLX 5437",
          "2E 4615",
          "CSI-21-22270",
          "ESO 602-22",
          "GCRV 14134",
          "GSC2 S32303301945",
          "IRAS 22267-2102",
          "IRAS X2226-210",
          "NAME HELICAL NEB",
          "NGC 7293",
          "PHL 287",
          "PK 036-57",
          "PK 036-57 1",
          "PKS 2226-211",
          "PKS J2229-2050",
          "PN G036.1-57.1",
          "PN ARO 17",
          "PN VV' 563",
          "PN VV 275",
          "USNO 271",
          "WD 2226-210",
          "[LFO93] 2226-21",
          "[RHK93] 18755",
          "uvby98 610160287",
          "2MASS J22293854-2050136",
          "PLX 5437.00",
          "2E 2226.9-2105",
          "2RXP J222938.8-205015",
          "2XMM J222938.5-205014",
          "[HBC92] 222654.7-210537",
          "NAME Helix Nebula",
          "WEB 19878",
          "Gaia DR2 6628874205642084224"
        ],
        "searchKey": "rhk9318755|2e222692105|2massj222938542050136|web19878|uvby98610160287|pk036571|gaiadr36628874205642084224|lfo93222621|ngc7293|usno271|wd2226210|csi2122270|pnaro17|nvssj222941205025|gsc2s32303301945|pnvv275|png0361571|gaiadr26628874205642084224|tic69813909|2e4615|plx543700|2xmmj2229385205014|gcrv14134|helicalneb|pks2226211|irasx2226210|hbc922226547210537|iras222672102|2rxpj2229388205015|plx5437|wishb222692105|gj9785|helixnebula|gleamj222942205017|pksj22292050|phl287|eso60222|helicalneb|pnvv563|pk03657",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 22.49404039019867,
        "dec": -20.83715201178,
        "fluxV": 13.524,
        "sizeMajor": 13.4,
        "sizeMinor": 13.4,
        "sizeAngle": 90.0,
        "simbadName": "NGC 7293",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950094428665217026",
        "name": "Tadpole Galaxy",
        "names": [
          "Gaia DR3 1621450076654263040",
          "2MFGC 12930",
          "APG 188",
          "Anon 1605+55",
          "LEDA 57129",
          "MCG+09-26-056",
          "UGC 10214",
          "VV 29",
          "VV 29a",
          "Z 1605.0+5533",
          "Z 275-23",
          "2MASX J16060394+5525313",
          "UZC J160604.0+552532",
          "SDSS J160603.92+552531.8",
          "NAME Tadpole Galaxy"
        ],
        "searchKey": "gaiadr31621450076654263040|leda57129|sdssj160603925525318|2mfgc12930|uzcj1606040552532|mcg0926056|z160505533|anon160555|vv29a|apg188|2masxj160603945525313|tadpolegalaxy|ugc10214|tadpolegalaxy|z27523|vv29",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.101085619244,
        "dec": 55.42544113908,
        "fluxV": null,
        "sizeMajor": 1.04,
        "sizeMinor": 0.27,
        "sizeAngle": 85.0,
        "simbadName": "VV 29",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950094428668002306",
        "name": "Sunflower Nebula",
        "names": [
          "[WPB2010] NEP J175833+663759",
          "Gaia DR3 1633325248915154176",
          "TYC 4212-508-1",
          "NVSS J175833+663758",
          "2XMM J175833.3+663800",
          "WN B1758.5+6638",
          "NAME Sunflower Nebula",
          "NAME Snail Nebula",
          "NAME Cat's Eye Nebula",
          "PLX 4128",
          "WMAP 64",
          "AG+66 812",
          "BD+66 1066",
          "BWE 1758+6637",
          "DO 36017",
          "EF B1758+6638",
          "EM* CDS 945",
          "GB6 J1758+6638",
          "GCRV 10447",
          "GSC2 N1121000443",
          "HD 164963",
          "IRAS F17585+6638",
          "IRAS 17584+6638A",
          "NGC 6543",
          "NSV 24075",
          "PK 096+29 1",
          "PN G096.4+29.9",
          "PN VV 143",
          "PN VV' 335",
          "PN ARO 6",
          "PPM 20679",
          "RAFGL 5429",
          "RGB J1758+666",
          "S4 1758+666",
          "TXS 1758+666",
          "WB 1758+6637",
          "ZW VII 759",
          "[DML87] 459",
          "[HH87] 3-52",
          "[HH87] 1-18",
          "[HH87] 68",
          "[HH87] 2-13",
          "2MASX J17583335+6637591",
          "WMAP J1758+6632",
          "PLX 4128.00",
          "87GB 175835.1+663759",
          "RX J1758.5+6637",
          "S4 1758+66",
          "AKARI-IRC-V1 J1758333+663759",
          "LRWR 370",
          "VLA -NEP J1758.6+6637",
          "WMAP J1759+6633",
          "WMAP J1758+6637",
          "WEB 14862",
          "Gaia DR2 1633325248915154176"
        ],
        "searchKey": "emcds945|hh87352|2xmmj1758333663800|wpb2010nepj175833663759|gb6j17586638|web14862|pnaro6|gaiadr21633325248915154176|lrwr370|irasf175856638|catseyenebula|hd164963|akariircv1j1758333663759|ag66812|wmapj17586637|rgbj1758666|snailnebula|gaiadr31633325248915154176|nsv24075|tyc42125081|pnvv143|wb17586637|pk096291|rxj175856637|pnvv335|wmapj17596633|s41758666|87gb1758351663759|zwvii759|ngc6543|gsc2n1121000443|hh87213|vlanepj175866637|dml87459|sunflowernebula|plx4128|gcrv10447|txs1758666|nvssj175833663758|hh87118|ppm20679|plx412800|wmap64|bd661066|rafgl5429|hh8768|2masxj175833356637591|wmapj17586632|png0964299|bwe17586637|efb17586638|s4175866|iras175846638a|sunflowernebula|wnb175856638|do36017",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.97594554409133,
        "dec": 66.632986315,
        "fluxV": 11.28,
        "sizeMajor": 0.623,
        "sizeMinor": 0.536,
        "sizeAngle": 10.0,
        "simbadName": "NGC 6543",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950094428709683202",
        "name": "V* V838 Mon",
        "names": [
          "[RMB2014] G217.79+01.05",
          "Gaia DR3 3107789548859766016",
          "WISEA J070404.82-035050.8",
          "TIC 33366214",
          "AAVSO 0659-03",
          "GSC 04822-00039",
          "IRAS 07015-0346",
          "NOVA Mon 2002",
          "USNO-A2.0 0825-03833116",
          "V* V838 Mon",
          "[WBM2003] 1",
          "EQ J0704.0-0350",
          "PSCz P07015-0346",
          "UCAC4 431-026681",
          "Gaia DR2 3107789548859766016",
          "USNO-B1.0 0861-00120000",
          "2MASS J07040482-0350506",
          "WISE J070404.82-035050.5",
          "DENIS J070404.8-035050"
        ],
        "searchKey": "tic33366214|wbm20031|eqj070400350|rmb2014g217790105|gaiadr33107789548859766016|wisej070404820350505|gaiadr23107789548859766016|usnoa20082503833116|gsc0482200039|2massj070404820350506|usnob10086100120000|novamon2002|vv838mon|ucac4431026681|denisj0704048035050|wiseaj070404820350508|aavso065903|iras070150346|psczp070150346",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.068006152638667,
        "dec": -3.84740247067,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* V838 Mon",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950094428995518466",
        "name": "UDF",
        "names": [
          "NAME UDF",
          "NAME HUDF",
          "NAME Hubble Ultra Deep Field"
        ],
        "searchKey": "hudf|hubbleultradeepfield|udf|udf",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 3.544166666666666,
        "dec": -27.79138888888889,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Hubble Ultra Deep Field",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950095179092951042",
        "name": "Cl Westerlund 2",
        "names": [
          "3FHL J1023.3-5747e",
          "Villafranca O-004",
          "C 1022-575",
          "ESO 127-18",
          "OCl 807",
          "[MBP2001] 82A",
          "Cl VDBH 95",
          "Cl Westerlund 2",
          "[KPS2012] MWSC 1801",
          "TeV J1023-575",
          "4FGL J1023.3-5747e"
        ],
        "searchKey": "c1022575|ocl807|clwesterlund2|tevj1023575|3fhlj102335747e|villafrancao004|mbp200182a|clvdbh95|4fglj102335747e|kps2012mwsc1801|eso12718",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 10.39947222222222,
        "dec": -57.76361111111111,
        "fluxV": null,
        "sizeMajor": 2.4,
        "sizeMinor": 2.4,
        "sizeAngle": null,
        "simbadName": "Cl Westerlund 2",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950095378152259586",
        "name": "NGC 1300",
        "names": [
          "Gaia DR3 5103388103701284864",
          "HIPASS J0319-19",
          "AGC 22472",
          "ESO 547-31",
          "ESO-LV 547-0310",
          "IRAS 03174-1935",
          "IRAS F03174-1935",
          "LEDA 12412",
          "MBG 03174-1935",
          "MCG-03-09-018",
          "NGC 1300",
          "UGCA 66",
          "[M98c] 031725.0-193530",
          "2MASX J03194107-1924408",
          "1E 0317.4-1935",
          "6dFGS gJ031941.1-192440",
          "SGC 031725-1935.5",
          "[CHM2007] LDC 251 J031941.07-1924408",
          "PSCz Q03174-1935",
          "Gaia DR2 5103388099406125056"
        ],
        "searchKey": "hipassj031919|gaiadr25103388099406125056|agc22472|eso54731|mcg0309018|irasf031741935|6dfgsgj0319411192440|ugca66|1e031741935|sgc03172519355|psczq031741935|ngc1300|esolv5470310|chm2007ldc251j031941071924408|mbg031741935|m98c0317250193530|leda12412|iras031741935|2masxj031941071924408|gaiadr35103388103701284864",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 3.328068394998,
        "dec": -19.41116321262,
        "fluxV": 10.42,
        "sizeMajor": 6.17,
        "sizeMinor": 3.47,
        "sizeAngle": 105.0,
        "simbadName": "NGC 1300",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950095378274648066",
        "name": "Bubble Nebula",
        "names": [
          "[ABB2014] WISE G112.212+00.229",
          "LBN 548",
          "NGC 7635",
          "SH 2-162",
          "[KC97c] G112.2+00.2b",
          "[L89b] 112.237+00.226",
          "GRS G112.22 +00.22",
          "NAME Bubble Nebula"
        ],
        "searchKey": "ngc7635|bubblenebula|bubblenebula|sh2162|grsg112220022|l89b11223700226|abb2014wiseg11221200229|kc97cg1122002b|lbn548",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 23.34675,
        "dec": 61.20166666666667,
        "fluxV": null,
        "sizeMajor": 41.666667938,
        "sizeMinor": 41.666667938,
        "sizeAngle": null,
        "simbadName": "NGC 7635",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950095378275991554",
        "name": "NGC 7049",
        "names": [
          "Gaia DR3 6479116843585467648",
          "AM 2115-484",
          "ESO 236-1",
          "ESO-LV 236-0010",
          "IRAS 21156-4846",
          "IRAS F21156-4846",
          "JB b 27",
          "LEDA 66549",
          "NGC 7049",
          "2MASX J21190024-4833432",
          "SGC 211537-4846.5",
          "[CHM2007] HDC 1152 J211900.24-4833432",
          "[CHM2007] LDC 1456 J211900.24-4833432  PSCz Q21156-4846"
        ],
        "searchKey": "sgc21153748465|ngc7049|am2115484|2masxj211900244833432|leda66549|gaiadr36479116843585467648|iras211564846|chm2007hdc1152j211900244833432|jbb27|esolv2360010|eso2361|chm2007ldc1456j211900244833432psczq211564846|irasf211564846",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "INDIGO",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.31674600900533,
        "dec": -48.56214263197,
        "fluxV": 10.74,
        "sizeMajor": 4.27,
        "sizeMinor": 2.95,
        "sizeAngle": 64.0,
        "simbadName": "NGC 7049",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959166914560001",
    "title": "Messier Objects",
    "color": "BLUE",
    "credit": "https://en.wikipedia.org/wiki/Messier_object",
    "imgURL": "/list_icons/messier.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "944241952512442369",
        "name": "Andromeda Galaxy",
        "names": [
          "PLX 124.00",
          "PPM 43228",
          "HD 3969",
          "BD+40 148",
          "AG+40 60",
          "PLX 124",
          "2C 56",
          "DA 21",
          "GIN 801",
          "IRAS F00400+4059",
          "IRAS 00400+4059",
          "IRC +40013",
          "K79 1C",
          "LEDA 2557",
          "M 31",
          "MCG+07-02-016",
          "NAME Andromeda",
          "NAME Andromeda Galaxy",
          "NGC 224",
          "RAFGL 104",
          "UGC 454",
          "XSS J00425+4102",
          "Z 535-17",
          "Z 0040.0+4100",
          "[DGW65] 4",
          "2MASX J00424433+4116074",
          "[M98c] 004000.1+405943",
          "[VV2000c] J004244.3+411610",
          "[VV2003c] J004244.3+411610",
          "[VV2006] J004244.3+411610",
          "[VV98c] J004245.1+411622",
          "UZC J004244.3+411608",
          "[VV2010] J004244.3+411610",
          "NAME And Nebula",
          "NAME Andromeda Nebula",
          "2MAXI J0043+412",
          "3FGL J0042.5+4117",
          "2FGL J0042.5+4114",
          "4FGL J0043.2+4114"
        ],
        "searchKey": "da21|dgw654|bd40148|2maxij0043412|2fglj004254114|hd3969|irasf004004059|z53517|m31|xssj004254102|iras004004059|vv2000cj0042443411610|4fglj004324114|andromeda|vv2006j0042443411610|plx12400|mcg0702016|ag4060|rafgl104|uzcj0042443411608|leda2557|ugc454|plx124|2c56|2masxj004244334116074|m98c0040001405943|irc40013|andromedanebula|andromedagalaxy|vv2010j0042443411610|vv2003cj0042443411610|z004004100|andnebula|3fglj004254117|ppm43228|k791c|andromedagalaxy|gin801|ngc224|vv98cj0042451411622",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/M31_09-01-2011_%28cropped%29.jpg/435px-M31_09-01-2011_%28cropped%29.jpg",
        "ra": 0.7123138888888888,
        "dec": 41.26875,
        "fluxV": 3.44,
        "sizeMajor": 199.53,
        "sizeMinor": 70.79,
        "sizeAngle": 35.0,
        "simbadName": "M 31",
        "imgCredit": "Torben Hansen",
        "description": "The Andromeda Galaxy is a barred spiral galaxy and is the nearest major galaxy to the Milky Way. It was originally named the Andromeda Nebula and is cataloged as Messier 31, M31, and NGC 224. Andromeda has a diameter of about 46.56 kiloparsecs (152,000 light-years) and is approximately 765 kpc (2.5 million light-years) from Earth. The galaxy's name stems from the area of Earth's sky in which it appears, the constellation of Andromeda, which itself is named after the princess who was the wife of Perseus in Greek mythology.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Andromeda_Galaxy"
      },
      {
        "id": "944241955830530049",
        "name": "Orion Nebula",
        "names": [
          "3C 145",
          "4C -05.21",
          "CTA 37",
          "LBN 209.13-19.35",
          "LBN 974",
          "M 42",
          "MSH 05-0-11",
          "Mills 05+0A",
          "NAME Great Orion Nebula",
          "NAME Ori Nebula",
          "NAME Orion Nebula",
          "NGC 1976",
          "NRL 6",
          "PKS 0532-054",
          "PKS 0532-05",
          "XSS J05351-0519",
          "[DGW65] 26",
          "[PT56] 6",
          "[WCO2009] J053517-052326",
          "GAL 209.01-19.4",
          "[KPS2012] MWSC 0582"
        ],
        "searchKey": "msh05011|orionnebula|pt566|4c0521|3c145|pks0532054|pks053205|ngc1976|dgw6526|lbn974|cta37|gal20901194|kps2012mwsc0582|wco2009j053517052326|lbn209131935|greatorionnebula|m42|xssj053510519|orinebula|orionnebula|nrl6|mills050a",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Orion_Nebula_-_Hubble_2006_mosaic_18000.jpg/450px-Orion_Nebula_-_Hubble_2006_mosaic_18000.jpg",
        "ra": 5.588,
        "dec": -5.390000000000001,
        "fluxV": null,
        "sizeMajor": 66.0,
        "sizeMinor": 66.0,
        "sizeAngle": 90.0,
        "simbadName": "M 42",
        "imgCredit": "NASA, ESA, M. Robberto",
        "description": "The Orion Nebula (also known as Messier 42, M42, or NGC 1976) is a diffuse nebula situated in the Milky Way, being south of Orion's Belt in the constellation of Orion, and is known as the middle \"star\" in the \"sword\" of Orion. It is one of the brightest nebulae and is visible to the naked eye in the night sky with apparent magnitude 4.0. It is 1,344 \u00b1 20 light-years (412.1 \u00b1 6.1 pc) away and is the closest region of massive star formation to Earth. The M42 nebula is estimated to be 24 light-years across (so its apparent size from Earth is approximately 1 degree). It has a mass of about 2,000 times that of the Sun. Older texts frequently refer to the Orion Nebula as the Great Nebula in Orion or the Great Orion Nebula.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Orion_Nebula"
      },
      {
        "id": "944241962644766721",
        "name": "Crab Nebula",
        "names": [
          "3HWC J0534+220",
          "2HWC J0534+220",
          "eHWC J0534+220",
          "1RXS J053431.2+220218",
          "2AGL J0534+2205",
          "SNR G184.6-05.8",
          "1H 0531+219",
          "1M 0531+219",
          "2C 481",
          "2E 1309",
          "2U 0531+22",
          "3A 0531+219",
          "3C 144",
          "3C 144.0",
          "3CR 144",
          "3U 0531+21",
          "4C 21.19",
          "4U 0531+21",
          "AJG 1",
          "CTA 36",
          "CTB 18",
          "Cul 0531+21",
          "Cul 0531+219",
          "DA 179",
          "DB 38",
          "H 0534+21",
          "H 0531+219",
          "IRAS 05314+2200",
          "LBN 833",
          "LBN 184.62-05.65",
          "M 1",
          "Mills 05+2A",
          "NAME CRAB NEB",
          "NAME Crab",
          "NAME Crab Nebula",
          "NAME Tau A",
          "NAME Taurus A",
          "NGC 1952",
          "NRAO 214",
          "NRL 2",
          "PKS 0531+219",
          "SH 2-244",
          "SIM 0531+21.0",
          "VRO 21.05.01",
          "X Tau X-1",
          "X Tau XR-1",
          "[BM83] X0531+219",
          "[DGW65] 25",
          "[PT56] 5",
          "1ES 0532+21.5",
          "2E 0531.5+2159",
          "PBC J0534.5+2201",
          "SWIFT J0534.6+2204",
          "SWIFT J0534.5+2200",
          "GRS G184.60 -05.80",
          "W 9",
          "NVSS J053428+220202",
          "ARGO J0535+2203",
          "3FGL J0534.5+2201i",
          "TeV J0534+220",
          "3FHL J0534.5+2201",
          "4FGL J0534.5+2201i"
        ],
        "searchKey": "3u053121|tevj0534220|3cr144|xtaux1|2c481|m1|taua|1m0531219|da179|ngc1952|2u053122|lbn184620565|crabneb|2e053152159|3a0531219|4fglj053452201i|1es0532215|grsg184600580|crabnebula|crabnebula|3hwcj0534220|pt565|ctb18|sh2244|xtauxr1|taurusa|nrao214|ajg1|dgw6525|2e1309|db38|nvssj053428220202|2aglj05342205|4u053121|h0531219|iras053142200|cta36|4c2119|lbn833|snrg1846058|mills052a|cul053121|nrl2|h053421|sim0531210|3fhlj053452201|3c1440|pbcj053452201|ehwcj0534220|1h0531219|swiftj053462204|w9|bm83x0531219|1rxsj0534312220218|crab|argoj05352203|pks0531219|3fglj053452201i|3c144|cul0531219|2hwcj0534220|vro210501|swiftj053452200",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.57525,
        "dec": 22.01472222222222,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 1",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241965995786241",
        "name": "Pleiades",
        "names": [
          "[KC2019] Theia 369",
          "C 0344+239",
          "H 0346+24",
          "M 45",
          "NAME Pleiades",
          "NAME Seven Sisters",
          "OCl 421.0",
          "[KPR2004b] 47",
          "Cl Melotte 22",
          "[KPS2012] MWSC 0305"
        ],
        "searchKey": "pleiades|clmelotte22|kpr2004b47|c0344239|m45|pleiades|kc2019theia369|ocl4210|h034624|sevensisters|kps2012mwsc0305",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 3.773388888888889,
        "dec": 24.11388888888889,
        "fluxV": null,
        "sizeMajor": 76.86,
        "sizeMinor": 76.86,
        "sizeAngle": null,
        "simbadName": "Cl Melotte 22",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241976047599617",
        "name": "Eagle Nebula",
        "names": [
          "Villafranca O-019",
          "C 1816-138",
          "CTB 51",
          "LBN 016.96+00.78",
          "LBN 67",
          "LMH 22",
          "M 16",
          "MM 21",
          "NGC 6611",
          "NRL 14",
          "OCISM 10",
          "OCl 54",
          "[KPR2004b] 441",
          "IC 4703",
          "GRS G017.00 +00.30",
          "[KPS2012] MWSC 2886",
          "NAME Eagle Nebula",
          "NAME Star Queen"
        ],
        "searchKey": "kpr2004b441|eaglenebula|villafrancao019|kps2012mwsc2886|lmh22|ocism10|ocl54|lbn67|grsg017000030|nrl14|ngc6611|lbn016960078|eaglenebula|mm21|starqueen|c1816138|ctb51|m16|ic4703",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.31252777777778,
        "dec": -13.79194444444444,
        "fluxV": null,
        "sizeMajor": 80.0,
        "sizeMinor": 80.0,
        "sizeAngle": null,
        "simbadName": "M 16",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241978420101121",
        "name": "Lagoon Nebula",
        "names": [
          "Villafranca O-018",
          "CTB 46",
          "Kes 58",
          "LBN 006.06-01.23",
          "LBN 25",
          "LMH 11",
          "M 8",
          "MM 10",
          "Mol 37",
          "NAME Lagoon",
          "NAME Lagoon Nebula",
          "NRL 11",
          "OCISM 1",
          "PMN J1803-2422",
          "SH 2-25",
          "[KC97c] G006.0-01.2",
          "[L89b] 5.973-01.178",
          "GRS G006.00 -01.20",
          "GRS G006.20 -01.20",
          "W 29"
        ],
        "searchKey": "sh225|lagoon|grsg006000120|ctb46|lmh11|mol37|pmnj18032422|w29|mm10|lagoonnebula|l89b597301178|grsg006200120|lagoonnebula|nrl11|kes58|lbn25|lbn006060123|m8|villafrancao018|kc97cg0060012|ocism1",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.06027777777778,
        "dec": -24.38666666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 8",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241988150820865",
        "name": "Trifid Nebula",
        "names": [
          "C 1759-230",
          "CSI-23-17593 1",
          "CTB 45",
          "LBN 006.99-00.17",
          "LBN 27",
          "LMH 12",
          "M 20",
          "MM 11",
          "MSH 17-2-16",
          "NAME Trifid Nebula",
          "NGC 6514",
          "NRL 10",
          "OCISM 2",
          "OCl 23.0",
          "[DGW65] 99",
          "[KPR2004b] 425",
          "[SC95] M 208",
          "[SC95] M 215",
          "Cl Collinder 360",
          "GRS G007.00 -00.30",
          "[KPS2012] MWSC 2789"
        ],
        "searchKey": "ocism2|kps2012mwsc2789|lmh12|sc95m208|ocl230|m20|dgw6599|c1759230|lbn006990017|kpr2004b425|mm11|csi23175931|ctb45|sc95m215|trifidnebula|ngc6514|msh17216|nrl10|lbn27|clcollinder360|trifidnebula|grsg007000030",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.045,
        "dec": -22.97166666666666,
        "fluxV": null,
        "sizeMajor": 28.0,
        "sizeMinor": 28.0,
        "sizeAngle": 90.0,
        "simbadName": "M 20",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241995678580737",
        "name": "Ring Nebula",
        "names": [
          "Gaia DR3 2090486618786534784",
          "PLX 4377",
          "BD+32 3246",
          "CSI+32-18517",
          "GCRV 11366",
          "GSC2 N0223131306",
          "HD 175353",
          "IRAS 18517+3257",
          "M 57",
          "NGC 6720",
          "PK 063+13 1",
          "PK 063+13",
          "PN G063.1+13.9",
          "PN ARO 9",
          "PN VV 214",
          "PN VV' 466",
          "WD 1851+329",
          "[LFO93] 1851+32",
          "PLX 4377.00",
          "NAME Ring Nebula",
          "[D71] 1851+32",
          "[FA87] 1851+329",
          "[A86] 1851+329",
          "B2.1 1851+32",
          "BWE 1851+3257",
          "GB6 B1851+3257",
          "MITG J185334+3301",
          "MITG J185337+3301",
          "NVSS J185335+330145",
          "WB 1851+3257",
          "87GB 185143.8+325740",
          "WN B1851.7+3257",
          "WEB 16043",
          "Gaia DR2 2090486618786534784"
        ],
        "searchKey": "gaiadr32090486618786534784|pnaro9|d71185132|web16043|bd323246|plx4377|iras185173257|gsc2n0223131306|m57|hd175353|wd1851329|csi3218517|mitgj1853343301|ringnebula|pk063131|87gb1851438325740|pnvv466|b21185132|ringnebula|wb18513257|plx437700|mitgj1853373301|gcrv11366|wnb185173257|png0631139|lfo93185132|gaiadr22090486618786534784|pnvv214|a861851329|bwe18513257|pk06313|fa871851329|nvssj185335330145|gb6b18513257|ngc6720",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.89308243497533,
        "dec": 33.02913424654,
        "fluxV": 15.769,
        "sizeMajor": 1.153,
        "sizeMinor": 1.153,
        "sizeAngle": 90.0,
        "simbadName": "M 57",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241998345437185",
        "name": "Dumbbell Nebula",
        "names": [
          "Gaia DR3 1827256624493300096",
          "2MASX J19593637+2243157",
          "PLX 4735",
          "2E 4308",
          "BD+22 3878",
          "CSI+22-19572",
          "GCRV 12336",
          "Hen 2-452",
          "IRAS 19574+2234",
          "M 27",
          "NGC 6853",
          "PK 060-03 1",
          "PK 060-03",
          "PN G060.8-03.6",
          "PN VV' 521",
          "PN VV 246",
          "RE J1959+224",
          "RE J195935+224359",
          "WD 1957+225",
          "PN ARO 14",
          "PLX 4735.00",
          "1RXS J195936.2+224309",
          "2E 1957.4+2235",
          "2EUVE J1959+22.7",
          "EUVE J1959+22.7",
          "RX J1959.6+2243",
          "NAME Diabolo Nebula",
          "NAME Dumbbell Nebula",
          "Gaia DR2 1827256624493300096"
        ],
        "searchKey": "rxj195962243|pk06003|plx473500|pnvv246|pk060031|rej195935224359|rej1959224|1rxsj1959362224309|png0608036|gcrv12336|euvej1959227|2masxj195936372243157|2e195742235|pnaro14|2e4308|gaiadr21827256624493300096|hen2452|diabolonebula|2euvej1959227|dumbbellnebula|ngc6853|dumbbellnebula|pnvv521|plx4735|csi2219572|iras195742234|bd223878|gaiadr31827256624493300096|wd1957225|m27",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.993434218058,
        "dec": 22.72119779432,
        "fluxV": 14.089,
        "sizeMajor": 0.133,
        "sizeMinor": 0.133,
        "sizeAngle": 90.0,
        "simbadName": "M 27",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242002229231617",
        "name": "Whirlpool Galaxy",
        "names": [
          "ILT J132952.71+471143.3",
          "[NKB95] N5194",
          "[OKM2018] SWIFT J1329.9+4719",
          "PLX 3084",
          "4C 47.36A",
          "APG 85A",
          "APG 85",
          "BD+47 2063",
          "GB1 1327+475",
          "IRAS F13277+4727",
          "IRAS 13277+4727",
          "ISOSS J13299+4714",
          "KHG 1-C 5",
          "KPG 379a",
          "LEDA 47404",
          "M 51",
          "MCG+08-25-012",
          "NAME Whirlpool",
          "NGC 5194",
          "TC 827",
          "UGC 8493",
          "VV 403",
          "VV 1a",
          "VV 1",
          "Z 246-8",
          "[DML87] 671",
          "[H92] 27",
          "[LPS2002] 16",
          "[SLK2004] 853",
          "[T76] 85A",
          "[VDD93] 187A",
          "2MASX J13295269+4711429",
          "PLX 3084.00",
          "1RXS J132953.8+471143",
          "RX J1329.8+4711",
          "XMMU J132952.9+471140",
          "Z 1327.8+4727",
          "[CHM2007] LDC 867 J132952.69+4711429  [M98c] 132746.9+472716",
          "[VV2000c] J132952.4+471141",
          "[VV2003c] J132952.4+471141",
          "[VV2006c] J132952.4+471141",
          "[VV98c] J132952.7+471143",
          "[ZEH2003] RX J1329.8+4711 1",
          "UZC J132952.1+471144",
          "PSCz Q13277+4727",
          "2XMM J132952.5+471144",
          "[VV2010c] J132952.4+471141",
          "B3 1327+474C",
          "GB6 B1327+4727",
          "WB 1327+4727",
          "87GB 132747.8+472723",
          "WN B1327.8+4727",
          "BWE 1327+4727",
          "6C 132748+472801",
          "7C 132735.60+472604.00",
          "7C 132746.79+472722.00",
          "7C 132753.10+473032.00",
          "[CAB95] IRAS F13277+4727",
          "NAME Question Mark Galaxy",
          "NAME Whirlpool Galaxy"
        ],
        "searchKey": "vv403|z132784727|chm2007ldc867j132952694711429m98c1327469472716|leda47404|7c1327467947272200|6c132748472801|7c1327531047303200|isossj132994714|t7685a|b31327474c|apg85a|vv1|4c4736a|lps200216|zeh2003rxj1329847111|z2468|plx3084|questionmarkgalaxy|gb11327475|mcg0825012|rxj132984711|whirlpoolgalaxy|cab95irasf132774727|dml87671|vv98cj1329527471143|7c1327356047260400|whirlpoolgalaxy|wb13274727|bwe13274727|vv1a|2xmmj1329525471144|khg1c5|ngc5194|kpg379a|87gb1327478472723|tc827|slk2004853|xmmuj1329529471140|vv2010cj1329524471141|wnb132784727|vv2003cj1329524471141|vv2006cj1329524471141|iltj132952714711433|apg85|uzcj1329521471144|iras132774727|2masxj132952694711429|h9227|nkb95n5194|bd472063|whirlpool|plx308400|vdd93187a|okm2018swiftj132994719|ugc8493|vv2000cj1329524471141|m51|gb6b13274727|1rxsj1329538471143|psczq132774727|irasf132774727",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/d/db/Messier51_sRGB.jpg/435px-Messier51_sRGB.jpg",
        "ra": 13.49797166666667,
        "dec": 47.19525833333333,
        "fluxV": 8.36,
        "sizeMajor": 10.0,
        "sizeMinor": 7.59,
        "sizeAngle": 163.0,
        "simbadName": "M 51",
        "imgCredit": "NASA and European Space Agency",
        "description": "The Whirlpool Galaxy, also known as Messier 51a (M51a) or NGC 5194, is an interacting grand-design spiral galaxy with a Seyfert 2 active galactic nucleus. It lies in the constellation Canes Venatici, and was the first galaxy to be classified as a spiral galaxy. It is 7.22 megaparsecs (23.5 million light-years) away and 23.58 kiloparsecs (76,900 ly) in diameter.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Whirlpool_Galaxy"
      },
      {
        "id": "944242005918744577",
        "name": "Bode's Galaxy",
        "names": [
          "RFC J0955+6903",
          "TGSSADR J095533.1+690354",
          "Gaia DR3 1070470609404512512",
          "HD 85534",
          "BD+69 543",
          "[LB2005] NGC 3031 X1",
          "2E 2195",
          "HIJASS J0955+69A",
          "IERS B0951+693",
          "IRAS F09514+6918",
          "IRAS 09514+6918",
          "K79 28A",
          "KPG 218a",
          "LEDA 28630",
          "M 81",
          "MCG+12-10-010",
          "NAME M 81*",
          "NGC 3031",
          "RBS 808",
          "RORF 0951+693",
          "RX J095532+69038",
          "SPB 115",
          "TC 714",
          "Gaia DR1 1070470605109644672",
          "UGC 5318",
          "Z 333-7",
          "[B93] 13",
          "[BAZ94] 3",
          "[DML87] 630",
          "[VDD93] 99",
          "2MASX J09553318+6903549",
          "1ES 0951+69.3",
          "1RXP J095532.8+690354",
          "1RXS J095534.7+690338",
          "2E 0951.4+6918",
          "EXO 0951.3+6917",
          "ICRF J095533.1+690355",
          "RX J0955.5+6903",
          "RX J095533.4+690353",
          "Z 0951.4+6918",
          "[CHM2007] HDC 552 J095533.18+6903549  [CHM2007] LDC 842 J095533.18+6903549",
          "[M98c] 095127.6+691813",
          "[VV2000c] J095533.2+690355",
          "[VV2003c] J095533.2+690355",
          "[VV2006] J095533.2+690355",
          "[VV98c] J095533.2+690355",
          "[ZEH2003] RX J0955.5+6903 1",
          "UZC J095535.5+690352",
          "PSCz Q09514+6918",
          "[VV2010] J095533.2+690355",
          "87GB 095125.0+691806",
          "GB6 B0951+6918",
          "NVSS J095533+690355",
          "WN B0951.4+6918",
          "2XMM J095533.1+690355",
          "SDSS J095533.16+690355.1",
          "[BAG2012] 149.0083+69.0811",
          "XMMSL1 J095534.3+690350",
          "SDSS J095533.14+690355.2",
          "BWE 0951+6918",
          "RGB J0955+690",
          "[CAB95] IRAS F09514+6918",
          "[NKB95] N3031",
          "NAME Bode's Galaxy",
          "SWIFT J0955.5+6907",
          "SWIFT J0955.9+6905",
          "Gaia DR2 1070470609404512512"
        ],
        "searchKey": "rgbj0955690|m81|2masxj095533186903549|mcg1210010|vv2006j0955332690355|2e095146918|iras095146918|2e2195|kpg218a|swiftj095596905|m81|rbs808|z3337|z095146918|vv2003cj0955332690355|psczq095146918|baz943|ugc5318|rorf0951693|wnb095146918|vv2000cj0955332690355|uzcj0955355690352|rfcj09556903|87gb0951250691806|zeh2003rxj0955569031|nvssj095533690355|nkb95n3031|iersb0951693|tgssadrj0955331690354|dml87630|vdd9399|vv98cj0955332690355|gaiadr21070470609404512512|rxj095556903|cab95irasf095146918|vv2010j0955332690355|exo095136917|swiftj095556907|gb6b09516918|leda28630|m98c0951276691813|bwe09516918|bd69543|rxj0955334690353|1rxsj0955347690338|sdssj095533166903551|sdssj095533146903552|k7928a|xmmsl1j0955343690350|gaiadr31070470609404512512|b9313|hijassj095569a|hd85534|rxj09553269038|tc714|bodesgalaxy|gaiadr11070470605109644672|2xmmj0955331690355|chm2007hdc552j095533186903549chm2007ldc842j095533186903549|bag20121490083690811|irasf095146918|lb2005ngc3031x1|ngc3031|spb115|1rxpj0955328690354|1es0951693|icrfj0955331690355|bodesgalaxy",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Messier_81_HST.jpg/450px-Messier_81_HST.jpg",
        "ra": 9.925881293236,
        "dec": 69.06529514038,
        "fluxV": 6.94,
        "sizeMajor": 21.38,
        "sizeMinor": 10.23,
        "sizeAngle": 157.0,
        "simbadName": "M 81",
        "imgCredit": "NASA, ESA and the Hubble Heritage Team",
        "description": "Messier 81 (also known as NGC 3031 or Bode's Galaxy) is a grand design spiral galaxy about 12 million light-years away in the constellation Ursa Major. It has a D25 isophotal diameter of 29.44 kiloparsecs (96,000 light-years). Because of its relative proximity to the Milky Way galaxy, large size, and active galactic nucleus (which harbors a 70 million M\u2609 supermassive black hole), Messier 81 has been studied extensively by professional astronomers. The galaxy's large size and relatively high brightness also makes it a popular target for amateur astronomers. In late February 2022, astronomers reported that M81 may be the source of FRB 20200120E, a repeating fast radio burst.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Messier_81"
      },
      {
        "id": "944242009715900417",
        "name": "Cigar Galaxy",
        "names": [
          "PCCS1 030 G141.43+40.58",
          "2MFGC 7685",
          "S4 0951+699",
          "1AXG J095549+6940",
          "1Jy 0951+69",
          "1Jy 0951+699",
          "2E 2197",
          "3C 231.0",
          "3C 231",
          "3CR 231",
          "4C 69.12",
          "4U 1004+70",
          "APG 337",
          "BWE 0951+6954",
          "DA 277",
          "GB6 B0951+6955",
          "HIJASS J0955+69B",
          "INTREF 396",
          "IRAS F09517+6954",
          "IRAS 09517+6954",
          "KPG 218b",
          "LEDA 28655",
          "M 82",
          "MCG+12-10-011",
          "NAME UMa A",
          "NGC 3034",
          "NRAO 341",
          "NVSS J095551+694046",
          "PRC D-13",
          "QSO B0951+699",
          "RAFGL 1388",
          "RBS 809",
          "RX J095550+69406",
          "S4 0951+69",
          "UGC 5322",
          "UT 0951+699",
          "WB 0951+6954",
          "Z 333-8",
          "[B93] 14",
          "[DML87] 745",
          "[DSL2000] 0951+700",
          "[R97] NGC 3031 1",
          "[RPS97] NGC 3034 2",
          "[SPS97] 8",
          "[VDD93] 100",
          "[WZX98] 09517+6954",
          "2MASX J09555243+6940469",
          "1E 0951+69.8",
          "1ES 0951+69.9",
          "1RXS J095550.4+694052",
          "2E 0951.7+6955",
          "87GB 095143.5+695452",
          "CXOM82 J095549.7+694043",
          "RGB J0955.9+6940",
          "RX J0955.8+6940",
          "WN B0951.7+6954",
          "WN B0951.7+6954A",
          "Z 0951.7+6955",
          "[CHM2007] HDC 552 J095552.43+6940469  [CHM2007] LDC 842 J095552.43+6940469",
          "[M98c] 095145.3+695511",
          "[TSA98] J095541.25+693927.31",
          "[ZEH2003] RX J0955.8+6940 1",
          "UZC J095557.0+694110",
          "PSCz Q09517+6954",
          "1FGL J0956.5+6938",
          "PBC J0955.7+6941",
          "PLCKERC -100 G141.42+40.57",
          "JVAS J0955+6940",
          "[BAG2012] 148.9211+69.6846",
          "2FGL J0955.9+6936",
          "SDSS J095551.73+694048.6",
          "WMAP J0955+6940",
          "6C 095143+695502",
          "7C 095143.50+695500.00",
          "8C 0951+699",
          "RGB J0955+696",
          "[CAB95] IRAS F09517+6954",
          "VLSS J0955.8+6940",
          "[NKB95] N3034",
          "NAME Cigar Galaxy",
          "3FGL J0955.4+6940",
          "SWIFT J0956.1+6942",
          "TeV J0955+696",
          "3FHL J0955.8+6939",
          "9Y-MST J0955+6939",
          "4FGL J0955.7+6940"
        ],
        "searchKey": "6c095143695502|dml87745|umaa|rxj09555069406|zeh2003rxj0955869401|1es0951699|iras095176954|rxj095586940|1fglj095656938|3fglj095546940|ugc5322|rgbj095596940|cxom82j0955497694043|b9314|2e095176955|nkb95n3034|1axgj0955496940|rafgl1388|gb6b09516955|jvasj09556940|9ymstj09556939|s4095169|rbs809|r97ngc30311|2mfgc7685|wmapj09556940|nvssj095551694046|rps97ngc30342|z095176955|2e2197|vdd93100|irasf095176954|da277|dsl20000951700|plckerc100g141424057|wzx98095176954|s40951699|kpg218b|4fglj095576940|3fhlj095586939|vlssj095586940|4c6912|2fglj095596936|3c231|2masxj095552436940469|prcd13|bwe09516954|7c0951435069550000|87gb0951435695452|3c2310|8c0951699|4u100470|sdssj095551736940486|nrao341|bag20121489211696846|hijassj095569b|3cr231|wnb095176954a|sps978|leda28655|cigargalaxy|1jy095169|tsa98j0955412569392731|m82|wnb095176954|cigargalaxy|chm2007hdc552j095552436940469chm2007ldc842j095552436940469|ngc3034|rgbj0955696|psczq095176954|cab95irasf095176954|pccs1030g141434058|intref396|tevj0955696|ut0951699|z3338|1e0951698|qsob0951699|uzcj0955570694110|1rxsj0955504694052|m98c0951453695511|swiftj095616942|wb09516954|pbcj095576941|mcg1210011|1jy0951699|apg337",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/M82_HST_ACS_2006-14-a-large_web.jpg/435px-M82_HST_ACS_2006-14-a-large_web.jpg",
        "ra": 9.931230555555555,
        "dec": 69.67970277777778,
        "fluxV": 8.41,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 82",
        "imgCredit": "NASA, ESA, and The Hubble Heritage Team",
        "description": "Messier 82 (also known as NGC 3034, Cigar Galaxy or M82) is a starburst galaxy approximately 12 million light-years away in the constellation Ursa Major. It is the second-largest member of the M81 Group, with the D25 isophotal diameter of 12.52 kiloparsecs (40,800 light-years). It is about five times more luminous than the Milky Way and its central region is about one hundred times more luminous. The starburst activity is thought to have been triggered by interaction with neighboring galaxy M81. As one of the closest starburst galaxies to Earth, M82 is the prototypical example of this galaxy type. SN 2014J, a type Ia supernova, was discovered in the galaxy on 21 January 2014. In 2014, in studying M82, scientists discovered the brightest pulsar yet known, designated M82 X-2.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Messier_82"
      },
      {
        "id": "944242012176351233",
        "name": "Black Eye Galaxy",
        "names": [
          "7C 125416.4+215657",
          "2E 2907",
          "ADBS J125711+2135",
          "EQ 1254+22",
          "IRAS F12542+2157",
          "IRAS 12542+2157",
          "IRAS 12543+2157",
          "ISOSS J12567+2140",
          "K73 559",
          "LEDA 44182",
          "M 64",
          "MCG+04-31-001",
          "NGC 4826",
          "RX J125643+21412",
          "UGC 8062",
          "Z 130-1",
          "[DSB94] 137",
          "[SLK2004] 755",
          "[VDD93] 176",
          "2MASX J12564369+2140575",
          "1RXS J125643.5+214106",
          "2E 1254.2+2157",
          "CAIRNS J125643.76+214051.9",
          "RX J1256.7+2141",
          "Z 1254.2+2157",
          "[GCS2005] J125643.63+214059.1",
          "[M98c] 125416.9+215718",
          "[VV2000c] J125643.9+214100",
          "[VV2003c] J125643.9+214100",
          "[VV2006c] J125643.9+214100",
          "[VV98c] J125644.3+214105",
          "UZC J125644.3+214059",
          "[RS2000] 201",
          "PSCz P12542+2157",
          "HIPASS J1256+21",
          "[VV2010c] J125643.9+214100",
          "BWE 1254+2157",
          "GB6 B1254+2157",
          "87GB 125417.4+215711",
          "NVSS J125643+214100",
          "[CAB95] IRAS F12542+2157",
          "[DC78] UGC 8062",
          "NAME Black Eye Galaxy",
          "NAME Evil Eye Galaxy",
          "EVCC 2248"
        ],
        "searchKey": "irasf125422157|rs2000201|hipassj125621|dc78ugc8062|evcc2248|dsb94137|iras125422157|vv2010cj1256439214100|vdd93176|eq125422|gcs2005j125643632140591|leda44182|rxj125672141|adbsj1257112135|k73559|evileyegalaxy|bwe12542157|7c1254164215657|vv2000cj1256439214100|cairnsj125643762140519|nvssj125643214100|ugc8062|m64|2masxj125643692140575|rxj12564321412|iras125432157|ngc4826|2e125422157|vv98cj1256443214105|gb6b12542157|cab95irasf125422157|vv2003cj1256439214100|psczp125422157|mcg0431001|z125422157|1rxsj1256435214106|slk2004755|m98c1254169215718|87gb1254174215711|z1301|blackeyegalaxy|uzcj1256443214059|vv2006cj1256439214100|blackeyegalaxy|isossj125672140|2e2907",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/NGC_4826_-_HST.png/450px-NGC_4826_-_HST.png",
        "ra": 12.94547111111111,
        "dec": 21.68265833333334,
        "fluxV": 8.52,
        "sizeMajor": 9.77,
        "sizeMinor": 4.9,
        "sizeAngle": 115.0,
        "simbadName": "M 64",
        "imgCredit": "Judy Schmidt",
        "description": "The Black Eye Galaxy (also called Sleeping Beauty Galaxy or Evil Eye Galaxy and designated Messier 64, M64, or NGC 4826) is a relatively isolated spiral galaxy 17 million light-years away in the mildly northern constellation of Coma Berenices. It was discovered by Edward Pigott in March 1779, and independently by Johann Elert Bode in April of the same year, as well as by Charles Messier the next year. A dark band of absorbing dust partially in front of its bright nucleus gave rise to its nicknames of the \"Black Eye\", \"Evil Eye\", or \"Sleeping Beauty\" galaxy. M64 is well known among amateur astronomers due to its form in small telescopes and visibility across inhabited latitudes.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Black_Eye_Galaxy"
      },
      {
        "id": "944242016663961601",
        "name": "Sunflower Galaxy",
        "names": [
          "Gaia DR3 1525844581388510208",
          "B3 1313+422",
          "BWE 1313+4217",
          "EQ 1313+422",
          "GB6 B1313+4217",
          "IRAS F13135+4217",
          "IRAS 13135+4217",
          "ISOSS J13158+4201",
          "LEDA 46153",
          "M 63",
          "MCG+07-27-054",
          "NGC 5055",
          "NVSS J131548+420147",
          "TC 822",
          "UGC 8334",
          "Z 217-23",
          "[H92] 26",
          "[SLK2004] 810",
          "[VDD93] 181",
          "2MASX J13154932+4201454",
          "87GB 131334.7+421742",
          "JCMTSE J131549.3+420147",
          "JCMTSF J131549.3+420147",
          "MY 131334.3+421820.6",
          "WN B1313.5+4217B",
          "WN B1313.5+4217A",
          "Z 1313.5+4217",
          "[CHM2007] HDC 706 J131549.32+4201454  [CHM2007] LDC 867 J131549.32+4201454  [M98c] 131334.9+421755",
          "[ZEH2003] RX J1315.8+4201 1",
          "UZC J131547.9+420201",
          "PSCz Q13135+4217",
          "SDSS J131549.26+420145.8",
          "WN B1313.5+4217",
          "MY 131334.3+420231.3",
          "6C 131336+421732",
          "7C 131335.39+421742.00",
          "[CAB95] IRAS F13135+4217",
          "[NKB95] N5055",
          "NAME Sunflower Galaxy",
          "Gaia DR2 1525844581388510208"
        ],
        "searchKey": "nvssj131548420147|jcmtsfj1315493420147|b31313422|87gb1313347421742|psczq131354217|my13133434218206|cab95irasf131354217|uzcj1315479420201|7c1313353942174200|z131354217|gaiadr21525844581388510208|nkb95n5055|iras131354217|isossj131584201|eq1313422|h9226|slk2004810|jcmtsej1315493420147|gb6b13134217|chm2007hdc706j131549324201454chm2007ldc867j131549324201454m98c1313349421755|zeh2003rxj1315842011|wnb131354217a|bwe13134217|mcg0727054|ugc8334|sdssj131549264201458|wnb131354217b|sunflowergalaxy|tc822|6c131336421732|2masxj131549324201454|gaiadr31525844581388510208|z21723|wnb131354217|leda46153|ngc5055|m63|vdd93181|irasf131354217|my13133434202313|sunflowergalaxy",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/M63_%28NGC_5055%29.jpg/435px-M63_%28NGC_5055%29.jpg",
        "ra": 13.26368727483133,
        "dec": 42.02936891003,
        "fluxV": 8.59,
        "sizeMajor": 13.18,
        "sizeMinor": 7.94,
        "sizeAngle": 102.0,
        "simbadName": "M 63",
        "imgCredit": "Hubble Space Telescope",
        "description": "Messier 63 or M63, also known as NGC 5055 or the seldom-used Sunflower Galaxy, is a spiral galaxy in the northern constellation of Canes Venatici with approximately 400 billion stars. M63 was first discovered by the French astronomer Pierre M\u00e9chain, then later verified by his colleague Charles Messier on June 14, 1779. The galaxy became listed as object 63 in the Messier Catalogue. In the mid-19th century, Anglo-Irish astronomer Lord Rosse identified spiral structures within the galaxy, making this one of the first galaxies in which such structure was identified.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Messier_63"
      },
      {
        "id": "944242351239495681",
        "name": "Pinwheel Galaxy",
        "names": [
          "APG 26",
          "IRAS F14012+5434",
          "IRAS 14013+5435",
          "K73 610",
          "LEDA 50063",
          "M 101",
          "MCG+09-23-028",
          "NAME Pinwheel",
          "NGC 5457",
          "SPB 243",
          "TC 302",
          "UGC 8981",
          "VV 344a",
          "VV 456",
          "VV 344",
          "Z 272-21",
          "2MASX J14031258+5420555",
          "Z 1401.5+5435",
          "[CHM2007] HDC 853 J140312.58+5420555  [CHM2007] LDC 842 J140312.58+5420555  [M98c] 140126.6+543525",
          "UZC J140312.5+542056",
          "PSCz Q14013+5435",
          "SDSS J140312.52+542056.2"
        ],
        "searchKey": "vv456|iras140135435|pinwheelgalaxy|leda50063|z140155435|irasf140125434|ugc8981|vv344a|uzcj1403125542056|m101|chm2007hdc853j140312585420555chm2007ldc842j140312585420555m98c1401266543525|k73610|spb243|sdssj140312525420562|pinwheel|tc302|z27221|2masxj140312585420555|mcg0923028|psczq140135435|ngc5457|apg26|vv344",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/M101_hires_STScI-PRC2006-10a.jpg/435px-M101_hires_STScI-PRC2006-10a.jpg",
        "ra": 14.05349527777778,
        "dec": 54.34875,
        "fluxV": 7.86,
        "sizeMajor": 21.88,
        "sizeMinor": 20.89,
        "sizeAngle": null,
        "simbadName": "M 101",
        "imgCredit": "European Space Agency & NASA",
        "description": "The Pinwheel Galaxy (also known as Messier 101, M101 or NGC 5457) is a face-on spiral galaxy 21 million light-years (6.4 megaparsecs) from Earth in the constellation Ursa Major. It was discovered by Pierre M\u00e9chain in 1781 and was communicated that year to Charles Messier, who verified its position for inclusion in the Messier Catalogue as one of its final entries.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Pinwheel_Galaxy"
      },
      {
        "id": "944242354172428289",
        "name": "Sombrero Galaxy",
        "names": [
          "WISEA J123959.44-113723.2",
          "CRATES J123959-113709",
          "TGSSADR J123959.3-113723",
          "Gaia DR3 3530135742021065472",
          "HD 110124",
          "BD-10 3525",
          "PMN J1239-1137",
          "NVSS J123959-113723",
          "VCS4 J1239-1137",
          "2E 2814",
          "EQ 1237-113",
          "IERS B1237-113",
          "IRAS 12373-1121",
          "IRAS F12374-1120",
          "LEDA 42407",
          "M 104",
          "MCG-02-32-020",
          "NAME Sombrero",
          "NAME Sombrero Galaxy",
          "NGC 4594",
          "UGCA 293",
          "[DML87] 660",
          "2MASX J12395949-1137230",
          "JCMTSE J123959.3-113726",
          "JCMTSF J123959.3-113726",
          "1E 1237.3-1120",
          "1RXP J123959.2-113731",
          "1RXS J123959.8-113725",
          "2E 1237.3-1120",
          "6dFGS gJ123959.4-113723",
          "ICRF J123959.4-113722",
          "WISH B1237.3-1121",
          "[CHM2007] LDC 904 J123959.49-1137230",
          "[M98c] 123723.3-112055",
          "[VV2000c] J123959.3-113723",
          "[VV2003c] J123959.3-113723",
          "[VV2006c] J123959.3-113723",
          "[VV98c] J123959.3-113723",
          "[VV2010c] J123959.3-113723",
          "AT20G J123959-113721",
          "[CAB95] IRAS F12374-1120",
          "[NKB95] N4594"
        ],
        "searchKey": "gaiadr33530135742021065472|cab95irasf123741120|bd103525|m104|vv2006cj1239593113723|nvssj123959113723|vcs4j12391137|iras123731121|6dfgsgj1239594113723|at20gj123959113721|irasf123741120|leda42407|eq1237113|wishb123731121|nkb95n4594|dml87660|jcmtsfj1239593113726|jcmtsej1239593113726|1e123731120|vv98cj1239593113723|wiseaj123959441137232|pmnj12391137|mcg0232020|tgssadrj1239593113723|icrfj1239594113722|hd110124|vv2010cj1239593113723|chm2007ldc904j123959491137230|2e123731120|iersb1237113|2masxj123959491137230|1rxsj1239598113725|m98c1237233112055|cratesj123959113709|sombrerogalaxy|2e2814|ugca293|1rxpj1239592113731|vv2000cj1239593113723|sombrerogalaxy|vv2003cj1239593113723|ngc4594|sombrero",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/M104_ngc4594_sombrero_galaxy_hi-res.jpg/435px-M104_ngc4594_sombrero_galaxy_hi-res.jpg",
        "ra": 12.66650884972778,
        "dec": -11.62305449444444,
        "fluxV": 8.0,
        "sizeMajor": 8.51,
        "sizeMinor": 5.01,
        "sizeAngle": 90.0,
        "simbadName": "M 104",
        "imgCredit": "NASA/ESA and The Hubble Heritage Team",
        "description": "The Sombrero Galaxy (also known as Messier Object 104, M104 or NGC 4594) is a peculiar galaxy of unclear classification in the constellation borders of Virgo and Corvus, being about 9.55 megaparsecs (31.1 million light-years) from the Milky Way galaxy. It is a member of the Virgo II Groups, a series of galaxies and galaxy clusters strung out from the southern edge of the Virgo Supercluster. It has an isophotal diameter of approximately 29.09 to 32.32 kiloparsecs (94,900 to 105,000 light-years), making it slightly bigger in size than the Milky Way.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Sombrero_Galaxy"
      },
      {
        "id": "944242358486532097",
        "name": "Triangulum Galaxy",
        "names": [
          "1AXG J013351+3039",
          "2E 409",
          "2E 0131.0+3024",
          "HIJASS J0133+30",
          "LEDA 5818",
          "M 33",
          "MCG+05-04-069",
          "NAME Triangulum Galaxy",
          "NAME Triangulum Pinwheel",
          "NGC 598",
          "RBS 214",
          "RX J013351+30399",
          "TC 906",
          "UGC 1117",
          "Z 0131.0+3024",
          "Z 502-110",
          "2MASX J01335090+3039357",
          "1ES 0131+30.3",
          "1RXS J013350.9+303932",
          "[CHM2007] LDC 160 J013350.90+3039357  [M98c] 013101.7+302415",
          "UZC J013351.1+303922"
        ],
        "searchKey": "z502110|1es0131303|2masxj013350903039357|chm2007ldc160j013350903039357m98c0131017302415|hijassj013330|m33|1axgj0133513039|triangulumgalaxy|triangulumgalaxy|rbs214|leda5818|ugc1117|triangulumpinwheel|uzcj0133511303922|tc906|1rxsj0133509303932|rxj01335130399|2e013103024|z013103024|2e409|mcg0504069|ngc598",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "INDIGO",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/VST_snaps_a_very_detailed_view_of_the_Triangulum_Galaxy.jpg/375px-VST_snaps_a_very_detailed_view_of_the_Triangulum_Galaxy.jpg",
        "ra": 1.564137937478667,
        "dec": 30.66017511198,
        "fluxV": 5.72,
        "sizeMajor": 60.26,
        "sizeMinor": 35.48,
        "sizeAngle": 22.0,
        "simbadName": "M 33",
        "imgCredit": "ESO",
        "description": "The Triangulum Galaxy is a spiral galaxy 2.73 million light-years (ly) from Earth in the constellation Triangulum. It is catalogued as Messier 33 or NGC (New General Catalogue) 598. With the D25 isophotal diameter of 18.74 kiloparsecs (61,100 light-years), the Triangulum Galaxy is the third-largest member of the Local Group of galaxies, behind the Andromeda Galaxy and the Milky Way.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Triangulum_Galaxy"
      },
      {
        "id": "944242455051763713",
        "name": "M 2",
        "names": [
          "BD-01 4175",
          "C 2130-010",
          "GCRV 13546",
          "GCl 121",
          "HD 205146",
          "M 2",
          "NGC 7089",
          "[KPS2012] MWSC 3526"
        ],
        "searchKey": "c2130010|ngc7089|gcrv13546|gcl121|hd205146|bd014175|m2|kps2012mwsc3526",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.55750555555556,
        "dec": -0.82325,
        "fluxV": 6.25,
        "sizeMajor": 12.9,
        "sizeMinor": 12.9,
        "sizeAngle": 90.0,
        "simbadName": "M 2",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242457935380481",
        "name": "M 3",
        "names": [
          "UBV M 19645",
          "HD 119333",
          "GEN# +1.00119333",
          "GCRV 8101",
          "BD+29 2450",
          "C 1339+286",
          "GCl 25",
          "M 3",
          "NGC 5272",
          "[ZEH2003] RX J1342.1+2822 1",
          "[KPS2012] MWSC 2152"
        ],
        "searchKey": "c1339286|gcl25|gen100119333|m3|bd292450|gcrv8101|kps2012mwsc2152|ngc5272|ubvm19645|hd119333|zeh2003rxj1342128221",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 13.70322777777778,
        "dec": 28.37727777777778,
        "fluxV": 6.39,
        "sizeMajor": 16.2,
        "sizeMinor": 16.2,
        "sizeAngle": 90.0,
        "simbadName": "M 3",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242461896704001",
        "name": "M 4",
        "names": [
          "C 1620-264",
          "GCl 41",
          "M 4",
          "NGC 6121",
          "[KPS2012] MWSC 2396",
          "CD-26 11314",
          "GCRV 5569 E",
          "HD 147552"
        ],
        "searchKey": "ngc6121|gcl41|kps2012mwsc2396|hd147552|cd2611314|m4|c1620264|gcrv5569e",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.39311666666667,
        "dec": -26.52575,
        "fluxV": null,
        "sizeMajor": 26.3,
        "sizeMinor": 26.3,
        "sizeAngle": 90.0,
        "simbadName": "M 4",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242466055880705",
        "name": "M 5",
        "names": [
          "C 1516+022",
          "GCl 34",
          "M 5",
          "NGC 5904",
          "[KPS2012] MWSC 2286",
          "GCRV 5244 E"
        ],
        "searchKey": "ngc5904|kps2012mwsc2286|m5|gcl34|c1516022|gcrv5244e",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 15.30922777777778,
        "dec": 2.081027777777778,
        "fluxV": 5.95,
        "sizeMajor": 17.4,
        "sizeMinor": 17.4,
        "sizeAngle": 90.0,
        "simbadName": "M 5",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242468436738049",
        "name": "M 6",
        "names": [
          "[KC2019] Theia 122",
          "C 1736-321",
          "M 6",
          "NGC 6405",
          "OCl 1030.0",
          "[KPR2004b] 408",
          "[SC95] M 38",
          "[SC95] M 43",
          "[SC96] GC 128",
          "[SC96] GC 130",
          "[SC96] GC 137",
          "[SC96] Mis 588",
          "[SC96] Mis 589",
          "[SC96] Mis 607",
          "Cl VDBH 242",
          "[KPS2012] MWSC 2661",
          "NAME Butterfly Cluster"
        ],
        "searchKey": "sc96mis589|c1736321|kc2019theia122|m6|kpr2004b408|sc95m43|butterflycluster|sc96gc137|kps2012mwsc2661|clvdbh242|ocl10300|sc96mis607|ngc6405|sc96gc128|sc96gc130|sc96mis588|sc95m38",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.67127777777778,
        "dec": -32.24194444444444,
        "fluxV": null,
        "sizeMajor": 20.0,
        "sizeMinor": 20.0,
        "sizeAngle": 90.0,
        "simbadName": "NGC 6405",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242470846922753",
        "name": "M 7",
        "names": [
          "[KC2019] Theia 692",
          "C 1750-348",
          "M 7",
          "NGC 6475",
          "OCl 1028.0",
          "[KPR2004b] 420",
          "[SC96] GC 228",
          "[SC96] GC 243",
          "[SC96] Mis 753",
          "[SC96] Mis 774",
          "Cl VDBH 254",
          "[KPS2012] MWSC 2739",
          "NAME Ptolemy's Cluster"
        ],
        "searchKey": "c1750348|kpr2004b420|sc96mis774|kps2012mwsc2739|clvdbh254|ptolemyscluster|ngc6475|kc2019theia692|sc96mis753|sc96gc243|ocl10280|m7|sc96gc228",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.89647222222222,
        "dec": -34.84111111111111,
        "fluxV": null,
        "sizeMajor": 100.1,
        "sizeMinor": 100.1,
        "sizeAngle": null,
        "simbadName": "NGC 6475",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242477396590593",
        "name": "M 9",
        "names": [
          "BD-18 4488",
          "C 1716-184",
          "FAUST 4082",
          "GCRV 9989",
          "GCl 60",
          "HD 156587",
          "M 9",
          "NGC 6333",
          "[FBA2002] 102",
          "[KPS2012] MWSC 2567"
        ],
        "searchKey": "hd156587|gcl60|bd184488|kps2012mwsc2567|m9|gcrv9989|ngc6333|fba2002102|c1716184|faust4082",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.31993888888889,
        "dec": -18.51625,
        "fluxV": 8.42,
        "sizeMajor": 8.7,
        "sizeMinor": 8.7,
        "sizeAngle": null,
        "simbadName": "M 9",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242480729686017",
        "name": "M 10",
        "names": [
          "C 1654-040",
          "GCl 49",
          "M 10",
          "NGC 6254",
          "[KPS2012] MWSC 2498",
          "GCRV 68711"
        ],
        "searchKey": "kps2012mwsc2498|gcl49|ngc6254|c1654040|m10|gcrv68711",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.95251388888889,
        "dec": -4.100305555555555,
        "fluxV": 4.98,
        "sizeMajor": 15.1,
        "sizeMinor": 15.1,
        "sizeAngle": 90.0,
        "simbadName": "M 10",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242483264749569",
        "name": "M 11",
        "names": [
          "[LP2019] 155",
          "NAME Amas de l'Ecu de Sobieski",
          "C 1848-063",
          "M 11",
          "NGC 6705",
          "OCl 76.0",
          "[KPR2004b] 453",
          "Cl Collinder 391",
          "Cl Melotte 213",
          "[KPS2012] MWSC 3008",
          "NAME Wild Duck Cluster"
        ],
        "searchKey": "c1848063|clmelotte213|lp2019155|wildduckcluster|kps2012mwsc3008|m11|ngc6705|ocl760|kpr2004b453|clcollinder391|amasdelecudesobieski",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.85105555555556,
        "dec": -6.271944444444444,
        "fluxV": 5.8,
        "sizeMajor": 8.9,
        "sizeMinor": 8.9,
        "sizeAngle": null,
        "simbadName": "NGC 6705",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242486539059201",
        "name": "M 12",
        "names": [
          "C 1644-018",
          "GCl 46",
          "M 12",
          "NGC 6218",
          "[KPS2012] MWSC 2464"
        ],
        "searchKey": "kps2012mwsc2464|m12|gcl46|c1644018|ngc6218",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.78727222222222,
        "dec": -1.948527777777778,
        "fluxV": 6.07,
        "sizeMajor": 14.5,
        "sizeMinor": 14.5,
        "sizeAngle": 90.0,
        "simbadName": "M 12",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242490594721793",
        "name": "M 13",
        "names": [
          "C 1639+365",
          "GCl 45",
          "M 13",
          "NGC 6205",
          "2MASX J16414163+3627407",
          "[KPS2012] MWSC 2445",
          "NAME Hercules Globular Cluster"
        ],
        "searchKey": "c1639365|gcl45|herculesglobularcluster|2masxj164141633627407|kps2012mwsc2445|m13|ngc6205",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.69489833333333,
        "dec": 36.46131944444445,
        "fluxV": 5.8,
        "sizeMajor": 33.0,
        "sizeMinor": 33.0,
        "sizeAngle": null,
        "simbadName": "M 13",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242494927568897",
        "name": "M 14",
        "names": [
          "C 1735-032",
          "GCl 72",
          "M 14",
          "NGC 6402",
          "BD-03 4142",
          "GCRV 10179",
          "HD 159974",
          "[KPS2012] MWSC 2643"
        ],
        "searchKey": "c1735032|gcrv10179|bd034142|gcl72|hd159974|m14|ngc6402|kps2012mwsc2643",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.62670833333333,
        "dec": -3.245916666666667,
        "fluxV": 5.73,
        "sizeMajor": 13.5,
        "sizeMinor": 13.5,
        "sizeAngle": null,
        "simbadName": "M 14",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242499278143489",
        "name": "M 15",
        "names": [
          "C 2127+119",
          "GCl 120",
          "M 15",
          "NGC 7078",
          "[KPS2012] MWSC 3518"
        ],
        "searchKey": "gcl120|m15|kps2012mwsc3518|ngc7078|c2127119",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.49953611111111,
        "dec": 12.167,
        "fluxV": null,
        "sizeMajor": 12.3,
        "sizeMinor": 12.3,
        "sizeAngle": 90.0,
        "simbadName": "M 15",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242505651683329",
        "name": "M 17",
        "names": [
          "Villafranca O-009",
          "[KC2019] Theia 846",
          "1E 1818.0-1610",
          "C 1817-162",
          "CTB 52",
          "LBN 60",
          "LBN 015.28-00.67",
          "LMH 21",
          "M 17",
          "MM 20",
          "NAME NGC 6618 Horseshoe",
          "NGC 6618",
          "NRL 4",
          "OCISM 9",
          "OCl 44",
          "OCl 44.0",
          "[BDB2003] G015.05-00.69",
          "[KPR2004b] 444",
          "[PT56] 41",
          "GRS G015.00 -00.70",
          "[KPS2012] MWSC 2896",
          "BD-16 4820",
          "GCRV 10811",
          "HD 168520",
          "W 38",
          "NAME Checkmark Nebula",
          "NAME Lobster Nebula",
          "NAME omega Nebula",
          "NAME Swan Nebula",
          "WEB 15351"
        ],
        "searchKey": "nrl4|swannebula|bdb2003g015050069|lmh21|pt5641|kc2019theia846|kps2012mwsc2896|gcrv10811|hd168520|lbn60|c1817162|ocl440|m17|lbn015280067|ctb52|1e181801610|ocism9|mm20|kpr2004b444|ocl44|ngc6618|checkmarknebula|bd164820|ngc6618horseshoe|grsg015000070|web15351|w38|omeganebula|lobsternebula|villafrancao009",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.34638888888889,
        "dec": -16.17166666666667,
        "fluxV": null,
        "sizeMajor": 1.63,
        "sizeMinor": 1.63,
        "sizeAngle": 90.0,
        "simbadName": "NGC 6618",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242508010749953",
        "name": "M 18",
        "names": [
          "C 1817-171",
          "M 18",
          "NGC 6613",
          "OCISM 8",
          "OCl 40",
          "[KPR2004b] 443",
          "[SC95] M 548",
          "[SC96] Mis 1288",
          "[KPS2012] MWSC 2892"
        ],
        "searchKey": "c1817171|kps2012mwsc2892|m18|ocism8|sc96mis1288|ngc6613|sc95m548|ocl40|kpr2004b443",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.33261111111111,
        "dec": -17.08888888888889,
        "fluxV": null,
        "sizeMajor": 5.0,
        "sizeMinor": 5.0,
        "sizeAngle": 90.0,
        "simbadName": "M 18",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242510403436545",
        "name": "M 19",
        "names": [
          "CD-26 11823",
          "C 1659-262",
          "GCRV 9819",
          "GCl 52",
          "HD 153799",
          "M 19",
          "NGC 6273",
          "[KPS2012] MWSC 2519"
        ],
        "searchKey": "m19|ngc6273|kps2012mwsc2519|cd2611823|gcrv9819|hd153799|c1659262|gcl52",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "CYAN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.04380277777778,
        "dec": -26.26794444444444,
        "fluxV": 5.57,
        "sizeMajor": 9.6,
        "sizeMinor": 9.6,
        "sizeAngle": null,
        "simbadName": "M 19",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242515855966209",
        "name": "M 21",
        "names": [
          "C 1801-225",
          "M 21",
          "NGC 6531",
          "OCISM 3",
          "OCl 26.0",
          "[KPR2004b] 427",
          "[SC95] M 248",
          "[SC96] GC 372",
          "[SC96] Mis 946",
          "[KPS2012] MWSC 2796"
        ],
        "searchKey": "ocl260|sc95m248|ocism3|c1801225|m21|kps2012mwsc2796|ngc6531|sc96mis946|sc96gc372|kpr2004b427",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.06905555555555,
        "dec": -22.505,
        "fluxV": null,
        "sizeMajor": 110.0,
        "sizeMinor": 110.0,
        "sizeAngle": null,
        "simbadName": "M 21",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242518955294721",
        "name": "M 22",
        "names": [
          "C 1833-239",
          "CD-24 14516",
          "GCRV 11055",
          "GCl 99",
          "HD 171560",
          "M 22",
          "NGC 6656",
          "1E 1833.3-2357",
          "[KPS2012] MWSC 2961",
          "GCRV 6244 E"
        ],
        "searchKey": "c1833239|gcrv11055|gcl99|m22|hd171560|gcrv6244e|ngc6656|cd2414516|1e183332357|kps2012mwsc2961",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.60665,
        "dec": -23.90475,
        "fluxV": null,
        "sizeMajor": 24.0,
        "sizeMinor": 24.0,
        "sizeAngle": 90.0,
        "simbadName": "M 22",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242522222821377",
        "name": "M 23",
        "names": [
          "[KC2019] Theia 653",
          "C 1753-190",
          "M 23",
          "NGC 6494",
          "[KPR2004b] 422",
          "[KPS2012] MWSC 2757"
        ],
        "searchKey": "c1753190|kps2012mwsc2757|ngc6494|kc2019theia653|kpr2004b422|m23",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.94913888888889,
        "dec": -18.98694444444445,
        "fluxV": null,
        "sizeMajor": 35.0,
        "sizeMinor": 35.0,
        "sizeAngle": null,
        "simbadName": "M 23",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242524582510593",
        "name": "M 24",
        "names": [
          "IC 4715",
          "M 24",
          "NAME Small Sgr Star Cloud"
        ],
        "searchKey": "m24|ic4715|smallsgrstarcloud",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.28,
        "dec": -18.55,
        "fluxV": null,
        "sizeMajor": 120.0,
        "sizeMinor": 60.0,
        "sizeAngle": 90.0,
        "simbadName": "M 24",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242528691224577",
        "name": "M 25",
        "names": [
          "[KC2019] Theia 650",
          "C 1828-192",
          "IC 4725",
          "M 25",
          "[KPR2004b] 449",
          "[SC95] M 662",
          "[SC95] M 667",
          "[SC96] Mis 1423",
          "[KPS2012] MWSC 2940"
        ],
        "searchKey": "kps2012mwsc2940|kpr2004b449|ic4725|sc95m662|sc96mis1423|m25|kc2019theia650|c1828192|sc95m667",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.52913888888889,
        "dec": -19.11388888888889,
        "fluxV": null,
        "sizeMajor": 31.3,
        "sizeMinor": 31.3,
        "sizeAngle": null,
        "simbadName": "IC 4725",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242531145875457",
        "name": "M 26",
        "names": [
          "C 1842-094",
          "M 26",
          "NGC 6694",
          "OCISM 13",
          "OCl 67",
          "OCl 67.0",
          "[KPR2004b] 451",
          "[KPS2012] MWSC 2987"
        ],
        "searchKey": "kpr2004b451|kps2012mwsc2987|ocism13|ocl67|ngc6694|c1842094|m26|ocl670",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.75447222222222,
        "dec": -9.386111111111111,
        "fluxV": 8.87,
        "sizeMajor": 7.1,
        "sizeMinor": 7.1,
        "sizeAngle": null,
        "simbadName": "M 26",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242537579315201",
        "name": "M 28",
        "names": [
          "C 1821-249",
          "GCl 94",
          "M 28",
          "NGC 6626",
          "[KPS2012] MWSC 2908",
          "CD-24 14289",
          "CPD-24 6385",
          "GCRV 10873",
          "HD 169199"
        ],
        "searchKey": "hd169199|kps2012mwsc2908|m28|cpd246385|ngc6626|c1821249|gcrv10873|cd2414289|gcl94",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.40913611111111,
        "dec": -24.86983333333333,
        "fluxV": null,
        "sizeMajor": 11.2,
        "sizeMinor": 11.2,
        "sizeAngle": 90.0,
        "simbadName": "M 28",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242540370001921",
        "name": "M 29",
        "names": [
          "C 2022+383",
          "M 29",
          "NGC 6913",
          "OCISM 36",
          "OCl 168",
          "[KPR2004b] 488",
          "[KPS2012] MWSC 3329"
        ],
        "searchKey": "ocl168|kpr2004b488|kps2012mwsc3329|m29|ocism36|ngc6913|c2022383",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.39619444444444,
        "dec": 38.48694444444445,
        "fluxV": 6.6,
        "sizeMajor": 12.7,
        "sizeMinor": 12.7,
        "sizeAngle": null,
        "simbadName": "M 29",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242543749169153",
        "name": "M 30",
        "names": [
          "C 2137-234",
          "GCl 122",
          "M 30",
          "NGC 7099",
          "[KPS2012] MWSC 3543",
          "CD-23 17047",
          "CPD-23 8016",
          "GCRV 13607",
          "HD 206107"
        ],
        "searchKey": "m30|gcrv13607|ngc7099|c2137234|cpd238016|gcl122|hd206107|cd2317047|kps2012mwsc3543",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.67281111111111,
        "dec": -23.17986111111111,
        "fluxV": 7.1,
        "sizeMajor": 11.0,
        "sizeMinor": 11.0,
        "sizeAngle": 90.0,
        "simbadName": "M 30",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242552248991745",
        "name": "M 32",
        "names": [
          "WISE J004241.80+405154.9",
          "2MASS J00424182+4051547",
          "AP J00424183+4051550",
          "APG 168",
          "Ark 12",
          "IRAS 00399+4035",
          "K79 1B",
          "LEDA 2555",
          "M 32",
          "MCG+07-02-015",
          "NGC 221",
          "UGC 452",
          "Z 535-16",
          "2MASX J00424182+4051546",
          "[FWB89] Galaxy 8",
          "RX J0042.6+4052",
          "Z 0039.9+4036",
          "[CHM2007] LDC 31 J004241.82+4051546",
          "[CHM2007] HDC 29 J004241.82+4051546  [M98c] 003957.7+403529",
          "UZC J004241.8+405154",
          "AG+40 59",
          "BD+40 147",
          "PPM 43225"
        ],
        "searchKey": "mcg0702015|z53516|iras003994035|apg168|z003994036|leda2555|ugc452|ag4059|apj004241834051550|2massj004241824051547|ark12|wisej004241804051549|bd40147|chm2007ldc31j004241824051546|rxj004264052|uzcj0042418405154|2masxj004241824051546|ppm43225|ngc221|fwb89galaxy8|chm2007hdc29j004241824051546m98c0039577403529|m32|k791b",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 0.711618,
        "dec": 40.86517,
        "fluxV": 8.08,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 32",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242559314984961",
        "name": "M 34",
        "names": [
          "[KC2019] Theia 251",
          "C 0238+425",
          "M 34",
          "NGC 1039",
          "OCl 382",
          "[KPR2004b] 36",
          "[KPS2012] MWSC 0223"
        ],
        "searchKey": "c0238425|ocl382|kpr2004b36|m34|kc2019theia251|ngc1039|kps2012mwsc0223",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.702055555555556,
        "dec": 42.72194444444445,
        "fluxV": null,
        "sizeMajor": 48.4,
        "sizeMinor": 48.4,
        "sizeAngle": null,
        "simbadName": "NGC 1039",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242562766766081",
        "name": "M 35",
        "names": [
          "[KC2019] Theia 203",
          "C 0605+243",
          "M 35",
          "NGC 2168",
          "OCl 466.0",
          "[KPR2004b] 87",
          "[KPS2012] MWSC 0754"
        ],
        "searchKey": "kps2012mwsc0754|kc2019theia203|ocl4660|c0605243|m35|kpr2004b87|ngc2168",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 6.151472222222223,
        "dec": 24.33611111111111,
        "fluxV": null,
        "sizeMajor": 38.3,
        "sizeMinor": 38.3,
        "sizeAngle": null,
        "simbadName": "NGC 2168",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242565123145729",
        "name": "M 36",
        "names": [
          "C 0532+341",
          "M 36",
          "NGC 1960",
          "OCISM 103",
          "OCl 445",
          "[KPR2004b] 78",
          "[KPS2012] MWSC 0594"
        ],
        "searchKey": "ocism103|m36|kpr2004b78|ngc1960|c0532341|ocl445|kps2012mwsc0594",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "CYAN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.605611111111111,
        "dec": 34.135,
        "fluxV": 6.0,
        "sizeMajor": 120.0,
        "sizeMinor": 120.0,
        "sizeAngle": null,
        "simbadName": "M 36",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242567538769921",
        "name": "M 37",
        "names": [
          "C 0549+325",
          "M 37",
          "NGC 2099",
          "[KPR2004b] 82",
          "[KPS2012] MWSC 0689"
        ],
        "searchKey": "ngc2099|kpr2004b82|kps2012mwsc0689|m37|c0549325",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.871611111111112,
        "dec": 32.54499999999999,
        "fluxV": 5.6,
        "sizeMajor": 19.3,
        "sizeMinor": 19.3,
        "sizeAngle": null,
        "simbadName": "M 37",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242570772807681",
        "name": "M 38",
        "names": [
          "[KC2019] Theia 874",
          "C 0525+358",
          "M 38",
          "NGC 1912",
          "OCl 433.0",
          "[KPR2004b] 71",
          "[KPS2012] MWSC 0557"
        ],
        "searchKey": "kc2019theia874|ocl4330|kpr2004b71|c0525358|kps2012mwsc0557|m38|ngc1912",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.477805555555555,
        "dec": 35.8238888888889,
        "fluxV": 6.4,
        "sizeMajor": 19.6,
        "sizeMinor": 19.6,
        "sizeAngle": null,
        "simbadName": "M 38",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242573168377857",
        "name": "M 39",
        "names": [
          "[KC2019] Theia 517",
          "C 2130+482",
          "M 39",
          "NGC 7092",
          "OCl 211.0",
          "[KPR2004b] 499",
          "[KPS2012] MWSC 3521"
        ],
        "searchKey": "ocl2110|kpr2004b499|kps2012mwsc3521|ngc7092|c2130482|m39|kc2019theia517",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.52594444444444,
        "dec": 48.24694444444444,
        "fluxV": null,
        "sizeMajor": 120.4,
        "sizeMinor": 120.4,
        "sizeAngle": null,
        "simbadName": "NGC 7092",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242576411820033",
        "name": "M 40",
        "names": [
          "M 40",
          "NAME Winnecke 4"
        ],
        "searchKey": "winnecke4|m40",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.37,
        "dec": 58.08333333333334,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 40",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242578818007041",
        "name": "M 41",
        "names": [
          "[KC2019] Theia 476",
          "C 0644-206",
          "M 41",
          "NGC 2287",
          "OCl 597.0",
          "[KPR2004b] 110",
          "[KPS2012] MWSC 0978"
        ],
        "searchKey": "c0644206|kc2019theia476|kps2012mwsc0978|ocl5970|m41|kpr2004b110|ngc2287",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 6.766611111111111,
        "dec": -20.71611111111111,
        "fluxV": 4.5,
        "sizeMajor": 39.8,
        "sizeMinor": 39.8,
        "sizeAngle": null,
        "simbadName": "M 41",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242586369228801",
        "name": "M 43",
        "names": [
          "M 43",
          "NAME Mairan's Nebula",
          "NGC 1982",
          "RAFGL 779",
          "GRS G208.90 -19.30"
        ],
        "searchKey": "grsg208901930|mairansnebula|rafgl779|ngc1982|m43",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.591944444444444,
        "dec": -5.27,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 43",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242589320609793",
        "name": "M 44",
        "names": [
          "[KC2019] Theia 1184",
          "C 0837+201",
          "M 44",
          "NAME Beehive",
          "NAME Praesepe",
          "NGC 2632",
          "OCl 507.0",
          "[KPR2004b] 201",
          "Cl Melotte 88",
          "[KPS2012] MWSC 1527",
          "NAME Praesepe Cluster"
        ],
        "searchKey": "c0837201|ngc2632|kps2012mwsc1527|beehive|praesepecluster|m44|kc2019theia1184|ocl5070|praesepe|kpr2004b201|clmelotte88",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 8.670277777777777,
        "dec": 19.62111111111111,
        "fluxV": null,
        "sizeMajor": 118.2,
        "sizeMinor": 118.2,
        "sizeAngle": null,
        "simbadName": "NGC 2632",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242596176494593",
        "name": "M 46",
        "names": [
          "C 0739-147",
          "M 46",
          "NGC 2437",
          "OCl 601.0",
          "[KPR2004b] 160",
          "[KPS2012] MWSC 1313"
        ],
        "searchKey": "ocl6010|c0739147|ngc2437|kps2012mwsc1313|kpr2004b160|m46",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.696333333333333,
        "dec": -14.84388888888889,
        "fluxV": null,
        "sizeMajor": 25.3,
        "sizeMinor": 25.3,
        "sizeAngle": null,
        "simbadName": "NGC 2437",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242598545260545",
        "name": "M 47",
        "names": [
          "[KC2019] Theia 320",
          "C 0734-143",
          "M 47",
          "NGC 2422",
          "OCl 596.0",
          "[KPR2004b] 147",
          "[KPS2012] MWSC 1278"
        ],
        "searchKey": "m47|kpr2004b147|ngc2422|ocl5960|kps2012mwsc1278|kc2019theia320|c0734143",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.609805555555555,
        "dec": -14.48888888888889,
        "fluxV": null,
        "sizeMajor": 31.1,
        "sizeMinor": 31.1,
        "sizeAngle": null,
        "simbadName": "NGC 2422",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242600907898881",
        "name": "M 48",
        "names": [
          "[KC2019] Theia 870",
          "C 0811-056",
          "M 48",
          "NGC 2548",
          "[KPR2004b] 189",
          "[KPS2012] MWSC 1454"
        ],
        "searchKey": "kc2019theia870|ngc2548|kpr2004b189|kps2012mwsc1454|c0811056|m48",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 8.227472222222222,
        "dec": -5.726111111111111,
        "fluxV": null,
        "sizeMajor": 44.3,
        "sizeMinor": 44.3,
        "sizeAngle": null,
        "simbadName": "NGC 2548",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242603837358081",
        "name": "M 49",
        "names": [
          "GLEAM J122946+075958",
          "TGSSADR J122946.6+080006",
          "ACSVCS 1",
          "1H 1228+081",
          "1M 1231+071",
          "2E 2735",
          "2U 1231+07",
          "3U 1231+07",
          "4U 1232+07",
          "APG 134",
          "GIN 781",
          "INTREF 501",
          "LEDA 41220",
          "M 49",
          "MCG+01-32-083",
          "NGC 4472",
          "NVSS B122714+081639",
          "UGC 7629",
          "VCC 1226",
          "XSS J12312+0833",
          "Z 42-134",
          "[DLB87] V9",
          "[DSB94] 225",
          "2MASX J12294679+0800014",
          "[FWB89] Galaxy 265",
          "1E 1227.5+0817",
          "1ES 1227+08.2",
          "2E 1227.2+0816",
          "87GB 122714.6+081649",
          "RGB J1229.8+0800",
          "RX J1229.7+0759",
          "UZC J122946.6+075958",
          "Z 1227.2+0816",
          "[CHM2007] HDC 720 J122946.79+0800014  [CHM2007] LDC 904 J122946.79+0800014  [M98c] 122713.9+081632",
          "[VV2003c] J122946.8+080002",
          "[VV2006c] J122946.8+080002",
          "[ZEH2003] RX J1229.7+0759 1",
          "[BEC2010] HRS 178",
          "[VV2010c] J122946.8+080002",
          "GALEX J122946.6+080000",
          "SDSS J122946.76+080001.7",
          "BWE 1227+0816",
          "MITG J122942+0800",
          "NVSS J122946+080002",
          "RGB J1229+080",
          "[DC78] UGC 7629",
          "GB6 B1227+0816",
          "SDSS J122944.50+080029.6",
          "EVCC 755"
        ],
        "searchKey": "galexj1229466080000|dsb94225|m49|nvssb122714081639|z122720816|zeh2003rxj1229707591|nvssj122946080002|vv2006cj1229468080002|3u123107|87gb1227146081649|mitgj1229420800|1e122750817|rgbj122980800|leda41220|bwe12270816|chm2007hdc720j122946790800014chm2007ldc904j122946790800014m98c1227139081632|vv2003cj1229468080002|uzcj1229466075958|dc78ugc7629|gin781|ugc7629|rgbj1229080|gb6b12270816|fwb89galaxy265|apg134|2masxj122946790800014|1m1231071|vv2010cj1229468080002|intref501|2u123107|sdssj122944500800296|tgssadrj1229466080006|gleamj122946075958|2e122720816|bec2010hrs178|evcc755|1es1227082|1h1228081|dlb87v9|mcg0132083|acsvcs1|vcc1226|xssj123120833|sdssj122946760800017|z42134|2e2735|ngc4472|4u123207|rxj122970759",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.49633333333333,
        "dec": 8.000277777777777,
        "fluxV": 12.17,
        "sizeMajor": 10.96,
        "sizeMinor": 8.91,
        "sizeAngle": 157.0,
        "simbadName": "M 49",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242606601240577",
        "name": "M 50",
        "names": [
          "[KC2019] Theia 294",
          "C 0700-082",
          "M 50",
          "NGC 2323",
          "OCISM 118",
          "OCl 559",
          "OCl 559.0",
          "[KPR2004b] 118",
          "[KPS2012] MWSC 1072"
        ],
        "searchKey": "kc2019theia294|ngc2323|ocl559|kpr2004b118|ocism118|ocl5590|kps2012mwsc1072|c0700082|m50",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.045611111111111,
        "dec": -8.365,
        "fluxV": null,
        "sizeMajor": 31.6,
        "sizeMinor": 31.6,
        "sizeAngle": null,
        "simbadName": "NGC 2323",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242613851586561",
        "name": "M 52",
        "names": [
          "C 2322+613",
          "M 52",
          "NGC 7654",
          "OCISM 57",
          "OCl 260",
          "OCl 260.0",
          "[KPR2004b] 512",
          "[KPS2012] MWSC 3725"
        ],
        "searchKey": "ocl260|kps2012mwsc3725|m52|ocl2600|c2322613|kpr2004b512|ocism57|ngc7654",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 23.413,
        "dec": 61.59,
        "fluxV": null,
        "sizeMajor": 16.0,
        "sizeMinor": 16.0,
        "sizeAngle": null,
        "simbadName": "M 52",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242617133170689",
        "name": "M 53",
        "names": [
          "HD 114820",
          "GCRV 7859",
          "BD+18 2701",
          "C 1310+184",
          "GCl 22",
          "M 53",
          "NGC 5024",
          "[KPS2012] MWSC 2094"
        ],
        "searchKey": "m53|bd182701|gcl22|kps2012mwsc2094|gcrv7859|c1310184|ngc5024|hd114820",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 13.21534722222222,
        "dec": 18.16816666666667,
        "fluxV": 7.79,
        "sizeMajor": 12.6,
        "sizeMinor": 12.6,
        "sizeAngle": 90.0,
        "simbadName": "M 53",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242619887583233",
        "name": "M 54",
        "names": [
          "C 1851-305",
          "GCl 104",
          "M 54",
          "NGC 6715",
          "[KPS2012] MWSC 3023",
          "LEDA 2802337",
          "CD-30 16412",
          "CPD-30 5748",
          "GCRV 11355",
          "HD 175113",
          "GCRV 6397 E"
        ],
        "searchKey": "c1851305|hd175113|cd3016412|gcrv6397e|gcrv11355|gcl104|cpd305748|m54|ngc6715|kps2012mwsc3023|leda2802337",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.91759166666667,
        "dec": -30.47986111111111,
        "fluxV": null,
        "sizeMajor": 9.1,
        "sizeMinor": 9.1,
        "sizeAngle": 90.0,
        "simbadName": "M 54",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242623132008449",
        "name": "M 55",
        "names": [
          "C 1936-310",
          "GCl 113",
          "M 55",
          "NGC 6809",
          "[KPS2012] MWSC 3150",
          "CD-31 16934",
          "GCRV 6723 E",
          "HD 185385"
        ],
        "searchKey": "cd3116934|gcl113|c1936310|gcrv6723e|hd185385|kps2012mwsc3150|ngc6809|m55",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.66658611111111,
        "dec": -30.96475,
        "fluxV": 6.49,
        "sizeMajor": 19.0,
        "sizeMinor": 19.0,
        "sizeAngle": 90.0,
        "simbadName": "M 55",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242626420834305",
        "name": "M 56",
        "names": [
          "C 1914+300",
          "CSI+30-19146 3",
          "GCRV 11736",
          "GCl 110",
          "M 56",
          "NGC 6779",
          "[KPS2012] MWSC 3077"
        ],
        "searchKey": "ngc6779|c1914300|csi30191463|kps2012mwsc3077|m56|gcl110|gcrv11736",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ROSE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.27654722222222,
        "dec": 30.18347222222222,
        "fluxV": null,
        "sizeMajor": 7.1,
        "sizeMinor": 7.1,
        "sizeAngle": 90.0,
        "simbadName": "M 56",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242632911028225",
        "name": "M 58",
        "names": [
          "WISE J123743.54+114905.5",
          "NVSS J123743+114908",
          "TGSSADR J123743.8+114907",
          "[DC78] UGC 7796",
          "[OKM2018] SWIFT J1237.5+1182",
          "1ES 1235+12.0",
          "2E 2801",
          "IRAS F12351+1205",
          "IRAS 12351+1205",
          "LEDA 42168",
          "M 58",
          "MCG+02-32-160",
          "NGC 4579",
          "NVSS B123512+120537",
          "RBS 1136",
          "UGC 7796",
          "VCC 1727",
          "VPC 1119",
          "Z 70-197",
          "[DSB94] 227",
          "[TH2002] 7",
          "[YOF95] 210",
          "2MASX J12374359+1149051",
          "ALFALFA 3-328",
          "JCMTSE J123743.4+114907",
          "1RXS J123743.6+114909",
          "2E 1235.2+1205",
          "JCMTSF J123743.4+114907",
          "RX J1237.7+1149",
          "UZC J123743.4+114905",
          "Z 1235.2+1205",
          "[CHM2007] HDC 720 J123743.59+1149051  [CHM2007] LDC 904 J123743.59+1149051",
          "[GCS2005] J123743.52+114905.5",
          "[M98c] 123512.6+120540",
          "[VV2000c] J123743.5+114905",
          "[VV2003c] J123743.5+114905",
          "[VV2006c] J123743.5+114905",
          "[VV98c] J123743.5+114905",
          "[ZEH2003] RX J1237.7+1149 1",
          "PSCz Q12351+1205",
          "[BEC2010] HRS 220",
          "SDSS J123743.52+114905.4",
          "[VV2010c] J123743.5+114905",
          "2XMM J123743.5+114905",
          "[TDA2013] AGESVC2 23",
          "PBC J1237.7+1150",
          "SDSS J123743.53+114905.5",
          "BWE 1235+1206",
          "GB6 B1235+1205",
          "87GB 123513.9+120558",
          "FIRST J123743.5+114905",
          "[CAB95] IRAS F12351+1205",
          "[NKB95] N4579",
          "PCCS1 857 G290.38+74.34",
          "PCCS1 545 G290.37+74.35",
          "SDSS J123745.44+114900.3",
          "EVCC 965"
        ],
        "searchKey": "m58|ngc4579|firstj1237435114905|cab95irasf123511205|jcmtsej1237434114907|vv98cj1237435114905|2masxj123743591149051|z70197|jcmtsfj1237434114907|bec2010hrs220|sdssj123743521149054|pccs1857g290387434|dsb94227|2e2801|leda42168|2e123521205|ugc7796|gb6b12351205|z123521205|th20027|vv2006cj1237435114905|okm2018swiftj123751182|yof95210|87gb1235139120558|wisej123743541149055|vcc1727|evcc965|rxj123771149|iras123511205|nkb95n4579|rbs1136|nvssb123512120537|tda2013agesvc223|m98c1235126120540|bwe12351206|nvssj123743114908|zeh2003rxj1237711491|psczq123511205|sdssj123745441149003|dc78ugc7796|1rxsj1237436114909|sdssj123743531149055|1es1235120|vv2003cj1237435114905|vv2010cj1237435114905|vv2000cj1237435114905|vpc1119|2xmmj1237435114905|pccs1545g290377435|irasf123511205|chm2007hdc720j123743591149051chm2007ldc904j123743591149051|pbcj123771150|tgssadrj1237438114907|mcg0232160|gcs2005j123743521149055|uzcj1237434114905|alfalfa3328",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.62875,
        "dec": 11.81833333333333,
        "fluxV": 9.66,
        "sizeMajor": 5.62,
        "sizeMinor": 4.37,
        "sizeAngle": 88.0,
        "simbadName": "M 58",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242636186583041",
        "name": "M 59",
        "names": [
          "Gaia DR3 3928413419497254016",
          "ACSVCS 9",
          "2E 2825",
          "LEDA 42628",
          "M 59",
          "MCG+02-32-183",
          "NGC 4621",
          "UGC 7858",
          "VCC 1903",
          "Z 70-223",
          "[DLB87] V17",
          "[TH2002] 8",
          "2MASX J12420232+1138489",
          "[FWB89] Galaxy 279",
          "2E 1239.5+1155",
          "UZC J124202.4+113848",
          "Z 1239.5+1155",
          "[CHM2007] HDC 720 J124202.32+1138489",
          "[CHM2007] LDC 904 J124202.32+1138489  [M98c] 123931.2+115515",
          "[BEC2010] HRS 236",
          "SDSS J124202.25+113848.8",
          "EVCC 2207"
        ],
        "searchKey": "dlb87v17|vcc1903|uzcj1242024113848|gaiadr33928413419497254016|chm2007ldc904j124202321138489m98c1239312115515|th20028|2e2825|ngc4621|bec2010hrs236|z70223|m59|evcc2207|fwb89galaxy279|leda42628|sdssj124202251138488|chm2007hdc720j124202321138489|acsvcs9|z123951155|2masxj124202321138489|2e123951155|mcg0232183|ugc7858",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.70062726042133,
        "dec": 11.64691930771,
        "fluxV": null,
        "sizeMajor": 5.5,
        "sizeMinor": 3.98,
        "sizeAngle": 164.0,
        "simbadName": "M 59",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242639088222209",
        "name": "M 60",
        "names": [
          "FIRST J124339.9+113309",
          "Gaia DR3 3927661422263289600",
          "[SMM2006b] J124339.98+113309.6",
          "2MASS J12434000+1133099",
          "[LB2005] NGC 4649 X1",
          "TIC 402133932",
          "CLASS J124339.9680+113309.695",
          "[DC78] UGC 7898",
          "NVSS J124340+113309",
          "ACSVCS 3",
          "2E 2835",
          "KPG 353b",
          "LEDA 42831",
          "M 60",
          "MCG+02-33-002",
          "NGC 4649",
          "NVSS B124108+114934",
          "RBS 1150",
          "UGC 7898",
          "VCC 1978",
          "VV 206a",
          "VV 206",
          "Z 71-16",
          "[DLB87] V19",
          "[T76] 78B",
          "[TH2002] 2",
          "2MASX J12434000+1133093",
          "[FWB89] Galaxy 284",
          "[CHM2007] LDC 904 J124340.00+1133093  [CHM2007] HDC 720 J124340.00+1133093",
          "1RXS J124340.6+113309",
          "2E 1241.1+1149",
          "RX J1243.6+1133",
          "RXC J1243.6+1133",
          "UZC J124339.7+113307",
          "Z 1241.1+1150",
          "[ZEH2003] RX J1243.6+1133 1",
          "[BEC2010] HRS 245",
          "SDSS J124339.97+113309.7",
          "SDSS J124342.26+113329.6",
          "EVCC 1101"
        ],
        "searchKey": "zeh2003rxj1243611331|uzcj1243397113307|vcc1978|rxcj124361133|leda42831|acsvcs3|z7116|tic402133932|1rxsj1243406113309|firstj1243399113309|smm2006bj124339981133096|mcg0233002|lb2005ngc4649x1|bec2010hrs245|sdssj124342261133296|z124111150|sdssj124339971133097|classj1243399680113309695|2e2835|2masxj124340001133093|gaiadr33927661422263289600|2e124111149|evcc1101|rbs1150|t7678b|vv206|fwb89galaxy284|m60|vv206a|th20022|chm2007ldc904j124340001133093chm2007hdc720j124340001133093|dc78ugc7898|ugc7898|kpg353b|nvssj124340113309|dlb87v19|rxj124361133|2massj124340001133099|nvssb124108114934|ngc4649",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.72776888888889,
        "dec": 11.55269333333333,
        "fluxV": null,
        "sizeMajor": 7.94,
        "sizeMinor": 6.61,
        "sizeAngle": 104.0,
        "simbadName": "M 60",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242642579259393",
        "name": "M 61",
        "names": [
          "Gaia DR3 3701944874926493440",
          "2E 2676",
          "IRAS 12194+0444",
          "IRAS F12193+0445",
          "LEDA 40001",
          "M 61",
          "MCG+01-32-022",
          "NGC 4303",
          "QDOT B1219241+044452",
          "RX J122155+04290",
          "TC E21",
          "UGC 7420",
          "UZC J122155.0+042824",
          "VCC 508",
          "Z 42-45",
          "2MASX J12215494+0428249",
          "NVSS B121921+044503",
          "1RXS J122155.6+042919",
          "2E 1219.3+0445",
          "RX J1221.9+0429",
          "Z 1219.3+0445",
          "[CHM2007] HDC 720 J122154.94+0428249  [CHM2007] LDC 904 J122154.94+0428249  [M98c] 121921.4+044458",
          "[VV2000c] J122154.9+042825",
          "[VV2003c] J122154.9+042825",
          "[VV2006c] J122154.9+042825",
          "[VV98c] J122154.9+042825",
          "PSCz Q12194+0444",
          "[BEC2010] HRS 114",
          "HIPASS J1221+04",
          "[VV2010c] J122154.9+042825",
          "SDSS J122154.92+042825.6",
          "2XMM J122154.8+042825",
          "WVFSCC J122156+042740",
          "PCCS1 857 G284.35+66.28",
          "PCCS1 545 G284.36+66.28",
          "EVCC 429",
          "Gaia DR2 3701944874926493440"
        ],
        "searchKey": "z121930445|sdssj122154920428256|2masxj122154940428249|pccs1545g284366628|vv2000cj1221549042825|2xmmj1221548042825|1rxsj1221556042919|uzcj1221550042824|vv2006cj1221549042825|pccs1857g284356628|gaiadr23701944874926493440|2e2676|gaiadr33701944874926493440|rxj122190429|hipassj122104|vv2010cj1221549042825|irasf121930445|mcg0132022|ngc4303|vv2003cj1221549042825|m61|evcc429|vv98cj1221549042825|wvfsccj122156042740|qdotb1219241044452|2e121930445|psczq121940444|ugc7420|vcc508|tce21|leda40001|nvssb121921044503|z4245|chm2007hdc720j122154940428249chm2007ldc904j122154940428249m98c1219214044458|iras121940444|rxj12215504290|bec2010hrs114",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.36525784952467,
        "dec": 4.47377704644,
        "fluxV": 9.65,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 61",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242645835710465",
        "name": "M 62",
        "names": [
          "C 1658-300",
          "GCl 51",
          "M 62",
          "NGC 6266",
          "1FIG J1701.1-3004",
          "Cl VDBH 210",
          "[KPS2012] MWSC 2512",
          "3FGL J1701.2-3006",
          "1FGL J1701.1-3005",
          "2FGL J1701.2-3007",
          "4FGL J1701.2-3006"
        ],
        "searchKey": "ngc6266|1figj170113004|3fglj170123006|kps2012mwsc2512|clvdbh210|2fglj170123007|4fglj170123006|c1658300|1fglj170113005|gcl51|m62",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.02016666666666,
        "dec": -30.11236111111111,
        "fluxV": null,
        "sizeMajor": 14.1,
        "sizeMinor": 14.1,
        "sizeAngle": 90.0,
        "simbadName": "M 62",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242656057032705",
        "name": "M 65",
        "names": [
          "Gaia DR3 3965037735208478976",
          "2EUVE J1118+13.0",
          "ADBS J111852+1305",
          "APG 317B",
          "EQ 1116+133",
          "EUVE J1118+13.0",
          "LEDA 34612",
          "M 65",
          "MCG+02-29-018",
          "NGC 3623",
          "TC 160",
          "UGC 6328",
          "Z 67-54",
          "2MASX J11185595+1305319",
          "1RXS J111857.4+130535",
          "1WGA J1118.8+1306",
          "CAIRNS J111855.90+130537.0",
          "UZC J111855.9+130537",
          "Z 1116.3+1322",
          "[CHM2007] HDC 626 J111855.95+1305319  [CHM2007] LDC 778 J111855.95+1305319",
          "[M98c] 111618.6+132200",
          "[RS2000] 156",
          "1RXS J111857.4+130536",
          "SDSS J111855.91+130532.3",
          "2XMM J111855.9+130532"
        ],
        "searchKey": "ugc6328|2xmmj1118559130532|tc160|m98c1116186132200|1wgaj111881306|1rxsj1118574130536|z6754|ngc3623|uzcj1118559130537|euvej1118130|apg317b|gaiadr33965037735208478976|eq1116133|leda34612|z111631322|chm2007hdc626j111855951305319chm2007ldc778j111855951305319|cairnsj111855901305370|m65|sdssj111855911305323|2euvej1118130|mcg0229018|2masxj111855951305319|adbsj1118521305|1rxsj1118574130535|rs2000156",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 11.31553012929933,
        "dec": 13.09230575102,
        "fluxV": null,
        "sizeMajor": 8.32,
        "sizeMinor": 2.14,
        "sizeAngle": 174.0,
        "simbadName": "M 65",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242660247109633",
        "name": "M 66",
        "names": [
          "GLEAM J112015+125926",
          "NAIC 1117+133",
          "[DC78] UGC 6346",
          "AAVSO 1115+13B",
          "ADBS J112020+1259",
          "APG 16",
          "APG 317A",
          "Ark 288",
          "IRAS F11176+1315",
          "IRAS 11176+1315",
          "LEDA 34695",
          "M 66",
          "MCG+02-29-019",
          "MITG J1120+1259",
          "MRC 1117+132",
          "NGC 3627",
          "TC 162",
          "UGC 6346",
          "VV 308",
          "VV 308a",
          "Z 67-57",
          "[LBH86] 1117+132",
          "2MASX J11201502+1259286",
          "1RXS J112016.7+125917",
          "CAIRNS J112015.02+125929.5",
          "RGB J1120.3+1259",
          "RX J1120.2+1259",
          "RX J1120.3+1259",
          "UZC J112015.1+125928",
          "Z 1117.6+1316",
          "[CHM2007] HDC 626 J112015.02+1259286  [CHM2007] LDC 778 J112015.02+1259286  [M98c] 111737.9+131608",
          "[VV2000c] J112014.9+125927",
          "[VV2003c] J112014.9+125927",
          "[VV2006c] J112014.9+125927",
          "[VV98c] J112014.9+125927",
          "[ZEH2003] RX J1120.2+1259 1",
          "[RS2000] 157",
          "PSCz Q11176+1315",
          "HIPASS J1120+13a",
          "[VV2010c] J112014.9+125927",
          "SDSS J112014.98+125929.4",
          "WB 1117+1316",
          "NVSS J112015+125902",
          "Cul 1117+132",
          "GB6 B1117+1315",
          "MITG J112015+1259",
          "JCMTSE J112014.8+125932",
          "87GB 111738.3+131603",
          "JCMTSF J112014.8+125932",
          "BWE 1117+1316",
          "RGB J1120+129",
          "[CAB95] IRAS F11176+1315",
          "[NKB95] N3627",
          "NAICGB 1117+132"
        ],
        "searchKey": "irasf111761315|aavso111513b|apg16|vv308a|nkb95n3627|z111761316|gb6b11171315|leda34695|rgbj112031259|1rxsj1120167125917|vv2006cj1120149125927|87gb1117383131603|ngc3627|2masxj112015021259286|vv98cj1120149125927|chm2007hdc626j112015021259286chm2007ldc778j112015021259286m98c1117379131608|wb11171316|zeh2003rxj1120212591|z6757|lbh861117132|vv2010cj1120149125927|jcmtsej1120148125932|mcg0229019|hipassj112013a|rs2000157|cul1117132|rxj112031259|vv308|rxj112021259|naic1117133|nvssj112015125902|cab95irasf111761315|mitgj11201259|sdssj112014981259294|rgbj1120129|m66|ugc6346|jcmtsfj1120148125932|adbsj1120201259|apg317a|bwe11171316|iras111761315|gleamj112015125926|uzcj1120151125928|naicgb1117132|mitgj1120151259|vv2003cj1120149125927|psczq111761315|dc78ugc6346|cairnsj112015021259295|mrc1117132|tc162|vv2000cj1120149125927|ark288",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 11.3375,
        "dec": 12.99138888888889,
        "fluxV": 8.92,
        "sizeMajor": 8.51,
        "sizeMinor": 3.8,
        "sizeAngle": 173.0,
        "simbadName": "M 66",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242664295301121",
        "name": "M 67",
        "names": [
          "C 0847+120",
          "M 67",
          "NGC 2682",
          "OCl 549.0",
          "[KPR2004b] 212",
          "HR 3515",
          "[KPS2012] MWSC 1585"
        ],
        "searchKey": "kpr2004b212|hr3515|ocl5490|m67|ngc2682|kps2012mwsc1585|c0847120",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 8.856388888888889,
        "dec": 11.81388888888889,
        "fluxV": null,
        "sizeMajor": 25.0,
        "sizeMinor": 25.0,
        "sizeAngle": null,
        "simbadName": "NGC 2682",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242666735435777",
        "name": "M 68",
        "names": [
          "C 1236-264",
          "CD-26 9257",
          "GCRV 7606",
          "GCl 20",
          "HD 110032",
          "M 68",
          "NGC 4590",
          "[KPS2012] MWSC 2059"
        ],
        "searchKey": "ngc4590|cd269257|gcrv7606|c1236264|kps2012mwsc2059|m68|gcl20|hd110032",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.65777222222222,
        "dec": -26.74405555555555,
        "fluxV": 7.96,
        "sizeMajor": 12.0,
        "sizeMinor": 12.0,
        "sizeAngle": 90.0,
        "simbadName": "M 68",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242669460193281",
        "name": "M 69",
        "names": [
          "C 1828-323",
          "CD-32 14224",
          "CPD-32 5409",
          "GCRV 10969",
          "GCl 96",
          "HD 170534",
          "M 69",
          "NGC 6637",
          "[KPS2012] MWSC 2936"
        ],
        "searchKey": "gcrv10969|kps2012mwsc2936|cpd325409|gcl96|hd170534|c1828323|cd3214224|m69|ngc6637",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.52308333333333,
        "dec": -32.34808333333334,
        "fluxV": null,
        "sizeMajor": 7.1,
        "sizeMinor": 7.1,
        "sizeAngle": 90.0,
        "simbadName": "M 69",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242672749084673",
        "name": "M 70",
        "names": [
          "C 1840-323",
          "GCl 101",
          "M 70",
          "NGC 6681",
          "[KPS2012] MWSC 2981"
        ],
        "searchKey": "ngc6681|gcl101|kps2012mwsc2981|m70|c1840323",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.72021111111111,
        "dec": -32.29211111111111,
        "fluxV": null,
        "sizeMajor": 7.8,
        "sizeMinor": 7.8,
        "sizeAngle": 90.0,
        "simbadName": "M 70",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242675263537153",
        "name": "M 71",
        "names": [
          "C 1951+186",
          "CSI+18-19515",
          "GCRV 12241",
          "GCl 115",
          "M 71",
          "NGC 6838",
          "[KPS2012] MWSC 3200"
        ],
        "searchKey": "gcl115|gcrv12241|m71|kps2012mwsc3200|ngc6838|csi1819515|c1951186",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.89624722222222,
        "dec": 18.77919444444444,
        "fluxV": null,
        "sizeMajor": 7.2,
        "sizeMinor": 7.2,
        "sizeAngle": 90.0,
        "simbadName": "M 71",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242677669888001",
        "name": "M 72",
        "names": [
          "C 2050-127",
          "GCl 118",
          "M 72",
          "NGC 6981",
          "[KPS2012] MWSC 3419"
        ],
        "searchKey": "c2050127|kps2012mwsc3419|gcl118|m72|ngc6981",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.89102777777778,
        "dec": -12.53730555555556,
        "fluxV": 8.96,
        "sizeMajor": 5.9,
        "sizeMinor": 5.9,
        "sizeAngle": 90.0,
        "simbadName": "M 72",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242680923619329",
        "name": "M 73",
        "names": [
          "C 2056-128",
          "M 73",
          "NGC 6994",
          "OCl 89",
          "Cl Collinder 426"
        ],
        "searchKey": "c2056128|ngc6994|clcollinder426|ocl89|m73",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.98333333333333,
        "dec": -12.63333333333333,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 6994",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242684275326977",
        "name": "M 74",
        "names": [
          "Gaia DR3 2589380639673817728",
          "[CHP2004] J013641.7+154701",
          "2MASS J01364173+1547008",
          "TIC 405375557",
          "2E 440",
          "2E 0134.0+1531",
          "87GB 013400.0+153119",
          "GB6 B0134+1531",
          "IRAS F01339+1532",
          "IRAS 01340+1532",
          "ISOSS J01366+1547",
          "LEDA 5974",
          "M 74",
          "MCG+03-05-011",
          "NGC 628",
          "TC 18",
          "UGC 1149",
          "Z 0134.0+1532",
          "Z 460-14",
          "[SLK2004] 223",
          "[VDD93] 8",
          "2MASX J01364177+1547004",
          "[M98c] 013400.7+153155",
          "UZC J013641.7+154659",
          "PSCz Q01340+1532",
          "HIPASS J0136+15"
        ],
        "searchKey": "chp2004j0136417154701|vdd938|leda5974|ugc1149|2masxj013641771547004|z46014|2massj013641731547008|z013401532|tic405375557|ngc628|iras013401532|87gb0134000153119|2e440|slk2004223|isossj013661547|m98c0134007153155|irasf013391532|gaiadr32589380639673817728|gb6b01341531|tc18|psczq013401532|mcg0305011|uzcj0136417154659|hipassj013615|m74|2e013401531",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "INDIGO",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 1.611595867684,
        "dec": 15.78364097564,
        "fluxV": 9.46,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 74",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242687545737217",
        "name": "M 75",
        "names": [
          "C 2003-220",
          "GCl 116",
          "M 75",
          "NGC 6864",
          "2MASX J20060484-2155201",
          "[KPS2012] MWSC 3254",
          "BD-22 5331",
          "CD-22 14479",
          "CPD-22 7639",
          "GCRV 12454",
          "HD 190526"
        ],
        "searchKey": "2masxj200604842155201|cpd227639|hd190526|bd225331|gcl116|m75|cd2214479|gcrv12454|kps2012mwsc3254|c2003220|ngc6864",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.10134472222222,
        "dec": -21.92226111111111,
        "fluxV": 8.26,
        "sizeMajor": 2.383,
        "sizeMinor": 2.145,
        "sizeAngle": 55.0,
        "simbadName": "M 75",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242690806022145",
        "name": "M 76",
        "names": [
          "Gaia DR3 406328443354164480",
          "BWE 0139+5119",
          "CSI+51-01391",
          "GB6 B0139+5119",
          "GCRV 950",
          "HD 10346",
          "IRAS F01391+5119",
          "IRAS 01391+5119",
          "M 76",
          "NAME Barbell Nebula",
          "NAME Cork Nebula",
          "NGC 651",
          "NGC 650",
          "NVSS J014219+513431",
          "PK 130-10 1",
          "PN G130.9-10.5",
          "PN VV' 9",
          "PN ARO 2",
          "PN VV 6",
          "WB 0139+5119",
          "WD 0139+513",
          "87GB 013909.6+511935",
          "WN B0139.1+5119",
          "PSCz P01391+5119",
          "[KP98] 1",
          "NAME Little Dumbbell Nebula",
          "WEB 1707",
          "Gaia DR2 406328439057955968"
        ],
        "searchKey": "pnvv6|gaiadr2406328439057955968|nvssj014219513431|pnvv9|bwe01395119|pnaro2|wb01395119|wnb013915119|gb6b01395119|csi5101391|gaiadr3406328443354164480|barbellnebula|png1309105|web1707|hd10346|gcrv950|wd0139513|iras013915119|corknebula|kp981|ngc651|pk130101|irasf013915119|ngc650|littledumbbellnebula|m76|87gb0139096511935|psczp013915119",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 1.705459936894667,
        "dec": 51.57542638057,
        "fluxV": 17.48,
        "sizeMajor": 2.307,
        "sizeMinor": 2.307,
        "sizeAngle": 90.0,
        "simbadName": "M 76",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242695437910017",
        "name": "M 77",
        "names": [
          "WISE J024240.69-000047.7",
          "VLSSr J024240.8-000044",
          "FIRST J024240.7-000046",
          "GLEAM J024240-000041",
          "SPASS J024245-000157",
          "TGSSADR J024240.7-000047",
          "NAICGB 0240-002",
          "NAIC 0240-002",
          "[OKM2018] SWIFT J0242.6+0000",
          "Gaia DR3 2498700788995507712",
          "HIPASS J0242+00",
          "SINGG HIPASS J0242+00",
          "1AXG J024241-0000",
          "1ES 0240-00.2",
          "[KRL2007b] 25",
          "GRA B0240-00",
          "PMN J0242-0000",
          "1Jy 0240-002",
          "1Jy 0240-00",
          "2E 649",
          "3C 71",
          "3C 71.0",
          "3CR 71",
          "4C -00.13",
          "AAVSO 0237-00",
          "APG 37",
          "DA 82",
          "INTREF 128",
          "IRAS 02401-0013",
          "IRAS F02401-0013",
          "ISOSS J02426-0000",
          "KUG 0240-002",
          "LEDA 10266",
          "LHE 67",
          "M 77",
          "MCG+00-07-083",
          "MRC 0240-002",
          "MSH 02-0-14",
          "NGC 1068",
          "NRAO 112",
          "OHIO D -067",
          "PKS J0242-0000",
          "PKS 0240-00",
          "PKS 0240-002",
          "RAFGL 4220",
          "RAFGL 4220S",
          "RBS 348",
          "RX J024239-00000",
          "S3 0240-00",
          "TC 656",
          "UGC 2188",
          "XSS J02445-0000",
          "Z 388-98",
          "[DML87] 57",
          "[HB91] 0240-002",
          "[SLK2004] 333",
          "2MASX J02424077-0000478",
          "1RXP J024240.9-000042",
          "1RXS J024240.9-000046",
          "2E 0240.1-0013",
          "6dFGS gJ024240.7-000048",
          "RX J0242.6+0000",
          "SDSS J024240.70-000047.9",
          "UZC J024240.7-000048",
          "Z 0240.1-0013",
          "[CHM2007] HDC 163 J024240.77-0000478",
          "[CHM2007] LDC 174 J024240.77-0000478  [M98c] 024006.5-001332",
          "[VV2000c] J024240.7-000047",
          "[VV2003c] J024240.7-000047",
          "[VV2006c] J024240.7-000047",
          "[VV98c] J024240.7-000047",
          "PSCz Q02401-0013",
          "PBC J0242.7-0000",
          "SWIFT J0242.6+0000",
          "SWIFT J0242.9-0000",
          "2XMM J024240.7-000046",
          "[VV2010c] J024240.7-000047",
          "AT20G J024240-000046",
          "1FGL J0242.7+0007",
          "2FGL J0242.5+0006",
          "Cul 0240-002",
          "MITG J024240-0000",
          "TXS 0240-002",
          "NVSS J024240-000047",
          "WB 0240-0013",
          "[CAB95] IRAS F02401-0013",
          "VLSS J0242.6-0000",
          "[NKB95] N1068",
          "BWE 0240-0013",
          "3FGL J0242.7-0001",
          "3FHL J0242.7-0002",
          "9Y-MST J0242+0001",
          "QSO B0240-002",
          "4FGL J0242.6-0000"
        ],
        "searchKey": "z024010013|rxj024260000|pmnj02420000|intref128|cul0240002|ohiod067|da82|spassj024245000157|s3024000|1axgj0242410000|mrc0240002|apg37|pksj02420000|naicgb0240002|chm2007hdc163j024240770000478|3c710|ngc1068|leda10266|1fglj024270007|wisej024240690000477|rafgl4220s|swiftj024290000|2fglj024250006|1es0240002|rafgl4220|pbcj024270000|vv2010cj0242407000047|grab024000|1rxsj0242409000046|nkb95n1068|psczq024010013|msh02014|vv2000cj0242407000047|irasf024010013|slk2004333|9ymstj02420001|4c0013|naic0240002|at20gj024240000046|krl2007b25|hb910240002|bwe02400013|3cr71|4fglj024260000|3fglj024270001|firstj0242407000046|vv2006cj0242407000047|wb02400013|vlssj024260000|chm2007ldc174j024240770000478m98c0240065001332|qsob0240002|lhe67|pks0240002|nrao112|2e024010013|6dfgsgj0242407000048|gaiadr32498700788995507712|gleamj024240000041|rbs348|mcg0007083|rxj02423900000|z38898|sdssj024240700000479|aavso023700|mitgj0242400000|nvssj024240000047|vv2003cj0242407000047|tgssadrj0242407000047|txs0240002|dml8757|okm2018swiftj024260000|kug0240002|cab95irasf024010013|uzcj0242407000048|m77|3fhlj024270002|vv98cj0242407000047|ugc2188|2masxj024240770000478|iras024010013|xssj024450000|hipassj024200|singghipassj024200|2xmmj0242407000046|3c71|1jy024000|1jy0240002|vlssrj0242408000044|tc656|pks024000|1rxpj0242409000042|isossj024260000|swiftj024260000|2e649",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ROSE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.711308101928,
        "dec": -0.01329435839,
        "fluxV": 8.87,
        "sizeMajor": 6.92,
        "sizeMinor": 6.03,
        "sizeAngle": 72.0,
        "simbadName": "M 77",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242697862283265",
        "name": "M 78",
        "names": [
          "Ced 55u",
          "DG 80",
          "M 78",
          "NGC 2068",
          "[KPS2012] MWSC 0664"
        ],
        "searchKey": "kps2012mwsc0664|dg80|ngc2068|ced55u|m78",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "CYAN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.779388888888889,
        "dec": 0.07916666666666666,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 78",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242700311920641",
        "name": "M 79",
        "names": [
          "C 0522-245",
          "GCl 10",
          "M 79",
          "NGC 1904",
          "[V2001] NGC 1904 X01",
          "CD-24 3058",
          "CPD-24 931",
          "GCRV 3251",
          "HD 35512",
          "1E 0522.1-2433",
          "[KPS2012] MWSC 0528"
        ],
        "searchKey": "v2001ngc1904x01|gcrv3251|ngc1904|cpd24931|1e052212433|hd35512|m79|kps2012mwsc0528|cd243058|c0522245|gcl10",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ROSE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.402941666666667,
        "dec": -24.52425,
        "fluxV": 8.16,
        "sizeMajor": 8.7,
        "sizeMinor": 8.7,
        "sizeAngle": 90.0,
        "simbadName": "M 79",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242703719006209",
        "name": "M 80",
        "names": [
          "RAVE J161702.4-225832",
          "C 1614-228",
          "GCl 39",
          "M 80",
          "NGC 6093",
          "[KPS2012] MWSC 2376",
          "BD-22 4135",
          "CD-22 11471",
          "CPD-22 6166",
          "GCRV 9366",
          "HD 146417",
          "3FGL J1616.8-2300",
          "2FGL J1616.8-2302",
          "4FGL J1616.9-2257"
        ],
        "searchKey": "c1614228|ngc6093|hd146417|3fglj161682300|4fglj161692257|m80|bd224135|gcl39|cpd226166|gcrv9366|cd2211471|2fglj161682302|kps2012mwsc2376|ravej1617024225832",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.28400277777778,
        "dec": -22.97608333333333,
        "fluxV": null,
        "sizeMajor": 8.9,
        "sizeMinor": 8.9,
        "sizeAngle": 90.0,
        "simbadName": "M 80",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242714419757057",
        "name": "M 83",
        "names": [
          "LVHIS 053",
          "VLSSr J133658.0-295147",
          "TGSSADR J133659.9-295145",
          "[QPD2020] G133659.4-295159",
          "HIPASS J1337-29",
          "SINGG HIPASS J1337-29",
          "HIDEEP J1337-2954",
          "PMN J1336-2951",
          "[LB2005] NGC 5236 X1",
          "NVSS J133700-295155",
          "1RXS J133657.0-295207",
          "2E 3112",
          "ESO-LV 444-0810",
          "ESO 444-81",
          "IRAS 13341-2936",
          "IRAS F13341-2936",
          "LEDA 48082",
          "M 83",
          "MCG-05-32-050",
          "MRC 1334-296",
          "MSH 13-2-05",
          "NAME Southern Pinwheel Galaxy",
          "NGC 5236",
          "PKS 1334-296",
          "PKS J1336-2951",
          "PKS 1334-29",
          "QDOT B1334113-293639",
          "RBS 1293",
          "RX J1337.0-2952",
          "SPB 225",
          "UGCA 366",
          "[DAM86] 103",
          "[DSB94] 62",
          "[M98c] 133411.0-293648",
          "[R84] A1-333",
          "[TFP85] I1",
          "[VDD93] 189",
          "2MASX J13370091-2951567",
          "SGC 133411-2936.8",
          "1ES 1334-29.6",
          "2E 1334.2-2936",
          "6dFGS gJ133658.1-294826",
          "FLASH J133700.23-295204.5",
          "[CHM2007] HDC 813 J133700.91-2951567  [CHM2007] LDC 993 J133700.91-2951567",
          "PSCz Q13341-2936",
          "Cul 1334-296",
          "CD-29 10465",
          "CPD-29 3793",
          "FAUST 3840",
          "HD 118366",
          "[BBW2000] FM 70",
          "TXS 1334-296",
          "[CAB95] IRAS F13341-2936",
          "[NKB95] N5236"
        ],
        "searchKey": "esolv4440810|lvhis053|sgc13341129368|dsb9462|qpd2020g1336594295159|hipassj133729|rxj133702952|southernpinwheelgalaxy|vdd93189|hd118366|txs1334296|faust3840|ugca366|r84a1333|eso44481|rbs1293|m98c1334110293648|nkb95n5236|ngc5236|iras133412936|2e3112|psczq133412936|cab95irasf133412936|irasf133412936|1rxsj1336570295207|cpd293793|pmnj13362951|1es1334296|6dfgsgj1336581294826|mrc1334296|bbw2000fm70|msh13205|mcg0532050|hideepj13372954|cul1334296|tgssadrj1336599295145|pks133429|tfp85i1|vlssrj1336580295147|qdotb1334113293639|cd2910465|2masxj133700912951567|lb2005ngc5236x1|nvssj133700295155|leda48082|m83|pksj13362951|spb225|2e133422936|chm2007hdc813j133700912951567chm2007ldc993j133700912951567|pks1334296|flashj133700232952045|singghipassj133729|dam86103",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 13.616922,
        "dec": -29.86576111111111,
        "fluxV": 7.52,
        "sizeMajor": 13.8,
        "sizeMinor": 12.88,
        "sizeAngle": null,
        "simbadName": "M 83",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242718523392001",
        "name": "M 84",
        "names": [
          "VLSSr J122505.0+125318",
          "GLEAM J122504+125308",
          "TGSSADR J122504.2+125309",
          "[BFA97] 44",
          "HD 108091",
          "FAUST 3119",
          "BD+13 2531",
          "WSTB 29W3",
          "ACSVCS 6",
          "ICRF J122503.7+125313",
          "IERS B1222+131",
          "1Jy 1222+131",
          "1Jy 1222+13",
          "2E 2696",
          "3C 272.1",
          "4C 13.47",
          "ASB 33",
          "Cul 1222+131",
          "DA 322",
          "GIN 778",
          "IRAS 12224+1309",
          "IRAS F12225+1309",
          "LEDA 40455",
          "LJHY 35",
          "M 84",
          "MCG+02-32-034",
          "MRC 1222+131",
          "NGC 4374",
          "NRAO 399",
          "NVSS B122231+130912",
          "OHIO A 322",
          "PKS 1222+13",
          "PKS 1223+132",
          "PKS 1222+131",
          "RX J1225.0+1253",
          "S3 1222+13",
          "UGC 7494",
          "VCC 763",
          "VPC 385",
          "VSOP J1225+1253",
          "Z 70-58",
          "[DLB87] V3",
          "[DML87] 746",
          "[DSB94] 272",
          "[TH2002] 5",
          "[TT2002] Virgo 2",
          "2MASX J12250377+1253130",
          "[FWB89] Galaxy 255",
          "[GW2008] CoNFIG 135",
          "JCMTSE J122503.6+125314",
          "2E 1222.5+1309",
          "JCMTSF J122503.6+125314",
          "RX J122503.7+125313",
          "UZC J122503.7+125314",
          "Z 1222.5+1310",
          "[CHM2007] HDC 720 J122503.77+1253130  [CHM2007] LDC 904 J122503.77+1253130",
          "[VV2003c] J122503.7+125313",
          "[VV2006c] J122503.7+125313",
          "[BEC2010] HRS 138",
          "SDSS J122503.74+125312.8",
          "[VV2010c] J122503.7+125313",
          "GB6 B1222+1309",
          "PKS J1225+1253",
          "WB 1222+1309",
          "87GB 122232.6+131000",
          "BWE 1222+1309",
          "MITG J122505+1252",
          "[CAB95] IRAS F12225+1309",
          "[DC78] UGC 7494",
          "VLSS J1225.0+1253",
          "SDSS J122503.03+125349.1",
          "EVCC 539"
        ],
        "searchKey": "bec2010hrs138|rxj1225037125313|ngc4374|vv2003cj1225037125313|z7058|dsb94272|dlb87v3|faust3119|vpc385|gleamj122504125308|iersb1222131|pks122213|gb6b12221309|bwe12221309|irasf122251309|3c2721|tt2002virgo2|m84|nrao399|ohioa322|pks1222131|iras122241309|nvssb122231130912|acsvcs6|2e2696|sdssj122503741253128|wb12221309|bd132531|gw2008config135|asb33|s3122213|pksj12251253|cab95irasf122251309|4c1347|rxj122501253|jcmtsfj1225036125314|vcc763|sdssj122503031253491|mcg0232034|mitgj1225051252|uzcj1225037125314|bfa9744|dc78ugc7494|hd108091|1jy122213|vv2006cj1225037125313|pks1223132|jcmtsej1225036125314|vv2010cj1225037125313|da322|87gb1222326131000|2e122251309|1jy1222131|vsopj12251253|dml87746|tgssadrj1225042125309|chm2007hdc720j122503771253130chm2007ldc904j122503771253130|icrfj1225037125313|cul1222131|z122251310|vlssj122501253|evcc539|ljhy35|mrc1222131|ugc7494|th20025|vlssrj1225050125318|gin778|2masxj122503771253130|fwb89galaxy255|wstb29w3|leda40455",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.41770648055556,
        "dec": 12.88698313888889,
        "fluxV": 10.49,
        "sizeMajor": 6.46,
        "sizeMinor": 5.75,
        "sizeAngle": 133.0,
        "simbadName": "M 84",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242721799372801",
        "name": "M 85",
        "names": [
          "ACSVCS 5",
          "2E 2700",
          "GIN 779",
          "KPG 334a",
          "LEDA 40515",
          "M 85",
          "MCG+03-32-029",
          "NGC 4382",
          "UGC 7508",
          "VCC 798",
          "Z 99-45",
          "[T76] 66A",
          "[TH2002] 4",
          "[VDD93] 157",
          "2MASX J12252405+1811278",
          "1E 1222.8+1828",
          "2E 1222.8+1828",
          "CAIRNS J122524.04+181125.9",
          "UZC J122524.2+181128",
          "Z 1222.9+1828",
          "[CHM2007] HDC 720 J122524.05+1811278",
          "[CHM2007] LDC 904 J122524.05+1811278  [M98c] 122253.2+182803",
          "CXOU J122524.0+181127",
          "[SSI2003b] NGC 4382 1",
          "2XMM J122524.0+181129",
          "SDSS J122524.06+181127.7",
          "SDSS J122524.07+181127.9",
          "SDSS J122523.46+181156.1",
          "EVCC 554"
        ],
        "searchKey": "z122291828|cairnsj122524041811259|1e122281828|acsvcs5|2xmmj1225240181129|2e122281828|ssi2003bngc43821|ugc7508|vcc798|chm2007hdc720j122524051811278|z9945|sdssj122524071811279|kpg334a|mcg0332029|2masxj122524051811278|chm2007ldc904j122524051811278m98c1222532182803|th20024|evcc554|cxouj1225240181127|sdssj122523461811561|sdssj122524061811277|uzcj1225242181128|t7666a|leda40515|2e2700|m85|gin779|vdd93157|ngc4382",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.42334805555556,
        "dec": 18.19108055555555,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 85",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242725292015617",
        "name": "M 86",
        "names": [
          "[BFA97] 51",
          "HD 108262",
          "FAUST 3133",
          "BD+13 2533",
          "ACSVCS 4",
          "2E 2707",
          "GIN 780",
          "IRAS F12234+1315",
          "IRAS 12234+1315",
          "LEDA 40653",
          "M 86",
          "MCG+02-32-046",
          "NAME FAUST V051",
          "NGC 4406",
          "UGC 7532",
          "VCC 881",
          "VPC 454",
          "Z 70-72",
          "[DLB87] V5",
          "[DSB94] 269",
          "[SBF2003] M86 FIR 1",
          "[TH2002] 3",
          "[TT2002] Virgo 1",
          "2MASX J12261181+1256454",
          "[FWB89] Galaxy 258",
          "ALFALFA 1-315",
          "1ES 1223+13.2",
          "2E 1223.6+1312",
          "Z 1223.7+1314",
          "[CHM2007] HDC 720 J122611.81+1256454",
          "[CHM2007] LDC 904 J122611.81+1256454  [M98c] 122339.7+131325",
          "UZC J122611.9+125647",
          "[BEC2010] HRS 150",
          "SDSS J122611.75+125646.3",
          "SDSS J122613.17+125614.3",
          "EVCC 597"
        ],
        "searchKey": "bfa9751|chm2007hdc720j122611811256454|bec2010hrs150|hd108262|faust3133|ngc4406|2masxj122611811256454|2e2707|mcg0232046|th20023|evcc597|bd132533|chm2007ldc904j122611811256454m98c1223397131325|tt2002virgo1|gin780|z7072|fwb89galaxy258|alfalfa1315|irasf122341315|1es1223132|z122371314|iras122341315|m86|dsb94269|sbf2003m86fir1|2e122361312|uzcj1226119125647|faustv051|acsvcs4|leda40653|dlb87v5|sdssj122611751256463|sdssj122613171256143|vcc881|vpc454|ugc7532",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.436615,
        "dec": 12.94596944444445,
        "fluxV": 8.9,
        "sizeMajor": 10.47,
        "sizeMinor": 7.59,
        "sizeAngle": 127.0,
        "simbadName": "M 86",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242728855666689",
        "name": "M 87",
        "names": [
          "PCCS2 143 G283.76+74.50",
          "RFC J1230+1223",
          "FIRST J123049.3+122323",
          "TGSSADR J123049.6+122321",
          "NGVS J123049.42+122328.0",
          "QSO B1228+126",
          "NAME M 87*",
          "IGR J12310+1221",
          "ACSVCS 2",
          "1A 1228+12",
          "1H 1226+128",
          "1Jy 1228+12",
          "1Jy 1228+126",
          "1M 1228+127",
          "2A 1228+125",
          "2E 2744",
          "2U 1228+12",
          "3A 1228+125",
          "3C 274.0",
          "3C 274",
          "3CR 274",
          "3U 1228+12",
          "4C 12.45",
          "4U 1228+12",
          "APG 152",
          "CTA 54",
          "Cul 1228+126",
          "Cul 1228+12",
          "DA 325",
          "DB 85",
          "GIN 800",
          "H 1228+127",
          "H 1227+12",
          "IERS B1228+126",
          "IRAS F12282+1240",
          "IRAS 12282+1240",
          "LEDA 41361",
          "M 87",
          "MCG+02-32-105",
          "MRC 1228+126",
          "Mills 12+1A",
          "NAME SMOKING GUN",
          "NAME Vir A",
          "NAME Virgo A",
          "NGC 4486",
          "NRAO 401",
          "NRL 8",
          "NVSS B122817+124004",
          "PKS J1230+1223",
          "PKS 1228+12",
          "PKS 1228+126",
          "PKS 1228+127",
          "RORF 1228+126",
          "SIM 1228+06.0",
          "UGC 7654",
          "VCC 1316",
          "VPC 771",
          "VSOP J1230+1223",
          "X Vir X-1",
          "XSS J12309+1223",
          "Z 70-139",
          "[DGW65] 57",
          "[DLB87] V12",
          "[DML87] 747",
          "[DSB94] 254",
          "[PT56] 24",
          "[TH2002] 1",
          "[VDD93] 163",
          "2MASX J12304942+1223279",
          "[FWB89] Galaxy 270",
          "X Vir XR-1",
          "[GW2008] CoNFIG 139",
          "1ES 1228+12.6",
          "2E 1228.2+1240",
          "2EUVE J1230+12.3",
          "EUVE J1230+12.3",
          "ICRF J123049.4+122328",
          "RX J1230.8+1223",
          "RX J1230.1+1223",
          "UZC J123049.3+122327",
          "Z 1228.3+1240",
          "[CHM2007] HDC 720 J123049.42+1223279  [CHM2007] LDC 904 J123049.42+1223279  [VV2000c] J123049.5+122328",
          "[VV2003c] J123049.5+122328",
          "[VV2006c] J123049.5+122328",
          "[VV98c] J123049.5+122328",
          "1FGL J1230.8+1223",
          "[BEC2010] HRS 183",
          "WMAP J1230+1223",
          "WMAP 165",
          "GB6 J1230+1223",
          "[WCO2009] J123049+122256",
          "NVSS J123049+122323",
          "SDSS J123049.41+122328.1",
          "[VV2010c] J123049.5+122328",
          "NEWPS5 J1230+1223",
          "GALEX J123049.4+122328",
          "2FGL J1230.8+1224",
          "1FHL J1230.8+1224",
          "87GB 122819.0+124029",
          "BWE 1228+1240",
          "GB6 B1228+1240",
          "NVSS J123049+122321",
          "RGB J1230+123",
          "[CAB95] IRAS F12282+1240",
          "[DC78] UGC 7654",
          "PCCS1 857 G283.74+74.48",
          "PCCS1 545 G283.76+74.47",
          "NAME Virgo Galaxy",
          "3FGL J1230.9+1224",
          "SDSS J123047.55+122318.0",
          "EVCC 786",
          "VER J1230+123",
          "2FHL J1230.8+1225",
          "TeV J1230+123",
          "3FHL J1230.8+1223",
          "4FGL J1230.8+1223",
          "Gaia DR2 3907709439453756032",
          "9Y-MST J1230+1221",
          "HIDDEN NAME M87*"
        ],
        "searchKey": "2masxj123049421223279|3cr274|virgogalaxy|vira|3a1228125|gb6b12281240|smokinggun|87gb1228190124029|firstj1230493122323|gb6j12301223|gin800|2e122821240|2euvej1230123|evcc786|2u122812|z70139|xvirxr1|rxj123081223|mrc1228126|vv2010cj1230495122328|m87|2a1228125|2fglj123081224|bwe12281240|vcc1316|mcg0232105|rxj123011223|rorf1228126|virgoa|4c1245|sdssj123049411223281|nvssb122817124004|leda41361|pks122812|z122831240|m87|ngvsj123049421223280|cul122812|verj1230123|wco2009j123049122256|cab95irasf122821240|uzcj1230493122327|1es1228126|cta54|pccs2143g283767450|ugc7654|nvssj123049122323|pccs1545g283767447|euvej1230123|2e2744|bec2010hrs183|dc78ugc7654|9ymstj12301221|da325|1m1228127|gaiadr23907709439453756032|pt5624|xvirx1|vpc771|nrl8|nrao401|pks1228127|3fhlj123081223|sim1228060|dgw6557|galexj1230494122328|acsvcs2|dml87747|apg152|rgbj1230123|3fglj123091224|vv98cj1230495122328|fwb89galaxy270|vv2006cj1230495122328|igrj123101221|3c2740|irasf122821240|cul1228126|iras122821240|vdd93163|ngc4486|1jy122812|h1228127|xssj123091223|vsopj12301223|mills121a|3c274|chm2007hdc720j123049421223279chm2007ldc904j123049421223279vv2000cj1230495122328|db85|vv2003cj1230495122328|hiddennamem87|1fhlj123081224|1jy1228126|h122712|2fhlj123081225|icrfj1230494122328|tevj1230123|nvssj123049122321|th20021|tgssadrj1230496122321|3u122812|wmapj12301223|1h1226128|pccs1857g283747448|pksj12301223|qsob1228126|1fglj123081223|wmap165|gw2008config139|dsb94254|dlb87v12|4fglj123081223|4u122812|iersb1228126|pks1228126|newps5j12301223|1a122812|rfcj12301223|sdssj123047551223180",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.51372871781667,
        "dec": 12.39112324608333,
        "fluxV": 8.63,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 87",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242731394498561",
        "name": "M 88",
        "names": [
          "GLEAM J123159+142503",
          "[DC78] UGC 7675",
          "Gaia DR3 3932964160685800064",
          "NVSS J123158+142505",
          "WB 1229+1441",
          "Z 99-76",
          "[DSB94] 300",
          "[YOF95] 178",
          "WSTB 29W39",
          "2E 2758",
          "EQ 1229+146",
          "INTREF 503",
          "IRAS F12294+1441",
          "IRAS 12294+1441",
          "LEDA 41517",
          "M 88",
          "MCG+03-32-059",
          "NGC 4501",
          "UGC 7675",
          "VCC 1401",
          "VPC 840",
          "2MASX J12315921+1425134",
          "NVSS B122927+144138",
          "ALFALFA 1-339",
          "2E 1229.4+1442",
          "UZC J123159.1+142515",
          "Z 1229.4+1442",
          "[CHM2007] HDC 720 J123159.21+1425134  [CHM2007] LDC 904 J123159.21+1425134  [M98c] 122928.1+144150",
          "[VV2000c] J123159.3+142513",
          "[VV2003c] J123159.3+142513",
          "[VV2006c] J123159.3+142513",
          "[VV98c] J123159.5+142517",
          "PSCz Q12294+1441",
          "[BEC2010] HRS 190",
          "HIPASS J1231+14",
          "[VV2010c] J123159.3+142513",
          "GB6 B1229+1441",
          "MITG J123159+1425",
          "87GB 122926.9+144138",
          "BWE 1229+1441",
          "[CAB95] IRAS F12294+1441",
          "[NKB95] N4501",
          "PCCS1 857 G282.29+76.51",
          "PCCS1 545 G282.29+76.51",
          "EVCC 2153"
        ],
        "searchKey": "nvssj123158142505|eq1229146|nvssb122927144138|leda41517|bec2010hrs190|evcc2153|vv2000cj1231593142513|dc78ugc7675|pccs1545g282297651|2masxj123159211425134|gb6b12291441|psczq122941441|vv98cj1231595142517|mitgj1231591425|m88|vv2003cj1231593142513|chm2007hdc720j123159211425134chm2007ldc904j123159211425134m98c1229281144150|nkb95n4501|pccs1857g282297651|z9976|ngc4501|wb12291441|vpc840|2e2758|cab95irasf122941441|dsb94300|bwe12291441|hipassj123114|iras122941441|2e122941442|alfalfa1339|gleamj123159142503|ugc7675|vcc1401|vv2010cj1231593142513|vv2006cj1231593142513|yof95178|z122941442|wstb29w39|87gb1229269144138|mcg0332059|irasf122941441|uzcj1231591142515|intref503|gaiadr33932964160685800064",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.53309791840133,
        "dec": 14.42031915884,
        "fluxV": 13.18,
        "sizeMajor": 7.76,
        "sizeMinor": 4.57,
        "sizeAngle": 138.0,
        "simbadName": "M 88",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242734834188289",
        "name": "M 89",
        "names": [
          "CRATES J123540+123257",
          "ICRF J123539.8+123322",
          "IERS B1233+128",
          "1REX J123539+1233.3",
          "ACSVCS 10",
          "2E 2786",
          "BWE 1233+1248",
          "LEDA 41968",
          "M 89",
          "MCG+02-32-149",
          "NGC 4552",
          "UGC 7760",
          "VCC 1632",
          "VPC 1012",
          "Z 70-184",
          "[BFA97] 124",
          "[DLB87] V15",
          "[TBH2001] PSPC 18",
          "[TH2002] 9",
          "[VV2000c] J123540.0+123323",
          "[VV2003c] J123540.0+123323",
          "[VV2006c] J123540.0+123323",
          "2MASX J12353988+1233217",
          "[FWB89] Galaxy 274",
          "NVSS B123308+124953",
          "2E 1233.1+1249",
          "87GB 123307.1+124854",
          "REX J1235.6+1232",
          "RX J123539.7+123328",
          "RX J1235.6+1233",
          "UZC J123539.9+123324",
          "Z 1233.1+1250",
          "[CHM2007] HDC 720 J123539.88+1233217",
          "[CHM2007] LDC 904 J123539.88+1233217  NVSS J123539+123322",
          "[BEC2010] HRS 211",
          "SDSS J123539.80+123322.8",
          "[VV2010c] J123540.0+123323",
          "MITG J123537+1233",
          "FIRST J123539.8+123322",
          "[DC78] UGC 7760",
          "CLASS J123539.8106+123322.832",
          "GB6 B1233+1249",
          "EVCC 2176"
        ],
        "searchKey": "ugc7760|uzcj1235399123324|classj1235398106123322832|m89|1rexj12353912333|chm2007ldc904j123539881233217nvssj123539123322|87gb1233071124854|fwb89galaxy274|dlb87v15|vv2003cj1235400123323|bwe12331248|rxj123561233|tbh2001pspc18|iersb1233128|rexj123561232|rxj1235397123328|mitgj1235371233|vcc1632|ngc4552|sdssj123539801233228|leda41968|bfa97124|vv2006cj1235400123323|chm2007hdc720j123539881233217|2masxj123539881233217|th20029|bec2010hrs211|z70184|vv2010cj1235400123323|gb6b12331249|cratesj123540123257|vv2000cj1235400123323|z123311250|evcc2176|acsvcs10|icrfj1235398123322|2e2786|dc78ugc7760|firstj1235398123322|nvssb123308124953|vpc1012|mcg0232149|2e123311249",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.59439092595278,
        "dec": 12.55634190713889,
        "fluxV": 9.75,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 89",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242737423351809",
        "name": "M 90",
        "names": [
          "Gaia DR3 3931816884728312320",
          "[SMM2006b] J123649.82+130946.7",
          "2MASS J12364979+1309465",
          "[LB2005] NGC 4569 X1",
          "TIC 87586513",
          "APG 76",
          "EQ 1234+134",
          "IRAS 12343+1326",
          "IRAS F12343+1326",
          "ISOSS J12368+1311",
          "LEDA 42089",
          "LJHY 39",
          "M 90",
          "MCG+02-32-155",
          "NGC 4569",
          "NVSS B123418+132627",
          "RX J123650+13092",
          "UGC 7786",
          "VCC 1690",
          "VPC 1082",
          "Z 70-192",
          "[DSB94] 236",
          "[SLK2004] 724",
          "[TBH2001] HRI 6",
          "[TBH2001] PSPC 14",
          "[THP2000] NGC 4569 0",
          "[VDD93] 166",
          "[YOF95] 206",
          "2MASX J12364981+1309463",
          "ALFALFA 1-370",
          "[RW2000] NGC 4569 X-1",
          "RX J123649.7+130947",
          "RX J1236.8+1309",
          "[CHM2007] HDC 720 J123649.81+1309463  1E 1234.2+1326",
          "UZC J123649.7+130946",
          "Z 1234.3+1326",
          "[CHM2007] LDC 904 J123649.81+1309463  [M98c] 123418.7+132618",
          "[VV2000c] J123649.9+130950",
          "[VV2003c] J123649.9+130950",
          "[VV2006c] J123649.9+130950",
          "[VV98c] J123649.9+130950",
          "PSCz Q12343+1326",
          "[BEC2010] HRS 217",
          "SDSS J123649.79+130946.6",
          "[VV2010c] J123649.9+130950",
          "PCCS1 857 G288.41+75.62",
          "PCCS1 545 G288.44+75.62",
          "EVCC 2184"
        ],
        "searchKey": "rw2000ngc4569x1|tbh2001pspc14|chm2007ldc904j123649811309463m98c1234187132618|slk2004724|2masxj123649811309463|pccs1545g288447562|psczq123431326|rxj12365013092|thp2000ngc45690|smm2006bj123649821309467|leda42089|evcc2184|pccs1857g288417562|mcg0232155|eq1234134|vv2006cj1236499130950|apg76|ugc7786|irasf123431326|vv98cj1236499130950|ljhy39|vv2000cj1236499130950|rxj123681309|vpc1082|isossj123681311|z123431326|gaiadr33931816884728312320|alfalfa1370|vdd93166|vcc1690|tic87586513|bec2010hrs217|iras123431326|nvssb123418132627|yof95206|vv2010cj1236499130950|tbh2001hri6|sdssj123649791309466|ngc4569|rxj1236497130947|uzcj1236497130946|chm2007hdc720j1236498113094631e123421326|dsb94236|vv2003cj1236499130950|2massj123649791309465|z70192|lb2005ngc4569x1|m90",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.61383360666333,
        "dec": 13.1629232814,
        "fluxV": 9.54,
        "sizeMajor": 9.77,
        "sizeMinor": 4.37,
        "sizeAngle": 23.0,
        "simbadName": "M 90",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242740833910785",
        "name": "M 91",
        "names": [
          "Gaia DR3 3932572184790211840",
          "IRAS 12328+1446",
          "IRAS F12329+1446",
          "LEDA 41934",
          "M 91",
          "MCG+03-32-075",
          "NGC 4548",
          "UGC 7753",
          "VCC 1615",
          "VPC 995",
          "Z 99-96",
          "[DSB94] 298",
          "[YOF95] 199",
          "2MASX J12352642+1429467",
          "ALFALFA 1-361",
          "UZC J123526.5+142948",
          "Z 1232.9+1446",
          "[CHM2007] HDC 720 J123526.42+1429467",
          "[CHM2007] LDC 904 J123526.42+1429467  [M98c] 123255.1+144620",
          "[VV2003c] J123526.5+143024",
          "[VV2006c] J123526.5+143024",
          "PSCz Q12328+1446",
          "[BEC2010] HRS 208",
          "SDSS J123526.44+142946.8",
          "[VV2010c] J123526.5+143024",
          "PCCS1 857 G285.72+76.81",
          "PCCS1 545 G285.69+76.83",
          "EVCC 2174"
        ],
        "searchKey": "ugc7753|pccs1857g285727681|chm2007ldc904j123526421429467m98c1232551144620|bec2010hrs208|uzcj1235265142948|dsb94298|gaiadr33932572184790211840|iras123281446|2masxj123526421429467|m91|alfalfa1361|z123291446|vv2010cj1235265143024|vv2006cj1235265143024|z9996|vv2003cj1235265143024|chm2007hdc720j123526421429467|mcg0332075|yof95199|irasf123291446|ngc4548|evcc2174|pccs1545g285697683|leda41934|vpc995|psczq123281446|sdssj123526441429468|vcc1615",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.59067907670333,
        "dec": 14.49632231493,
        "fluxV": 13.57,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 91",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242747054915585",
        "name": "M 92",
        "names": [
          "TD1 20249",
          "SAO 46606",
          "PPM 56062",
          "HD 156711",
          "GCRV 9983",
          "BD+43 2711",
          "AG+43 1399",
          "C 1715+432",
          "GCl 59",
          "M 92",
          "NGC 6341",
          "[KPS2012] MWSC 2557"
        ],
        "searchKey": "sao46606|ngc6341|gcl59|c1715432|bd432711|m92|hd156711|kps2012mwsc2557|ppm56062|gcrv9983|ag431399|td120249",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.28538611111111,
        "dec": 43.13594444444444,
        "fluxV": 6.52,
        "sizeMajor": 11.2,
        "sizeMinor": 11.2,
        "sizeAngle": 90.0,
        "simbadName": "M 92",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242749471752193",
        "name": "M 93",
        "names": [
          "[KC2019] Theia 277",
          "C 0742-237",
          "M 93",
          "NGC 2447",
          "OCl 649.0",
          "[KPR2004b] 163",
          "Cl Collinder 160",
          "[KPS2012] MWSC 1324"
        ],
        "searchKey": "kps2012mwsc1324|m93|kpr2004b163|c0742237|clcollinder160|kc2019theia277|ngc2447|ocl6490",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.742722222222223,
        "dec": -23.85305555555556,
        "fluxV": null,
        "sizeMajor": 24.2,
        "sizeMinor": 24.2,
        "sizeAngle": null,
        "simbadName": "NGC 2447",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242752383680513",
        "name": "M 94",
        "names": [
          "Gaia DR3 1527952521271039616",
          "JCMTSF J125053.1+410708",
          "JCMTSE J125053.1+410708",
          "SAO 44350",
          "PPM 53213",
          "BD+41 2333",
          "AG+41 1146",
          "1AXG J125055+4107",
          "IRAS 12485+4123",
          "IRAS F12485+4123",
          "LEDA 43495",
          "M 94",
          "MCG+07-26-058",
          "NGC 4736",
          "QDOT B1248318+412334",
          "RBS 1168",
          "UGC 7996",
          "UZC J125053.0+410715",
          "Z 217-1",
          "Z 216-34",
          "[LLJ2000] NGC 4736 X3",
          "[RW2000] NGC 4736 X-4",
          "[RWO99] NGC 4736 X9",
          "[VDD93] 173",
          "2MASX J12505314+4107125",
          "1RXS J125052.5+410713",
          "RGB J1250.9+4107",
          "RX J1250.8+4107",
          "Z 1248.5+4123",
          "[CHM2007] HDC 706 J125053.14+4107125",
          "[CHM2007] LDC 867 J125053.14+4107125  [M98c] 124832.4+412328",
          "[VV2000c] J125052.7+410710",
          "[VV2003c] J125052.7+410710",
          "[VV2006c] J125052.7+410710",
          "[VV98c] J125053.2+410718",
          "[ZEH2003] RX J1250.8+4107 1",
          "PSCz Q12485+4123",
          "[VV2010c] J125052.7+410710",
          "2XMM J125053.0+410714",
          "B3 1248+413",
          "BWE 1248+4123",
          "GB6 B1248+4123",
          "NVSS J125053+410708",
          "WB 1248+4123",
          "87GB 124832.4+412354",
          "MY 124830.0+412333.5",
          "WN B1248.5+4123",
          "MY 124830.0+410714.9",
          "6C 124830+412321",
          "7C 124831.39+412324.00",
          "RGB J1250+411",
          "[CAB95] IRAS F12485+4123",
          "[NKB95] N4736"
        ],
        "searchKey": "wb12484123|cab95irasf124854123|rwo99ngc4736x9|irasf124854123|nkb95n4736|ag411146|vv2006cj1250527410710|7c1248313941232400|jcmtsej1250531410708|iras124854123|llj2000ngc4736x3|uzcj1250530410715|vv2000cj1250527410710|jcmtsfj1250531410708|ppm53213|vdd93173|vv98cj1250532410718|1rxsj1250525410713|2masxj125053144107125|leda43495|ugc7996|chm2007hdc706j125053144107125|my12483004123335|sao44350|rw2000ngc4736x4|vv2010cj1250527410710|6c124830412321|gb6b12484123|qdotb1248318412334|z21634|my12483004107149|vv2003cj1250527410710|1axgj1250554107|zeh2003rxj1250841071|bd412333|nvssj125053410708|z2171|psczq124854123|ngc4736|mcg0726058|87gb1248324412354|rgbj1250411|bwe12484123|z124854123|chm2007ldc867j125053144107125m98c1248324412328|b31248413|2xmmj1250530410714|m94|gaiadr31527952521271039616|rbs1168|rxj125084107|wnb124854123|rgbj125094107",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.848076054762,
        "dec": 41.12025024573,
        "fluxV": 8.24,
        "sizeMajor": 11.48,
        "sizeMinor": 10.0,
        "sizeAngle": 105.0,
        "simbadName": "M 94",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242755787259905",
        "name": "M 95",
        "names": [
          "Gaia DR3 3872271737307651712",
          "IRAS F10413+1157",
          "IRAS 10413+1158",
          "LEDA 32007",
          "M 95",
          "MCG+02-28-001",
          "NGC 3351",
          "TC 726",
          "UGC 5850",
          "Z 66-4",
          "[FS90] Leo 7",
          "[VDD93] 120",
          "2MASX J10435773+1142129",
          "UZC J104357.8+114214",
          "Z 1041.4+1158",
          "[CHM2007] HDC 626 J104357.73+1142129  [CHM2007] LDC 778 J104357.73+1142129  [M98c] 104119.6+115800",
          "PSCz Q10413+1158",
          "HIPASS J1044+11",
          "ALFALFA 5-309",
          "SDSS J104357.69+114213.6"
        ],
        "searchKey": "z104141158|tc726|m95|alfalfa5309|hipassj104411|iras104131158|z664|leda32007|ugc5850|gaiadr33872271737307651712|irasf104131157|uzcj1043578114214|sdssj104357691142136|psczq104131158|mcg0228001|ngc3351|vdd93120|fs90leo7|chm2007hdc626j104357731142129chm2007ldc778j104357731142129m98c1041196115800|2masxj104357731142129",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 10.73270315238333,
        "dec": 11.70369477537,
        "fluxV": 9.73,
        "sizeMajor": 7.08,
        "sizeMinor": 4.27,
        "sizeAngle": 13.0,
        "simbadName": "M 95",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242758237945857",
        "name": "M 96",
        "names": [
          "EQ 1044+120",
          "IRAS 10441+1205",
          "IRAS F10441+1205",
          "LEDA 32192",
          "M 96",
          "MCG+02-28-006",
          "NGC 3368",
          "TC 532",
          "UGC 5882",
          "Z 66-13",
          "[FS90] Leo 19",
          "[N93] 50",
          "[VDD93] 122",
          "2MASX J10464574+1149117",
          "JCMTSE J104645.8+114914",
          "JCMTSF J104645.8+114914",
          "UZC J104645.9+114926",
          "Z 1044.1+1205",
          "[CHM2007] HDC 626 J104645.74+1149117  [CHM2007] LDC 778 J104645.74+1149117  [M98c] 104406.9+120505",
          "PSCz Q10441+1205",
          "HIPASS J1046+11",
          "ALFALFA 5-321",
          "SDSS J104645.67+114911.8"
        ],
        "searchKey": "jcmtsfj1046458114914|irasf104411205|m96|psczq104411205|n9350|jcmtsej1046458114914|ugc5882|tc532|z104411205|fs90leo19|leda32192|sdssj104645671149118|mcg0228006|z6613|iras104411205|alfalfa5321|chm2007hdc626j104645741149117chm2007ldc778j104645741149117m98c1044069120505|hipassj104611|2masxj104645741149117|ngc3368|eq1044120|vdd93122|uzcj1046459114926",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 10.77937333333333,
        "dec": 11.81993888888889,
        "fluxV": 9.25,
        "sizeMajor": 7.24,
        "sizeMinor": 4.79,
        "sizeAngle": 5.0,
        "simbadName": "M 96",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242761510387713",
        "name": "M 97",
        "names": [
          "NVSS J111450+550126",
          "NVSS J111443+550045",
          "WN B1111.8+5517B",
          "WN B1111.8+5517A",
          "Gaia DR3 843950873117830528",
          "TIC 309863161",
          "2EUVE J1114+55.0",
          "87GB 111154.0+551728",
          "AG82 135",
          "BWE 1111+5517",
          "CSI+55-11119",
          "EUVE J1114+55.0",
          "GCRV 6897",
          "IRAS F11118+5517",
          "IRAS 11119+5517",
          "LJHY 9",
          "M 97",
          "NAME Owl Nebula",
          "NGC 3587",
          "PK 148+57 1",
          "PN G148.4+57.0",
          "PN VV 59",
          "PN VV' 107",
          "PN ARO 25",
          "GB6 B1111+5517",
          "PSCz P11119+5517",
          "WD 1111+552",
          "SDSS J111447.71+550108.4",
          "SDSS J111447.70+550108.7",
          "2MASS J11144772+5501085",
          "Gaia DR2 843950873117830528",
          "RX J111447.9+550106"
        ],
        "searchKey": "pnvv59|sdssj111447705501087|euvej1114550|pk148571|gcrv6897|wd1111552|owlnebula|ngc3587|irasf111185517|pnvv107|sdssj111447715501084|pnaro25|m97|bwe11115517|2massj111447725501085|gaiadr2843950873117830528|png1484570|ljhy9|csi5511119|wnb111185517a|wnb111185517b|87gb1111540551728|rxj1114479550106|nvssj111450550126|gb6b11115517|psczp111195517|nvssj111443550045|gaiadr3843950873117830528|ag82135|2euvej1114550|tic309863161|iras111195517",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 11.24658674855,
        "dec": 55.01902300891,
        "fluxV": 15.777,
        "sizeMajor": 3.333,
        "sizeMinor": 3.333,
        "sizeAngle": 90.0,
        "simbadName": "M 97",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242763983880193",
        "name": "M 98",
        "names": [
          "[CAB95] IRAS F12112+1510",
          "TGSSADR J121348.3+145402",
          "[NKB95] N4192",
          "[DC78] UGC 7231",
          "Gaia DR3 3921364416093374208",
          "2MFGC 9627",
          "INTREF 480",
          "IRAS F12112+1510",
          "IRAS 12112+1510",
          "ISOSS J12138+1453",
          "LEDA 39028",
          "M 98",
          "MCG+03-31-079",
          "NGC 4192",
          "NVSS B121115+151032",
          "UGC 7231",
          "VCC 92",
          "Z 98-108",
          "[DSB94] 113",
          "[SLK2004] 670",
          "[THP2000] NGC 4192 0",
          "[YOF95] 30",
          "2MASX J12134829+1454016",
          "ALFALFA 1-177",
          "1E 1211.2+1510",
          "CAIRNS J121348.28+145401.2",
          "UZC J121348.2+145400",
          "Z 1211.2+1510",
          "[CHM2007] HDC 708 J121348.29+1454016  [CHM2007] LDC 904 J121348.29+1454016",
          "[M98c] 121115.4+151023",
          "[VV2000c] J121348.3+145402",
          "[VV2003c] J121348.3+145402",
          "[VV2006c] J121348.3+145402",
          "[VV98c] J121348.3+145402",
          "PSCz Q12112+1510",
          "[BEC2010] HRS 91",
          "[VV2010c] J121348.3+145402",
          "[YWP2010] 183.451+14.901",
          "PCCS1 857 G265.42+74.97",
          "PCCS1 545 G265.43+74.97",
          "SDSS J121348.28+145401.6",
          "SDSS J121347.34+145356.9",
          "EVCC 188"
        ],
        "searchKey": "ngc4192|gaiadr33921364416093374208|mcg0331079|sdssj121348281454016|cairnsj121348281454012|psczq121121510|tgssadrj1213483145402|vv2000cj1213483145402|leda39028|2mfgc9627|uzcj1213482145400|ugc7231|thp2000ngc41920|irasf121121510|m98|isossj121381453|vv2010cj1213483145402|vcc92|iras121121510|intref480|yof9530|z121121510|pccs1857g265427497|1e121121510|chm2007hdc708j121348291454016chm2007ldc904j121348291454016|sdssj121347341453569|vv2003cj1213483145402|vv98cj1213483145402|dc78ugc7231|z98108|dsb94113|m98c1211154151023|nvssb121115151032|slk2004670|bec2010hrs91|cab95irasf121121510|ywp201018345114901|nkb95n4192|evcc188|2masxj121348291454016|vv2006cj1213483145402|pccs1545g265437497|alfalfa1177",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.230080852416,
        "dec": 14.900542632,
        "fluxV": 10.14,
        "sizeMajor": 9.55,
        "sizeMinor": 2.29,
        "sizeAngle": 152.0,
        "simbadName": "M 98",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242767552675841",
        "name": "M 99",
        "names": [
          "GLEAM J121850+142515",
          "NAIC 1216+147",
          "[DC78] UGC 7345",
          "2E 2650",
          "HIPASS J1218+14",
          "IRAS F12163+1441",
          "IRAS 12162+1441",
          "LEDA 39578",
          "M 99",
          "MCG+03-31-099",
          "MRC 1216+146",
          "NAME Coma Pinwheel",
          "NGC 4254",
          "NVSS J121850+142450",
          "UGC 7345",
          "VCC 307",
          "WB 1216+1442",
          "Z 98-144",
          "Z 99-11",
          "[DSB94] 308",
          "[VDD93] 153",
          "[YOF95] 67",
          "2MASX J12184962+1424593",
          "GB6 B1216+1442",
          "NVSS B121617+144129",
          "ALFALFA 1-248",
          "JCMTSE J121849.6+142502",
          "1RXS J121853.8+142602",
          "2E 1216.3+1441",
          "87GB 121618.2+144207",
          "CAIRNS J121849.56+142459.4",
          "Z 1216.3+1442",
          "[CHM2007] HDC 702 J121849.62+1424593",
          "[CHM2007] LDC 904 J121849.62+1424593  [M98c] 121616.9+144146",
          "UZC J121849.6+142501",
          "PSCz Q12162+1441",
          "[BEC2010] HRS 102",
          "SDSS J121849.60+142459.4",
          "BWE 1216+1442",
          "MITG J121849+1424",
          "[CAB95] IRAS F12163+1441",
          "VLSS J1218.8+1424",
          "[NKB95] N4254",
          "NAICGB 1216+147",
          "PCCS1 857 G270.45+75.20",
          "PCCS1 545 G270.36+75.19",
          "NAME Virgo Cluster Pinwheel",
          "EVCC 319"
        ],
        "searchKey": "chm2007ldc904j121849621424593m98c1216169144146|gleamj121850142515|ugc7345|z121631442|vlssj121881424|gb6b12161442|uzcj1218496142501|cairnsj121849561424594|naicgb1216147|vcc307|alfalfa1248|leda39578|virgoclusterpinwheel|bwe12161442|evcc319|ngc4254|nvssb121617144129|vdd93153|jcmtsej1218496142502|m99|bec2010hrs102|comapinwheel|psczq121621441|pccs1857g270457520|naic1216147|nkb95n4254|mitgj1218491424|pccs1545g270367519|2e2650|2masxj121849621424593|sdssj121849601424594|2e121631441|z9911|hipassj121814|1rxsj1218538142602|irasf121631441|iras121621441|mcg0331099|z98144|dc78ugc7345|mrc1216146|wb12161442|dsb94308|87gb1216182144207|chm2007hdc702j121849621424593|yof9567|nvssj121850142450|cab95irasf121631441",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.31378472222222,
        "dec": 14.41648888888889,
        "fluxV": 9.87,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 99",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242770386223105",
        "name": "M 100",
        "names": [
          "NVSS J122255+154921",
          "NVSS J122251+154941",
          "Gaia DR3 3945577826800693888",
          "MITG J122256+1549",
          "GB6 B1220+1605",
          "87GB 122023.7+160528",
          "1RXS J122256.0+154923",
          "2E 2684",
          "IRAS 12204+1605",
          "IRAS F12203+1605",
          "KUG 1220+160",
          "LEDA 40153",
          "M 100",
          "MCG+03-32-015",
          "NGC 4321",
          "NVSS B122022+160601",
          "UGC 7450",
          "VCC 596",
          "Z 99-30",
          "[BFA97] 31",
          "[DSB94] 294",
          "[IPA98] H23",
          "[IPA98] E7",
          "[TH2002] 6",
          "[VDD93] 156",
          "[YOF95] 102",
          "2MASX J12225489+1549205",
          "UZC J122255.0+154922",
          "ALFALFA 1-289",
          "1E 1220.4+1605",
          "2E 1220.3+1605",
          "CAIRNS J122254.89+154920.7",
          "Z 1220.4+1606",
          "[CHM2007] HDC 720 J122254.89+1549205  [CHM2007] LDC 904 J122254.89+1549205  [GCS2005] J122254.91+154919.9",
          "[M98c] 122023.2+160600",
          "PSCz Q12204+1605",
          "[BEC2010] HRS 122",
          "HIPASS J1222+15",
          "SDSS J122254.91+154920.2",
          "BWE 1220+1605",
          "WB 1220+1605",
          "[CAB95] IRAS F12203+1605",
          "[DC78] UGC 7450",
          "PCCS1 857 G271.17+76.89",
          "PCCS1 545 G271.16+76.88",
          "EVCC 467",
          "Gaia DR2 3945577826800372992"
        ],
        "searchKey": "mcg0332015|pccs1545g271167688|psczq122041605|ugc7450|nvssj122251154941|ipa98e7|dsb94294|m100|2e2684|yof95102|iras122041605|z122041606|bec2010hrs122|nvssj122255154921|th20026|uzcj1222550154922|bwe12201605|ngc4321|kug1220160|ipa98h23|vdd93156|2e122031605|vcc596|1rxsj1222560154923|cairnsj122254891549207|evcc467|nvssb122022160601|gaiadr33945577826800693888|leda40153|gaiadr23945577826800372992|alfalfa1289|wb12201605|pccs1857g271177689|z9930|dc78ugc7450|2masxj122254891549205|87gb1220237160528|cab95irasf122031605|bfa9731|m98c1220232160600|mitgj1222561549|sdssj122254911549202|irasf122031605|chm2007hdc720j122254891549205chm2007ldc904j122254891549205gcs2005j122254911549199|1e122041605|gb6b12201605|hipassj122215",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.381924999822,
        "dec": 15.8223045161,
        "fluxV": 9.35,
        "sizeMajor": 7.59,
        "sizeMinor": 6.76,
        "sizeAngle": 170.0,
        "simbadName": "M 100",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242778041843713",
        "name": "M 102",
        "names": [
          "IRAS 15051+5557",
          "IRAS F15051+5557",
          "LEDA 53933",
          "M 102",
          "MCG+09-25-017",
          "NGC 5866",
          "UGC 9723",
          "Z 274-16",
          "[VDD93] 203",
          "2MASX J15062956+5545479",
          "JCMTSE J150629.2+554546",
          "JCMTSF J150629.2+554552",
          "1E 1505.1+5557",
          "Z 1505.1+5557",
          "[CHM2007] HDC 903 J150629.56+5545479",
          "[CHM2007] LDC 1112 J150629.56+5545479  [VV2003c] J150629.5+554548",
          "[VV2006c] J150629.5+554548",
          "UZC J150629.4+554548",
          "PSCz Q15051+5557",
          "[VV2010c] J150629.5+554548",
          "SDSS J150629.63+554549.1",
          "EON 226.622+55.763"
        ],
        "searchKey": "z150515557|chm2007ldc1112j150629565545479vv2003cj1506295554548|irasf150515557|vdd93203|jcmtsej1506292554546|m102|1e150515557|chm2007hdc903j150629565545479|sdssj150629635545491|ngc5866|eon22662255763|mcg0925017|ugc9723|2masxj150629565545479|leda53933|psczq150515557|jcmtsfj1506292554552|vv2010cj1506295554548|uzcj1506294554548|vv2006cj1506295554548|z27416|iras150515557",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 15.10821138888889,
        "dec": 55.76330833333333,
        "fluxV": 9.89,
        "sizeMajor": 6.31,
        "sizeMinor": 2.69,
        "sizeAngle": 128.0,
        "simbadName": "M 102",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242780638871553",
        "name": "M 103",
        "names": [
          "C 0129+604",
          "M 103",
          "NGC 581",
          "OCISM 75",
          "OCl 326",
          "[KPR2004b] 18",
          "[KPS2012] MWSC 0124"
        ],
        "searchKey": "c0129604|ngc581|m103|kps2012mwsc0124|ocism75|ocl326|kpr2004b18",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 1.555944444444445,
        "dec": 60.65888888888889,
        "fluxV": 7.4,
        "sizeMajor": 7.4,
        "sizeMinor": 7.4,
        "sizeAngle": null,
        "simbadName": "M 103",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242790036832257",
        "name": "M 105",
        "names": [
          "GIN 773",
          "K79 33A",
          "LEDA 32256",
          "M 105",
          "MCG+02-28-011",
          "NGC 3379",
          "UGC 5902",
          "Z 66-18",
          "[FS90] Leo 25",
          "[TT2002] Leo 2",
          "2MASX J10474959+1234538",
          "[FWB89] Galaxy 208",
          "UZC J104749.6+123453",
          "Z 1045.2+1251",
          "[CHM2007] HDC 626 J104749.59+1234538",
          "[CHM2007] LDC 778 J104749.59+1234538  SDSS J104749.60+123453.9"
        ],
        "searchKey": "gin773|m105|fs90leo25|uzcj1047496123453|mcg0228011|chm2007hdc626j104749591234538|k7933a|z6618|fwb89galaxy208|chm2007ldc778j104749591234538sdssj104749601234539|ngc3379|tt2002leo2|leda32256|z104521251|ugc5902|2masxj104749591234538",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 10.79711111111111,
        "dec": 12.58163055555556,
        "fluxV": 9.76,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 105",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242794096820225",
        "name": "M 106",
        "names": [
          "WN B1216.4+4735",
          "[ZEH2003] RX J1219.0+4717 1",
          "[KRL2007b] 111",
          "B3 1216+475",
          "GB1 1216+476",
          "LEDA 39600",
          "M 106",
          "MCG+08-22-104",
          "NGC 4258",
          "OHIO N 427",
          "RX J1219.0+4717",
          "UGC 7353",
          "VV 448",
          "XSS J12190+4715",
          "Z 243-67",
          "Z 244-3",
          "[CPF88] 121629.0+473500.0",
          "[VDD93] 154",
          "2MASX J12185761+4718133",
          "1RXP J121856.4+471755",
          "1RXS J121900.4+471747",
          "RX J1218.9+4718",
          "SAX J1219.7+4721",
          "UZC J121857.7+471820",
          "Z 1216.5+4735",
          "[CHM2007] HDC 706 J121857.61+4718133  [CHM2007] LDC 867 J121857.61+4718133",
          "[M98c] 121629.7+473455",
          "[VV2000c] J121857.5+471814",
          "[VV2003c] J121857.5+471814",
          "[VV2006c] J121857.5+471814",
          "[VV98c] J121857.5+471814",
          "PBC J1218.9+4718",
          "SWIFT J1219.4+4720",
          "SWIFT J1219.0+4715",
          "[VV2010c] J121857.5+471814",
          "[BAG2012] 184.7378+47.2874",
          "SDSS J121857.50+471814.3",
          "2XMM J121857.5+471814",
          "87GB 121625.0+473537",
          "GB6 B1216+4735",
          "MY 121625.0+471813.7",
          "WB 1216+4735",
          "7C 121620.39+473545.00",
          "7C 121626.19+473526.00",
          "7C 121635.39+473404.00",
          "VLSS J1218.9+4718",
          "[NKB95] N4258",
          "SWIFT J1218.9+4717"
        ],
        "searchKey": "swiftj121894717|2xmmj1218575471814|ugc7353|1rxsj1219004471747|vdd93154|pbcj121894718|z24367|nkb95n4258|mcg0822104|vv2003cj1218575471814|gb6b12164735|7c1216261947352600|chm2007hdc706j121857614718133chm2007ldc867j121857614718133|vv98cj1218575471814|rxj121894718|swiftj121944720|vv2000cj1218575471814|m106|krl2007b111|wnb121644735|xssj121904715|swiftj121904715|rxj121904717|z2443|z121654735|cpf8812162904735000|7c1216353947340400|b31216475|gb11216476|ohion427|saxj121974721|sdssj121857504718143|leda39600|ngc4258|zeh2003rxj1219047171|wb12164735|1rxpj1218564471755|vlssj121894718|2masxj121857614718133|7c1216203947354500|vv2010cj1218575471814|bag20121847378472874|vv448|vv2006cj1218575471814|my12162504718137|87gb1216250473537|uzcj1218577471820|m98c1216297473455",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.31600555555556,
        "dec": 47.30371944444444,
        "fluxV": 8.41,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 106",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242796507332609",
        "name": "M 107",
        "names": [
          "C 1629-129",
          "GCl 44",
          "M 107",
          "NGC 6171",
          "[KPS2012] MWSC 2422"
        ],
        "searchKey": "c1629129|m107|kps2012mwsc2422|gcl44|ngc6171",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ROSE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.54218333333333,
        "dec": -13.05377777777778,
        "fluxV": null,
        "sizeMajor": 10.0,
        "sizeMinor": 10.0,
        "sizeAngle": 90.0,
        "simbadName": "M 107",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242798926168065",
        "name": "M 108",
        "names": [
          "ILT J111134.75+554042.2",
          "2MFGC 8733",
          "AAVSO 1105+56",
          "IRAS 11085+5556",
          "IRAS F11085+5556",
          "ISOSS J11116+5540",
          "K73 469",
          "LEDA 34030",
          "LJHY 8",
          "M 108",
          "MCG+09-18-098",
          "NGC 3556",
          "TC E17",
          "TC 917",
          "UGC 6225",
          "Z 268-1",
          "Z 267-48",
          "[SLK2004] 604",
          "[VDD93] 128",
          "2MASX J11113096+5540268",
          "Z 1108.5+5556",
          "[CHM2007] LDC 867 J111130.96+5540268  [M98c] 110836.8+555633",
          "UZC J111131.2+554025",
          "PSCz Q11085+5556",
          "SDSS J111131.06+554027.4",
          "BWE 1108+5556",
          "GB6 B1108+5556",
          "NVSS J111130+554027",
          "WB 1108+5556",
          "87GB 110837.4+555624",
          "WN B1108.6+5556",
          "6C 110835+555638",
          "7C 110836.79+555641.00",
          "[CAB95] IRAS F11085+5556",
          "[NKB95] N3556",
          "SDSS J111130.83+554027.5"
        ],
        "searchKey": "slk2004604|bwe11085556|wb11085556|nvssj111130554027|m108|7c1108367955564100|aavso110556|nkb95n3556|2mfgc8733|leda34030|iltj111134755540422|psczq110855556|chm2007ldc867j111130965540268m98c1108368555633|6c110835555638|iras110855556|tc917|z26748|ljhy8|mcg0918098|gb6b11085556|z110855556|tce17|vdd93128|2masxj111130965540268|ngc3556|irasf110855556|cab95irasf110855556|z2681|sdssj111131065540274|wnb110865556|isossj111165540|ugc6225|uzcj1111312554025|87gb1108374555624|sdssj111130835540275|k73469",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 11.19193527777778,
        "dec": 55.67412222222222,
        "fluxV": null,
        "sizeMajor": 7.76,
        "sizeMinor": 2.69,
        "sizeAngle": 79.0,
        "simbadName": "M 108",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242801319084033",
        "name": "M 109",
        "names": [
          "Gaia DR3 792531417276332544",
          "EQ 1155+536",
          "IRAS 11549+5339",
          "IRAS 11550+5339",
          "LEDA 37617",
          "M 109",
          "MCG+09-20-044",
          "NGC 3992",
          "TC 755",
          "TC E19",
          "UGC 6937",
          "Z 1155.0+5339",
          "Z 269-23",
          "[M98c] 115501.0+533913",
          "2MASX J11573598+5322282",
          "[CHM2007] HDC 706 J115735.98+5322282  [CHM2007] LDC 867 J115735.98+5322282  UZC J115736.0+532229",
          "PSCz Q11549+5339",
          "SDSS J115735.97+532228.9"
        ],
        "searchKey": "sdssj115735975322289|tc755|chm2007hdc706j115735985322282chm2007ldc867j115735985322282uzcj1157360532229|m109|mcg0920044|ngc3992|z115505339|gaiadr3792531417276332544|ugc6937|iras115505339|z26923|2masxj115735985322282|iras115495339|psczq115495339|eq1155536|leda37617|m98c1155010533913|tce19",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 11.95998976332333,
        "dec": 53.37472391176,
        "fluxV": null,
        "sizeMajor": 7.24,
        "sizeMinor": 4.79,
        "sizeAngle": 78.0,
        "simbadName": "M 109",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242804283047937",
        "name": "M 110",
        "names": [
          "Gaia DR3 381334757352292096",
          "IRAS 00376+4124",
          "ISOSS J00403+4140",
          "LEDA 2429",
          "M 110",
          "MCG+07-02-014",
          "NGC 205",
          "UGC 426",
          "Z 535-14",
          "[SLK2004] 93",
          "2MASX J00402207+4141070",
          "[FWB89] Galaxy 7",
          "[CHM2007] LDC 31 J004022.07+4141070  Z 0037.6+4125",
          "[CHM2007] HDC 29 J004022.07+4141070",
          "[M98c] 003738.7+412444",
          "UZC J004022.0+414107",
          "Gaia DR2 381334757352292096"
        ],
        "searchKey": "iras003764124|isossj004034140|z53514|m98c0037387412444|uzcj0040220414107|chm2007hdc29j004022074141070|ugc426|gaiadr3381334757352292096|gaiadr2381334757352292096|m110|leda2429|ngc205|2masxj004022074141070|mcg0702014|chm2007ldc31j004022074141070z003764125|fwb89galaxy7|slk200493",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 0.6727936763886666,
        "dec": 41.68541867226,
        "fluxV": 8.07,
        "sizeMajor": 18.62,
        "sizeMinor": 10.47,
        "sizeAngle": 170.0,
        "simbadName": "M 110",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959184277307393",
    "title": "Red Giants",
    "color": "RED",
    "credit": "https://www.go-astronomy.com/red-giant-stars.php",
    "imgURL": "/list_icons/red-giants.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "948744223158206465",
        "name": "Antares",
        "names": [
          "HIP 80763",
          "TIC 175934060",
          "PLX 3741",
          "* alf Sco",
          "* 21 Sco",
          "CD-26 11359",
          "CMC 184415",
          "CPD-26 5648",
          "CSI-26 11359 21",
          "Elia 2-74",
          "FK5 616",
          "GC 22157",
          "GCRV 9479",
          "GEN# +1.00148478",
          "GSC 06803-02158",
          "HIC 80763",
          "HR 6134",
          "IRAS 16262-2619",
          "IRC -30265",
          "N30 3686",
          "NAME Antares",
          "PMC 90-93 439",
          "PPM 265579",
          "RAFGL 1863",
          "ROT 2345",
          "SAO 184415",
          "SKY# 29714",
          "SRS 30616",
          "UBV M 21449",
          "CSI-26 11359 23",
          "TD1 19208",
          "UBV 21661",
          "2MASS J16292443-2625549",
          "Ced 132",
          "DG 141",
          "LBN 1108",
          "LBN 351.88+15.11",
          "GN 16.26.3",
          "VDB 107",
          "GEN# +1.00148479",
          "TYC 6803-2158-1",
          "ADS 10074 A",
          "CCDM J16294-2626A",
          "WDS J16294-2626A",
          "* alf Sco A",
          "V* alf Sco",
          "AAVSO 1623-26",
          "HD 148478",
          "WEB 13658",
          "IDS 16233-2613 A",
          "** GNT 1A",
          "WISEA J162924.49-262554.8"
        ],
        "searchKey": "n303686|pmc9093439|wdsj162942626a|tic175934060|21sco|sao184415|srs30616|iras162622619|irc30265|cpd265648|gcrv9479|tyc680321581|hic80763|alfscoa|hr6134|dg141|cmc184415|rafgl1863|sky29714|cd2611359|gsc0680302158|hip80763|fk5616|gen100148479|ads10074a|gen100148478|alfsco|rot2345|wiseaj162924492625548|antares|2massj162924432625549|ids162332613a|vdb107|ccdmj162942626a|gc22157|lbn351881511|web13658|lbn1108|ppm265579|csi261135923|antares|plx3741|td119208|valfsco|ubv21661|aavso162326|elia274|ubvm21449|ced132|hd148478|gnt1a|csi261135921|gn16263",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.49012769444445,
        "dec": -26.43200261111111,
        "fluxV": 0.91,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Sco",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084460332351490",
        "name": "V* RW Cep",
        "names": [
          "Gaia DR3 2005992002061917312",
          "TIC 422108142",
          "AAVSO 2219+55A",
          "AG+55 1572",
          "AGKR 20037",
          "AN 128.1907",
          "BD+55 2737",
          "DO 41257",
          "GC 31299",
          "GCRV 73164",
          "GEN# +1.00212466",
          "GSC 03986-00365",
          "HD 212466",
          "HIC 110504",
          "HIP 110504",
          "IRAS 22212+5542",
          "IRC +60353",
          "JP11 3481",
          "LF 4 +55 299",
          "PPM 40586",
          "RAFGL 2896",
          "SAO 34387",
          "SKY# 42615",
          "SV* HV 2982",
          "TYC 3986-365-1",
          "UBV M 26377",
          "V* RW Cep",
          "YZ 55 13111",
          "2MASS J22230701+5557477",
          "UCAC3 292-186925",
          "Gaia DR1 2005991997748954112",
          "Gaia DR2 2005992002061917312"
        ],
        "searchKey": "aavso221955a|jp113481|sao34387|vrwcep|yz5513111|ag551572|lf455299|2massj222307015557477|gaiadr12005991997748954112|gaiadr22005992002061917312|gen100212466|ppm40586|hip110504|iras222125542|bd552737|agkr20037|svhv2982|ubvm26377|an1281907|hic110504|gcrv73164|hd212466|do41257|ucac3292186925|gaiadr32005992002061917312|gc31299|tyc39863651|gsc0398600365|tic422108142|irc60353|sky42615|rafgl2896",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 22.3852820017,
        "dec": 55.96322899119,
        "fluxV": 6.65,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* RW Cep",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603256143874",
        "name": "V* UY Sct",
        "names": [
          "WISE J182736.57-122800.7",
          "Gaia DR3 4152993273702130432",
          "TIC 177198220",
          "BD-12 5055",
          "GEN# -0.01205055",
          "GSC 05698-05176",
          "IRAS 18248-1229",
          "IRC -10422",
          "JP11 293",
          "PPM 234561",
          "RAFGL 2162",
          "SV* HV 3805",
          "TYC 5698-5176-1",
          "UBV M 5387",
          "V* UY Sct",
          "2MASS J18273652-1227589",
          "UCAC3 156-208543",
          "Gaia DR2 4152993273702130432"
        ],
        "searchKey": "wisej182736571228007|tic177198220|tyc569851761|2massj182736521227589|gen001205055|iras182481229|rafgl2162|ucac3156208543|gaiadr34152993273702130432|irc10422|ubvm5387|bd125055|jp11293|svhv3805|vuysct|gsc0569805176|gaiadr24152993273702130432|ppm234561",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.460146714524,
        "dec": -12.46636071492,
        "fluxV": 11.2,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* UY Sct",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603269251074",
        "name": "WOH G 64",
        "names": [
          "WISE J045510.51-682030.2",
          "Gaia DR3 4661527262798576768",
          "TIC 30186593",
          "DCMC J045510.49-682030.2",
          "2MASS J04551048-6820298",
          "2MASSI J0455105-682029",
          "IRAS 04553-6825",
          "LI-LMC 181",
          "MSX LMC 1182",
          "WOH G 64",
          "OGLE LMC-LPV-6819",
          "MACHO 47.2368.9",
          "[VOG2010] 6",
          "Gaia DR2 4661527262798576768"
        ],
        "searchKey": "wisej045510516820302|dcmcj045510496820302|oglelmclpv6819|tic30186593|lilmc181|gaiadr34661527262798576768|iras045536825|vog20106|gaiadr24661527262798576768|2massj045510486820298|wohg64|2massij0455105682029|macho4723689|msxlmc1182",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 4.919589891525334,
        "dec": -68.34167194751,
        "fluxV": 18.46,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "WOH G 64",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603270397954",
        "name": "Rasalgethi",
        "names": [
          "HIP 84345",
          "GEN# +1.00156014",
          "TIC 154699609",
          "* alf Her",
          "AAVSO 1710+14",
          "ADS 10418 AB",
          "BD+14 3207",
          "CCDM J17146+1424AB",
          "CSI+14 3207 5",
          "HIC 84345",
          "IDS 17101+1430 AB",
          "NAME Rasalgethi",
          "TD1 20143",
          "UBV 14596",
          "WDS J17146+1423AB",
          "** STF 2140AB",
          "1RXS J171439.3+142320",
          "2MASS J17143885+1423253"
        ],
        "searchKey": "ids171011430ab|hip84345|2massj171438851423253|tic154699609|ads10418ab|rasalgethi|ccdmj171461424ab|bd143207|ubv14596|alfher|1rxsj1714393142320|rasalgethi|td120143|aavso171014|csi1432075|wdsj171461423ab|stf2140ab|hic84345|gen100156014",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.24412727222222,
        "dec": 14.39034061111111,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Her",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603316142082",
        "name": "V* S Per",
        "names": [
          "HIP 11093",
          "Gaia DR3 459101393719884800",
          "[RMB2014] G134.62-02.19",
          "TIC 348619093",
          "2MASS J02225173+5835112",
          "AAVSO 0215+58",
          "AG+58 255",
          "BD+57 552",
          "CCDM J02229+5836A",
          "CSI+57 552 2",
          "DO 25619",
          "GC 2843",
          "GCRV 27308",
          "GEN# +1.00014528",
          "HD 14528",
          "HIC 11093",
          "IDS 02156+5808 A",
          "IRAS 02192+5821",
          "IRC +60088",
          "JP11 633",
          "PPM 27539",
          "RAFGL 323",
          "SAO 23261",
          "UBV 21545",
          "V* S Per",
          "YZ 58 2170",
          "[KW97] 11-22",
          "[PCC93] 22",
          "[TVH89] 5",
          "TYC 3698-3073-1",
          "GSC 03698-03073",
          "UCAC3 298-47913",
          "WDS J02229+5835A",
          "Gaia DR2 459101393719884800"
        ],
        "searchKey": "rmb2014g134620219|hic11093|jp11633|tyc369830731|wdsj022295835a|hd14528|tvh895|aavso021558|ucac329847913|gen100014528|rafgl323|gaiadr3459101393719884800|ubv21545|gaiadr2459101393719884800|pcc9322|tic348619093|ppm27539|gc2843|hip11093|iras021925821|gcrv27308|ccdmj022295836a|vsper|ids021565808a|sao23261|do25619|bd57552|csi575522|kw971122|yz582170|gsc0369803073|ag58255|2massj022251735835112|irc60088",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.381030920362667,
        "dec": 58.58650632618,
        "fluxV": 7.9,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* S Per",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603335770114",
        "name": "Herschel's Garnet Star",
        "names": [
          "Gaia DR3 2202630001603369856",
          "TIC 260614141",
          "NAME Herschel's Garnet Star",
          "NAME Erakis",
          "PLX 5252",
          "* mu. Cep",
          "AAVSO 2140+58",
          "ADS 15271 A",
          "AG+58 1378",
          "BD+58 2316",
          "CCDM J21435+5847A",
          "CSI+58 2316 1",
          "DO 40029",
          "GC 30440",
          "GCRV 13658",
          "GSC 03979-01616",
          "HD 206936",
          "HIC 107259",
          "HIP 107259",
          "HR 8316",
          "IDS 21404+5819 A",
          "IRAS 21419+5832",
          "IRC +60325",
          "JP11 3398",
          "PPM 39787",
          "RAFGL 2802",
          "SAO 33693",
          "SKY# 41413",
          "SV* VV 469",
          "TYC 3979-1616-1",
          "UBV 21553",
          "V* mu. Cep",
          "YZ 58 12465",
          "[KW97] 59-21",
          "[LFO93] 2141+58",
          "2MASS J21433045+5846480",
          "Cl Trumpler 37 1319",
          "Cl* Trumpler 37 MMU 1319",
          "PLX 5252.00",
          "WDS J21435+5847A",
          "WEB 19372",
          "Gaia DR2 2202630001591176064"
        ],
        "searchKey": "kw975921|irc60325|do40029|bd582316|sao33693|web19372|gaiadr32202630001603369856|plx5252|svvv469|mucep|cltrumpler371319|rafgl2802|ag581378|hd206936|lfo93214158|erakis|2massj214330455846480|gc30440|ppm39787|ads15271a|tyc397916161|plx525200|herschelsgarnetstar|sky41413|ubv21553|ccdmj214355847a|aavso214058|hip107259|gaiadr22202630001591176064|vmucep|hr8316|hic107259|ids214045819a|yz5812465|jp113398|gsc0397901616|iras214195832|cltrumpler37mmu1319|tic260614141|gcrv13658|csi5823161|wdsj214355847a|herschelsgarnetstar",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.7251280715,
        "dec": 58.78004449472222,
        "fluxV": 4.08,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* mu. Cep",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603335802882",
        "name": "Gacrux",
        "names": [
          "GJ 470",
          "HIP 61084",
          "TIC 272314138",
          "* gam Cru",
          "CCDM J12312-5706A",
          "CD-56 4504",
          "CPC 20 3840",
          "CPD-56 5272",
          "CSV 101285",
          "FK5 468",
          "GC 17052",
          "GCRV 7528",
          "GEN# +1.00108903",
          "HD 108903",
          "HIC 61084",
          "HR 4763",
          "IDS 12256-5633 A",
          "IRAS 12283-5650",
          "JP11 2226",
          "LTT 4752",
          "N30 2892",
          "NLTT 30973",
          "NSV 5672",
          "PPM 341058",
          "RAFGL 4150",
          "SAO 240019",
          "SKY# 23406",
          "SV* BV 839",
          "SV* ZI 944",
          "UBV 11239",
          "UBV M 18297",
          "[DS98] 174",
          "[L91b] 208",
          "2MASS J12310993-5706474",
          "WDS J12312-5707A",
          "NAME Gacrux",
          "TYC 8654-3422-1",
          "WEB 10875"
        ],
        "searchKey": "sky23406|iras122835650|ppm341058|cpc203840|ids122565633a|rafgl4150|n302892|gacrux|nltt30973|nsv5672|ubv11239|jp112226|svzi944|tic272314138|ubvm18297|gacrux|ltt4752|2massj123109935706474|hip61084|tyc865434221|ds98174|ccdmj123125706a|csv101285|web10875|l91b208|gc17052|fk5468|cd564504|svbv839|gamcru|gen100108903|sao240019|wdsj123125707a|gj470|hic61084|cpd565272|hr4763|gcrv7528|hd108903",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.519433225,
        "dec": -57.11321344444445,
        "fluxV": 1.64,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* gam Cru",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603340128258",
        "name": "V* VX Sgr",
        "names": [
          "HIP 88838",
          "OH 008.344-01.002",
          "OH 008.3-01.0",
          "[SVM2001b] 32",
          "Gaia DR3 4069737050648231424",
          "[RMB2014] G008.34-01.00",
          "BAaDE ce3a-00110",
          "TIC 175117667",
          "AAVSO 1802-22",
          "AN 271.1904",
          "BD-22 4575",
          "CD-22 12589",
          "GEN# +1.00165674",
          "HD 165674",
          "HIC 88838",
          "IRAS 18050-2213",
          "IRC -20431",
          "JP11 2930",
          "RAFGL 2071",
          "SCHB 299",
          "SV* HV 1152",
          "UBV M 22627",
          "V* VX Sgr",
          "[LFO93] 1805-22",
          "[PCC93] 218",
          "[TVH89] 250",
          "2MASS J18080404-2213266",
          "[WCP90] 180502.959-221355.58",
          "TYC 6276-721-1",
          "GSC 06276-00721",
          "UCAC2 22972679",
          "Gaia DR2 4069737050648231424"
        ],
        "searchKey": "vvxsgr|baadece3a00110|ubvm22627|hd165674|gsc0627600721|rmb2014g008340100|svm2001b32|hic88838|ucac222972679|cd2212589|tyc62767211|lfo93180522|rafgl2071|an2711904|gen100165674|oh00834401002|tic175117667|bd224575|aavso180222|2massj180804042213266|tvh89250|schb299|hip88838|gaiadr34069737050648231424|pcc93218|wcp9018050295922135558|gaiadr24069737050648231424|iras180502213|svhv1152|oh0083010|irc20431|jp112930",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.13445674418733,
        "dec": -22.22405580529,
        "fluxV": 6.52,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* VX Sgr",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603340587010",
        "name": "V* VV Cep",
        "names": [
          "** WRH 36",
          "uvby98 100208816",
          "WISE J215638.90+633731.1",
          "Gaia DR3 2216536246703152256",
          "GEN# +1.00208816",
          "TIC 412120154",
          "[B10] 5650",
          "PLX 5308",
          "SBC9 1340",
          "AAVSO 2153+63",
          "AG+63 1207",
          "BD+62 2007",
          "CCDM J21567+6338AB",
          "CSI+62 2007 1",
          "DO 40453",
          "FK5 3756",
          "GC 30731",
          "GCRV 13794",
          "GSC 04266-03252",
          "HD 208816",
          "HIC 108317",
          "HIP 108317",
          "HR 8383",
          "IDS 21538+6309 AB",
          "IRAS 21552+6323",
          "IRC +60333",
          "JP11 3418",
          "N30 4842",
          "PMC 90-93 1458",
          "PPM 23328",
          "RAFGL 2821",
          "SAO 19753",
          "SBC7 883",
          "SKY# 41813",
          "TYC 4266-3252-1",
          "UBV 18792",
          "UBV M 26033",
          "V* VV Cep",
          "[KW97] 60-38",
          "2MASS J21563917+6337319",
          "PLX 5308.00",
          "WDS J21567+6338AB",
          "WEB 19512",
          "Gaia DR2 2216536246703152256"
        ],
        "searchKey": "ccdmj215676338ab|pmc90931458|gaiadr32216536246703152256|sbc91340|vvvcep|web19512|tic412120154|do40453|wdsj215676338ab|rafgl2821|ubvm26033|csi6220071|gaiadr22216536246703152256|ids215386309ab|aavso215363|gsc0426603252|irc60333|sao19753|wisej215638906337311|plx5308|bd622007|sbc7883|hip108317|2massj215639176337319|tyc426632521|fk53756|b105650|hd208816|ag631207|kw976038|plx530800|iras215526323|ubv18792|sky41813|hr8383|hic108317|gc30731|uvby98100208816|n304842|wrh36|jp113418|gen100208816|gcrv13794|ppm23328",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.94420597119858,
        "dec": 63.62555045456666,
        "fluxV": 4.9,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* VV Cep",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603348287490",
        "name": "* 119 Tau",
        "names": [
          "HIP 25945",
          "TIC 367174762",
          "2MASS J05321276+1835394",
          "PLX 1253",
          "* 119 Tau",
          "AAVSO 0526+18",
          "AG+18 459",
          "BD+18 875",
          "DO 11336",
          "GC 6841",
          "GCRV 3354",
          "GEN# +1.00036389",
          "GSC 01301-00707",
          "HD 36389",
          "HIC 25945",
          "HR 1845",
          "IRAS 05292+1833",
          "IRC +20112",
          "JP11 1123",
          "PMC 90-93 5888",
          "PPM 121070",
          "RAFGL 767",
          "SAO 94628",
          "SKY# 8886",
          "TYC 1301-707-1",
          "UBV 21346",
          "V* CE Tau",
          "YZ 18 1563",
          "PLX 1253.00",
          "WEB 5046",
          "Gaia DR2 3400796031718128256"
        ],
        "searchKey": "gcrv3354|tyc13017071|irc20112|2massj053212761835394|ubv21346|ppm121070|tic367174762|rafgl767|plx125300|gc6841|aavso052618|yz181563|jp111123|hd36389|hip25945|gaiadr23400796031718128256|plx1253|vcetau|sao94628|gen100036389|119tau|do11336|hic25945|iras052921833|pmc90935888|gsc0130100707|web5046|hr1845|ag18459|bd18875|sky8886",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.536875696444445,
        "dec": 18.59423433027778,
        "fluxV": 4.33,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* 119 Tau",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603354841090",
        "name": "V* VY CMa",
        "names": [
          "HIP 35793",
          "OH 239.3-05.1",
          "[TCH91] 07209-2540",
          "** B 719",
          "WISE J072258.10-254603.5",
          "UCAC4 322-023976",
          "GEN# +1.00058061",
          "TIC 107149174",
          "GCRV 27408",
          "GCRV 27369",
          "AAVSO 0718-25",
          "ADS 6033 AB",
          "AN 60.1931",
          "CD-25 4441",
          "CPD-25 2286",
          "GCRV 4908",
          "GSC 06541-02525",
          "HD 58061",
          "HIC 35793",
          "IRAS 07209-2540",
          "IRC -30087",
          "PPM 252258",
          "RAFGL 1111",
          "SAO 173591",
          "SKY# 13432",
          "SRS 7681",
          "SV* P 468",
          "V* VY CMa",
          "YZ 115 4839",
          "[LFO93] 0720-25",
          "[PCC93] 116",
          "[TVH89] 21",
          "2MASS J07225830-2546030",
          "JCMTSE J072258.5-254602",
          "JCMTSF J072258.5-254602",
          "[WCP90] 072054.739-254012.27",
          "WDS J07230-2546AB",
          "WEB 7134"
        ],
        "searchKey": "tic107149174|yz1154839|hd58061|pcc93116|cd254441|wcp9007205473925401227|sky13432|gcrv4908|sao173591|cpd252286|gsc0654102525|web7134|an601931|srs7681|wdsj072302546ab|iras072092540|irc30087|wisej072258102546035|vvycma|rafgl1111|hic35793|ads6033ab|gcrv27369|aavso071825|jcmtsfj0722585254602|tch91072092540|gen100058061|tvh8921|ppm252258|oh2393051|gcrv27408|svp468|hip35793|2massj072258302546030|jcmtsej0722585254602|lfo93072025|ucac4322023976|b719",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.382868370894139,
        "dec": -25.76755399738722,
        "fluxV": 8.691,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* VY CMa",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603617607682",
        "name": "Aldebaran",
        "names": [
          "GJ 9159 A",
          "GJ 171.1 A",
          "GES J04355530+1630309",
          "HIP 21421",
          "GALAH 141102003801353",
          "AP J04355524+1630331",
          "TIC 245873777",
          "2MASS J04355524+1630331",
          "PLX 1014",
          "LSPM J0435+1630",
          "ASCC 838711",
          "USNO-B1.0 1065-00048822",
          "* alf Tau",
          "* 87 Tau",
          "ADS 3321 A",
          "AG+16 400",
          "BD+16 629",
          "CCDM J04359+1631A",
          "CEL 436",
          "CSI+16 629 1",
          "CSV 6116",
          "FK5 168",
          "GC 5605",
          "GCRV 2689",
          "GEN# +1.00029139",
          "HD 29139",
          "HIC 21421",
          "HR 1457",
          "IDS 04302+1619 A",
          "IRAS 04330+1624",
          "IRC +20087",
          "JP11 898",
          "LTT 11462",
          "N30 962",
          "NAME Aldebaran",
          "NLTT 13584",
          "PMC 90-93 119",
          "PPM 120061",
          "RAFGL 601",
          "ROT 655",
          "SAO 94027",
          "SKY# 7128",
          "SRS 30168",
          "TD1 3349",
          "UBV 4426",
          "UBV M 10223",
          "V* alf Tau",
          "YZ 16 1233",
          "[B10] 1077",
          "TYC 1266-1416-1",
          "GSC 01266-01416",
          "WDS J04359+1631A",
          "GAT 1331",
          "** BU 550A",
          "** BU 1031A",
          "** STF 4002A",
          "uvby98 100029139",
          "WEB 4111"
        ],
        "searchKey": "gcrv2689|ids043021619a|ltt11462|n30962|gesj043555301630309|87tau|wdsj043591631a|hip21421|fk5168|b101077|lspmj04351630|aldebaran|cel436|sao94027|gsc0126601416|irc20087|csv6116|hr1457|rafgl601|bu1031a|plx1014|csi166291|gj1711a|apj043555241630331|bd16629|iras043301624|ubvm10223|bu550a|pmc9093119|gc5605|tyc126614161|ag16400|galah141102003801353|2massj043555241630331|srs30168|uvby98100029139|gat1331|hic21421|td13349|ads3321a|ccdmj043591631a|nltt13584|gj9159a|stf4002a|sky7128|ppm120061|rot655|ascc838711|tic245873777|ubv4426|gen100029139|aldebaran|yz161233|hd29139|alftau|jp11898|web4111|valftau|usnob10106500048822",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 4.598677519444444,
        "dec": 16.50930236111111,
        "fluxV": 0.86,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Tau",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603617705986",
        "name": "Arcturus",
        "names": [
          "GJ 541",
          "HIP 69673",
          "GALAH 150210005801171",
          "AP J14153968+1910558",
          "TIC 459832522",
          "PLX 3242",
          "LSPM J1415+1910",
          "NLTT 36756",
          "TYC 1472-1436-1",
          "ASCC 870215",
          "USNO-B1.0 1091-00221874",
          "* alf Boo",
          "* 16 Boo",
          "AAVSO 1411+19",
          "AG+19 1335",
          "BD+19 2777",
          "CSV 101433",
          "Ci 20 843",
          "FK5 526",
          "GC 19242",
          "GCRV 8341",
          "GEN# +1.00124897",
          "HD 124897",
          "HIC 69673",
          "HR 5340",
          "IRAS 14133+1925",
          "IRC +20270",
          "JP11 2486",
          "LFT 1084",
          "LHS 48",
          "LTT 14184",
          "N30 3229",
          "NAME Arcturus",
          "NSV 6603",
          "PM 14134+1927",
          "PM 14134-1927",
          "PMC 90-93 376",
          "PPM 130442",
          "RAFGL 1693",
          "ROT 2044",
          "SAO 100944",
          "SKY# 26028",
          "SRS 30526",
          "SV* ZI 1054",
          "TD1 17351",
          "UBV 12551",
          "UBV M 20076",
          "USNO 850",
          "YPAC 52",
          "[HFE83] 1018",
          "uvby98 100124897",
          "2MASS J14153968+1910558",
          "PLX 3242.00",
          "GAT 1341",
          "** HDS 2003A",
          "WDS J14157+1911A",
          "CCDM J14157+1911A",
          "WEB 12132"
        ],
        "searchKey": "16boo|bd192777|lhs48|td117351|tic459832522|hip69673|hr5340|ypac52|uvby98100124897|gat1341|rafgl1693|usnob10109100221874|irc20270|2massj141539681910558|hfe831018|jp112486|galah150210005801171|gen100124897|srs30526|tyc147214361|ltt14184|plx3242|rot2044|svzi1054|n303229|arcturus|ccdmj141571911a|aavso141119|pm141341927|sky26028|gj541|ppm130442|nltt36756|iras141331925|sao100944|pm141341927|gc19242|pmc9093376|arcturus|ag191335|hds2003a|alfboo|hd124897|web12132|lft1084|apj141539681910558|ascc870215|csv101433|hic69673|plx324200|wdsj141571911a|ci20843|ubvm20076|nsv6603|fk5526|lspmj14151910|usno850|ubv12551|gcrv8341",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 14.26102001944444,
        "dec": 19.18240916666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Boo",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603618525186",
        "name": "Betelgeuse",
        "names": [
          "HIP 27989",
          "GEN# +1.00039801",
          "TIC 269273552",
          "2MASS J05551028+0724255",
          "PLX 1362",
          "* 58 Ori",
          "* alf Ori",
          "AAVSO 0549+07",
          "ADS 4506 AP",
          "AG+07 681",
          "BD+07 1055",
          "CCDM J05552+0724AP",
          "CSI+07 1055 1",
          "EIC 108",
          "FK5 224",
          "GC 7451",
          "GCRV 3679",
          "GSC 00129-01873",
          "HD 39801",
          "HIC 27989",
          "HR 2061",
          "IRAS 05524+0723",
          "IRC +10100",
          "JP11 1282",
          "N30 1266",
          "NAME Betelgeuse",
          "PMC 90-93 162",
          "PPM 149643",
          "RAFGL 836",
          "SAO 113271",
          "SKY# 9804",
          "TD1 5587",
          "TYC 129-1873-1",
          "UBV 21314",
          "V* alf Ori",
          "YZ 7 2503",
          "[LFO93] 0552+07",
          "JCMTSE J055510.1+072426",
          "PLX 1362.00",
          "JCMTSF J055510.1+072426",
          "WDS J05552+0724Aa,Ab",
          "** H 639A",
          "WDS J05552+0724Aa,Ac",
          "WDS J05552+0724A",
          "** KAR 1",
          "WEB 5485"
        ],
        "searchKey": "plx136200|sky9804|gc7451|alfori|csi0710551|irc10100|wdsj055520724aaac|hd39801|bd071055|sao113271|td15587|iras055240723|betelgeuse|hic27989|hip27989|pmc9093162|valfori|tyc12918731|ads4506ap|lfo93055207|wdsj055520724a|jp111282|kar1|ppm149643|web5485|rafgl836|n301266|yz72503|gcrv3679|wdsj055520724aaab|betelgeuse|jcmtsfj0555101072426|2massj055510280724255|hr2061|h639a|ccdmj055520724ap|gsc0012901873|tic269273552|58ori|jcmtsej0555101072426|plx1362|ubv21314|eic108|ag07681|aavso054907|gen100039801|fk5224",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.919529266666667,
        "dec": 7.407064,
        "fluxV": 0.42,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Ori",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959177472638977",
    "title": "Solar System",
    "color": "YELLOW",
    "credit": "https://en.wikipedia.org/wiki/Solar_System",
    "imgURL": "/list_icons/solar-system.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "944241942959718401",
        "name": "Sun",
        "names": [
          "NAME Sun",
          "NAME Sol",
          "NAME Helios"
        ],
        "searchKey": "helios|sun|sol|sun",
        "solarSystemKey": "sun",
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/The_Sun_in_white_light.jpg/450px-The_Sun_in_white_light.jpg",
        "ra": null,
        "dec": null,
        "fluxV": -26.76,
        "sizeMajor": 32.2,
        "sizeMinor": 32.2,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "Mat\u00fa\u0161 Motlo",
        "description": "The Sun is the star at the center of the Solar System. It is a massive, hot ball of plasma, inflated and heated by energy produced by nuclear fusion reactions at its core. Part of this energy is emitted from its surface as light, ultraviolet, and infrared radiation, providing most of the energy for life on Earth. The Sun has been an object of veneration in many cultures. It has been a central subject for astronomical research since ancient times.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Sun"
      },
      {
        "id": "944241943064412161",
        "name": "Mercury",
        "names": [
          "NAME Mercury",
          "NAME Stilb\u014dn"
        ],
        "searchKey": "mercury|mercury|stilb\u014dn",
        "solarSystemKey": "mercury",
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/Mercury_in_true_color.jpg/435px-Mercury_in_true_color.jpg",
        "ra": null,
        "dec": null,
        "fluxV": -1.28,
        "sizeMajor": 0.09033333,
        "sizeMinor": 0.09033333,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "NASA/Johns Hopkins University",
        "description": "Mercury is the first planet from the Sun and the smallest in the Solar System. In English, it is named after the Roman god Mercurius (Mercury), god of commerce and communication, and the messenger of the gods. Mercury is classified as a terrestrial planet, with roughly the same surface gravity as Mars. The surface of Mercury is heavily cratered, as a result of countless impact events that have accumulated over billions of years. Its largest crater, Caloris Planitia, has a diameter of 1,550 km (960 mi) and one-third the diameter of the planet (4,880 km or 3,030 mi). Similarly to the Earth's Moon, Mercury's surface displays an expansive rupes system generated from thrust faults and bright ray systems formed by impact event remnants.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Mercury_(planet)"
      },
      {
        "id": "944241943171366913",
        "name": "Venus",
        "names": [
          "NAME Venus",
          "NAME Ninsi'anna",
          "NAME Lucifer"
        ],
        "searchKey": "ninsianna|venus|venus|lucifer",
        "solarSystemKey": "venus",
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Venus_2_Approach_Image.jpg/435px-Venus_2_Approach_Image.jpg",
        "ra": null,
        "dec": null,
        "fluxV": -3.8,
        "sizeMajor": 0.18,
        "sizeMinor": 0.18,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "NASA/Johns Hopkins University",
        "description": "Venus is the second planet from the Sun. It is a rocky planet with the densest atmosphere of all the rocky bodies in the Solar System, and the closest in mass and size to its orbital neighbour Earth. Orbiting inferiorly (inside of Earth's orbit), it always appears close to the Sun in Earth's sky, as either a \"morning star\" or an \"evening star\". While this is also true for Mercury, Venus appears more prominent, since it is the third brightest object in Earth's sky after the Moon and the Sun. Venus has historically been a common and important object for humans, in both their cultures and astronomy.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Venus"
      },
      {
        "id": "944241943273340929",
        "name": "Mars",
        "names": [
          "NAME Mars",
          "NAME The Red Planet"
        ],
        "searchKey": "mars|theredplanet|mars",
        "solarSystemKey": "mars",
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Mars_-_August_30_2021_-_Flickr_-_Kevin_M._Gill.png/435px-Mars_-_August_30_2021_-_Flickr_-_Kevin_M._Gill.png",
        "ra": null,
        "dec": null,
        "fluxV": 1.24,
        "sizeMajor": 0.07183333,
        "sizeMinor": 0.07183333,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "Kevin Gill",
        "description": "Mars is the fourth planet from the Sun. The surface of Mars is orange-red because it is covered in iron(III) oxide dust, giving it the nickname \"the Red Planet\". Mars is among the brightest objects in Earth's sky and its high-contrast albedo features have made it a common subject for telescope viewing. It is classified as a terrestrial planet and is the second smallest of the Solar System's planets with a diameter of 6,779 km (4,212 mi). In terms of orbital motion, a Martian solar day (sol) is equal to 24.5 hours and a Martian solar year is equal to 1.88 Earth years (687 Earth days). Mars has two natural satellites that are small and irregular in shape: Phobos and Deimos.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Mars"
      },
      {
        "id": "944241943363649537",
        "name": "Jupiter",
        "names": [
          "NAME Jupiter"
        ],
        "searchKey": "jupiter|jupiter",
        "solarSystemKey": "jupiter barycenter",
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Jupiter_New_Horizons.jpg/435px-Jupiter_New_Horizons.jpg",
        "ra": null,
        "dec": null,
        "fluxV": -1.99,
        "sizeMajor": 0.5795,
        "sizeMinor": 0.5795,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "National Aeronautics and Space Administration",
        "description": "Jupiter is the fifth planet from the Sun and the largest in the Solar System. It is a gas giant with a mass more than two and a half times that of all the other planets in the Solar System combined, and slightly less than one one-thousandth the mass of the Sun. Jupiter orbits the Sun at a distance of 5.20 AU (778.5 Gm) with an orbital period of 11.86 years. Jupiter is the third brightest natural object in the Earth's night sky after the Moon and Venus, and it has been observed since prehistoric times. It was named after Jupiter, the chief deity of ancient Roman religion.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Jupiter"
      },
      {
        "id": "944241943455465473",
        "name": "Saturn",
        "names": [
          "NAME Saturn"
        ],
        "searchKey": "saturn|saturn",
        "solarSystemKey": "saturn barycenter",
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Saturn_Storm.jpg/330px-Saturn_Storm.jpg",
        "ra": null,
        "dec": null,
        "fluxV": 1.01,
        "sizeMajor": 0.25016667,
        "sizeMinor": 0.25016667,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "NASA / JPL / Space Science Institute",
        "description": "Saturn is the sixth planet from the Sun and the second-largest in the Solar System, after Jupiter. It is a gas giant with an average radius of about nine-and-a-half times that of Earth. It has only one-eighth the average density of Earth, but is over 95 times more massive. Even though Saturn is nearly the size of Jupiter, Saturn has less than one-third of Jupiter's mass. Saturn orbits the Sun at a distance of 9.59 AU (1,434 million km) with an orbital period of 29.45 years.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Saturn"
      },
      {
        "id": "944241943558094849",
        "name": "Uranus",
        "names": [
          "NAME Uranus",
          "NAME Georgium Sidus",
          "NAME Ouranos"
        ],
        "searchKey": "georgiumsidus|uranus|uranus|ouranos",
        "solarSystemKey": "uranus barycenter",
        "cometKey": null,
        "celestrakKey": null,
        "color": "CYAN",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Uranus_Voyager2_color_calibrated.png/435px-Uranus_Voyager2_color_calibrated.png",
        "ra": null,
        "dec": null,
        "fluxV": 5.79,
        "sizeMajor": 0.058,
        "sizeMinor": 0.058,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "Ardenau4",
        "description": "Uranus is the seventh planet from the Sun. It is a gaseous cyan-coloured ice giant. Most of the planet is made of water, ammonia, and methane in a supercritical phase of matter, which in astronomy is called 'ice' or volatiles. The planet's atmosphere has a complex layered cloud structure and has the lowest minimum temperature of 49 K (\u2212224 \u00b0C; \u2212371 \u00b0F) out of all the Solar System's planets. It has a marked axial tilt of 82.23\u00b0 with a retrograde rotation period of 17 hours and 14 minutes. This means that in an 84-Earth-year orbital period around the Sun, its poles get around 42 years of continuous sunlight, followed by 42 years of continuous darkness.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Uranus"
      },
      {
        "id": "944241943667408897",
        "name": "Neptune",
        "names": [
          "NAME Neptune",
          "NAME Le Verrier's planet",
          "NAME Janus"
        ],
        "searchKey": "janus|leverriersplanet|neptune|neptune",
        "solarSystemKey": "neptune barycenter",
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Neptune_Voyager2_color_calibrated.png/435px-Neptune_Voyager2_color_calibrated.png",
        "ra": null,
        "dec": null,
        "fluxV": 7.96,
        "sizeMajor": 0.0366667,
        "sizeMinor": 0.0366667,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "NASA / Voyager 2 / PDS / OPUS / Ardenau4",
        "description": "Neptune is the eighth and farthest known planet from the Sun. It is the fourth-largest planet in the Solar System by diameter, the third-most-massive planet, and the densest giant planet. It is 17 times the mass of Earth, and slightly more massive than fellow ice giant Uranus. Neptune is denser and physically smaller than Uranus because its greater mass causes more gravitational compression of its atmosphere. Being composed primarily of gases and liquids, it has no well-defined solid surface. The planet orbits the Sun once every 164.8 years at an orbital distance of 30.1 astronomical units (4.5 billion kilometres; 2.8 billion miles). It is named after the Roman god of the sea and has the astronomical symbol \u2646, representing Neptune's trident.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Neptune"
      },
      {
        "id": "944241943765680129",
        "name": "Pluto",
        "names": [
          "NAME Pluto",
          "NAME Minerva"
        ],
        "searchKey": "pluto|pluto|minerva",
        "solarSystemKey": "pluto barycenter",
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Pluto_in_True_Color_-_High-Res.jpg/435px-Pluto_in_True_Color_-_High-Res.jpg",
        "ra": null,
        "dec": null,
        "fluxV": 15.52,
        "sizeMajor": 0.0015,
        "sizeMinor": 0.0015,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "NASA/Johns Hopkins University",
        "description": "Pluto (minor-planet designation: 134340 Pluto) is a dwarf planet in the Kuiper belt, a ring of bodies beyond the orbit of Neptune. It is the ninth-largest and tenth-most-massive known object to directly orbit the Sun. It is the largest known trans-Neptunian object by volume, by a small margin, but is less massive than Eris. Like other Kuiper belt objects, Pluto is made primarily of ice and rock and is much smaller than the inner planets. Pluto has only one sixth the mass of Earth's moon, and one third its volume.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Pluto"
      },
      {
        "id": "944241943867162625",
        "name": "Moon",
        "names": [
          "NAME Moon",
          "NAME Luna"
        ],
        "searchKey": "moon|moon|luna",
        "solarSystemKey": "moon",
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/FullMoon2010.jpg/631px-FullMoon2010.jpg",
        "ra": null,
        "dec": null,
        "fluxV": -6.68,
        "sizeMajor": 33.78,
        "sizeMinor": 33.78,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "Gregory H. Revera",
        "description": "The Moon is Earth's only natural satellite. It orbits at an average distance of 384,400 km (238,900 mi), about 30 times Earth's diameter. The Moon always presents the same side to Earth, because gravitational pull has locked its rotation to the planet. This results in the lunar day of 29.5 Earth days matching the lunar month. The Moon's gravitational pull \u2013 and to a lesser extent the Sun's \u2013 are the main drivers of the tides.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Moon"
      }
    ]
  },
  {
    "id": "978959195086979073",
    "title": "Space Stations",
    "color": "SLATE",
    "credit": "https://celestrak.org/NORAD/elements/table.php?GROUP=stations&FORMAT=tle",
    "imgURL": "/list_icons/stations.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "954179580633350145",
        "name": "ISS (ZARYA)",
        "names": [
          "SATNUM 25544",
          "ISS (ZARYA)"
        ],
        "searchKey": "satnum25544|isszarya",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": "ISS (ZARYA)",
        "color": "SKY",
        "type": "EARTH_SATELLITE",
        "imgURL": null,
        "ra": null,
        "dec": null,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954179876414390273",
        "name": "CSS (TIANHE)",
        "names": [
          "SATNUM 48274",
          "CSS (TIANHE)"
        ],
        "searchKey": "csstianhe|satnum48274",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": "CSS (TIANHE)",
        "color": "TEAL",
        "type": "EARTH_SATELLITE",
        "imgURL": null,
        "ra": null,
        "dec": null,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954180355792764929",
        "name": "SOYUZ-MS 24",
        "names": [
          "SATNUM 57862",
          "SOYUZ-MS 24"
        ],
        "searchKey": "soyuzms24|satnum57862",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": "SOYUZ-MS 24",
        "color": "YELLOW",
        "type": "EARTH_SATELLITE",
        "imgURL": null,
        "ra": null,
        "dec": null,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954180380961505281",
        "name": "SHENZHOU-17 (SZ-17)",
        "names": [
          "SATNUM 58146",
          "SHENZHOU-17 (SZ-17)"
        ],
        "searchKey": "shenzhou17sz17|satnum58146",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": "SHENZHOU-17 (SZ-17)",
        "color": "AMBER",
        "type": "EARTH_SATELLITE",
        "imgURL": null,
        "ra": null,
        "dec": null,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954180435784335361",
        "name": "TIANZHOU-7",
        "names": [
          "SATNUM 58811",
          "TIANZHOU-7"
        ],
        "searchKey": "tianzhou7|satnum58811",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": "TIANZHOU-7",
        "color": "ZINC",
        "type": "EARTH_SATELLITE",
        "imgURL": null,
        "ra": null,
        "dec": null,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959187269484545",
    "title": "Star Clusters",
    "color": "BLUE",
    "credit": "https://skyandtelescope.org/astronomy-blogs/imaging-foundations-richard-wright/star-clusters-the-low-hanging-fruit-of-astrophotography/",
    "imgURL": "/list_icons/clusters.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "944241965995786241",
        "name": "Pleiades",
        "names": [
          "[KC2019] Theia 369",
          "C 0344+239",
          "H 0346+24",
          "M 45",
          "NAME Pleiades",
          "NAME Seven Sisters",
          "OCl 421.0",
          "[KPR2004b] 47",
          "Cl Melotte 22",
          "[KPS2012] MWSC 0305"
        ],
        "searchKey": "pleiades|clmelotte22|kpr2004b47|c0344239|m45|pleiades|kc2019theia369|ocl4210|h034624|sevensisters|kps2012mwsc0305",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 3.773388888888889,
        "dec": 24.11388888888889,
        "fluxV": null,
        "sizeMajor": 76.86,
        "sizeMinor": 76.86,
        "sizeAngle": null,
        "simbadName": "Cl Melotte 22",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242455051763713",
        "name": "M 2",
        "names": [
          "BD-01 4175",
          "C 2130-010",
          "GCRV 13546",
          "GCl 121",
          "HD 205146",
          "M 2",
          "NGC 7089",
          "[KPS2012] MWSC 3526"
        ],
        "searchKey": "c2130010|ngc7089|gcrv13546|gcl121|hd205146|bd014175|m2|kps2012mwsc3526",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.55750555555556,
        "dec": -0.82325,
        "fluxV": 6.25,
        "sizeMajor": 12.9,
        "sizeMinor": 12.9,
        "sizeAngle": 90.0,
        "simbadName": "M 2",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242457935380481",
        "name": "M 3",
        "names": [
          "UBV M 19645",
          "HD 119333",
          "GEN# +1.00119333",
          "GCRV 8101",
          "BD+29 2450",
          "C 1339+286",
          "GCl 25",
          "M 3",
          "NGC 5272",
          "[ZEH2003] RX J1342.1+2822 1",
          "[KPS2012] MWSC 2152"
        ],
        "searchKey": "c1339286|gcl25|gen100119333|m3|bd292450|gcrv8101|kps2012mwsc2152|ngc5272|ubvm19645|hd119333|zeh2003rxj1342128221",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 13.70322777777778,
        "dec": 28.37727777777778,
        "fluxV": 6.39,
        "sizeMajor": 16.2,
        "sizeMinor": 16.2,
        "sizeAngle": 90.0,
        "simbadName": "M 3",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242461896704001",
        "name": "M 4",
        "names": [
          "C 1620-264",
          "GCl 41",
          "M 4",
          "NGC 6121",
          "[KPS2012] MWSC 2396",
          "CD-26 11314",
          "GCRV 5569 E",
          "HD 147552"
        ],
        "searchKey": "ngc6121|gcl41|kps2012mwsc2396|hd147552|cd2611314|m4|c1620264|gcrv5569e",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.39311666666667,
        "dec": -26.52575,
        "fluxV": null,
        "sizeMajor": 26.3,
        "sizeMinor": 26.3,
        "sizeAngle": 90.0,
        "simbadName": "M 4",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242466055880705",
        "name": "M 5",
        "names": [
          "C 1516+022",
          "GCl 34",
          "M 5",
          "NGC 5904",
          "[KPS2012] MWSC 2286",
          "GCRV 5244 E"
        ],
        "searchKey": "ngc5904|kps2012mwsc2286|m5|gcl34|c1516022|gcrv5244e",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 15.30922777777778,
        "dec": 2.081027777777778,
        "fluxV": 5.95,
        "sizeMajor": 17.4,
        "sizeMinor": 17.4,
        "sizeAngle": 90.0,
        "simbadName": "M 5",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242480729686017",
        "name": "M 10",
        "names": [
          "C 1654-040",
          "GCl 49",
          "M 10",
          "NGC 6254",
          "[KPS2012] MWSC 2498",
          "GCRV 68711"
        ],
        "searchKey": "kps2012mwsc2498|gcl49|ngc6254|c1654040|m10|gcrv68711",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.95251388888889,
        "dec": -4.100305555555555,
        "fluxV": 4.98,
        "sizeMajor": 15.1,
        "sizeMinor": 15.1,
        "sizeAngle": 90.0,
        "simbadName": "M 10",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242483264749569",
        "name": "M 11",
        "names": [
          "[LP2019] 155",
          "NAME Amas de l'Ecu de Sobieski",
          "C 1848-063",
          "M 11",
          "NGC 6705",
          "OCl 76.0",
          "[KPR2004b] 453",
          "Cl Collinder 391",
          "Cl Melotte 213",
          "[KPS2012] MWSC 3008",
          "NAME Wild Duck Cluster"
        ],
        "searchKey": "c1848063|clmelotte213|lp2019155|wildduckcluster|kps2012mwsc3008|m11|ngc6705|ocl760|kpr2004b453|clcollinder391|amasdelecudesobieski",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.85105555555556,
        "dec": -6.271944444444444,
        "fluxV": 5.8,
        "sizeMajor": 8.9,
        "sizeMinor": 8.9,
        "sizeAngle": null,
        "simbadName": "NGC 6705",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242486539059201",
        "name": "M 12",
        "names": [
          "C 1644-018",
          "GCl 46",
          "M 12",
          "NGC 6218",
          "[KPS2012] MWSC 2464"
        ],
        "searchKey": "kps2012mwsc2464|m12|gcl46|c1644018|ngc6218",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.78727222222222,
        "dec": -1.948527777777778,
        "fluxV": 6.07,
        "sizeMajor": 14.5,
        "sizeMinor": 14.5,
        "sizeAngle": 90.0,
        "simbadName": "M 12",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242490594721793",
        "name": "M 13",
        "names": [
          "C 1639+365",
          "GCl 45",
          "M 13",
          "NGC 6205",
          "2MASX J16414163+3627407",
          "[KPS2012] MWSC 2445",
          "NAME Hercules Globular Cluster"
        ],
        "searchKey": "c1639365|gcl45|herculesglobularcluster|2masxj164141633627407|kps2012mwsc2445|m13|ngc6205",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.69489833333333,
        "dec": 36.46131944444445,
        "fluxV": 5.8,
        "sizeMajor": 33.0,
        "sizeMinor": 33.0,
        "sizeAngle": null,
        "simbadName": "M 13",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242499278143489",
        "name": "M 15",
        "names": [
          "C 2127+119",
          "GCl 120",
          "M 15",
          "NGC 7078",
          "[KPS2012] MWSC 3518"
        ],
        "searchKey": "gcl120|m15|kps2012mwsc3518|ngc7078|c2127119",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.49953611111111,
        "dec": 12.167,
        "fluxV": null,
        "sizeMajor": 12.3,
        "sizeMinor": 12.3,
        "sizeAngle": 90.0,
        "simbadName": "M 15",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242524582510593",
        "name": "M 24",
        "names": [
          "IC 4715",
          "M 24",
          "NAME Small Sgr Star Cloud"
        ],
        "searchKey": "m24|ic4715|smallsgrstarcloud",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.28,
        "dec": -18.55,
        "fluxV": null,
        "sizeMajor": 120.0,
        "sizeMinor": 60.0,
        "sizeAngle": 90.0,
        "simbadName": "M 24",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242531145875457",
        "name": "M 26",
        "names": [
          "C 1842-094",
          "M 26",
          "NGC 6694",
          "OCISM 13",
          "OCl 67",
          "OCl 67.0",
          "[KPR2004b] 451",
          "[KPS2012] MWSC 2987"
        ],
        "searchKey": "kpr2004b451|kps2012mwsc2987|ocism13|ocl67|ngc6694|c1842094|m26|ocl670",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.75447222222222,
        "dec": -9.386111111111111,
        "fluxV": 8.87,
        "sizeMajor": 7.1,
        "sizeMinor": 7.1,
        "sizeAngle": null,
        "simbadName": "M 26",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242540370001921",
        "name": "M 29",
        "names": [
          "C 2022+383",
          "M 29",
          "NGC 6913",
          "OCISM 36",
          "OCl 168",
          "[KPR2004b] 488",
          "[KPS2012] MWSC 3329"
        ],
        "searchKey": "ocl168|kpr2004b488|kps2012mwsc3329|m29|ocism36|ngc6913|c2022383",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.39619444444444,
        "dec": 38.48694444444445,
        "fluxV": 6.6,
        "sizeMajor": 12.7,
        "sizeMinor": 12.7,
        "sizeAngle": null,
        "simbadName": "M 29",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242543749169153",
        "name": "M 30",
        "names": [
          "C 2137-234",
          "GCl 122",
          "M 30",
          "NGC 7099",
          "[KPS2012] MWSC 3543",
          "CD-23 17047",
          "CPD-23 8016",
          "GCRV 13607",
          "HD 206107"
        ],
        "searchKey": "m30|gcrv13607|ngc7099|c2137234|cpd238016|gcl122|hd206107|cd2317047|kps2012mwsc3543",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.67281111111111,
        "dec": -23.17986111111111,
        "fluxV": 7.1,
        "sizeMajor": 11.0,
        "sizeMinor": 11.0,
        "sizeAngle": 90.0,
        "simbadName": "M 30",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242559314984961",
        "name": "M 34",
        "names": [
          "[KC2019] Theia 251",
          "C 0238+425",
          "M 34",
          "NGC 1039",
          "OCl 382",
          "[KPR2004b] 36",
          "[KPS2012] MWSC 0223"
        ],
        "searchKey": "c0238425|ocl382|kpr2004b36|m34|kc2019theia251|ngc1039|kps2012mwsc0223",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.702055555555556,
        "dec": 42.72194444444445,
        "fluxV": null,
        "sizeMajor": 48.4,
        "sizeMinor": 48.4,
        "sizeAngle": null,
        "simbadName": "NGC 1039",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242562766766081",
        "name": "M 35",
        "names": [
          "[KC2019] Theia 203",
          "C 0605+243",
          "M 35",
          "NGC 2168",
          "OCl 466.0",
          "[KPR2004b] 87",
          "[KPS2012] MWSC 0754"
        ],
        "searchKey": "kps2012mwsc0754|kc2019theia203|ocl4660|c0605243|m35|kpr2004b87|ngc2168",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 6.151472222222223,
        "dec": 24.33611111111111,
        "fluxV": null,
        "sizeMajor": 38.3,
        "sizeMinor": 38.3,
        "sizeAngle": null,
        "simbadName": "NGC 2168",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242565123145729",
        "name": "M 36",
        "names": [
          "C 0532+341",
          "M 36",
          "NGC 1960",
          "OCISM 103",
          "OCl 445",
          "[KPR2004b] 78",
          "[KPS2012] MWSC 0594"
        ],
        "searchKey": "ocism103|m36|kpr2004b78|ngc1960|c0532341|ocl445|kps2012mwsc0594",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "CYAN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.605611111111111,
        "dec": 34.135,
        "fluxV": 6.0,
        "sizeMajor": 120.0,
        "sizeMinor": 120.0,
        "sizeAngle": null,
        "simbadName": "M 36",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242567538769921",
        "name": "M 37",
        "names": [
          "C 0549+325",
          "M 37",
          "NGC 2099",
          "[KPR2004b] 82",
          "[KPS2012] MWSC 0689"
        ],
        "searchKey": "ngc2099|kpr2004b82|kps2012mwsc0689|m37|c0549325",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.871611111111112,
        "dec": 32.54499999999999,
        "fluxV": 5.6,
        "sizeMajor": 19.3,
        "sizeMinor": 19.3,
        "sizeAngle": null,
        "simbadName": "M 37",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242570772807681",
        "name": "M 38",
        "names": [
          "[KC2019] Theia 874",
          "C 0525+358",
          "M 38",
          "NGC 1912",
          "OCl 433.0",
          "[KPR2004b] 71",
          "[KPS2012] MWSC 0557"
        ],
        "searchKey": "kc2019theia874|ocl4330|kpr2004b71|c0525358|kps2012mwsc0557|m38|ngc1912",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.477805555555555,
        "dec": 35.8238888888889,
        "fluxV": 6.4,
        "sizeMajor": 19.6,
        "sizeMinor": 19.6,
        "sizeAngle": null,
        "simbadName": "M 38",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242573168377857",
        "name": "M 39",
        "names": [
          "[KC2019] Theia 517",
          "C 2130+482",
          "M 39",
          "NGC 7092",
          "OCl 211.0",
          "[KPR2004b] 499",
          "[KPS2012] MWSC 3521"
        ],
        "searchKey": "ocl2110|kpr2004b499|kps2012mwsc3521|ngc7092|c2130482|m39|kc2019theia517",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.52594444444444,
        "dec": 48.24694444444444,
        "fluxV": null,
        "sizeMajor": 120.4,
        "sizeMinor": 120.4,
        "sizeAngle": null,
        "simbadName": "NGC 7092",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242589320609793",
        "name": "M 44",
        "names": [
          "[KC2019] Theia 1184",
          "C 0837+201",
          "M 44",
          "NAME Beehive",
          "NAME Praesepe",
          "NGC 2632",
          "OCl 507.0",
          "[KPR2004b] 201",
          "Cl Melotte 88",
          "[KPS2012] MWSC 1527",
          "NAME Praesepe Cluster"
        ],
        "searchKey": "c0837201|ngc2632|kps2012mwsc1527|beehive|praesepecluster|m44|kc2019theia1184|ocl5070|praesepe|kpr2004b201|clmelotte88",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 8.670277777777777,
        "dec": 19.62111111111111,
        "fluxV": null,
        "sizeMajor": 118.2,
        "sizeMinor": 118.2,
        "sizeAngle": null,
        "simbadName": "NGC 2632",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242596176494593",
        "name": "M 46",
        "names": [
          "C 0739-147",
          "M 46",
          "NGC 2437",
          "OCl 601.0",
          "[KPR2004b] 160",
          "[KPS2012] MWSC 1313"
        ],
        "searchKey": "ocl6010|c0739147|ngc2437|kps2012mwsc1313|kpr2004b160|m46",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.696333333333333,
        "dec": -14.84388888888889,
        "fluxV": null,
        "sizeMajor": 25.3,
        "sizeMinor": 25.3,
        "sizeAngle": null,
        "simbadName": "NGC 2437",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242598545260545",
        "name": "M 47",
        "names": [
          "[KC2019] Theia 320",
          "C 0734-143",
          "M 47",
          "NGC 2422",
          "OCl 596.0",
          "[KPR2004b] 147",
          "[KPS2012] MWSC 1278"
        ],
        "searchKey": "m47|kpr2004b147|ngc2422|ocl5960|kps2012mwsc1278|kc2019theia320|c0734143",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.609805555555555,
        "dec": -14.48888888888889,
        "fluxV": null,
        "sizeMajor": 31.1,
        "sizeMinor": 31.1,
        "sizeAngle": null,
        "simbadName": "NGC 2422",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242600907898881",
        "name": "M 48",
        "names": [
          "[KC2019] Theia 870",
          "C 0811-056",
          "M 48",
          "NGC 2548",
          "[KPR2004b] 189",
          "[KPS2012] MWSC 1454"
        ],
        "searchKey": "kc2019theia870|ngc2548|kpr2004b189|kps2012mwsc1454|c0811056|m48",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 8.227472222222222,
        "dec": -5.726111111111111,
        "fluxV": null,
        "sizeMajor": 44.3,
        "sizeMinor": 44.3,
        "sizeAngle": null,
        "simbadName": "NGC 2548",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242606601240577",
        "name": "M 50",
        "names": [
          "[KC2019] Theia 294",
          "C 0700-082",
          "M 50",
          "NGC 2323",
          "OCISM 118",
          "OCl 559",
          "OCl 559.0",
          "[KPR2004b] 118",
          "[KPS2012] MWSC 1072"
        ],
        "searchKey": "kc2019theia294|ngc2323|ocl559|kpr2004b118|ocism118|ocl5590|kps2012mwsc1072|c0700082|m50",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.045611111111111,
        "dec": -8.365,
        "fluxV": null,
        "sizeMajor": 31.6,
        "sizeMinor": 31.6,
        "sizeAngle": null,
        "simbadName": "NGC 2323",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242617133170689",
        "name": "M 53",
        "names": [
          "HD 114820",
          "GCRV 7859",
          "BD+18 2701",
          "C 1310+184",
          "GCl 22",
          "M 53",
          "NGC 5024",
          "[KPS2012] MWSC 2094"
        ],
        "searchKey": "m53|bd182701|gcl22|kps2012mwsc2094|gcrv7859|c1310184|ngc5024|hd114820",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 13.21534722222222,
        "dec": 18.16816666666667,
        "fluxV": 7.79,
        "sizeMajor": 12.6,
        "sizeMinor": 12.6,
        "sizeAngle": 90.0,
        "simbadName": "M 53",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242626420834305",
        "name": "M 56",
        "names": [
          "C 1914+300",
          "CSI+30-19146 3",
          "GCRV 11736",
          "GCl 110",
          "M 56",
          "NGC 6779",
          "[KPS2012] MWSC 3077"
        ],
        "searchKey": "ngc6779|c1914300|csi30191463|kps2012mwsc3077|m56|gcl110|gcrv11736",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ROSE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.27654722222222,
        "dec": 30.18347222222222,
        "fluxV": null,
        "sizeMajor": 7.1,
        "sizeMinor": 7.1,
        "sizeAngle": 90.0,
        "simbadName": "M 56",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242664295301121",
        "name": "M 67",
        "names": [
          "C 0847+120",
          "M 67",
          "NGC 2682",
          "OCl 549.0",
          "[KPR2004b] 212",
          "HR 3515",
          "[KPS2012] MWSC 1585"
        ],
        "searchKey": "kpr2004b212|hr3515|ocl5490|m67|ngc2682|kps2012mwsc1585|c0847120",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 8.856388888888889,
        "dec": 11.81388888888889,
        "fluxV": null,
        "sizeMajor": 25.0,
        "sizeMinor": 25.0,
        "sizeAngle": null,
        "simbadName": "NGC 2682",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242675263537153",
        "name": "M 71",
        "names": [
          "C 1951+186",
          "CSI+18-19515",
          "GCRV 12241",
          "GCl 115",
          "M 71",
          "NGC 6838",
          "[KPS2012] MWSC 3200"
        ],
        "searchKey": "gcl115|gcrv12241|m71|kps2012mwsc3200|ngc6838|csi1819515|c1951186",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.89624722222222,
        "dec": 18.77919444444444,
        "fluxV": null,
        "sizeMajor": 7.2,
        "sizeMinor": 7.2,
        "sizeAngle": 90.0,
        "simbadName": "M 71",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242677669888001",
        "name": "M 72",
        "names": [
          "C 2050-127",
          "GCl 118",
          "M 72",
          "NGC 6981",
          "[KPS2012] MWSC 3419"
        ],
        "searchKey": "c2050127|kps2012mwsc3419|gcl118|m72|ngc6981",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.89102777777778,
        "dec": -12.53730555555556,
        "fluxV": 8.96,
        "sizeMajor": 5.9,
        "sizeMinor": 5.9,
        "sizeAngle": 90.0,
        "simbadName": "M 72",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242680923619329",
        "name": "M 73",
        "names": [
          "C 2056-128",
          "M 73",
          "NGC 6994",
          "OCl 89",
          "Cl Collinder 426"
        ],
        "searchKey": "c2056128|ngc6994|clcollinder426|ocl89|m73",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.98333333333333,
        "dec": -12.63333333333333,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 6994",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242687545737217",
        "name": "M 75",
        "names": [
          "C 2003-220",
          "GCl 116",
          "M 75",
          "NGC 6864",
          "2MASX J20060484-2155201",
          "[KPS2012] MWSC 3254",
          "BD-22 5331",
          "CD-22 14479",
          "CPD-22 7639",
          "GCRV 12454",
          "HD 190526"
        ],
        "searchKey": "2masxj200604842155201|cpd227639|hd190526|bd225331|gcl116|m75|cd2214479|gcrv12454|kps2012mwsc3254|c2003220|ngc6864",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.10134472222222,
        "dec": -21.92226111111111,
        "fluxV": 8.26,
        "sizeMajor": 2.383,
        "sizeMinor": 2.145,
        "sizeAngle": 55.0,
        "simbadName": "M 75",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242747054915585",
        "name": "M 92",
        "names": [
          "TD1 20249",
          "SAO 46606",
          "PPM 56062",
          "HD 156711",
          "GCRV 9983",
          "BD+43 2711",
          "AG+43 1399",
          "C 1715+432",
          "GCl 59",
          "M 92",
          "NGC 6341",
          "[KPS2012] MWSC 2557"
        ],
        "searchKey": "sao46606|ngc6341|gcl59|c1715432|bd432711|m92|hd156711|kps2012mwsc2557|ppm56062|gcrv9983|ag431399|td120249",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.28538611111111,
        "dec": 43.13594444444444,
        "fluxV": 6.52,
        "sizeMajor": 11.2,
        "sizeMinor": 11.2,
        "sizeAngle": 90.0,
        "simbadName": "M 92",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242749471752193",
        "name": "M 93",
        "names": [
          "[KC2019] Theia 277",
          "C 0742-237",
          "M 93",
          "NGC 2447",
          "OCl 649.0",
          "[KPR2004b] 163",
          "Cl Collinder 160",
          "[KPS2012] MWSC 1324"
        ],
        "searchKey": "kps2012mwsc1324|m93|kpr2004b163|c0742237|clcollinder160|kc2019theia277|ngc2447|ocl6490",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.742722222222223,
        "dec": -23.85305555555556,
        "fluxV": null,
        "sizeMajor": 24.2,
        "sizeMinor": 24.2,
        "sizeAngle": null,
        "simbadName": "NGC 2447",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242780638871553",
        "name": "M 103",
        "names": [
          "C 0129+604",
          "M 103",
          "NGC 581",
          "OCISM 75",
          "OCl 326",
          "[KPR2004b] 18",
          "[KPS2012] MWSC 0124"
        ],
        "searchKey": "c0129604|ngc581|m103|kps2012mwsc0124|ocism75|ocl326|kpr2004b18",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 1.555944444444445,
        "dec": 60.65888888888889,
        "fluxV": 7.4,
        "sizeMajor": 7.4,
        "sizeMinor": 7.4,
        "sizeAngle": null,
        "simbadName": "M 103",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242796507332609",
        "name": "M 107",
        "names": [
          "C 1629-129",
          "GCl 44",
          "M 107",
          "NGC 6171",
          "[KPS2012] MWSC 2422"
        ],
        "searchKey": "c1629129|m107|kps2012mwsc2422|gcl44|ngc6171",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ROSE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.54218333333333,
        "dec": -13.05377777777778,
        "fluxV": null,
        "sizeMajor": 10.0,
        "sizeMinor": 10.0,
        "sizeAngle": 90.0,
        "simbadName": "M 107",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "947766584711380993",
        "name": "h Per Cluster",
        "names": [
          "C 0215+569",
          "NGC 869",
          "OCISM 84",
          "OCl 350.0",
          "OCl 350",
          "Raab 9",
          "[KPR2004b] 29",
          "NAME h Per Cluster",
          "Cl Collinder 24",
          "Cl Melotte 13",
          "NAME h Persei Cluster",
          "[KPS2012] MWSC 0175"
        ],
        "searchKey": "ngc869|ocism84|raab9|hpercluster|c0215569|clmelotte13|ocl350|hperseicluster|kps2012mwsc0175|kpr2004b29|hpercluster|clcollinder24|ocl3500",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.316055555555555,
        "dec": 57.13388888888889,
        "fluxV": null,
        "sizeMajor": 16.3,
        "sizeMinor": 16.3,
        "sizeAngle": null,
        "simbadName": "NGC 869",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950076963008643074",
        "name": "Hyades Cluster",
        "names": [
          "[KC2019] Theia 1004",
          "C 0424+157",
          "OCl 456.0",
          "Cl Melotte 25",
          "NAME Hyades Cluster",
          "NAME Hyades",
          "Cl Collinder 50"
        ],
        "searchKey": "hyades|kc2019theia1004|clcollinder50|hyadescluster|clmelotte25|ocl4560|hyadescluster|c0424157",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 4.496472222222223,
        "dec": 16.94805555555556,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "Cl Melotte 25",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950076966857342978",
        "name": "chi Per Cluster",
        "names": [
          "C 0218+568",
          "NGC 884",
          "OCISM 86",
          "OCl 353.0",
          "OCl 353",
          "Raab 10",
          "[KPR2004b] 30",
          "Cl Collinder 25",
          "Cl Melotte 14",
          "NAME chi Per Cluster",
          "NAME chi Persei Cluster",
          "NAME khi Per Cluster",
          "[KPS2012] MWSC 0184"
        ],
        "searchKey": "raab10|c0218568|kps2012mwsc0184|chipercluster|ocl353|khipercluster|ocl3530|ocism86|kpr2004b30|clcollinder25|clmelotte14|chipercluster|ngc884|chiperseicluster",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.372277777777778,
        "dec": 57.14888888888889,
        "fluxV": null,
        "sizeMajor": 15.0,
        "sizeMinor": 15.0,
        "sizeAngle": null,
        "simbadName": "NGC 884",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "954160330591830017",
        "name": "ET Cluster",
        "names": [
          "[KC2019] Theia 772",
          "C 0115+580",
          "NGC 457",
          "OCISM 73",
          "OCl 321",
          "OCl 321.0",
          "[KPR2004b] 17",
          "[KPS2012] MWSC 0114",
          "NAME ET Cluster",
          "NAME Owl Cluster",
          "[FSR2007] 0540"
        ],
        "searchKey": "etcluster|etcluster|ngc457|ocl321|kps2012mwsc0114|c0115580|ocl3210|kpr2004b17|fsr20070540|owlcluster|ocism73|kc2019theia772",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 1.325805555555555,
        "dec": 58.27805555555555,
        "fluxV": 6.4,
        "sizeMajor": 15.0,
        "sizeMinor": 15.0,
        "sizeAngle": null,
        "simbadName": "NGC 457",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959191219601409",
    "title": "Supernova Remnants",
    "color": "GREEN",
    "credit": "https://www.go-astronomy.com/supernova-remnants.php",
    "imgURL": "/list_icons/snrs.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "944241962644766721",
        "name": "Crab Nebula",
        "names": [
          "3HWC J0534+220",
          "2HWC J0534+220",
          "eHWC J0534+220",
          "1RXS J053431.2+220218",
          "2AGL J0534+2205",
          "SNR G184.6-05.8",
          "1H 0531+219",
          "1M 0531+219",
          "2C 481",
          "2E 1309",
          "2U 0531+22",
          "3A 0531+219",
          "3C 144",
          "3C 144.0",
          "3CR 144",
          "3U 0531+21",
          "4C 21.19",
          "4U 0531+21",
          "AJG 1",
          "CTA 36",
          "CTB 18",
          "Cul 0531+21",
          "Cul 0531+219",
          "DA 179",
          "DB 38",
          "H 0534+21",
          "H 0531+219",
          "IRAS 05314+2200",
          "LBN 833",
          "LBN 184.62-05.65",
          "M 1",
          "Mills 05+2A",
          "NAME CRAB NEB",
          "NAME Crab",
          "NAME Crab Nebula",
          "NAME Tau A",
          "NAME Taurus A",
          "NGC 1952",
          "NRAO 214",
          "NRL 2",
          "PKS 0531+219",
          "SH 2-244",
          "SIM 0531+21.0",
          "VRO 21.05.01",
          "X Tau X-1",
          "X Tau XR-1",
          "[BM83] X0531+219",
          "[DGW65] 25",
          "[PT56] 5",
          "1ES 0532+21.5",
          "2E 0531.5+2159",
          "PBC J0534.5+2201",
          "SWIFT J0534.6+2204",
          "SWIFT J0534.5+2200",
          "GRS G184.60 -05.80",
          "W 9",
          "NVSS J053428+220202",
          "ARGO J0535+2203",
          "3FGL J0534.5+2201i",
          "TeV J0534+220",
          "3FHL J0534.5+2201",
          "4FGL J0534.5+2201i"
        ],
        "searchKey": "3u053121|tevj0534220|3cr144|xtaux1|2c481|m1|taua|1m0531219|da179|ngc1952|2u053122|lbn184620565|crabneb|2e053152159|3a0531219|4fglj053452201i|1es0532215|grsg184600580|crabnebula|crabnebula|3hwcj0534220|pt565|ctb18|sh2244|xtauxr1|taurusa|nrao214|ajg1|dgw6525|2e1309|db38|nvssj053428220202|2aglj05342205|4u053121|h0531219|iras053142200|cta36|4c2119|lbn833|snrg1846058|mills052a|cul053121|nrl2|h053421|sim0531210|3fhlj053452201|3c1440|pbcj053452201|ehwcj0534220|1h0531219|swiftj053462204|w9|bm83x0531219|1rxsj0534312220218|crab|argoj05352203|pks0531219|3fglj053452201i|3c144|cul0531219|2hwcj0534220|vro210501|swiftj053452200",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.57525,
        "dec": 22.01472222222222,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 1",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241973560868865",
        "name": "Veil Nebula",
        "names": [
          "LBN 191",
          "LBN 074.53-08.42",
          "NAME Cirrus Nebula",
          "NAME Filamentary Nebula",
          "NGC 6960",
          "NAME Veil Nebula"
        ],
        "searchKey": "veilnebula|veilnebula|cirrusnebula|lbn191|filamentarynebula|lbn074530842|ngc6960",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.76055555555556,
        "dec": 30.70833333333333,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 6960",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241993160130561",
        "name": "Tarantula Nebula",
        "names": [
          "DEM L 263",
          "EQ 0539-691",
          "LHA 120-N 157A",
          "MDM 62",
          "MDM 72",
          "MDM 77",
          "NAME 30 Dor Nebula",
          "NAME 30 Doradus Nebula",
          "[MBB72] 74",
          "MCELS L-328",
          "NAME Tarantula Nebula"
        ],
        "searchKey": "lha120n157a|tarantulanebula|deml263|tarantulanebula|30dornebula|30doradusnebula|mdm72|mdm77|mcelsl328|mdm62|eq0539691|mbb7274",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "INDIGO",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.643333333333333,
        "dec": -69.08638888888889,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME 30 Dor Nebula",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "948664008210415617",
        "name": "1FGL J0538.6+2717",
        "names": [
          "SNR G180.0-01.7",
          "SNR G180.8-02.2",
          "4FGL J0540.3+2756e",
          "SIM 147",
          "SNR G180.1-01.7",
          "GRS G180.00 -01.70",
          "1FGL J0538.6+2717",
          "2FGL J0538.1+2718",
          "3FGL J0540.3+2756e",
          "3FHL J0537.6+2751e",
          "SNR G180.3-01.7"
        ],
        "searchKey": "sim147|2fglj053812718|3fglj054032756e|snrg1808022|grsg180000170|4fglj054032756e|snrg1803017|1fglj053862717|snrg1800017|snrg1801017|3fhlj053762751e",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.65,
        "dec": 27.83333333333333,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "1FGL J0538.6+2717",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079447824564226",
        "name": "SNR G039.7-02.0",
        "names": [
          "SNR G039.7-02.0",
          "AJG 93",
          "CTB 69",
          "LMH 40",
          "MM 43",
          "Zel 1909+049",
          "[DGW65] 121",
          "[HC69] 28",
          "1E 1909.3+0453",
          "GRS G039.70 -02.00",
          "[ADD2012] SNR 18",
          "W 50"
        ],
        "searchKey": "dgw65121|lmh40|ajg93|w50|mm43|zel1909049|ctb69|grsg039700200|1e190930453|snrg0397020|add2012snr18|hc6928",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.20555555555556,
        "dec": 4.916666666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "SNR G039.7-02.0",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079717720064002",
        "name": "W 49b",
        "names": [
          "SNR G043.3-00.2",
          "1ES 1908+09.0",
          "2E 4203",
          "3C 398",
          "3C 398.0",
          "4C 09.63",
          "AJG 95",
          "CTA 84",
          "Cul 1908+09",
          "GAL 043.3-00.2",
          "NEK 43.3-00.2",
          "NRAO 599",
          "2E 1908.7+0901",
          "GRS G043.30 -00.20",
          "[ADD2012] SNR 23",
          "2FGL J1911.0+0905",
          "0FGL J1911.0+0905",
          "1FGL J1910.9+0906c",
          "1FHL J1911.0+0905",
          "W 49b",
          "3FGL J1910.9+0906",
          "2FHL J1911.0+0905",
          "TeV J1911+090",
          "3FHL J1911.0+0905",
          "HESS J1911+090",
          "4FGL J1911.0+0905"
        ],
        "searchKey": "4c0963|2fglj191100905|1es1908090|nek433002|grsg043300020|3c3980|snrg0433002|4fglj191100905|gal0433002|1fhlj191100905|1fglj191090906c|hessj1911090|cul190809|ajg95|3fhlj191100905|3c398|0fglj191100905|nrao599|w49b|2e190870901|tevj1911090|2fhlj191100905|3fglj191090906|2e4203|cta84|add2012snr23",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.18583333333333,
        "dec": 9.106666666666666,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "W 49b",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950085514117120002",
        "name": "SNR G034.6-00.5",
        "names": [
          "SNR G034.6-00.5",
          "SNR G034.7-00.4",
          "2E 4163",
          "2EG J1857+0118",
          "3C 392.0",
          "3C 392",
          "3CR 392",
          "3EG J1856+0114",
          "4C 01.57",
          "AJG 91",
          "ASB 47",
          "CTA 83",
          "CTB 60",
          "GAL 034.7-00.4",
          "GPS 1853+014",
          "GRO J1853+01",
          "GeV J1856+0115",
          "Kes 80",
          "LMH 37",
          "MM 38",
          "MSH 18+0-11",
          "NEK 34.7-00.4",
          "NRAO 585",
          "[DGW65] 114",
          "[HC69] 14",
          "GPSR 034.928-0.387",
          "1ES 1853+01.2",
          "2E 1853.3+0119",
          "0FGL J1855.9+0126",
          "1FGL J1856.1+0122",
          "1AGL J1857+0137",
          "GRS G034.60 -00.50",
          "GRS G034.70 -00.40",
          "[ADD2012] SNR 15",
          "2FGL J1855.9+0121e",
          "1FHL J1855.9+0121",
          "W 44",
          "3FGL J1855.9+0121e",
          "[ADD2012] HII 30",
          "3FHL J1855.9+0121e",
          "2AGL J1856+0119e",
          "4FGL J1855.9+0121e"
        ],
        "searchKey": "grsg034600050|1es1853012|3c3920|gpsr0349280387|snrg0347004|nek347004|2e185330119|3fglj185590121e|groj185301|3egj18560114|ajg91|mm38|grsg034700040|4c0157|nrao585|1aglj18570137|snrg0346005|3c392|0fglj185590126|gevj18560115|lmh37|asb47|gps1853014|gal0347004|cta83|ctb60|2egj18570118|3cr392|3fhlj185590121e|4fglj185590121e|add2012snr15|1fhlj185590121|2fglj185590121e|2aglj18560119e|2e4163|kes80|1fglj185610122|hc6914|w44|msh18011|add2012hii30|dgw65114",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.93629166666667,
        "dec": 1.222583333333333,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "SNR G034.6-00.5",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950085899662622722",
        "name": "Cas B",
        "names": [
          "RRF 1174",
          "[KRL2007b] 2",
          "SNR G120.1+01.4",
          "SNR G120.2+01.4",
          "1H 0022+638",
          "1M 0022+639",
          "1XRS 00224+638",
          "2C 34",
          "2E 83",
          "2U 0022+63",
          "3A 0022+638",
          "3C 10.0",
          "3C 10",
          "3CR 10",
          "3U 0022+63",
          "4C 63.01",
          "4U 0022+63",
          "8C 0022+638",
          "AJG 112",
          "ASB 1",
          "BD+63 39a",
          "BG 0022+63",
          "CTA 2",
          "CTB 4",
          "DA 11",
          "DB 2",
          "H 0021+63",
          "HBH 1",
          "KR 101",
          "NOVA Cas 1572",
          "NRAO 22",
          "SN 1572",
          "SN 1572A",
          "V* B Cas",
          "VRO 63.00.01",
          "X Cep X-1",
          "[DGW65] 3",
          "[FS2003] 0015",
          "1ES 0022+63.8",
          "1RXS J002509.2+640946",
          "2E 0022.1+6353",
          "HR 92",
          "PBC J0024.9+6407",
          "VER J0025+641",
          "SWIFT J0024.9+6407",
          "GRS G120.10 +01.40",
          "NAME Cas B",
          "NAME Tycho SN",
          "NAME Tycho SNR",
          "NAME Tycho's SN",
          "GPA 120.11+1.41",
          "87GB 002241.1+635151",
          "BWE 0022+6351",
          "F3R 3628",
          "WN B0022.5+6351",
          "MY 002240.5+640959.9",
          "WB 0022+6351",
          "CGPSE 107",
          "GB6 B0022+6352",
          "SWIFT J0025.2+6410",
          "TeV J0025+640"
        ],
        "searchKey": "cgpse107|pbcj002496407|sn1572|1h0022638|swiftj002526410|wnb002256351|dgw653|1rxsj0025092640946|gb6b00226352|tevj0025640|wb00226351|novacas1572|3a0022638|swiftj002496407|1m0022639|rrf1174|3c10|gpa12011141|2e83|2c34|87gb0022411635151|1xrs00224638|my00224056409599|kr101|tychosnr|tychosn|f3r3628|3cr10|db2|fs20030015|grsg120100140|1es0022638|sn1572a|4u002263|4c6301|xcepx1|casb|2e002216353|krl2007b2|tychossn|cta2|bg002263|8c0022638|bwe00226351|asb1|nrao22|3u002263|casb|snrg1201014|ctb4|3c100|vro630001|hbh1|h002163|2u002263|snrg1202014|bd6339a|da11|vbcas|ajg112|hr92|verj0025641",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 0.4226388888888889,
        "dec": 64.14083333333333,
        "fluxV": null,
        "sizeMajor": 8.0,
        "sizeMinor": 8.0,
        "sizeAngle": null,
        "simbadName": "SN 1572A",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950085899662917634",
        "name": "Kepler's SN",
        "names": [
          "TGSSADR J173040.5-212914",
          "[OKM2018] SWIFT J1731.3-2148",
          "SNR G004.5+06.8",
          "SN 1604A",
          "1H 1728-213",
          "2E 3911",
          "3C 358",
          "AJG 71",
          "CSI-21-17276",
          "CTA 78",
          "CTB 41",
          "Cul 1727-21",
          "Cul 1727-214",
          "ESO 588-4",
          "GCRV 67121",
          "Haro 3-12",
          "IRAS 17276-2126",
          "IRAS Z17276-2126",
          "Kes 57",
          "MSH 17-2-11",
          "NAME Kepler's SN",
          "NAME Kepler SN",
          "NAME Kepler SNR",
          "NOVA Oph 1604",
          "OHIO T -246",
          "PK 004+06 1",
          "PKS 1727-21",
          "PKS 1727-214",
          "PKS J1730-2129",
          "PMN J1730-2129",
          "PN G004.5+06.8",
          "PN VV' 208",
          "PN H 2-12",
          "SN 1604",
          "V* V843 Oph",
          "[CTR95] 1",
          "[TRC2001] 004.5+06.8",
          "1ES 1727-21.4",
          "1RXS J173040.4-212836",
          "2E 1727.6-2126",
          "HR 6515",
          "GRS G004.50 +06.80",
          "MRC 1727-214",
          "[PBD2003] G004.5+06.8",
          "VLSS J1730.6-2128"
        ],
        "searchKey": "haro312|novaoph1604|trc20010045068|mrc1727214|1rxsj1730404212836|okm2018swiftj173132148|ohiot246|keplersn|pnh212|2e172762126|pmnj17302129|2e3911|3c358|1es1727214|csi2117276|gcrv67121|cul172721|msh17211|pbd2003g0045068|png0045068|ajg71|cta78|ctb41|cul1727214|sn1604a|keplersnr|grsg004500680|irasz172762126|kes57|snrg0045068|pksj17302129|keplerssn|pnvv208|vv843oph|ctr951|tgssadrj1730405212914|hr6515|iras172762126|keplerssn|pk004061|pks1727214|eso5884|sn1604|pks172721|1h1728213|vlssj173062128",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.51125,
        "dec": -21.48722222222222,
        "fluxV": null,
        "sizeMajor": 3.0,
        "sizeMinor": 3.0,
        "sizeAngle": 90.0,
        "simbadName": "NAME Kepler SNR",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950085899736186882",
        "name": "Pup A",
        "names": [
          "SNR G260.4-03.4",
          "1H 0820-426",
          "1M 0821-426",
          "2U 0821-42",
          "3A 0821-427",
          "3U 0821-42",
          "4U 0821-42",
          "AJG 6",
          "CTA 46",
          "INTREF 352",
          "MRC 0821-428",
          "MSH 08-4-04",
          "NAME Pup A",
          "NAME Puppis A",
          "NAME Puppis SNR",
          "NAME SNR Pup A",
          "NAME SNR Puppis A",
          "PKS 0822-42",
          "PKS 0822-428",
          "PKS J0824-4259",
          "[TRC2001] 260.4-03.4",
          "0ES 0821-42.6",
          "1E 0820.5-4250",
          "GRS G260.40 -03.40",
          "1FHL J0822.6-4250",
          "1FGL J0823.3-4248",
          "2FGL J0823.0-4246",
          "3FGL J0822.6-4250e",
          "2FHL J0822.6-4250e",
          "3FHL J0822.1-4253e",
          "4FGL J0822.1-4253e"
        ],
        "searchKey": "trc20012604034|4fglj082214253e|0es0821426|1e082054250|snrpuppisa|2fglj082304246|3fhlj082214253e|pks082242|puppissnr|pksj08244259|4u082142|2u082142|1fhlj082264250|cta46|pupa|snrpupa|3u082142|ajg6|pupa|intref352|snrg2604034|grsg260400340|1h0820426|1m0821426|puppisa|msh08404|1fglj082334248|3fglj082264250e|2fhlj082264250e|pks0822428|mrc0821428|3a0821427",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 8.401944444444444,
        "dec": -42.99666666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Pup A",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950085899836325890",
        "name": "Lupus SN",
        "names": [
          "KOHX 1",
          "SN 1006A",
          "SNR G327.6+14.6",
          "SNR G327.6+14.5",
          "XSS J15031-4149",
          "SN 1016",
          "SN 1006",
          "PKS 1459-41",
          "MRC 1459-417",
          "H 1506-42",
          "AJG 37",
          "4U 1458-41",
          "3U 1439-39",
          "2U 1440-39",
          "1M 1457-416",
          "1H 1458-416",
          "1E 1459.6-4146",
          "1ES 1500-41.5",
          "MSH 14-4-15",
          "PKS 1459-419",
          "PKS J1502-4205",
          "GRS G327.60 +14.60",
          "NAME Lupus SN",
          "TeV J1502-419"
        ],
        "searchKey": "sn1016|tevj1502419|sn1006|pks1459419|snrg3276145|msh14415|1es1500415|4u145841|1e145964146|sn1006a|pks145941|3u143939|snrg3276146|lupussn|h150642|grsg327601460|2u144039|1m1457416|mrc1459417|1h1458416|lupussn|pksj15024205|ajg37|xssj150314149|kohx1",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 15.03947222222222,
        "dec": -42.09694444444445,
        "fluxV": null,
        "sizeMajor": 30.0,
        "sizeMinor": 30.0,
        "sizeAngle": 90.0,
        "simbadName": "NAME Lupus SN",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950085984914505730",
        "name": "Suzaku J0105-72",
        "names": [
          "[YIT2003] 81",
          "[YIT2000] 30",
          "[MNB76] 20",
          "[KPF99] 183",
          "[KPF99] 182",
          "[HFP2000] 107",
          "[FJW97] SMC B0102-7218",
          "WW 51",
          "RX J0104.0-7201",
          "RBS 146",
          "PMN J0103-7202",
          "PKS 0102-723",
          "IKT 22",
          "BKGS 12",
          "2E 261",
          "LHA 115-N 76",
          "[FPH98] SMC 23",
          "[SHP2000] SMC 107",
          "1E 0102-72.3",
          "[FBR2002] J010402-720149",
          "DEM S 124",
          "1E 0102.2-7218",
          "1E 0102-72.2",
          "1E 0102.2-7219",
          "1E 0102.1-7219",
          "1ES 0102-72.2",
          "1RXS J010403.5-720158",
          "2E 0102.3-7217",
          "MC4 0102-72.3",
          "RX J0103.9-7202",
          "SNR B0102-72.3",
          "NAME Suzaku J0105-72",
          "SNR B0102-72.2",
          "[BMD2010] SNR J0104.0-7202",
          "MCSNR J0104-7201",
          "SUMSS J010401-720153"
        ],
        "searchKey": "1es0102722|hfp2000107|bmd2010snrj010407202|fph98smc23|shp2000smc107|fbr2002j010402720149|rbs146|1e010217219|suzakuj010572|pmnj01037202|pks0102723|sumssj010401720153|1e010227218|mnb7620|yit200381|lha115n76|mc40102723|2e010237217|kpf99183|snrb0102723|yit200030|rxj010397202|dems124|bkgs12|kpf99182|fjw97smcb01027218|snrb0102722|rxj010407201|1rxsj0104035720158|suzakuj010572|ww51|mcsnrj01047201|1e010227219|1e0102722|1e0102723|ikt22|2e261",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 1.067,
        "dec": -72.03111111111112,
        "fluxV": null,
        "sizeMajor": 0.73,
        "sizeMinor": 0.73,
        "sizeAngle": 90.0,
        "simbadName": "SNR B0102-72.3",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950085985229930498",
        "name": "1A 1439-61",
        "names": [
          "SNR G315.0-02.3",
          "SNR G315.4-02.3",
          "AJG 27",
          "1A 1439-61",
          "1H 1438-623",
          "1M 1439-622",
          "3A 1438-626",
          "GPS 1438-624",
          "H 1427-61",
          "MSC 315.4-2.3",
          "MSH 14-6-03",
          "4FGL J1443.0-6227e",
          "SN 185",
          "X Cen X-1",
          "[TRC2001] 315.4-02.3",
          "1E 1438.7-6215",
          "1ES 1436-62.4",
          "1RXS J144254.3-622815",
          "GRS G315.00 -02.30",
          "GRS G315.40 -02.30",
          "2FHL J1443.2-6221e",
          "HESS J1442-624",
          "3FHL J1443.0-6227e"
        ],
        "searchKey": "1es1436624|1rxsj1442543622815|1a143961|3fhlj144306227e|trc20013154023|grsg315000230|xcenx1|1h1438623|1m1439622|2fhlj144326221e|1e143876215|3a1438626|gps1438624|msc315423|grsg315400230|sn185|hessj1442624|4fglj144306227e|snrg3154023|snrg3150023|ajg27|h142761|msh14603",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 14.71666666666667,
        "dec": -62.5,
        "fluxV": null,
        "sizeMajor": 42.0,
        "sizeMinor": 42.0,
        "sizeAngle": 90.0,
        "simbadName": "1A 1439-61",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086169689456642",
        "name": "SNR J052559-660453",
        "names": [
          "SNR J052559-660453",
          "2E 1279",
          "CAL 36",
          "DEM L 190",
          "LHA 120-N 49",
          "LMC RASS 207",
          "PKS 0525-66",
          "PMN J0526-6604",
          "RBS 650",
          "RX J0526.0-6605",
          "[FHW95] LMC B0525-6607",
          "[FPH98] LMC 28",
          "[HP99] 241",
          "[MBB72] 43",
          "[SHP2000] LMC 146",
          "1ES 0526-66.1",
          "1RXS J052559.2-660450",
          "2E 0525.9-6607",
          "SNR B0525-66.1",
          "WHHW 0525.9-6608",
          "[VOG2010] 23",
          "[BMD2010] SNR J0526.0-6605",
          "HD 271255",
          "MCELS L-246",
          "PKS 0525-661",
          "PKS J0525-6604",
          "SUMSS J052600-660502",
          "MRC 0525-661",
          "MCSNR J0526-6605"
        ],
        "searchKey": "2e1279|1es0526661|whhw052596608|1rxsj0525592660450|sumssj052600660502|snrb0525661|pmnj05266604|fhw95lmcb05256607|mbb7243|pksj05256604|deml190|lha120n49|cal36|mcelsl246|mcsnrj05266605|hd271255|snrj052559660453|vog201023|rbs650|lmcrass207|shp2000lmc146|mrc0525661|pks052566|rxj052606605|fph98lmc28|pks0525661|bmd2010snrj052606605|hp99241|2e052596607",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "INDIGO",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.433444444444445,
        "dec": -66.08388888888888,
        "fluxV": 12.71,
        "sizeMajor": 1.4,
        "sizeMinor": 1.4,
        "sizeAngle": 90.0,
        "simbadName": "SNR J052559-660453",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086213916229634",
        "name": "HD 25137",
        "names": [
          "HIP 18660",
          "Gaia DR3 3259573383856734720",
          "TIC 459931738",
          "2MASS J03594157+0147415",
          "AG+01 406",
          "BD+01 689",
          "GSC 00066-01154",
          "HD 25137",
          "HIC 18660",
          "PPM 147107",
          "SAO 111549",
          "TD1 2718",
          "TYC 66-1154-1",
          "YZ 1 1167",
          "uvby98 100025137",
          "Gaia DR1 3259573379560616960",
          "GEN# +1.00025137",
          "Gaia DR2 3259573383856734720"
        ],
        "searchKey": "ag01406|td12718|gen100025137|bd01689|gaiadr13259573379560616960|gsc0006601154|gaiadr23259573383856734720|hic18660|sao111549|yz11167|ppm147107|2massj035941570147415|tyc6611541|uvby98100025137|gaiadr33259573383856734720|hip18660|hd25137|tic459931738",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 3.994882173882667,
        "dec": 1.79481418854,
        "fluxV": 7.26,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "HD 25137",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086214349160450",
        "name": "Vela",
        "names": [
          "GRS G263.90 -03.00",
          "SNR G263.9-03.0",
          "SNR G263.4-03.0",
          "SNR G263.9-03.3",
          "AJG 8",
          "1E 0840.0-4430",
          "2RE J0838-430",
          "2RE J083856-430913",
          "MSC 263.9-3.3",
          "NAME Vela",
          "NAME Vela SNR",
          "NAME Vela XYZ",
          "RE J0838-430",
          "RE J083854-430902",
          "1ES 0839-44.5",
          "GRS G263.90 -03.30",
          "1FGL J0841.9-4620"
        ],
        "searchKey": "2rej0838430|rej083854430902|snrg2639033|grsg263900330|2rej083856430913|1fglj084194620|msc263933|snrg2634030|1es0839445|velasnr|velaxyz|vela|rej0838430|vela|grsg263900300|ajg8|snrg2639030|1e084004430",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 8.566666666666666,
        "dec": -45.83333333333334,
        "fluxV": null,
        "sizeMajor": 255.0,
        "sizeMinor": 255.0,
        "sizeAngle": 90.0,
        "simbadName": "NAME Vela XYZ",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086214428000258",
        "name": "NGC 6995",
        "names": [
          "NGC 6995"
        ],
        "searchKey": "ngc6995",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.95,
        "dec": 31.21666666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 6995",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086214428033026",
        "name": "SNR G029.7-00.2",
        "names": [
          "F3R 1025",
          "[ADP79] 029.701-0.246",
          "[PBD2003] G029.7-00.2",
          "[ADD2012] SNR 11",
          "GRS G029.70 -00.30",
          "2E 1843.8-0301",
          "GPSR 029.690-0.242",
          "RFS 490",
          "NRAO 580",
          "NEK 29.7-00.2",
          "Kes 75",
          "GPS 1843-031",
          "GAL 029.7-00.3",
          "GAL 029.7-00.2",
          "Cul 1843-03",
          "ASB 44",
          "AJG 86",
          "4C -03.70",
          "SNR G029.7-00.3",
          "SNR G029.7-00.2",
          "2E 4129",
          "HSNH 85",
          "Cul 1843-030",
          "PMN J1846-0259",
          "TXS 1843-030"
        ],
        "searchKey": "pbd2003g0297002|hsnh85|ajg86|txs1843030|gpsr0296900242|2e184380301|rfs490|snrg0297003|adp790297010246|gal0297003|cul184303|kes75|asb44|4c0370|nek297002|nrao580|gal0297002|gps1843031|add2012snr11|pmnj18460259|2e4129|grsg029700030|f3r1025|cul1843030|snrg0297002",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.77422222222222,
        "dec": -2.986388888888889,
        "fluxV": null,
        "sizeMajor": 3.0,
        "sizeMinor": 3.0,
        "sizeAngle": 90.0,
        "simbadName": "SNR G029.7-00.2",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086214429769730",
        "name": "SNR G130.7+03.1",
        "names": [
          "TGSSADR J020535.7+644941",
          "RRF 1311",
          "SNR G130.7+03.1",
          "12P 13",
          "2C 177",
          "2E 518",
          "3C 58",
          "3C 58.0",
          "3CR 58",
          "4C 64.02",
          "6C 020148+643517",
          "AJG 113",
          "ASB 5",
          "BG 0201+64",
          "CTB 8",
          "DA 66",
          "INTREF 102",
          "KR 130",
          "NRAO 90",
          "SN 1181",
          "[BM83] X0201+645",
          "0ES 0201+64.5",
          "1RXS J020529.7+644934",
          "2E 0201.8+6435",
          "RX J0205.5+6449",
          "RX J0201.8+6435",
          "[BSF97] J020538.9+644939",
          "GRS G130.70 +03.10",
          "GPA 130.73+3.11",
          "87GB 020149.3+643518",
          "BWE 0201+6435",
          "F3R 3887",
          "WN B0201.8+6435",
          "MY 020150.3+644954.9",
          "WB 0201+6435",
          "7C 020144.89+643534.00",
          "8C 0201+645",
          "VLSS J0205.5+6449",
          "CGPSE 125",
          "GB6 B0201+6435",
          "TeV J0209+648"
        ],
        "searchKey": "3cr58|2e518|vlssj020556449|snrg1307031|f3r3887|tgssadrj0205357644941|0es0201645|bsf97j0205389644939|2c177|grsg130700310|kr130|4c6402|bg020164|87gb0201493643518|asb5|rxj020556449|cgpse125|ctb8|wnb020186435|8c0201645|sn1181|da66|intref102|rrf1311|1rxsj0205297644934|gb6b02016435|my02015036449549|bwe02016435|bm83x0201645|7c0201448964353400|ajg113|3c58|rxj020186435|12p13|wb02016435|6c020148643517|3c580|2e020186435|gpa13073311|tevj0209648|nrao90",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ROSE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.093611111111111,
        "dec": 64.82833333333333,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "SNR G130.7+03.1",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086214430130178",
        "name": "Vela Jr SN",
        "names": [
          "NAME Vela Jr SN",
          "SNR G266.2-01.2",
          "SNR G266.3-01.2",
          "GRO J0852-467",
          "GRO J0852-46",
          "NAME Vela Junior",
          "4FGL J0851.9-4620e",
          "RX J0852.0-4622",
          "HESS J0852-463",
          "1FHL J0852.7-4631",
          "2FGL J0851.7-4635",
          "1FGL J0854.0-4632",
          "3FGL J0852.7-4631e",
          "2FHL J0852.8-4631",
          "2FHL J0852.8-4631e",
          "TeV J0852-463",
          "3FHL J0851.9-4620e"
        ],
        "searchKey": "3fhlj085194620e|snrg2662012|snrg2663012|2fglj085174635|2fhlj085284631|hessj0852463|groj085246|1fhlj085274631|rxj085204622|2fhlj085284631e|tevj0852463|1fglj085404632|groj0852467|velajrsn|velajunior|velajrsn|3fglj085274631e|4fglj085194620e",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 8.866666666666667,
        "dec": -46.33333333333334,
        "fluxV": null,
        "sizeMajor": 120.0,
        "sizeMinor": 120.0,
        "sizeAngle": 90.0,
        "simbadName": "NAME Vela Jr SN",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086214521815042",
        "name": "Cyg Loop",
        "names": [
          "SNR G074.0-08.6",
          "AJG 102",
          "SNR G074.3-08.5",
          "SNR G072.4-09.5",
          "SNR G074.0-08.5",
          "1H 2050+310",
          "1M 2051+309",
          "2RE J2057+311",
          "2RE J205730+311130",
          "4U 2046+31",
          "CTA 93",
          "CTB 99",
          "DA 528",
          "DB 119",
          "NAME Cyg Loop",
          "NAME Cygnus Loop",
          "SH 2-103",
          "X Cyg X-5",
          "[DGW65] 137",
          "1E 2049.4+3050",
          "2FGL J2051.0+3040e",
          "W 78",
          "1FGL J2046.4+3041",
          "3FGL J2051.0+3040e",
          "3FHL J2051.0+3040e",
          "4FGL J2051.0+3049e"
        ],
        "searchKey": "4fglj205103049e|sh2103|da528|3fglj205103040e|cygloop|2rej205730311130|snrg0740086|1m2051309|2fglj205103040e|snrg0724095|dgw65137|1fglj204643041|snrg0740085|1e204943050|snrg0743085|4u204631|w78|1h2050310|cygnusloop|3fhlj205103040e|db119|ctb99|ajg102|2rej2057311|cta93|xcygx5|cygloop",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.85,
        "dec": 30.66666666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Cyg Loop",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086214542491650",
        "name": "Jellyfish Nebula",
        "names": [
          "3HWC J0617+224",
          "NAME Jellyfish Nebula",
          "2AGL J0617+2239e",
          "MAGIC J0616+225",
          "SNR G189.0+03.0",
          "SNR G189.1+03.0",
          "1H 0612+226",
          "1M 0614+225",
          "2C 537",
          "2EG J0618+2234",
          "2U 0601+21",
          "3A 0614+224",
          "3C 157",
          "3CR 157",
          "3EG J0617+2238",
          "3U 0620+23",
          "4C 22.15",
          "4U 0617+23",
          "ASB 26",
          "CTB 20",
          "DA 203",
          "DB 41",
          "GRO J0617+22",
          "GeV J0617+2237",
          "H 0620+22",
          "IC 443",
          "INTREF 295",
          "LBN 844",
          "LBN 189.13+02.97",
          "LEDA 2817561",
          "Mills 06+2A",
          "Min 1-84",
          "NAME Gem A",
          "4FGL J0617.2+2234e",
          "NRL 22",
          "PKS 0615+226",
          "SH 2-248",
          "SIM 0614+22.0",
          "VRO 22.06.01",
          "WKB 0614+22.7",
          "[DGW65] 34",
          "[PT56] 11",
          "EGR J0617+2238",
          "VER J0616+223",
          "1ES 0613+22.7",
          "1ES 0614+22.7",
          "1AGL J0617+2236",
          "0FGL J0617.4+2234",
          "1FGL J0617.2+2233",
          "SNR G189.1-03.0",
          "GRS G188.90 +03.00",
          "GRS G189.10 +03.00",
          "2FGL J0617.2+2234e",
          "1FHL J0617.2+2234",
          "3FGL J0617.2+2234e",
          "2FHL J0617.2+2234e",
          "TeV J0616+225",
          "3FHL J0617.2+2234e"
        ],
        "searchKey": "4u061723|asb26|grsg188900300|gevj06172237|2fglj061722234e|tevj0616225|1fglj061722233|1es0614227|snrg1891030|pt5611|vro220601|sim0614220|2u060121|ctb20|dgw6534|da203|3u062023|snrg1890030|3fglj061722234e|magicj0616225|3fhlj061722234e|jellyfishnebula|1fhlj061722234|1aglj06172236|db41|leda2817561|nrl22|3cr157|3c157|2egj06182234|mills062a|gema|4c2215|wkb0614227|1es0613227|groj061722|snrg1891030|intref295|min184|3a0614224|lbn189130297|3egj06172238|2fhlj061722234e|jellyfishnebula|verj0616223|pks0615226|h062022|4fglj061722234e|ic443|egrj06172238|2c537|2aglj06172239e|1h0612226|grsg189100300|sh2248|1m0614225|lbn844|0fglj061742234|3hwcj0617224",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 6.283333333333333,
        "dec": 22.56999969472222,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "IC 443",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086294083633154",
        "name": "HD 168132",
        "names": [
          "Gaia DR3 4274514322447787008",
          "TIC 167492079",
          "AG-00 2297",
          "BD-00 3458",
          "GSC 05098-00301",
          "HD 168132",
          "PPM 180290",
          "SAO 142209",
          "TYC 5098-301-1",
          "YZ 90 4540",
          "2MASS J18182249-0051537",
          "Gaia DR1 4274514318139079680",
          "Gaia DR2 4274514322447787008"
        ],
        "searchKey": "tic167492079|bd003458|sao142209|gaiadr34274514322447787008|yz904540|hd168132|ag002297|gsc0509800301|ppm180290|2massj181822490051537|gaiadr24274514322447787008|tyc50983011|gaiadr14274514318139079680",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.30624706669733,
        "dec": -0.86496651618,
        "fluxV": 8.68,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "HD 168132",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086294557982722",
        "name": "AJG 44",
        "names": [
          "SNR G332.4-00.4",
          "2E 3626",
          "AJG 44",
          "GPS 1615-508",
          "H 1615-51",
          "Kes 33",
          "MSC 332.4-0.4",
          "PKS 1613-50",
          "RCW 103",
          "[BM83] X1613-509",
          "1ES 1613-50.9",
          "2E 1613.8-5057",
          "GRS G332.40 -00.40",
          "3FGL J1616.2-5054e",
          "2FGL J1615.0-5051",
          "1FGL J1617.5-5105c",
          "1FHL J1616.2-5054",
          "HESS J1616-508",
          "2FHL J1616.2-5054",
          "2FHL J1616.2-5054e",
          "TeV J1616-508",
          "[NNM2015] 16",
          "3FHL J1616.2-5054e",
          "4FGL J1616.2-5054e"
        ],
        "searchKey": "2e161385057|4fglj161625054e|gps1615508|h161551|1fglj161755105c|grsg332400040|msc332404|rcw103|bm83x1613509|hessj1616508|3fhlj161625054e|2e3626|snrg3324004|3fglj161625054e|2fhlj161625054e|2fglj161505051|pks161350|kes33|ajg44|1es1613509|nnm201516|tevj1616508|2fhlj161625054|1fhlj161625054",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.2925,
        "dec": -51.03333333333333,
        "fluxV": null,
        "sizeMajor": 10.0,
        "sizeMinor": 10.0,
        "sizeAngle": 90.0,
        "simbadName": "AJG 44",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086294867607554",
        "name": "Cas A",
        "names": [
          "[KRL2007b] 402",
          "SNR G111.7-02.1",
          "SN 1671",
          "1H 2321+585",
          "2E 4724",
          "2U 2321+58",
          "3A 2321+585",
          "3C 461",
          "3C 461.0",
          "3CR 461",
          "3U 2321+58",
          "4C 58.40",
          "4U 2321+58",
          "8C 2321+585",
          "AJG 109",
          "BWE 2321+5832",
          "CTA 105",
          "CTB 110",
          "DA 601",
          "DB 123",
          "GAL 111.7-02.1",
          "H 2334+60",
          "INTREF 1108",
          "NAME Cas A",
          "NAME Cassiopeia A",
          "NRAO 711",
          "SN 1667",
          "SN 1680",
          "[DGW65] 148",
          "1ES 2321+58.5",
          "1RXS J232325.4+584838",
          "2E 2321.1+5833",
          "PBC J2323.3+5849",
          "SWIFT J2323.3+5849",
          "1FGL J2323.4+5849",
          "SWIFT J2323.2+5848",
          "2FGL J2323.4+5849",
          "1FHL J2323.3+5849",
          "W 81",
          "3FGL J2323.4+5849",
          "2FHL J2323.4+5848",
          "TeV J2323+588",
          "3FHL J2323.4+5848",
          "4FGL J2323.4+5849"
        ],
        "searchKey": "gal1117021|3fglj232345849|db123|casa|swiftj232325848|3c4610|cassiopeiaa|bwe23215832|3c461|ctb110|3cr461|1fhlj232335849|tevj2323588|casa|dgw65148|snrg1117021|da601|h233460|sn1667|1rxsj2323254584838|4fglj232345849|sn1680|3u232158|2e4724|2fglj232345849|1h2321585|w81|sn1671|3fhlj232345848|nrao711|1fglj232345849|2fhlj232345848|2u232158|3a2321585|pbcj232335849|2e232115833|1es2321585|8c2321585|ajg109|4c5840|cta105|4u232158|intref1108|krl2007b402|swiftj232335849",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "INDIGO",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 23.39,
        "dec": 58.815,
        "fluxV": 14.3,
        "sizeMajor": 5.0,
        "sizeMinor": 5.0,
        "sizeAngle": 90.0,
        "simbadName": "3C 461",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086294960209922",
        "name": "LMC SN",
        "names": [
          "WISEA J053527.99-691610.9",
          "AAVSO 0534-69",
          "INTREF 262",
          "NAME LMC SN",
          "SN 1987A",
          "[HP99] 854",
          "[SHP2000] LMC 264",
          "[WS90] 1",
          "XMMU J053528.5-691614",
          "[GC2009] J053527.99-691611.1",
          "[WSI2008] 884",
          "SSTISAGE1C J053528.01-691611.0",
          "2XMM J053528.1-691611",
          "MCSNR J0535-6916",
          "HSO BMHERICC J083.8662-69.2699",
          "GSC 09162-00821",
          "CPD-69 402",
          "GEN# +8.58690202",
          "SK -69 202",
          "UBV M 51417",
          "[WLJ87] Star 1",
          "USNO-B1.0 0207-00125299",
          "[WS91b] Star 1",
          "WISE J053527.97-691610.9",
          "NAME NS 1987A",
          "NAME SNR 1987A",
          "SNR B0535-69.3",
          "[BMD2010] SNR J0535.5-6916"
        ],
        "searchKey": "sn1987a|ws91bstar1|sstisage1cj053528016916110|ns1987a|wsi2008884|ws901|bmd2010snrj053556916|snrb0535693|2xmmj0535281691611|hsobmhericcj0838662692699|gen858690202|hp99854|wisej053527976916109|gsc0916200821|lmcsn|wlj87star1|usnob10020700125299|wiseaj053527996916109|xmmuj0535285691614|ubvm51417|mcsnrj05356916|shp2000lmc264|intref262|aavso053469|sk69202|lmcsn|snr1987a|cpd69402|gc2009j053527996916111",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.591116666666666,
        "dec": -69.26974166666666,
        "fluxV": null,
        "sizeMajor": 0.03,
        "sizeMinor": 0.03,
        "sizeAngle": 90.0,
        "simbadName": "SN 1987A",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086423877615618",
        "name": "SN 1996av",
        "names": [
          "SN 1996av"
        ],
        "searchKey": "sn1996av",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 1.898394444444444,
        "dec": 0.2585,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "SN 1996av",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950086519658348546",
        "name": "MGC 56140",
        "names": [
          "Gaia DR3 3660040944898147072",
          "TIC 119229847",
          "MGC 56140",
          "2MASS J14060932-0015024",
          "Gaia DR2 3660040944898147072"
        ],
        "searchKey": "2massj140609320015024|tic119229847|gaiadr33660040944898147072|gaiadr23660040944898147072|mgc56140",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 14.10258800165067,
        "dec": -0.25064563311,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "MGC 56140",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959186004869121",
    "title": "Variable Stars",
    "color": "RED",
    "credit": "https://en.wikipedia.org/wiki/Variable_star",
    "imgURL": "/list_icons/var-stars.jpg",
    "type": "CURATED_LIST",
    "objects": [
      {
        "id": "950084603354841090",
        "name": "V* VY CMa",
        "names": [
          "HIP 35793",
          "OH 239.3-05.1",
          "[TCH91] 07209-2540",
          "** B 719",
          "WISE J072258.10-254603.5",
          "UCAC4 322-023976",
          "GEN# +1.00058061",
          "TIC 107149174",
          "GCRV 27408",
          "GCRV 27369",
          "AAVSO 0718-25",
          "ADS 6033 AB",
          "AN 60.1931",
          "CD-25 4441",
          "CPD-25 2286",
          "GCRV 4908",
          "GSC 06541-02525",
          "HD 58061",
          "HIC 35793",
          "IRAS 07209-2540",
          "IRC -30087",
          "PPM 252258",
          "RAFGL 1111",
          "SAO 173591",
          "SKY# 13432",
          "SRS 7681",
          "SV* P 468",
          "V* VY CMa",
          "YZ 115 4839",
          "[LFO93] 0720-25",
          "[PCC93] 116",
          "[TVH89] 21",
          "2MASS J07225830-2546030",
          "JCMTSE J072258.5-254602",
          "JCMTSF J072258.5-254602",
          "[WCP90] 072054.739-254012.27",
          "WDS J07230-2546AB",
          "WEB 7134"
        ],
        "searchKey": "tic107149174|yz1154839|hd58061|pcc93116|cd254441|wcp9007205473925401227|sky13432|gcrv4908|sao173591|cpd252286|gsc0654102525|web7134|an601931|srs7681|wdsj072302546ab|iras072092540|irc30087|wisej072258102546035|vvycma|rafgl1111|hic35793|ads6033ab|gcrv27369|aavso071825|jcmtsfj0722585254602|tch91072092540|gen100058061|tvh8921|ppm252258|oh2393051|gcrv27408|svp468|hip35793|2massj072258302546030|jcmtsej0722585254602|lfo93072025|ucac4322023976|b719",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 7.382868370894139,
        "dec": -25.76755399738722,
        "fluxV": 8.691,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* VY CMa",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950084603618525186",
        "name": "Betelgeuse",
        "names": [
          "HIP 27989",
          "GEN# +1.00039801",
          "TIC 269273552",
          "2MASS J05551028+0724255",
          "PLX 1362",
          "* 58 Ori",
          "* alf Ori",
          "AAVSO 0549+07",
          "ADS 4506 AP",
          "AG+07 681",
          "BD+07 1055",
          "CCDM J05552+0724AP",
          "CSI+07 1055 1",
          "EIC 108",
          "FK5 224",
          "GC 7451",
          "GCRV 3679",
          "GSC 00129-01873",
          "HD 39801",
          "HIC 27989",
          "HR 2061",
          "IRAS 05524+0723",
          "IRC +10100",
          "JP11 1282",
          "N30 1266",
          "NAME Betelgeuse",
          "PMC 90-93 162",
          "PPM 149643",
          "RAFGL 836",
          "SAO 113271",
          "SKY# 9804",
          "TD1 5587",
          "TYC 129-1873-1",
          "UBV 21314",
          "V* alf Ori",
          "YZ 7 2503",
          "[LFO93] 0552+07",
          "JCMTSE J055510.1+072426",
          "PLX 1362.00",
          "JCMTSF J055510.1+072426",
          "WDS J05552+0724Aa,Ab",
          "** H 639A",
          "WDS J05552+0724Aa,Ac",
          "WDS J05552+0724A",
          "** KAR 1",
          "WEB 5485"
        ],
        "searchKey": "plx136200|sky9804|gc7451|alfori|csi0710551|irc10100|wdsj055520724aaac|hd39801|bd071055|sao113271|td15587|iras055240723|betelgeuse|hic27989|hip27989|pmc9093162|valfori|tyc12918731|ads4506ap|lfo93055207|wdsj055520724a|jp111282|kar1|ppm149643|web5485|rafgl836|n301266|yz72503|gcrv3679|wdsj055520724aaab|betelgeuse|jcmtsfj0555101072426|2massj055510280724255|hr2061|h639a|ccdmj055520724ap|gsc0012901873|tic269273552|58ori|jcmtsej0555101072426|plx1362|ubv21314|eic108|ag07681|aavso054907|gen100039801|fk5224",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.919529266666667,
        "dec": 7.407064,
        "fluxV": 0.42,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* alf Ori",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950092792569331714",
        "name": "* chi Cyg",
        "names": [
          "HIP 97629",
          "Gaia DR3 2034702312280673792",
          "TIC 83403878",
          "PLX 4673",
          "* chi Cyg",
          "AAVSO 1946+32",
          "AG+32 1840",
          "BD+32 3593",
          "CCDM J19505+3254A",
          "CSI+32 3593 1",
          "CSS 1165",
          "GC 27481",
          "GCRV 12201",
          "GEN# +1.00187796",
          "GSC 02673-04643",
          "HD 187796",
          "HIC 97629",
          "HR 7564",
          "Hen 4-220",
          "IDS 19468+3240 A",
          "IRAS 19486+3247",
          "IRC +30395",
          "JP11 3144",
          "PPM 83661",
          "RAFGL 2465",
          "S1* 625",
          "SAO 68943",
          "SKY# 37119",
          "TYC 2673-4643-1",
          "UBV 21439",
          "V* chi Cyg",
          "[AHB74] V 20",
          "[C97] 57",
          "[KW97] 40-11",
          "[LFO93] 1948+32",
          "2MASS J19503392+3254509",
          "PLX 4673.00",
          "[WCP90] 194838.470+324710.06",
          "UCAC2 43322008",
          "WDS J19506+3255A",
          "WEB 17165"
        ],
        "searchKey": "css1165|vchicyg|ag321840|aavso194632|gc27481|gcrv12201|ubv21439|tic83403878|bd323593|chicyg|wdsj195063255a|ids194683240a|gaiadr32034702312280673792|sao68943|irc30395|iras194863247|rafgl2465|hen4220|2massj195033923254509|c9757|csi3235931|s1625|tyc267346431|sky37119|wcp9019483847032471006|kw974011|gsc0267304643|ahb74v20|gen100187796|ucac243322008|plx4673|hip97629|hr7564|lfo93194832|ppm83661|plx467300|hd187796|jp113144|ccdmj195053254a|hic97629|web17165",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "CYAN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.84275592862069,
        "dec": 32.91404875869278,
        "fluxV": 4.24,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* chi Cyg",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950092792569987074",
        "name": "V* R CrB",
        "names": [
          "HIP 77442",
          "Gaia DR3 1224565824009709312",
          "ATO J237.1433+28.1567",
          "ATO J237.1433+28.1566",
          "TIC 284759010",
          "GSC2 N1330022410",
          "PLX 3581",
          "AAVSO 1544+28A",
          "AG+28 1513",
          "BD+28 2477",
          "EM* CDS 886",
          "GC 21257",
          "GCRV 9116",
          "GEN# +1.00141527",
          "GSC 02039-01605",
          "HD 141527",
          "HIC 77442",
          "HR 5880",
          "IRAS 15465+2818",
          "N30 3552",
          "PPM 104338",
          "RAFGL 4219",
          "ROT 2225",
          "SAO 84015",
          "SKY# 28584",
          "TD1 18592",
          "TYC 2039-1605-1",
          "V* R CrB",
          "YZ 28 7350",
          "2MASS J15483440+2809242",
          "PLX 3581.00",
          "ASAS J154834+2809.4",
          "Gaia DR1 1224565819711854208",
          "uvby98 100141527",
          "WEB 13113",
          "Gaia DR2 1224565824009017600"
        ],
        "searchKey": "vrcrb|atoj2371433281567|asasj15483428094|sky28584|ppm104338|plx3581|aavso154428a|tic284759010|plx358100|uvby98100141527|web13113|gaiadr21224565824009017600|hd141527|hr5880|2massj154834402809242|rot2225|sao84015|atoj2371433281566|n303552|hip77442|bd282477|emcds886|rafgl4219|yz287350|gsc0203901605|gsc2n1330022410|gc21257|gaiadr11224565819711854208|td118592|gen100141527|iras154652818|gcrv9116|tyc203916051|hic77442|ag281513|gaiadr31224565824009709312",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 15.80955985631533,
        "dec": 28.1567488479,
        "fluxV": 5.71,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* R CrB",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950092792588632066",
        "name": "* eta Aql",
        "names": [
          "HIP 97804",
          "Gaia DR3 4240272953377646592",
          "WISEA J195228.37+010020.5",
          "WISE J195228.43+010020.5",
          "UCAC4 456-103989",
          "Gaia DR2 4240272953376907648",
          "TIC 111010123",
          "PLX 4679",
          "* 55 Aql",
          "* eta Aql",
          "AAVSO 1947+00",
          "AG+00 2415",
          "BD+00 4337",
          "FK5 746",
          "GC 27517",
          "GCRV 12220",
          "GEN# +1.00187929",
          "GSC 00480-03027",
          "HD 187929",
          "HIC 97804",
          "HR 7570",
          "IRAS 19499+0052",
          "IRC +00457",
          "N30 4395",
          "PMC 90-93 531",
          "PPM 168843",
          "RAFGL 5437S",
          "ROT 2863",
          "SAO 125159",
          "SKY# 37213",
          "TD1 25584",
          "TYC 480-3027-1",
          "UBV 21297",
          "V* eta Aql",
          "YZ 0 5001",
          "2MASS J19522835+0100203",
          "PLX 4679.00",
          "uvby98 100187929",
          "WEB 17207",
          "** KRV 27A",
          "** EVS 2A",
          "WDS J19525+0100A"
        ],
        "searchKey": "ucac4456103989|wdsj195250100a|gcrv12220|wiseaj195228370100205|hd187929|td125584|etaaql|sky37213|hip97804|ag002415|uvby98100187929|gsc0048003027|ppm168843|fk5746|pmc9093531|web17207|hic97804|tic111010123|55aql|plx467900|rafgl5437s|tyc48030271|gaiadr24240272953376907648|rot2863|sao125159|n304395|ubv21297|vetaaql|evs2a|krv27a|bd004337|yz05001|aavso194700|gc27517|iras194990052|wisej195228430100205|hr7570|2massj195228350100203|gaiadr34240272953377646592|plx4679|gen100187929|irc00457",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ZINC",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.87454692288281,
        "dec": 1.005658883476389,
        "fluxV": 3.8,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* eta Aql",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950092792700567554",
        "name": "V* RR Lyr",
        "names": [
          "HIP 95497",
          "AP J19252793+4247040",
          "Gaia DR3 2125982599343482624",
          "TIC 159717514",
          "NSVS 5543125",
          "PLX 4550",
          "LSPM J1925+4247",
          "ASCC 425414",
          "USNO-B1.0 1327-00425262",
          "BD+42 3338",
          "AG+42 1698",
          "AN 34.1901",
          "G 125-7",
          "GAT 791",
          "GC 26836",
          "GCRV 11873",
          "GEN# +1.00182989",
          "GSC 03142-00494",
          "HD 182989",
          "HIC 95497",
          "LTT 15677",
          "NLTT 47799",
          "PPM 58291",
          "SAO 48421",
          "SKY# 36151",
          "SV* HV 180",
          "TD1 24704",
          "TYC 3142-494-1",
          "USNO 886",
          "V* RR Lyr",
          "PLX 4550.00",
          "uvby98 100182989",
          "WEB 16701",
          "Gaia DR2 2125982599341232896",
          "2MASS J19252793+4247040",
          "KIC 7198959"
        ],
        "searchKey": "lspmj19254247|gen100182989|svhv180|2massj192527934247040|sky36151|sao48421|gat791|plx4550|gsc0314200494|usnob10132700425262|nltt47799|g1257|tyc31424941|tic159717514|web16701|ag421698|gcrv11873|hic95497|td124704|gaiadr22125982599341232896|usno886|gc26836|ltt15677|kic7198959|ascc425414|apj192527934247040|hip95497|bd423338|plx455000|ppm58291|uvby98100182989|hd182989|an341901|gaiadr32125982599343482624|vrrlyr|nsvs5543125",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ROSE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 19.424420266814,
        "dec": 42.78435923839,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "V* RR Lyr",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950092792702500866",
        "name": "* del Cep",
        "names": [
          "AP J22291029+5824549",
          "Gaia DR3 2200153454733285248",
          "WISE J222910.75+582452.5",
          "UCAC4 743-079541",
          "Gaia DR2 2200153454723522432",
          "TIC 415741355",
          "PLX 5443",
          "* 27 Cep",
          "* del Cep",
          "AAVSO 2225+57",
          "ADS 15987 A",
          "AG+58 1460",
          "BD+57 2548",
          "CCDM J22292+5825A",
          "CEL 5480",
          "CSI+57 2548 1",
          "FK5 847",
          "GC 31421",
          "GCRV 14138",
          "GEN# +1.00213306",
          "GSC 03995-01479",
          "HD 213306",
          "HIC 110991",
          "HIP 110991",
          "HR 8571",
          "IDS 22254+5754 A",
          "IRAS 22273+5809",
          "IRC +60356",
          "N30 4955",
          "PMC 90-93 594",
          "PPM 40731",
          "ROT 3272",
          "SAO 34508",
          "SKY# 42788",
          "TD1 29132",
          "TYC 3995-1479-1",
          "UBV 21546",
          "V* del Cep",
          "2MASS J22291029+5824549",
          "WDS J22292+5825A",
          "uvby98 100213306",
          "WEB 19873"
        ],
        "searchKey": "wdsj222925825a|sao34508|tyc399514791|n304955|bd572548|cel5480|uvby98100213306|2massj222910295824549|ads15987a|ids222545754a|apj222910295824549|ag581460|vdelcep|delcep|tic415741355|27cep|plx5443|gaiadr32200153454733285248|web19873|gen100213306|ccdmj222925825a|hd213306|gsc0399501479|pmc9093594|rot3272|sky42788|hip110991|td129132|fk5847|irc60356|ppm40731|hic110991|hr8571|aavso222557|wisej222910755824525|gc31421|ubv21546|csi5725481|iras222735809|gcrv14138|gaiadr22200153454723522432|ucac4743079541",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 22.48618407334206,
        "dec": 58.41519378156944,
        "fluxV": 3.75,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* del Cep",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950092792845336578",
        "name": "Algol",
        "names": [
          "GJ 9110 A",
          "GJ 9110",
          "HIP 14576",
          "Gaia DR2 239863001382455424",
          "TIC 346783960",
          "PLX 646",
          "SBC9 158",
          "SBC9 157",
          "* bet Per",
          "* 26 Per",
          "1RXS J030810.0+405727",
          "1XRS 03049+407",
          "2E 724",
          "2RE J0308+405",
          "2RE J030809+405736",
          "AAVSO 0301+40",
          "AG+40 360",
          "BD+40 673",
          "CEL 301",
          "CSI+40 673 1",
          "FK5 111",
          "GAT 1267",
          "GC 3733",
          "GCRV 1731",
          "GEN# +1.00019356",
          "H 0307+40",
          "HD 19356",
          "HIC 14576",
          "HR 936",
          "IRAS 03048+4045",
          "IRC +40055",
          "JP11 752",
          "N30 636",
          "NAME Algol",
          "PMC 90-93 80",
          "PPM 45864",
          "RAFGL 443",
          "RE J030810+405734",
          "RE J0308+405",
          "ROT 417",
          "SAO 38592",
          "SKY# 4684",
          "TD1 1905",
          "UBV 21464",
          "UBV M 9337",
          "V* bet Per",
          "XSS J03089+4101",
          "[B10] 708",
          "[BM83] X0304+407",
          "1E 0304.8+4045",
          "1ES 0304+40.7",
          "2E 0304.9+4045",
          "2EUVE J0308+40.9",
          "EUVE J0308+40.9",
          "RX J0308.1+4057",
          "2MASS J03081012+4057204",
          "TYC 2851-2168-1",
          "* bet Per B",
          "* bet Per C",
          "SBC7 114",
          "SBC7 113",
          "* bet Per A",
          "NAME Algol B",
          "NAME Algol C",
          "WDS J03082+4057A",
          "IDS 03017+4034 A",
          "CCDM J03082+4057A",
          "WDS J03082+4057Aa,Ab",
          "** LAB 2",
          "** BU 526A",
          "ADS 2362 A",
          "PMSC 03017+4034A",
          "2MAXI J0308+410",
          "NAME Algol A",
          "WEB 2833",
          "WEB 2834"
        ],
        "searchKey": "ppm45864|rxj030814057|2e724|hr936|2e030494045|gaiadr2239863001382455424|bm83x0304407|wdsj030824057a|2rej0308405|hd19356|b10708|web2834|xssj030894101|lab2|algola|sao38592|pmsc030174034a|tyc285121681|2massj030810124057204|2maxij0308410|rej0308405|plx646|jp11752|ads2362a|web2833|csi406731|pmc909380|bd40673|ids030174034a|aavso030140|rot417|gj9110a|algol|sbc9158|ubvm9337|sbc9157|1es0304407|algol|irc40055|sky4684|algolb|iras030484045|td11905|n30636|h030740|betper|cel301|1e030484045|1xrs03049407|sbc7113|1rxsj0308100405727|ag40360|hip14576|gc3733|sbc7114|hic14576|betpera|algolc|2euvej0308409|bu526a|2rej030809405736|gat1267|betperb|vbetper|gcrv1731|26per|euvej0308409|gj9110|ccdmj030824057a|fk5111|wdsj030824057aaab|betperc|rafgl443|tic346783960|ubv21464|gen100019356|rej030810405734",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "CYAN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 3.13614790375,
        "dec": 40.95564667027778,
        "fluxV": 2.12,
        "sizeMajor": 0.0167,
        "sizeMinor": null,
        "sizeAngle": 123.0,
        "simbadName": "* bet Per",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950092792952553474",
        "name": "Mira",
        "names": [
          "HIP 10826",
          "4XMM J021920.7-025841",
          "TIC 332890609",
          "PLX 477",
          "* 68 Cet",
          "* omi Cet",
          "AAVSO 0214-03",
          "BD-03 353",
          "CSI-03 353 1",
          "DO 430",
          "EIC 37",
          "EM* MWC 35",
          "GC 2796",
          "GCRV 1301",
          "GSC 04693-01144",
          "HD 14386",
          "HIC 10826",
          "HR 681",
          "IRAS 02168-0312",
          "IRC +00030",
          "JP11 625",
          "LTT 1179",
          "NAME Mira",
          "NLTT 7657",
          "PPM 184482",
          "RAFGL 318",
          "SAO 129825",
          "SKY# 3428",
          "TD1 1361",
          "TYC 4693-1144-1",
          "UBV 21604",
          "V* omi Cet",
          "YZ 93 562",
          "[LFO93] 0216-03",
          "[PCC93] 21",
          "ASAS J021920-0258.6",
          "PLX 477.00",
          "JCMTSF J021920.8-025838",
          "JCMTSE J021920.8-025838",
          "** BU 1371A",
          "WDS J02193-0259A",
          "UCAC3 175-6614",
          "UCAC4 436-002922",
          "2MASS J02192081-0258393",
          "[WCP90] 021649.076-031222.60",
          "PLCKERC -857 G167.74-57.97",
          "WEB 2282",
          "ASAS J021921-0258.8",
          "GEN# +1.00014386",
          "* omi Cet A",
          "WDS J02193-0259Aa",
          "NAME Mira A",
          "** JOY 1Aa",
          "CCDM J02194-0258A",
          "ADS 1778 A",
          "Gaia DR2 2493390319631662720",
          "3XLSS J021920.8-025840"
        ],
        "searchKey": "wdsj021930259aa|pcc9321|omiceta|jcmtsej0219208025838|asasj02192002586|ads1778a|iras021680312|irc00030|ppm184482|hip10826|hr681|3xlssj0219208025840|hd14386|tyc469311441|gen100014386|web2282|asasj02192102588|plx477|tic332890609|mira|ucac31756614|ccdmj021940258a|csi033531|nltt7657|gsc0469301144|ubv21604|aavso021403|jcmtsfj0219208025838|hic10826|plx47700|ltt1179|wcp9002164907603122260|bu1371a|joy1aa|2massj021920810258393|lfo93021603|vomicet|do430|68cet|jp11625|gaiadr22493390319631662720|emmwc35|sky3428|bd03353|4xmmj0219207025841|sao129825|omicet|ucac4436002922|gc2796|plckerc857g167745797|wdsj021930259a|mira|td11361|yz93562|rafgl318|gcrv1301|miraa|eic37",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.32244225,
        "dec": -2.977637666666667,
        "fluxV": 6.53,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* omi Cet",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950092793052299266",
        "name": "T Tau A",
        "names": [
          "HIP 20390",
          "SCOPE G176.23-20.89",
          "Gaia DR3 48192969034959232",
          "TIC 17308640",
          "[ABG2007] 473",
          "2MASS J04215943+1932063",
          "XEST 01-OM-039",
          "XEST 01-045",
          "[GBA2007] 854",
          "1E 0419.0+1925",
          "2E 990",
          "2E 0419.0+1925",
          "AAVSO 0416+19",
          "AG+19 341",
          "BD+19 706",
          "GCRV 2524",
          "HBC 35",
          "HD 284419",
          "HIC 20390",
          "IRAS 04190+1924",
          "JP11 3794",
          "PPM 119824",
          "RAFGL 5121",
          "UBV M 28947",
          "V* T Tau",
          "VDB 28",
          "[D98] 34",
          "PLX 961.01",
          "1RXS J042200.5+193209",
          "PSCz P04190+1924",
          "WDS J04220+1932AB",
          "TYC 1272-470-1",
          "HH 355",
          "AKARI-IRC-V1 J0421594+193205",
          "UCAC2 38742060",
          "WISE J042159.44+193205.9",
          "2XMM J042159.4+193206",
          "EPIC 210777987",
          "GBS-VLA J042159.43+193205.7",
          "WISE J042159.45+193205.9",
          "Gaia DR1 48192969034959232",
          "WEB 3899",
          "Gaia DR2 48192969034959232",
          "EPIC 210777988",
          "uvby98 100284419",
          "** GHE 6",
          "GSC 01272-00470",
          "NAME T Tau A",
          "NAME T Tau N",
          "[SSZ84] T Tau N",
          "UCAC4 548-009300",
          "[SSZ84] T Tau Sb",
          "NAME T Tau S",
          "[SSZ84] T Tau Sa",
          "NAME T Tau IRC",
          "[SSZ84] T Tau S",
          "JCMTSE J042159.3+193206",
          "JCMTSF J042159.3+193206"
        ],
        "searchKey": "jcmtsej0421593193206|hic20390|ucac238742060|2xmmj0421594193206|ttaus|ssz84ttaus|ssz84ttausa|uvby98100284419|rafgl5121|gsc0127200470|2e990|hip20390|ttaua|ag19341|gbsvlaj042159431932057|ssz84ttaun|epic210777987|xest01045|tyc12724701|ttauirc|jcmtsfj0421593193206|wisej042159451932059|jp113794|gaiadr248192969034959232|epic210777988|2massj042159431932063|tic17308640|gaiadr148192969034959232|bd19706|hd284419|iras041901924|1e041901925|scopeg176232089|wdsj042201932ab|hbc35|xest01om039|akariircv1j0421594193205|ttaua|2e041901925|ucac4548009300|plx96101|gaiadr348192969034959232|hh355|aavso041619|psczp041901924|abg2007473|d9834|ttaun|ppm119824|gba2007854|ghe6|web3899|vttau|ssz84ttausb|vdb28|ubvm28947|wisej042159441932059|1rxsj0422005193209|gcrv2524",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 4.366508885388666,
        "dec": 19.53512010926,
        "fluxV": 10.12,
        "sizeMajor": 0.8,
        "sizeMinor": 0.57,
        "sizeAngle": 154.0,
        "simbadName": "V* T Tau",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  }
];

export const PUBLIC_LISTS = [
  {
    "id": "978959208544698369",
    "title": "AstroBackyard",
    "color": "RED",
    "credit": "https://astrobackyard.com/learn-astrophotography/",
    "imgURL": "/list_icons/backyard-astro.jpg",
    "type": "PUBLIC_LIST",
    "objects": [
      {
        "id": "944241952512442369",
        "name": "Andromeda Galaxy",
        "names": [
          "PLX 124.00",
          "PPM 43228",
          "HD 3969",
          "BD+40 148",
          "AG+40 60",
          "PLX 124",
          "2C 56",
          "DA 21",
          "GIN 801",
          "IRAS F00400+4059",
          "IRAS 00400+4059",
          "IRC +40013",
          "K79 1C",
          "LEDA 2557",
          "M 31",
          "MCG+07-02-016",
          "NAME Andromeda",
          "NAME Andromeda Galaxy",
          "NGC 224",
          "RAFGL 104",
          "UGC 454",
          "XSS J00425+4102",
          "Z 535-17",
          "Z 0040.0+4100",
          "[DGW65] 4",
          "2MASX J00424433+4116074",
          "[M98c] 004000.1+405943",
          "[VV2000c] J004244.3+411610",
          "[VV2003c] J004244.3+411610",
          "[VV2006] J004244.3+411610",
          "[VV98c] J004245.1+411622",
          "UZC J004244.3+411608",
          "[VV2010] J004244.3+411610",
          "NAME And Nebula",
          "NAME Andromeda Nebula",
          "2MAXI J0043+412",
          "3FGL J0042.5+4117",
          "2FGL J0042.5+4114",
          "4FGL J0043.2+4114"
        ],
        "searchKey": "da21|dgw654|bd40148|2maxij0043412|2fglj004254114|hd3969|irasf004004059|z53517|m31|xssj004254102|iras004004059|vv2000cj0042443411610|4fglj004324114|andromeda|vv2006j0042443411610|plx12400|mcg0702016|ag4060|rafgl104|uzcj0042443411608|leda2557|ugc454|plx124|2c56|2masxj004244334116074|m98c0040001405943|irc40013|andromedanebula|andromedagalaxy|vv2010j0042443411610|vv2003cj0042443411610|z004004100|andnebula|3fglj004254117|ppm43228|k791c|andromedagalaxy|gin801|ngc224|vv98cj0042451411622",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/M31_09-01-2011_%28cropped%29.jpg/435px-M31_09-01-2011_%28cropped%29.jpg",
        "ra": 0.7123138888888888,
        "dec": 41.26875,
        "fluxV": 3.44,
        "sizeMajor": 199.53,
        "sizeMinor": 70.79,
        "sizeAngle": 35.0,
        "simbadName": "M 31",
        "imgCredit": "Torben Hansen",
        "description": "The Andromeda Galaxy is a barred spiral galaxy and is the nearest major galaxy to the Milky Way. It was originally named the Andromeda Nebula and is cataloged as Messier 31, M31, and NGC 224. Andromeda has a diameter of about 46.56 kiloparsecs (152,000 light-years) and is approximately 765 kpc (2.5 million light-years) from Earth. The galaxy's name stems from the area of Earth's sky in which it appears, the constellation of Andromeda, which itself is named after the princess who was the wife of Perseus in Greek mythology.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Andromeda_Galaxy"
      },
      {
        "id": "944241955830530049",
        "name": "Orion Nebula",
        "names": [
          "3C 145",
          "4C -05.21",
          "CTA 37",
          "LBN 209.13-19.35",
          "LBN 974",
          "M 42",
          "MSH 05-0-11",
          "Mills 05+0A",
          "NAME Great Orion Nebula",
          "NAME Ori Nebula",
          "NAME Orion Nebula",
          "NGC 1976",
          "NRL 6",
          "PKS 0532-054",
          "PKS 0532-05",
          "XSS J05351-0519",
          "[DGW65] 26",
          "[PT56] 6",
          "[WCO2009] J053517-052326",
          "GAL 209.01-19.4",
          "[KPS2012] MWSC 0582"
        ],
        "searchKey": "msh05011|orionnebula|pt566|4c0521|3c145|pks0532054|pks053205|ngc1976|dgw6526|lbn974|cta37|gal20901194|kps2012mwsc0582|wco2009j053517052326|lbn209131935|greatorionnebula|m42|xssj053510519|orinebula|orionnebula|nrl6|mills050a",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Orion_Nebula_-_Hubble_2006_mosaic_18000.jpg/450px-Orion_Nebula_-_Hubble_2006_mosaic_18000.jpg",
        "ra": 5.588,
        "dec": -5.390000000000001,
        "fluxV": null,
        "sizeMajor": 66.0,
        "sizeMinor": 66.0,
        "sizeAngle": 90.0,
        "simbadName": "M 42",
        "imgCredit": "NASA, ESA, M. Robberto",
        "description": "The Orion Nebula (also known as Messier 42, M42, or NGC 1976) is a diffuse nebula situated in the Milky Way, being south of Orion's Belt in the constellation of Orion, and is known as the middle \"star\" in the \"sword\" of Orion. It is one of the brightest nebulae and is visible to the naked eye in the night sky with apparent magnitude 4.0. It is 1,344 \u00b1 20 light-years (412.1 \u00b1 6.1 pc) away and is the closest region of massive star formation to Earth. The M42 nebula is estimated to be 24 light-years across (so its apparent size from Earth is approximately 1 degree). It has a mass of about 2,000 times that of the Sun. Older texts frequently refer to the Orion Nebula as the Great Nebula in Orion or the Great Orion Nebula.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Orion_Nebula"
      },
      {
        "id": "944241958223970305",
        "name": "Rosette Nebula",
        "names": [
          "GPA 206.29-2.35",
          "Zel 0629+050",
          "MSH 06+0-08",
          "[DGW65] 37",
          "ASB 27",
          "3C 163",
          "DA 211",
          "Cul 0629+049",
          "[GS55] 97",
          "LBN 949",
          "LBN 206.39-01.87",
          "[S50] 29",
          "LBN 948",
          "CTB 21",
          "SH 2-275",
          "GRS G206.20 -01.80",
          "GRS G206.40 -02.40",
          "W 16",
          "NAME Rosette Nebula",
          "NAME Skull Nebula"
        ],
        "searchKey": "sh2275|s5029|rosettenebula|lbn948|3c163|asb27|msh06008|zel0629050|rosettenebula|lbn206390187|gpa20629235|grsg206400240|da211|ctb21|w16|grsg206200180|gs5597|dgw6537|skullnebula|lbn949|cul0629049",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Rosette_Nebula_Narrowband_SHO_focal_length_384mm_Stephan_Hamel.jpg/435px-Rosette_Nebula_Narrowband_SHO_focal_length_384mm_Stephan_Hamel.jpg",
        "ra": 6.540766666666666,
        "dec": 4.793638888888888,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Rosette Nebula",
        "imgCredit": "Stephan Hamel",
        "description": "The Rosette Nebula (also known as Caldwell 49) is an H II region located near one end of a giant molecular cloud in the Monoceros region of the Milky Way Galaxy. The open cluster NGC 2244 (Caldwell 50) is closely associated with the nebulosity, the stars of the cluster having been formed from the nebula's matter.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Rosette_Nebula"
      },
      {
        "id": "944241968814784513",
        "name": "Horsehead Nebula",
        "names": [
          "Barnard 33",
          "M3T 31",
          "[OS98] 52",
          "[DB2002b] G206.95-16.80",
          "NAME Horsehead Nebula"
        ],
        "searchKey": "m3t31|horseheadnebula|barnard33|horseheadnebula|os9852|db2002bg206951680",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.683055555555556,
        "dec": -2.458333333333333,
        "fluxV": null,
        "sizeMajor": 6.0,
        "sizeMinor": 4.0,
        "sizeAngle": 90.0,
        "simbadName": "NAME Horsehead Nebula",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241976047599617",
        "name": "Eagle Nebula",
        "names": [
          "Villafranca O-019",
          "C 1816-138",
          "CTB 51",
          "LBN 016.96+00.78",
          "LBN 67",
          "LMH 22",
          "M 16",
          "MM 21",
          "NGC 6611",
          "NRL 14",
          "OCISM 10",
          "OCl 54",
          "[KPR2004b] 441",
          "IC 4703",
          "GRS G017.00 +00.30",
          "[KPS2012] MWSC 2886",
          "NAME Eagle Nebula",
          "NAME Star Queen"
        ],
        "searchKey": "kpr2004b441|eaglenebula|villafrancao019|kps2012mwsc2886|lmh22|ocism10|ocl54|lbn67|grsg017000030|nrl14|ngc6611|lbn016960078|eaglenebula|mm21|starqueen|c1816138|ctb51|m16|ic4703",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.31252777777778,
        "dec": -13.79194444444444,
        "fluxV": null,
        "sizeMajor": 80.0,
        "sizeMinor": 80.0,
        "sizeAngle": null,
        "simbadName": "M 16",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241978420101121",
        "name": "Lagoon Nebula",
        "names": [
          "Villafranca O-018",
          "CTB 46",
          "Kes 58",
          "LBN 006.06-01.23",
          "LBN 25",
          "LMH 11",
          "M 8",
          "MM 10",
          "Mol 37",
          "NAME Lagoon",
          "NAME Lagoon Nebula",
          "NRL 11",
          "OCISM 1",
          "PMN J1803-2422",
          "SH 2-25",
          "[KC97c] G006.0-01.2",
          "[L89b] 5.973-01.178",
          "GRS G006.00 -01.20",
          "GRS G006.20 -01.20",
          "W 29"
        ],
        "searchKey": "sh225|lagoon|grsg006000120|ctb46|lmh11|mol37|pmnj18032422|w29|mm10|lagoonnebula|l89b597301178|grsg006200120|lagoonnebula|nrl11|kes58|lbn25|lbn006060123|m8|villafrancao018|kc97cg0060012|ocism1",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.06027777777778,
        "dec": -24.38666666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 8",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242361449775105",
        "name": "Leo Triplet",
        "names": [
          "APG 317",
          "NAME Leo Triplet"
        ],
        "searchKey": "leotriplet|leotriplet|apg317",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/LeoTripletHunterWilson.jpg/450px-LeoTripletHunterWilson.jpg",
        "ra": 11.31666666666667,
        "dec": 13.2,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Leo Triplet",
        "imgCredit": "Hewholooks",
        "description": "The Leo Triplet (also known as the M66 Group) is a small group of galaxies about 35 million light-years away in the constellation Leo. This galaxy group consists of the spiral galaxies M65, M66, and NGC 3628.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Leo_Triplet"
      },
      {
        "id": "944242718523392001",
        "name": "M 84",
        "names": [
          "VLSSr J122505.0+125318",
          "GLEAM J122504+125308",
          "TGSSADR J122504.2+125309",
          "[BFA97] 44",
          "HD 108091",
          "FAUST 3119",
          "BD+13 2531",
          "WSTB 29W3",
          "ACSVCS 6",
          "ICRF J122503.7+125313",
          "IERS B1222+131",
          "1Jy 1222+131",
          "1Jy 1222+13",
          "2E 2696",
          "3C 272.1",
          "4C 13.47",
          "ASB 33",
          "Cul 1222+131",
          "DA 322",
          "GIN 778",
          "IRAS 12224+1309",
          "IRAS F12225+1309",
          "LEDA 40455",
          "LJHY 35",
          "M 84",
          "MCG+02-32-034",
          "MRC 1222+131",
          "NGC 4374",
          "NRAO 399",
          "NVSS B122231+130912",
          "OHIO A 322",
          "PKS 1222+13",
          "PKS 1223+132",
          "PKS 1222+131",
          "RX J1225.0+1253",
          "S3 1222+13",
          "UGC 7494",
          "VCC 763",
          "VPC 385",
          "VSOP J1225+1253",
          "Z 70-58",
          "[DLB87] V3",
          "[DML87] 746",
          "[DSB94] 272",
          "[TH2002] 5",
          "[TT2002] Virgo 2",
          "2MASX J12250377+1253130",
          "[FWB89] Galaxy 255",
          "[GW2008] CoNFIG 135",
          "JCMTSE J122503.6+125314",
          "2E 1222.5+1309",
          "JCMTSF J122503.6+125314",
          "RX J122503.7+125313",
          "UZC J122503.7+125314",
          "Z 1222.5+1310",
          "[CHM2007] HDC 720 J122503.77+1253130  [CHM2007] LDC 904 J122503.77+1253130",
          "[VV2003c] J122503.7+125313",
          "[VV2006c] J122503.7+125313",
          "[BEC2010] HRS 138",
          "SDSS J122503.74+125312.8",
          "[VV2010c] J122503.7+125313",
          "GB6 B1222+1309",
          "PKS J1225+1253",
          "WB 1222+1309",
          "87GB 122232.6+131000",
          "BWE 1222+1309",
          "MITG J122505+1252",
          "[CAB95] IRAS F12225+1309",
          "[DC78] UGC 7494",
          "VLSS J1225.0+1253",
          "SDSS J122503.03+125349.1",
          "EVCC 539"
        ],
        "searchKey": "bec2010hrs138|rxj1225037125313|ngc4374|vv2003cj1225037125313|z7058|dsb94272|dlb87v3|faust3119|vpc385|gleamj122504125308|iersb1222131|pks122213|gb6b12221309|bwe12221309|irasf122251309|3c2721|tt2002virgo2|m84|nrao399|ohioa322|pks1222131|iras122241309|nvssb122231130912|acsvcs6|2e2696|sdssj122503741253128|wb12221309|bd132531|gw2008config135|asb33|s3122213|pksj12251253|cab95irasf122251309|4c1347|rxj122501253|jcmtsfj1225036125314|vcc763|sdssj122503031253491|mcg0232034|mitgj1225051252|uzcj1225037125314|bfa9744|dc78ugc7494|hd108091|1jy122213|vv2006cj1225037125313|pks1223132|jcmtsej1225036125314|vv2010cj1225037125313|da322|87gb1222326131000|2e122251309|1jy1222131|vsopj12251253|dml87746|tgssadrj1225042125309|chm2007hdc720j122503771253130chm2007ldc904j122503771253130|icrfj1225037125313|cul1222131|z122251310|vlssj122501253|evcc539|ljhy35|mrc1222131|ugc7494|th20025|vlssrj1225050125318|gin778|2masxj122503771253130|fwb89galaxy255|wstb29w3|leda40455",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.41770648055556,
        "dec": 12.88698313888889,
        "fluxV": 10.49,
        "sizeMajor": 6.46,
        "sizeMinor": 5.75,
        "sizeAngle": 133.0,
        "simbadName": "M 84",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "948663126517514241",
        "name": "Flaming Star Nebula",
        "names": [
          "CTB 16",
          "IC 405",
          "LBN 172.16-02.02",
          "LBN 795",
          "NAME Flaming Star Nebula"
        ],
        "searchKey": "flamingstarnebula|lbn795|ic405|flamingstarnebula|ctb16|lbn172160202",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.29,
        "dec": 34.38333333333333,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "IC 405",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "949161339607580673",
        "name": "Crescent Nebula",
        "names": [
          "LBN 203",
          "LBN 075.51+02.29",
          "NGC 6888",
          "[T70] 32",
          "NAME Crescent Nebula"
        ],
        "searchKey": "lbn203|crescentnebula|t7032|crescentnebula|lbn075510229|ngc6888",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.20194444444444,
        "dec": 38.355,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 6888",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950090578285133826",
        "name": "Cave Nebula",
        "names": [
          "Ced 201",
          "DG 179",
          "LBN 110.25+11.38",
          "LBN 531",
          "NAME Cave Nebula",
          "[B77] 45",
          "[RK68] 117"
        ],
        "searchKey": "lbn531|cavenebula|cavenebula|dg179|rk68117|b7745|lbn110251138|ced201",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 22.22416666666667,
        "dec": 70.255,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Cave Nebula",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950090578361483266",
        "name": "BD+67 1300",
        "names": [
          "Gaia DR3 2222370083595582592",
          "TIC 422431768",
          "BD+67 1300",
          "GEN# +0.06701300",
          "GSC 04461-00645",
          "TYC 4461-645-1",
          "UBV M 4827",
          "VDB 141",
          "2MASS J21162632+6815366",
          "Gaia DR1 2222370079297770752",
          "Gaia DR2 2222370083595582592"
        ],
        "searchKey": "gaiadr32222370083595582592|bd671300|gsc0446100645|ubvm4827|2massj211626326815366|gaiadr12222370079297770752|gen006701300|tyc44616451|tic422431768|vdb141|gaiadr22222370083595582592",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.27398019249667,
        "dec": 68.26015496375,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "BD+67 1300",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950090661505040386",
        "name": "IC 1396",
        "names": [
          "[KC2019] Theia 9",
          "C 2137+572",
          "CTB 105",
          "Cl Collinder 439",
          "IC 1396",
          "OCISM 46",
          "OCl 222",
          "OCl 222.0",
          "[DGW65] 144",
          "[KPR2004b] 500",
          "Cl Trumpler 37",
          "[KPS2012] MWSC 3539"
        ],
        "searchKey": "ocl222|dgw65144|kpr2004b500|ic1396|kps2012mwsc3539|ocism46|cltrumpler37|ctb105|c2137572|clcollinder439|kc2019theia9|ocl2220",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.64966666666666,
        "dec": 57.51388888888889,
        "fluxV": null,
        "sizeMajor": 146.0,
        "sizeMinor": 146.0,
        "sizeAngle": null,
        "simbadName": "IC 1396",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959201825456129",
    "title": "Go Astronomy - Top 20",
    "color": "PURPLE",
    "credit": "https://skyandtelescope.org/observing/deep-sky-naked-eye/",
    "imgURL": "/list_icons/go-astro.jpg",
    "type": "PUBLIC_LIST",
    "objects": [
      {
        "id": "944241952512442369",
        "name": "Andromeda Galaxy",
        "names": [
          "PLX 124.00",
          "PPM 43228",
          "HD 3969",
          "BD+40 148",
          "AG+40 60",
          "PLX 124",
          "2C 56",
          "DA 21",
          "GIN 801",
          "IRAS F00400+4059",
          "IRAS 00400+4059",
          "IRC +40013",
          "K79 1C",
          "LEDA 2557",
          "M 31",
          "MCG+07-02-016",
          "NAME Andromeda",
          "NAME Andromeda Galaxy",
          "NGC 224",
          "RAFGL 104",
          "UGC 454",
          "XSS J00425+4102",
          "Z 535-17",
          "Z 0040.0+4100",
          "[DGW65] 4",
          "2MASX J00424433+4116074",
          "[M98c] 004000.1+405943",
          "[VV2000c] J004244.3+411610",
          "[VV2003c] J004244.3+411610",
          "[VV2006] J004244.3+411610",
          "[VV98c] J004245.1+411622",
          "UZC J004244.3+411608",
          "[VV2010] J004244.3+411610",
          "NAME And Nebula",
          "NAME Andromeda Nebula",
          "2MAXI J0043+412",
          "3FGL J0042.5+4117",
          "2FGL J0042.5+4114",
          "4FGL J0043.2+4114"
        ],
        "searchKey": "da21|dgw654|bd40148|2maxij0043412|2fglj004254114|hd3969|irasf004004059|z53517|m31|xssj004254102|iras004004059|vv2000cj0042443411610|4fglj004324114|andromeda|vv2006j0042443411610|plx12400|mcg0702016|ag4060|rafgl104|uzcj0042443411608|leda2557|ugc454|plx124|2c56|2masxj004244334116074|m98c0040001405943|irc40013|andromedanebula|andromedagalaxy|vv2010j0042443411610|vv2003cj0042443411610|z004004100|andnebula|3fglj004254117|ppm43228|k791c|andromedagalaxy|gin801|ngc224|vv98cj0042451411622",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/M31_09-01-2011_%28cropped%29.jpg/435px-M31_09-01-2011_%28cropped%29.jpg",
        "ra": 0.7123138888888888,
        "dec": 41.26875,
        "fluxV": 3.44,
        "sizeMajor": 199.53,
        "sizeMinor": 70.79,
        "sizeAngle": 35.0,
        "simbadName": "M 31",
        "imgCredit": "Torben Hansen",
        "description": "The Andromeda Galaxy is a barred spiral galaxy and is the nearest major galaxy to the Milky Way. It was originally named the Andromeda Nebula and is cataloged as Messier 31, M31, and NGC 224. Andromeda has a diameter of about 46.56 kiloparsecs (152,000 light-years) and is approximately 765 kpc (2.5 million light-years) from Earth. The galaxy's name stems from the area of Earth's sky in which it appears, the constellation of Andromeda, which itself is named after the princess who was the wife of Perseus in Greek mythology.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Andromeda_Galaxy"
      },
      {
        "id": "944241955830530049",
        "name": "Orion Nebula",
        "names": [
          "3C 145",
          "4C -05.21",
          "CTA 37",
          "LBN 209.13-19.35",
          "LBN 974",
          "M 42",
          "MSH 05-0-11",
          "Mills 05+0A",
          "NAME Great Orion Nebula",
          "NAME Ori Nebula",
          "NAME Orion Nebula",
          "NGC 1976",
          "NRL 6",
          "PKS 0532-054",
          "PKS 0532-05",
          "XSS J05351-0519",
          "[DGW65] 26",
          "[PT56] 6",
          "[WCO2009] J053517-052326",
          "GAL 209.01-19.4",
          "[KPS2012] MWSC 0582"
        ],
        "searchKey": "msh05011|orionnebula|pt566|4c0521|3c145|pks0532054|pks053205|ngc1976|dgw6526|lbn974|cta37|gal20901194|kps2012mwsc0582|wco2009j053517052326|lbn209131935|greatorionnebula|m42|xssj053510519|orinebula|orionnebula|nrl6|mills050a",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Orion_Nebula_-_Hubble_2006_mosaic_18000.jpg/450px-Orion_Nebula_-_Hubble_2006_mosaic_18000.jpg",
        "ra": 5.588,
        "dec": -5.390000000000001,
        "fluxV": null,
        "sizeMajor": 66.0,
        "sizeMinor": 66.0,
        "sizeAngle": 90.0,
        "simbadName": "M 42",
        "imgCredit": "NASA, ESA, M. Robberto",
        "description": "The Orion Nebula (also known as Messier 42, M42, or NGC 1976) is a diffuse nebula situated in the Milky Way, being south of Orion's Belt in the constellation of Orion, and is known as the middle \"star\" in the \"sword\" of Orion. It is one of the brightest nebulae and is visible to the naked eye in the night sky with apparent magnitude 4.0. It is 1,344 \u00b1 20 light-years (412.1 \u00b1 6.1 pc) away and is the closest region of massive star formation to Earth. The M42 nebula is estimated to be 24 light-years across (so its apparent size from Earth is approximately 1 degree). It has a mass of about 2,000 times that of the Sun. Older texts frequently refer to the Orion Nebula as the Great Nebula in Orion or the Great Orion Nebula.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Orion_Nebula"
      },
      {
        "id": "944241965995786241",
        "name": "Pleiades",
        "names": [
          "[KC2019] Theia 369",
          "C 0344+239",
          "H 0346+24",
          "M 45",
          "NAME Pleiades",
          "NAME Seven Sisters",
          "OCl 421.0",
          "[KPR2004b] 47",
          "Cl Melotte 22",
          "[KPS2012] MWSC 0305"
        ],
        "searchKey": "pleiades|clmelotte22|kpr2004b47|c0344239|m45|pleiades|kc2019theia369|ocl4210|h034624|sevensisters|kps2012mwsc0305",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 3.773388888888889,
        "dec": 24.11388888888889,
        "fluxV": null,
        "sizeMajor": 76.86,
        "sizeMinor": 76.86,
        "sizeAngle": null,
        "simbadName": "Cl Melotte 22",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241978420101121",
        "name": "Lagoon Nebula",
        "names": [
          "Villafranca O-018",
          "CTB 46",
          "Kes 58",
          "LBN 006.06-01.23",
          "LBN 25",
          "LMH 11",
          "M 8",
          "MM 10",
          "Mol 37",
          "NAME Lagoon",
          "NAME Lagoon Nebula",
          "NRL 11",
          "OCISM 1",
          "PMN J1803-2422",
          "SH 2-25",
          "[KC97c] G006.0-01.2",
          "[L89b] 5.973-01.178",
          "GRS G006.00 -01.20",
          "GRS G006.20 -01.20",
          "W 29"
        ],
        "searchKey": "sh225|lagoon|grsg006000120|ctb46|lmh11|mol37|pmnj18032422|w29|mm10|lagoonnebula|l89b597301178|grsg006200120|lagoonnebula|nrl11|kes58|lbn25|lbn006060123|m8|villafrancao018|kc97cg0060012|ocism1",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.06027777777778,
        "dec": -24.38666666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 8",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241985593442305",
        "name": "Carina Nebula",
        "names": [
          "NAME Keyhole",
          "1E 1044.0-5940",
          "BRAN 316A",
          "GUM 33",
          "MHR 11",
          "MSH 10-5-07",
          "NGC 3372",
          "RCW 53",
          "NAME Car Nebula",
          "NAME Carina Nebula",
          "NAME eta Car Nebula",
          "NAME Keyhole Nebula"
        ],
        "searchKey": "carnebula|carinanebula|ngc3372|keyholenebula|mhr11|gum33|carinanebula|rcw53|msh10507|bran316a|1e104405940|keyhole|etacarnebula",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 10.75061944444444,
        "dec": -59.69994444444444,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 3372",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241988150820865",
        "name": "Trifid Nebula",
        "names": [
          "C 1759-230",
          "CSI-23-17593 1",
          "CTB 45",
          "LBN 006.99-00.17",
          "LBN 27",
          "LMH 12",
          "M 20",
          "MM 11",
          "MSH 17-2-16",
          "NAME Trifid Nebula",
          "NGC 6514",
          "NRL 10",
          "OCISM 2",
          "OCl 23.0",
          "[DGW65] 99",
          "[KPR2004b] 425",
          "[SC95] M 208",
          "[SC95] M 215",
          "Cl Collinder 360",
          "GRS G007.00 -00.30",
          "[KPS2012] MWSC 2789"
        ],
        "searchKey": "ocism2|kps2012mwsc2789|lmh12|sc95m208|ocl230|m20|dgw6599|c1759230|lbn006990017|kpr2004b425|mm11|csi23175931|ctb45|sc95m215|trifidnebula|ngc6514|msh17216|nrl10|lbn27|clcollinder360|trifidnebula|grsg007000030",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.045,
        "dec": -22.97166666666666,
        "fluxV": null,
        "sizeMajor": 28.0,
        "sizeMinor": 28.0,
        "sizeAngle": 90.0,
        "simbadName": "M 20",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242358486532097",
        "name": "Triangulum Galaxy",
        "names": [
          "1AXG J013351+3039",
          "2E 409",
          "2E 0131.0+3024",
          "HIJASS J0133+30",
          "LEDA 5818",
          "M 33",
          "MCG+05-04-069",
          "NAME Triangulum Galaxy",
          "NAME Triangulum Pinwheel",
          "NGC 598",
          "RBS 214",
          "RX J013351+30399",
          "TC 906",
          "UGC 1117",
          "Z 0131.0+3024",
          "Z 502-110",
          "2MASX J01335090+3039357",
          "1ES 0131+30.3",
          "1RXS J013350.9+303932",
          "[CHM2007] LDC 160 J013350.90+3039357  [M98c] 013101.7+302415",
          "UZC J013351.1+303922"
        ],
        "searchKey": "z502110|1es0131303|2masxj013350903039357|chm2007ldc160j013350903039357m98c0131017302415|hijassj013330|m33|1axgj0133513039|triangulumgalaxy|triangulumgalaxy|rbs214|leda5818|ugc1117|triangulumpinwheel|uzcj0133511303922|tc906|1rxsj0133509303932|rxj01335130399|2e013103024|z013103024|2e409|mcg0504069|ngc598",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "INDIGO",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/VST_snaps_a_very_detailed_view_of_the_Triangulum_Galaxy.jpg/375px-VST_snaps_a_very_detailed_view_of_the_Triangulum_Galaxy.jpg",
        "ra": 1.564137937478667,
        "dec": 30.66017511198,
        "fluxV": 5.72,
        "sizeMajor": 60.26,
        "sizeMinor": 35.48,
        "sizeAngle": 22.0,
        "simbadName": "M 33",
        "imgCredit": "ESO",
        "description": "The Triangulum Galaxy is a spiral galaxy 2.73 million light-years (ly) from Earth in the constellation Triangulum. It is catalogued as Messier 33 or NGC (New General Catalogue) 598. With the D25 isophotal diameter of 18.74 kiloparsecs (61,100 light-years), the Triangulum Galaxy is the third-largest member of the Local Group of galaxies, behind the Andromeda Galaxy and the Milky Way.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Triangulum_Galaxy"
      },
      {
        "id": "944242461896704001",
        "name": "M 4",
        "names": [
          "C 1620-264",
          "GCl 41",
          "M 4",
          "NGC 6121",
          "[KPS2012] MWSC 2396",
          "CD-26 11314",
          "GCRV 5569 E",
          "HD 147552"
        ],
        "searchKey": "ngc6121|gcl41|kps2012mwsc2396|hd147552|cd2611314|m4|c1620264|gcrv5569e",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.39311666666667,
        "dec": -26.52575,
        "fluxV": null,
        "sizeMajor": 26.3,
        "sizeMinor": 26.3,
        "sizeAngle": 90.0,
        "simbadName": "M 4",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242468436738049",
        "name": "M 6",
        "names": [
          "[KC2019] Theia 122",
          "C 1736-321",
          "M 6",
          "NGC 6405",
          "OCl 1030.0",
          "[KPR2004b] 408",
          "[SC95] M 38",
          "[SC95] M 43",
          "[SC96] GC 128",
          "[SC96] GC 130",
          "[SC96] GC 137",
          "[SC96] Mis 588",
          "[SC96] Mis 589",
          "[SC96] Mis 607",
          "Cl VDBH 242",
          "[KPS2012] MWSC 2661",
          "NAME Butterfly Cluster"
        ],
        "searchKey": "sc96mis589|c1736321|kc2019theia122|m6|kpr2004b408|sc95m43|butterflycluster|sc96gc137|kps2012mwsc2661|clvdbh242|ocl10300|sc96mis607|ngc6405|sc96gc128|sc96gc130|sc96mis588|sc95m38",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.67127777777778,
        "dec": -32.24194444444444,
        "fluxV": null,
        "sizeMajor": 20.0,
        "sizeMinor": 20.0,
        "sizeAngle": 90.0,
        "simbadName": "NGC 6405",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242470846922753",
        "name": "M 7",
        "names": [
          "[KC2019] Theia 692",
          "C 1750-348",
          "M 7",
          "NGC 6475",
          "OCl 1028.0",
          "[KPR2004b] 420",
          "[SC96] GC 228",
          "[SC96] GC 243",
          "[SC96] Mis 753",
          "[SC96] Mis 774",
          "Cl VDBH 254",
          "[KPS2012] MWSC 2739",
          "NAME Ptolemy's Cluster"
        ],
        "searchKey": "c1750348|kpr2004b420|sc96mis774|kps2012mwsc2739|clvdbh254|ptolemyscluster|ngc6475|kc2019theia692|sc96mis753|sc96gc243|ocl10280|m7|sc96gc228",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.89647222222222,
        "dec": -34.84111111111111,
        "fluxV": null,
        "sizeMajor": 100.1,
        "sizeMinor": 100.1,
        "sizeAngle": null,
        "simbadName": "NGC 6475",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242490594721793",
        "name": "M 13",
        "names": [
          "C 1639+365",
          "GCl 45",
          "M 13",
          "NGC 6205",
          "2MASX J16414163+3627407",
          "[KPS2012] MWSC 2445",
          "NAME Hercules Globular Cluster"
        ],
        "searchKey": "c1639365|gcl45|herculesglobularcluster|2masxj164141633627407|kps2012mwsc2445|m13|ngc6205",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.69489833333333,
        "dec": 36.46131944444445,
        "fluxV": 5.8,
        "sizeMajor": 33.0,
        "sizeMinor": 33.0,
        "sizeAngle": null,
        "simbadName": "M 13",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242518955294721",
        "name": "M 22",
        "names": [
          "C 1833-239",
          "CD-24 14516",
          "GCRV 11055",
          "GCl 99",
          "HD 171560",
          "M 22",
          "NGC 6656",
          "1E 1833.3-2357",
          "[KPS2012] MWSC 2961",
          "GCRV 6244 E"
        ],
        "searchKey": "c1833239|gcrv11055|gcl99|m22|hd171560|gcrv6244e|ngc6656|cd2414516|1e183332357|kps2012mwsc2961",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.60665,
        "dec": -23.90475,
        "fluxV": null,
        "sizeMajor": 24.0,
        "sizeMinor": 24.0,
        "sizeAngle": 90.0,
        "simbadName": "M 22",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242589320609793",
        "name": "M 44",
        "names": [
          "[KC2019] Theia 1184",
          "C 0837+201",
          "M 44",
          "NAME Beehive",
          "NAME Praesepe",
          "NGC 2632",
          "OCl 507.0",
          "[KPR2004b] 201",
          "Cl Melotte 88",
          "[KPS2012] MWSC 1527",
          "NAME Praesepe Cluster"
        ],
        "searchKey": "c0837201|ngc2632|kps2012mwsc1527|beehive|praesepecluster|m44|kc2019theia1184|ocl5070|praesepe|kpr2004b201|clmelotte88",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 8.670277777777777,
        "dec": 19.62111111111111,
        "fluxV": null,
        "sizeMajor": 118.2,
        "sizeMinor": 118.2,
        "sizeAngle": null,
        "simbadName": "NGC 2632",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "947766584711380993",
        "name": "h Per Cluster",
        "names": [
          "C 0215+569",
          "NGC 869",
          "OCISM 84",
          "OCl 350.0",
          "OCl 350",
          "Raab 9",
          "[KPR2004b] 29",
          "NAME h Per Cluster",
          "Cl Collinder 24",
          "Cl Melotte 13",
          "NAME h Persei Cluster",
          "[KPS2012] MWSC 0175"
        ],
        "searchKey": "ngc869|ocism84|raab9|hpercluster|c0215569|clmelotte13|ocl350|hperseicluster|kps2012mwsc0175|kpr2004b29|hpercluster|clcollinder24|ocl3500",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.316055555555555,
        "dec": 57.13388888888889,
        "fluxV": null,
        "sizeMajor": 16.3,
        "sizeMinor": 16.3,
        "sizeAngle": null,
        "simbadName": "NGC 869",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950076962260287490",
        "name": "Alpha Per Cluster",
        "names": [
          "[KC2019] Theia 133",
          "C 0318+484",
          "OCl 392.0",
          "[KPR2004b] 42",
          "Cl Melotte 20",
          "[KPS2012] MWSC 0274",
          "Cl Collinder 39",
          "NAME Alpha Per Cluster",
          "NAME Alpha Persei Cluster",
          "NAME alf Per Cluster"
        ],
        "searchKey": "kps2012mwsc0274|ocl3920|alfpercluster|alphapercluster|kpr2004b42|alphaperseicluster|clcollinder39|alphapercluster|kc2019theia133|c0318484|clmelotte20",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 3.441138888888889,
        "dec": 48.975,
        "fluxV": null,
        "sizeMajor": 243.2,
        "sizeMinor": 243.2,
        "sizeAngle": null,
        "simbadName": "Cl Melotte 20",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950076962335588354",
        "name": "Herschel's Jewel Box",
        "names": [
          "C 1250-600",
          "NAME Herschel's Jewel Box",
          "NAME Jewel Box",
          "NGC 4755",
          "[KPR2004b] 318",
          "Cl Melotte 114",
          "Cl VDBH 141",
          "[KPS2012] MWSC 2072",
          "NAME kap Cru Cluster",
          "NAME kappa Crucis Cluster"
        ],
        "searchKey": "herschelsjewelbox|kapcrucluster|c1250600|herschelsjewelbox|kappacruciscluster|clmelotte114|clvdbh141|kps2012mwsc2072|jewelbox|ngc4755|kpr2004b318",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.89433333333333,
        "dec": -60.37111111111111,
        "fluxV": null,
        "sizeMajor": 10.3,
        "sizeMinor": 10.3,
        "sizeAngle": null,
        "simbadName": "NGC 4755",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950076962351939586",
        "name": "Mizar",
        "names": [
          "HIP 65378",
          "ADS 8891 AB",
          "CSI+55 1598 3",
          "JP11 2391",
          "UBV 12102",
          "UBV M 19361",
          "WDS J13239+5456AB",
          "** STF 1744AB",
          "HIC 65378",
          "PMSC 13199+5527",
          "NAME Mizar",
          "* zet UMa",
          "* 79 UMa",
          "BD+55 1598",
          "IRC +60224",
          "AKARI-IRC-V1 J1323557+545529",
          "RAFGL 1621S",
          "IRAS 13219+5511",
          "uvby98 100116656",
          "1RXS J132356.5+545437"
        ],
        "searchKey": "ubv12102|hip65378|bd551598|wdsj132395456ab|uvby98100116656|mizar|pmsc131995527|jp112391|irc60224|rafgl1621s|1rxsj1323565545437|csi5515983|ubvm19361|iras132195511|ads8891ab|akariircv1j1323557545529|stf1744ab|zetuma|hic65378|mizar|79uma",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 13.39876124444444,
        "dec": 54.92535197222222,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "* zet UMa",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950076963008643074",
        "name": "Hyades Cluster",
        "names": [
          "[KC2019] Theia 1004",
          "C 0424+157",
          "OCl 456.0",
          "Cl Melotte 25",
          "NAME Hyades Cluster",
          "NAME Hyades",
          "Cl Collinder 50"
        ],
        "searchKey": "hyades|kc2019theia1004|clcollinder50|hyadescluster|clmelotte25|ocl4560|hyadescluster|c0424157",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 4.496472222222223,
        "dec": 16.94805555555556,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "Cl Melotte 25",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950076963069460482",
        "name": "ome Cen Cluster",
        "names": [
          "* ome Cen",
          "C 1323-472",
          "CD-46 8646",
          "CPD-46 6348",
          "GCRV 4762 E",
          "GCl 24",
          "HD 116790",
          "NGC 5139",
          "1E 1323.8-4713",
          "[KPS2012] MWSC 2120",
          "NAME ome Cen Cluster",
          "3FGL J1326.7-4727",
          "2FGL J1326.4-4729",
          "FL8Y J1326.7-4729",
          "4FGL J1326.6-4729"
        ],
        "searchKey": "cpd466348|1e132384713|omecencluster|ngc5139|2fglj132644729|cd468646|4fglj132664729|kps2012mwsc2120|omecen|3fglj132674727|gcrv4762e|omecencluster|c1323472|gcl24|hd116790|fl8yj132674729",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 13.44646666666667,
        "dec": -47.47947222222223,
        "fluxV": null,
        "sizeMajor": 36.3,
        "sizeMinor": 36.3,
        "sizeAngle": 90.0,
        "simbadName": "NGC 5139",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950076964489166850",
        "name": "SMC",
        "names": [
          "Anon 0051-73",
          "ESO 29-21",
          "GLXY G291.4-41.7+191",
          "LEDA 3085",
          "NAME SMC",
          "NGC 292",
          "XSS J00595-7303",
          "[M98c] 005053.0-730418",
          "[MI94] Im 6",
          "1FGL J0101.3-7257",
          "PBC J0102.7-7241",
          "2FGL J0059.0-7242e",
          "NAME Nubecula Minor",
          "NAME Small Magellanic Cloud",
          "3FGL J0059.0-7242e",
          "4FGL J0058.0-7245e"
        ],
        "searchKey": "m98c0050530730418|eso2921|nubeculaminor|leda3085|anon005173|smc|3fglj005907242e|smallmagellaniccloud|ngc292|1fglj010137257|pbcj010277241|4fglj005807245e|xssj005957303|2fglj005907242e|smc|glxyg2914417191|mi94im6",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 0.8772222222222222,
        "dec": -72.80027777777778,
        "fluxV": 2.2,
        "sizeMajor": 158.113,
        "sizeMinor": 93.105,
        "sizeAngle": 45.0,
        "simbadName": "NAME SMC",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959204031987713",
    "title": "Go Astronomy - Top 20 Wide Field",
    "color": "PURPLE",
    "credit": "https://www.go-astronomy.com/top20-astrophotography.htm",
    "imgURL": "/list_icons/go-astro.jpg",
    "type": "PUBLIC_LIST",
    "objects": [
      {
        "id": "944241952512442369",
        "name": "Andromeda Galaxy",
        "names": [
          "PLX 124.00",
          "PPM 43228",
          "HD 3969",
          "BD+40 148",
          "AG+40 60",
          "PLX 124",
          "2C 56",
          "DA 21",
          "GIN 801",
          "IRAS F00400+4059",
          "IRAS 00400+4059",
          "IRC +40013",
          "K79 1C",
          "LEDA 2557",
          "M 31",
          "MCG+07-02-016",
          "NAME Andromeda",
          "NAME Andromeda Galaxy",
          "NGC 224",
          "RAFGL 104",
          "UGC 454",
          "XSS J00425+4102",
          "Z 535-17",
          "Z 0040.0+4100",
          "[DGW65] 4",
          "2MASX J00424433+4116074",
          "[M98c] 004000.1+405943",
          "[VV2000c] J004244.3+411610",
          "[VV2003c] J004244.3+411610",
          "[VV2006] J004244.3+411610",
          "[VV98c] J004245.1+411622",
          "UZC J004244.3+411608",
          "[VV2010] J004244.3+411610",
          "NAME And Nebula",
          "NAME Andromeda Nebula",
          "2MAXI J0043+412",
          "3FGL J0042.5+4117",
          "2FGL J0042.5+4114",
          "4FGL J0043.2+4114"
        ],
        "searchKey": "da21|dgw654|bd40148|2maxij0043412|2fglj004254114|hd3969|irasf004004059|z53517|m31|xssj004254102|iras004004059|vv2000cj0042443411610|4fglj004324114|andromeda|vv2006j0042443411610|plx12400|mcg0702016|ag4060|rafgl104|uzcj0042443411608|leda2557|ugc454|plx124|2c56|2masxj004244334116074|m98c0040001405943|irc40013|andromedanebula|andromedagalaxy|vv2010j0042443411610|vv2003cj0042443411610|z004004100|andnebula|3fglj004254117|ppm43228|k791c|andromedagalaxy|gin801|ngc224|vv98cj0042451411622",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/M31_09-01-2011_%28cropped%29.jpg/435px-M31_09-01-2011_%28cropped%29.jpg",
        "ra": 0.7123138888888888,
        "dec": 41.26875,
        "fluxV": 3.44,
        "sizeMajor": 199.53,
        "sizeMinor": 70.79,
        "sizeAngle": 35.0,
        "simbadName": "M 31",
        "imgCredit": "Torben Hansen",
        "description": "The Andromeda Galaxy is a barred spiral galaxy and is the nearest major galaxy to the Milky Way. It was originally named the Andromeda Nebula and is cataloged as Messier 31, M31, and NGC 224. Andromeda has a diameter of about 46.56 kiloparsecs (152,000 light-years) and is approximately 765 kpc (2.5 million light-years) from Earth. The galaxy's name stems from the area of Earth's sky in which it appears, the constellation of Andromeda, which itself is named after the princess who was the wife of Perseus in Greek mythology.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Andromeda_Galaxy"
      },
      {
        "id": "944241955830530049",
        "name": "Orion Nebula",
        "names": [
          "3C 145",
          "4C -05.21",
          "CTA 37",
          "LBN 209.13-19.35",
          "LBN 974",
          "M 42",
          "MSH 05-0-11",
          "Mills 05+0A",
          "NAME Great Orion Nebula",
          "NAME Ori Nebula",
          "NAME Orion Nebula",
          "NGC 1976",
          "NRL 6",
          "PKS 0532-054",
          "PKS 0532-05",
          "XSS J05351-0519",
          "[DGW65] 26",
          "[PT56] 6",
          "[WCO2009] J053517-052326",
          "GAL 209.01-19.4",
          "[KPS2012] MWSC 0582"
        ],
        "searchKey": "msh05011|orionnebula|pt566|4c0521|3c145|pks0532054|pks053205|ngc1976|dgw6526|lbn974|cta37|gal20901194|kps2012mwsc0582|wco2009j053517052326|lbn209131935|greatorionnebula|m42|xssj053510519|orinebula|orionnebula|nrl6|mills050a",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Orion_Nebula_-_Hubble_2006_mosaic_18000.jpg/450px-Orion_Nebula_-_Hubble_2006_mosaic_18000.jpg",
        "ra": 5.588,
        "dec": -5.390000000000001,
        "fluxV": null,
        "sizeMajor": 66.0,
        "sizeMinor": 66.0,
        "sizeAngle": 90.0,
        "simbadName": "M 42",
        "imgCredit": "NASA, ESA, M. Robberto",
        "description": "The Orion Nebula (also known as Messier 42, M42, or NGC 1976) is a diffuse nebula situated in the Milky Way, being south of Orion's Belt in the constellation of Orion, and is known as the middle \"star\" in the \"sword\" of Orion. It is one of the brightest nebulae and is visible to the naked eye in the night sky with apparent magnitude 4.0. It is 1,344 \u00b1 20 light-years (412.1 \u00b1 6.1 pc) away and is the closest region of massive star formation to Earth. The M42 nebula is estimated to be 24 light-years across (so its apparent size from Earth is approximately 1 degree). It has a mass of about 2,000 times that of the Sun. Older texts frequently refer to the Orion Nebula as the Great Nebula in Orion or the Great Orion Nebula.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Orion_Nebula"
      },
      {
        "id": "944241958223970305",
        "name": "Rosette Nebula",
        "names": [
          "GPA 206.29-2.35",
          "Zel 0629+050",
          "MSH 06+0-08",
          "[DGW65] 37",
          "ASB 27",
          "3C 163",
          "DA 211",
          "Cul 0629+049",
          "[GS55] 97",
          "LBN 949",
          "LBN 206.39-01.87",
          "[S50] 29",
          "LBN 948",
          "CTB 21",
          "SH 2-275",
          "GRS G206.20 -01.80",
          "GRS G206.40 -02.40",
          "W 16",
          "NAME Rosette Nebula",
          "NAME Skull Nebula"
        ],
        "searchKey": "sh2275|s5029|rosettenebula|lbn948|3c163|asb27|msh06008|zel0629050|rosettenebula|lbn206390187|gpa20629235|grsg206400240|da211|ctb21|w16|grsg206200180|gs5597|dgw6537|skullnebula|lbn949|cul0629049",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Rosette_Nebula_Narrowband_SHO_focal_length_384mm_Stephan_Hamel.jpg/435px-Rosette_Nebula_Narrowband_SHO_focal_length_384mm_Stephan_Hamel.jpg",
        "ra": 6.540766666666666,
        "dec": 4.793638888888888,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Rosette Nebula",
        "imgCredit": "Stephan Hamel",
        "description": "The Rosette Nebula (also known as Caldwell 49) is an H II region located near one end of a giant molecular cloud in the Monoceros region of the Milky Way Galaxy. The open cluster NGC 2244 (Caldwell 50) is closely associated with the nebulosity, the stars of the cluster having been formed from the nebula's matter.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Rosette_Nebula"
      },
      {
        "id": "944241965995786241",
        "name": "Pleiades",
        "names": [
          "[KC2019] Theia 369",
          "C 0344+239",
          "H 0346+24",
          "M 45",
          "NAME Pleiades",
          "NAME Seven Sisters",
          "OCl 421.0",
          "[KPR2004b] 47",
          "Cl Melotte 22",
          "[KPS2012] MWSC 0305"
        ],
        "searchKey": "pleiades|clmelotte22|kpr2004b47|c0344239|m45|pleiades|kc2019theia369|ocl4210|h034624|sevensisters|kps2012mwsc0305",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 3.773388888888889,
        "dec": 24.11388888888889,
        "fluxV": null,
        "sizeMajor": 76.86,
        "sizeMinor": 76.86,
        "sizeAngle": null,
        "simbadName": "Cl Melotte 22",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241968814784513",
        "name": "Horsehead Nebula",
        "names": [
          "Barnard 33",
          "M3T 31",
          "[OS98] 52",
          "[DB2002b] G206.95-16.80",
          "NAME Horsehead Nebula"
        ],
        "searchKey": "m3t31|horseheadnebula|barnard33|horseheadnebula|os9852|db2002bg206951680",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.683055555555556,
        "dec": -2.458333333333333,
        "fluxV": null,
        "sizeMajor": 6.0,
        "sizeMinor": 4.0,
        "sizeAngle": 90.0,
        "simbadName": "NAME Horsehead Nebula",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241971144065025",
        "name": "Heart Nebula",
        "names": [
          "GAL 134.2+00.8",
          "W 4",
          "NAME Heart Nebula"
        ],
        "searchKey": "gal1342008|heartnebula|heartnebula|w4",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.545,
        "dec": 61.45,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Heart Nebula",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241973560868865",
        "name": "Veil Nebula",
        "names": [
          "LBN 191",
          "LBN 074.53-08.42",
          "NAME Cirrus Nebula",
          "NAME Filamentary Nebula",
          "NGC 6960",
          "NAME Veil Nebula"
        ],
        "searchKey": "veilnebula|veilnebula|cirrusnebula|lbn191|filamentarynebula|lbn074530842|ngc6960",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.76055555555556,
        "dec": 30.70833333333333,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 6960",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241978420101121",
        "name": "Lagoon Nebula",
        "names": [
          "Villafranca O-018",
          "CTB 46",
          "Kes 58",
          "LBN 006.06-01.23",
          "LBN 25",
          "LMH 11",
          "M 8",
          "MM 10",
          "Mol 37",
          "NAME Lagoon",
          "NAME Lagoon Nebula",
          "NRL 11",
          "OCISM 1",
          "PMN J1803-2422",
          "SH 2-25",
          "[KC97c] G006.0-01.2",
          "[L89b] 5.973-01.178",
          "GRS G006.00 -01.20",
          "GRS G006.20 -01.20",
          "W 29"
        ],
        "searchKey": "sh225|lagoon|grsg006000120|ctb46|lmh11|mol37|pmnj18032422|w29|mm10|lagoonnebula|l89b597301178|grsg006200120|lagoonnebula|nrl11|kes58|lbn25|lbn006060123|m8|villafrancao018|kc97cg0060012|ocism1",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.06027777777778,
        "dec": -24.38666666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 8",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241982988746753",
        "name": "North America Nebula",
        "names": [
          "Villafranca O-014 NW",
          "Villafranca O-014",
          "NAME Bermuda Cluster",
          "CTB 100",
          "LBN 085.75-01.48",
          "LBN 373",
          "NAME NORTH AMER NEB",
          "NAME North America",
          "NGC 7000",
          "NRL 21",
          "SH 2-117",
          "[DGW65] 138",
          "[PT56] 49",
          "GRS G084.70 -01.00",
          "NAME North America Nebula"
        ],
        "searchKey": "bermudacluster|northamerneb|nrl21|sh2117|dgw65138|northamericanebula|grsg084700100|lbn373|ngc7000|lbn085750148|northamerica|villafrancao014|villafrancao014nw|pt5649|northamericanebula|ctb100",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.97972222222222,
        "dec": 44.33000000000001,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 7000",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241985593442305",
        "name": "Carina Nebula",
        "names": [
          "NAME Keyhole",
          "1E 1044.0-5940",
          "BRAN 316A",
          "GUM 33",
          "MHR 11",
          "MSH 10-5-07",
          "NGC 3372",
          "RCW 53",
          "NAME Car Nebula",
          "NAME Carina Nebula",
          "NAME eta Car Nebula",
          "NAME Keyhole Nebula"
        ],
        "searchKey": "carnebula|carinanebula|ngc3372|keyholenebula|mhr11|gum33|carinanebula|rcw53|msh10507|bran316a|1e104405940|keyhole|etacarnebula",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 10.75061944444444,
        "dec": -59.69994444444444,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 3372",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241990489964545",
        "name": "California Nebula",
        "names": [
          "LBN 756",
          "LBN 160.18-12.15",
          "NAME California Nebula",
          "NGC 1499",
          "SH 2-220"
        ],
        "searchKey": "lbn756|californianebula|lbn160181215|sh2220|ngc1499|californianebula",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 4.055,
        "dec": 36.42166666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 1499",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242358486532097",
        "name": "Triangulum Galaxy",
        "names": [
          "1AXG J013351+3039",
          "2E 409",
          "2E 0131.0+3024",
          "HIJASS J0133+30",
          "LEDA 5818",
          "M 33",
          "MCG+05-04-069",
          "NAME Triangulum Galaxy",
          "NAME Triangulum Pinwheel",
          "NGC 598",
          "RBS 214",
          "RX J013351+30399",
          "TC 906",
          "UGC 1117",
          "Z 0131.0+3024",
          "Z 502-110",
          "2MASX J01335090+3039357",
          "1ES 0131+30.3",
          "1RXS J013350.9+303932",
          "[CHM2007] LDC 160 J013350.90+3039357  [M98c] 013101.7+302415",
          "UZC J013351.1+303922"
        ],
        "searchKey": "z502110|1es0131303|2masxj013350903039357|chm2007ldc160j013350903039357m98c0131017302415|hijassj013330|m33|1axgj0133513039|triangulumgalaxy|triangulumgalaxy|rbs214|leda5818|ugc1117|triangulumpinwheel|uzcj0133511303922|tc906|1rxsj0133509303932|rxj01335130399|2e013103024|z013103024|2e409|mcg0504069|ngc598",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "INDIGO",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/VST_snaps_a_very_detailed_view_of_the_Triangulum_Galaxy.jpg/375px-VST_snaps_a_very_detailed_view_of_the_Triangulum_Galaxy.jpg",
        "ra": 1.564137937478667,
        "dec": 30.66017511198,
        "fluxV": 5.72,
        "sizeMajor": 60.26,
        "sizeMinor": 35.48,
        "sizeAngle": 22.0,
        "simbadName": "M 33",
        "imgCredit": "ESO",
        "description": "The Triangulum Galaxy is a spiral galaxy 2.73 million light-years (ly) from Earth in the constellation Triangulum. It is catalogued as Messier 33 or NGC (New General Catalogue) 598. With the D25 isophotal diameter of 18.74 kiloparsecs (61,100 light-years), the Triangulum Galaxy is the third-largest member of the Local Group of galaxies, behind the Andromeda Galaxy and the Milky Way.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Triangulum_Galaxy"
      },
      {
        "id": "950076964489166850",
        "name": "SMC",
        "names": [
          "Anon 0051-73",
          "ESO 29-21",
          "GLXY G291.4-41.7+191",
          "LEDA 3085",
          "NAME SMC",
          "NGC 292",
          "XSS J00595-7303",
          "[M98c] 005053.0-730418",
          "[MI94] Im 6",
          "1FGL J0101.3-7257",
          "PBC J0102.7-7241",
          "2FGL J0059.0-7242e",
          "NAME Nubecula Minor",
          "NAME Small Magellanic Cloud",
          "3FGL J0059.0-7242e",
          "4FGL J0058.0-7245e"
        ],
        "searchKey": "m98c0050530730418|eso2921|nubeculaminor|leda3085|anon005173|smc|3fglj005907242e|smallmagellaniccloud|ngc292|1fglj010137257|pbcj010277241|4fglj005807245e|xssj005957303|2fglj005907242e|smc|glxyg2914417191|mi94im6",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 0.8772222222222222,
        "dec": -72.80027777777778,
        "fluxV": 2.2,
        "sizeMajor": 158.113,
        "sizeMinor": 93.105,
        "sizeAngle": 45.0,
        "simbadName": "NAME SMC",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950078990682980354",
        "name": "Coalsack",
        "names": [
          "NAME Coalsack",
          "NAME Southern Coalsack",
          "NAME Coalsack Nebula",
          "NAME COAL SACK"
        ],
        "searchKey": "coalsack|coalsack|coalsack|southerncoalsack|coalsacknebula",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "GREEN",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 12.52194444444445,
        "dec": -63.74333333333333,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Coalsack Nebula",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079199704514562",
        "name": "Barnard Loop",
        "names": [
          "NAME Barnard Loop",
          "NAME Barnard's Arc",
          "NAME Barnard's Loop",
          "SH 2-276"
        ],
        "searchKey": "barnardsloop|barnardloop|barnardsarc|barnardloop|sh2276",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ROSE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.458333333333334,
        "dec": -3.966666666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Barnard's Loop",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079199785123842",
        "name": "Mon R2 IRS3",
        "names": [
          "Ced 63",
          "DG 88",
          "LBN 994",
          "LBN 213.69-12.65",
          "NAME Mon R2 IRS3",
          "NGC 2170",
          "RAFGL 877"
        ],
        "searchKey": "lbn994|lbn213691265|monr2irs3|ngc2170|dg88|monr2irs3|rafgl877|ced63",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 6.13,
        "dec": -6.385000000000001,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 2170",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079199834963970",
        "name": "the Witch Head Nebula",
        "names": [
          "NGC 1909",
          "Ced 41",
          "DG 52",
          "Hubble I 9",
          "IC 2118",
          "LBN 207.50-27.78",
          "LBN 959",
          "RNO 37",
          "NAME the Witch Head Nebula"
        ],
        "searchKey": "rno37|lbn207502778|dg52|ced41|ic2118|thewitchheadnebula|ngc1909|lbn959|hubblei9|thewitchheadnebula",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.033333333333333,
        "dec": -7.9,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 1909",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079200878821378",
        "name": "OPHIUCUS",
        "names": [
          "NAME OPHIUCUS",
          "NAME Oph Region",
          "NAME Ophiuchus",
          "NAME Ophiuchus Region",
          "NAME rho Oph Region",
          "NAME Ophiuchus Cloud",
          "INTREF 691",
          "LDN 1688",
          "NAME RHO OPH MCLD",
          "NAME Oph Cloud",
          "NAME rho Oph Cloud",
          "NAME rho Oph Dark Cloud",
          "NAME rho Ophiuchi Cloud",
          "XSS J16271-2423",
          "NAME Oph Molecular Cloud",
          "NAME Ophiuchus Dark Cloud",
          "NAME Ophiuchus Molecular Cloud",
          "NAME rho Ophiuchus Cloud"
        ],
        "searchKey": "ophiucus|intref691|ophmolecularcloud|rhoophregion|rhoophcloud|ophiuchuscloud|ophiucus|ophiuchusregion|xssj162712423|ophiuchus|ophiuchusmolecularcloud|ldn1688|rhoophdarkcloud|ophregion|rhoophiuchicloud|ophcloud|rhoophmcld|rhoophiuchuscloud|ophiuchusdarkcloud",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.46833333333333,
        "dec": -24.54166666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Ophiuchus Molecular Cloud",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079201617838082",
        "name": "Galactic Center",
        "names": [
          "GAL 000.0+00.0",
          "HESS J1745-290",
          "NAME Gal Centre",
          "VER J1745-290",
          "NAME Galactic Centre",
          "NAME Gal Center",
          "NAME Galactic Center",
          "TeV J1745-290"
        ],
        "searchKey": "galacticcentre|galcenter|galacticcenter|verj1745290|hessj1745290|galacticcenter|galcentre|gal0000000|tevj1745290",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.76100059166667,
        "dec": -29.00611111111111,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Galactic Center",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079203757391874",
        "name": "LMC",
        "names": [
          "Anon 0524-69",
          "ESO 56-115",
          "ESO-LV 56-1150",
          "GLXY G279.0-34.4+262",
          "IRAS 05240-6948",
          "LEDA 17223",
          "NAME LMC",
          "4FGL J0519.9-6845e",
          "[M98c] 052400.0-694800",
          "[MI94] Sm 29",
          "2FGL J0526.6-6825e",
          "NAME Large Magellanic Cloud",
          "NAME Nubecula Major",
          "1FHL J0526.6-6825",
          "2EG J0532-6914",
          "3EG J0533-6916",
          "EGR J0537-6946",
          "1FGL J0538.9-6914",
          "3FGL J0526.6-6825e",
          "2FHL J0526.6-6825e",
          "1FLE J0536-6856"
        ],
        "searchKey": "4fglj051996845e|lmc|3fglj052666825e|egrj05376946|3egj05336916|glxyg2790344262|largemagellaniccloud|eso56115|anon052469|iras052406948|esolv561150|lmc|2fglj052666825e|leda17223|m98c0524000694800|2fhlj052666825e|1fglj053896914|2egj05326914|nubeculamajor|1fhlj052666825|1flej05366856|mi94sm29",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 5.392944444444445,
        "dec": -69.75611111111111,
        "fluxV": 0.4,
        "sizeMajor": 322.827,
        "sizeMinor": 274.77,
        "sizeAngle": 170.0,
        "simbadName": "NAME LMC",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959206223609857",
    "title": "S&T - Naked Eye",
    "color": "RED",
    "credit": "https://skyandtelescope.org/observing/deep-sky-naked-eye/",
    "imgURL": "/list_icons/st.jpg",
    "type": "PUBLIC_LIST",
    "objects": [
      {
        "id": "944241952512442369",
        "name": "Andromeda Galaxy",
        "names": [
          "PLX 124.00",
          "PPM 43228",
          "HD 3969",
          "BD+40 148",
          "AG+40 60",
          "PLX 124",
          "2C 56",
          "DA 21",
          "GIN 801",
          "IRAS F00400+4059",
          "IRAS 00400+4059",
          "IRC +40013",
          "K79 1C",
          "LEDA 2557",
          "M 31",
          "MCG+07-02-016",
          "NAME Andromeda",
          "NAME Andromeda Galaxy",
          "NGC 224",
          "RAFGL 104",
          "UGC 454",
          "XSS J00425+4102",
          "Z 535-17",
          "Z 0040.0+4100",
          "[DGW65] 4",
          "2MASX J00424433+4116074",
          "[M98c] 004000.1+405943",
          "[VV2000c] J004244.3+411610",
          "[VV2003c] J004244.3+411610",
          "[VV2006] J004244.3+411610",
          "[VV98c] J004245.1+411622",
          "UZC J004244.3+411608",
          "[VV2010] J004244.3+411610",
          "NAME And Nebula",
          "NAME Andromeda Nebula",
          "2MAXI J0043+412",
          "3FGL J0042.5+4117",
          "2FGL J0042.5+4114",
          "4FGL J0043.2+4114"
        ],
        "searchKey": "da21|dgw654|bd40148|2maxij0043412|2fglj004254114|hd3969|irasf004004059|z53517|m31|xssj004254102|iras004004059|vv2000cj0042443411610|4fglj004324114|andromeda|vv2006j0042443411610|plx12400|mcg0702016|ag4060|rafgl104|uzcj0042443411608|leda2557|ugc454|plx124|2c56|2masxj004244334116074|m98c0040001405943|irc40013|andromedanebula|andromedagalaxy|vv2010j0042443411610|vv2003cj0042443411610|z004004100|andnebula|3fglj004254117|ppm43228|k791c|andromedagalaxy|gin801|ngc224|vv98cj0042451411622",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/M31_09-01-2011_%28cropped%29.jpg/435px-M31_09-01-2011_%28cropped%29.jpg",
        "ra": 0.7123138888888888,
        "dec": 41.26875,
        "fluxV": 3.44,
        "sizeMajor": 199.53,
        "sizeMinor": 70.79,
        "sizeAngle": 35.0,
        "simbadName": "M 31",
        "imgCredit": "Torben Hansen",
        "description": "The Andromeda Galaxy is a barred spiral galaxy and is the nearest major galaxy to the Milky Way. It was originally named the Andromeda Nebula and is cataloged as Messier 31, M31, and NGC 224. Andromeda has a diameter of about 46.56 kiloparsecs (152,000 light-years) and is approximately 765 kpc (2.5 million light-years) from Earth. The galaxy's name stems from the area of Earth's sky in which it appears, the constellation of Andromeda, which itself is named after the princess who was the wife of Perseus in Greek mythology.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Andromeda_Galaxy"
      },
      {
        "id": "944241976047599617",
        "name": "Eagle Nebula",
        "names": [
          "Villafranca O-019",
          "C 1816-138",
          "CTB 51",
          "LBN 016.96+00.78",
          "LBN 67",
          "LMH 22",
          "M 16",
          "MM 21",
          "NGC 6611",
          "NRL 14",
          "OCISM 10",
          "OCl 54",
          "[KPR2004b] 441",
          "IC 4703",
          "GRS G017.00 +00.30",
          "[KPS2012] MWSC 2886",
          "NAME Eagle Nebula",
          "NAME Star Queen"
        ],
        "searchKey": "kpr2004b441|eaglenebula|villafrancao019|kps2012mwsc2886|lmh22|ocism10|ocl54|lbn67|grsg017000030|nrl14|ngc6611|lbn016960078|eaglenebula|mm21|starqueen|c1816138|ctb51|m16|ic4703",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.31252777777778,
        "dec": -13.79194444444444,
        "fluxV": null,
        "sizeMajor": 80.0,
        "sizeMinor": 80.0,
        "sizeAngle": null,
        "simbadName": "M 16",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241978420101121",
        "name": "Lagoon Nebula",
        "names": [
          "Villafranca O-018",
          "CTB 46",
          "Kes 58",
          "LBN 006.06-01.23",
          "LBN 25",
          "LMH 11",
          "M 8",
          "MM 10",
          "Mol 37",
          "NAME Lagoon",
          "NAME Lagoon Nebula",
          "NRL 11",
          "OCISM 1",
          "PMN J1803-2422",
          "SH 2-25",
          "[KC97c] G006.0-01.2",
          "[L89b] 5.973-01.178",
          "GRS G006.00 -01.20",
          "GRS G006.20 -01.20",
          "W 29"
        ],
        "searchKey": "sh225|lagoon|grsg006000120|ctb46|lmh11|mol37|pmnj18032422|w29|mm10|lagoonnebula|l89b597301178|grsg006200120|lagoonnebula|nrl11|kes58|lbn25|lbn006060123|m8|villafrancao018|kc97cg0060012|ocism1",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.06027777777778,
        "dec": -24.38666666666667,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "M 8",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242466055880705",
        "name": "M 5",
        "names": [
          "C 1516+022",
          "GCl 34",
          "M 5",
          "NGC 5904",
          "[KPS2012] MWSC 2286",
          "GCRV 5244 E"
        ],
        "searchKey": "ngc5904|kps2012mwsc2286|m5|gcl34|c1516022|gcrv5244e",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 15.30922777777778,
        "dec": 2.081027777777778,
        "fluxV": 5.95,
        "sizeMajor": 17.4,
        "sizeMinor": 17.4,
        "sizeAngle": 90.0,
        "simbadName": "M 5",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242468436738049",
        "name": "M 6",
        "names": [
          "[KC2019] Theia 122",
          "C 1736-321",
          "M 6",
          "NGC 6405",
          "OCl 1030.0",
          "[KPR2004b] 408",
          "[SC95] M 38",
          "[SC95] M 43",
          "[SC96] GC 128",
          "[SC96] GC 130",
          "[SC96] GC 137",
          "[SC96] Mis 588",
          "[SC96] Mis 589",
          "[SC96] Mis 607",
          "Cl VDBH 242",
          "[KPS2012] MWSC 2661",
          "NAME Butterfly Cluster"
        ],
        "searchKey": "sc96mis589|c1736321|kc2019theia122|m6|kpr2004b408|sc95m43|butterflycluster|sc96gc137|kps2012mwsc2661|clvdbh242|ocl10300|sc96mis607|ngc6405|sc96gc128|sc96gc130|sc96mis588|sc95m38",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.67127777777778,
        "dec": -32.24194444444444,
        "fluxV": null,
        "sizeMajor": 20.0,
        "sizeMinor": 20.0,
        "sizeAngle": 90.0,
        "simbadName": "NGC 6405",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242470846922753",
        "name": "M 7",
        "names": [
          "[KC2019] Theia 692",
          "C 1750-348",
          "M 7",
          "NGC 6475",
          "OCl 1028.0",
          "[KPR2004b] 420",
          "[SC96] GC 228",
          "[SC96] GC 243",
          "[SC96] Mis 753",
          "[SC96] Mis 774",
          "Cl VDBH 254",
          "[KPS2012] MWSC 2739",
          "NAME Ptolemy's Cluster"
        ],
        "searchKey": "c1750348|kpr2004b420|sc96mis774|kps2012mwsc2739|clvdbh254|ptolemyscluster|ngc6475|kc2019theia692|sc96mis753|sc96gc243|ocl10280|m7|sc96gc228",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "RED",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.89647222222222,
        "dec": -34.84111111111111,
        "fluxV": null,
        "sizeMajor": 100.1,
        "sizeMinor": 100.1,
        "sizeAngle": null,
        "simbadName": "NGC 6475",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242483264749569",
        "name": "M 11",
        "names": [
          "[LP2019] 155",
          "NAME Amas de l'Ecu de Sobieski",
          "C 1848-063",
          "M 11",
          "NGC 6705",
          "OCl 76.0",
          "[KPR2004b] 453",
          "Cl Collinder 391",
          "Cl Melotte 213",
          "[KPS2012] MWSC 3008",
          "NAME Wild Duck Cluster"
        ],
        "searchKey": "c1848063|clmelotte213|lp2019155|wildduckcluster|kps2012mwsc3008|m11|ngc6705|ocl760|kpr2004b453|clcollinder391|amasdelecudesobieski",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "BLUE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.85105555555556,
        "dec": -6.271944444444444,
        "fluxV": 5.8,
        "sizeMajor": 8.9,
        "sizeMinor": 8.9,
        "sizeAngle": null,
        "simbadName": "NGC 6705",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242490594721793",
        "name": "M 13",
        "names": [
          "C 1639+365",
          "GCl 45",
          "M 13",
          "NGC 6205",
          "2MASX J16414163+3627407",
          "[KPS2012] MWSC 2445",
          "NAME Hercules Globular Cluster"
        ],
        "searchKey": "c1639365|gcl45|herculesglobularcluster|2masxj164141633627407|kps2012mwsc2445|m13|ngc6205",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 16.69489833333333,
        "dec": 36.46131944444445,
        "fluxV": 5.8,
        "sizeMajor": 33.0,
        "sizeMinor": 33.0,
        "sizeAngle": null,
        "simbadName": "M 13",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242499278143489",
        "name": "M 15",
        "names": [
          "C 2127+119",
          "GCl 120",
          "M 15",
          "NGC 7078",
          "[KPS2012] MWSC 3518"
        ],
        "searchKey": "gcl120|m15|kps2012mwsc3518|ngc7078|c2127119",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "EMERALD",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.49953611111111,
        "dec": 12.167,
        "fluxV": null,
        "sizeMajor": 12.3,
        "sizeMinor": 12.3,
        "sizeAngle": 90.0,
        "simbadName": "M 15",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242505651683329",
        "name": "M 17",
        "names": [
          "Villafranca O-009",
          "[KC2019] Theia 846",
          "1E 1818.0-1610",
          "C 1817-162",
          "CTB 52",
          "LBN 60",
          "LBN 015.28-00.67",
          "LMH 21",
          "M 17",
          "MM 20",
          "NAME NGC 6618 Horseshoe",
          "NGC 6618",
          "NRL 4",
          "OCISM 9",
          "OCl 44",
          "OCl 44.0",
          "[BDB2003] G015.05-00.69",
          "[KPR2004b] 444",
          "[PT56] 41",
          "GRS G015.00 -00.70",
          "[KPS2012] MWSC 2896",
          "BD-16 4820",
          "GCRV 10811",
          "HD 168520",
          "W 38",
          "NAME Checkmark Nebula",
          "NAME Lobster Nebula",
          "NAME omega Nebula",
          "NAME Swan Nebula",
          "WEB 15351"
        ],
        "searchKey": "nrl4|swannebula|bdb2003g015050069|lmh21|pt5641|kc2019theia846|kps2012mwsc2896|gcrv10811|hd168520|lbn60|c1817162|ocl440|m17|lbn015280067|ctb52|1e181801610|ocism9|mm20|kpr2004b444|ocl44|ngc6618|checkmarknebula|bd164820|ngc6618horseshoe|grsg015000070|web15351|w38|omeganebula|lobsternebula|villafrancao009",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.34638888888889,
        "dec": -16.17166666666667,
        "fluxV": null,
        "sizeMajor": 1.63,
        "sizeMinor": 1.63,
        "sizeAngle": 90.0,
        "simbadName": "NGC 6618",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242518955294721",
        "name": "M 22",
        "names": [
          "C 1833-239",
          "CD-24 14516",
          "GCRV 11055",
          "GCl 99",
          "HD 171560",
          "M 22",
          "NGC 6656",
          "1E 1833.3-2357",
          "[KPS2012] MWSC 2961",
          "GCRV 6244 E"
        ],
        "searchKey": "c1833239|gcrv11055|gcl99|m22|hd171560|gcrv6244e|ngc6656|cd2414516|1e183332357|kps2012mwsc2961",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.60665,
        "dec": -23.90475,
        "fluxV": null,
        "sizeMajor": 24.0,
        "sizeMinor": 24.0,
        "sizeAngle": 90.0,
        "simbadName": "M 22",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242522222821377",
        "name": "M 23",
        "names": [
          "[KC2019] Theia 653",
          "C 1753-190",
          "M 23",
          "NGC 6494",
          "[KPR2004b] 422",
          "[KPS2012] MWSC 2757"
        ],
        "searchKey": "c1753190|kps2012mwsc2757|ngc6494|kc2019theia653|kpr2004b422|m23",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.94913888888889,
        "dec": -18.98694444444445,
        "fluxV": null,
        "sizeMajor": 35.0,
        "sizeMinor": 35.0,
        "sizeAngle": null,
        "simbadName": "M 23",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242528691224577",
        "name": "M 25",
        "names": [
          "[KC2019] Theia 650",
          "C 1828-192",
          "IC 4725",
          "M 25",
          "[KPR2004b] 449",
          "[SC95] M 662",
          "[SC95] M 667",
          "[SC96] Mis 1423",
          "[KPS2012] MWSC 2940"
        ],
        "searchKey": "kps2012mwsc2940|kpr2004b449|ic4725|sc95m662|sc96mis1423|m25|kc2019theia650|c1828192|sc95m667",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "NEUTRAL",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.52913888888889,
        "dec": -19.11388888888889,
        "fluxV": null,
        "sizeMajor": 31.3,
        "sizeMinor": 31.3,
        "sizeAngle": null,
        "simbadName": "IC 4725",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242573168377857",
        "name": "M 39",
        "names": [
          "[KC2019] Theia 517",
          "C 2130+482",
          "M 39",
          "NGC 7092",
          "OCl 211.0",
          "[KPR2004b] 499",
          "[KPS2012] MWSC 3521"
        ],
        "searchKey": "ocl2110|kpr2004b499|kps2012mwsc3521|ngc7092|c2130482|m39|kc2019theia517",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 21.52594444444444,
        "dec": 48.24694444444444,
        "fluxV": null,
        "sizeMajor": 120.4,
        "sizeMinor": 120.4,
        "sizeAngle": null,
        "simbadName": "NGC 7092",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242747054915585",
        "name": "M 92",
        "names": [
          "TD1 20249",
          "SAO 46606",
          "PPM 56062",
          "HD 156711",
          "GCRV 9983",
          "BD+43 2711",
          "AG+43 1399",
          "C 1715+432",
          "GCl 59",
          "M 92",
          "NGC 6341",
          "[KPS2012] MWSC 2557"
        ],
        "searchKey": "sao46606|ngc6341|gcl59|c1715432|bd432711|m92|hd156711|kps2012mwsc2557|ppm56062|gcrv9983|ag431399|td120249",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.28538611111111,
        "dec": 43.13594444444444,
        "fluxV": 6.52,
        "sizeMajor": 11.2,
        "sizeMinor": 11.2,
        "sizeAngle": 90.0,
        "simbadName": "M 92",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "947766584711380993",
        "name": "h Per Cluster",
        "names": [
          "C 0215+569",
          "NGC 869",
          "OCISM 84",
          "OCl 350.0",
          "OCl 350",
          "Raab 9",
          "[KPR2004b] 29",
          "NAME h Per Cluster",
          "Cl Collinder 24",
          "Cl Melotte 13",
          "NAME h Persei Cluster",
          "[KPS2012] MWSC 0175"
        ],
        "searchKey": "ngc869|ocism84|raab9|hpercluster|c0215569|clmelotte13|ocl350|hperseicluster|kps2012mwsc0175|kpr2004b29|hpercluster|clcollinder24|ocl3500",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "FUCHSIA",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.316055555555555,
        "dec": 57.13388888888889,
        "fluxV": null,
        "sizeMajor": 16.3,
        "sizeMinor": 16.3,
        "sizeAngle": null,
        "simbadName": "NGC 869",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950076966839615490",
        "name": "NGC 6633",
        "names": [
          "[KC2019] Theia 924",
          "C 1825+065",
          "NGC 6633",
          "[KPR2004b] 446",
          "[KPS2012] MWSC 2917"
        ],
        "searchKey": "ngc6633|kc2019theia924|kpr2004b446|c1825065|kps2012mwsc2917",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.45633333333333,
        "dec": 6.614999999999999,
        "fluxV": null,
        "sizeMajor": 43.2,
        "sizeMinor": 43.2,
        "sizeAngle": null,
        "simbadName": "NGC 6633",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950076966856818690",
        "name": "IC 4665",
        "names": [
          "[KC2019] Theia 76",
          "C 1743+057",
          "IC 4665",
          "OCl 85.0",
          "[KPR2004b] 412",
          "Cl Collinder 349",
          "Cl Melotte 179",
          "[KPS2012] MWSC 2689"
        ],
        "searchKey": "kps2012mwsc2689|c1743057|kc2019theia76|ocl850|ic4665|clmelotte179|clcollinder349|kpr2004b412",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.77027777777778,
        "dec": 5.614999999999999,
        "fluxV": null,
        "sizeMajor": 60.7,
        "sizeMinor": 60.7,
        "sizeAngle": null,
        "simbadName": "IC 4665",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950076966857342978",
        "name": "chi Per Cluster",
        "names": [
          "C 0218+568",
          "NGC 884",
          "OCISM 86",
          "OCl 353.0",
          "OCl 353",
          "Raab 10",
          "[KPR2004b] 30",
          "Cl Collinder 25",
          "Cl Melotte 14",
          "NAME chi Per Cluster",
          "NAME chi Persei Cluster",
          "NAME khi Per Cluster",
          "[KPS2012] MWSC 0184"
        ],
        "searchKey": "raab10|c0218568|kps2012mwsc0184|chipercluster|ocl353|khipercluster|ocl3530|ocism86|kpr2004b30|clcollinder25|clmelotte14|chipercluster|ngc884|chiperseicluster",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.372277777777778,
        "dec": 57.14888888888889,
        "fluxV": null,
        "sizeMajor": 15.0,
        "sizeMinor": 15.0,
        "sizeAngle": null,
        "simbadName": "NGC 884",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950076966917439490",
        "name": "IC 4756",
        "names": [
          "[KC2019] Theia 1115",
          "C 1836+054",
          "IC 4756",
          "OCl 94.0",
          "[KPR2004b] 450",
          "Cl Melotte 210",
          "[KPS2012] MWSC 2967"
        ],
        "searchKey": "ocl940|kc2019theia1115|kpr2004b450|kps2012mwsc2967|ic4756|clmelotte210|c1836054",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "VIOLET",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 18.64327777777778,
        "dec": 5.435,
        "fluxV": null,
        "sizeMajor": 64.1,
        "sizeMinor": 64.1,
        "sizeAngle": null,
        "simbadName": "IC 4756",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950077336616042498",
        "name": "NGC 663",
        "names": [
          "C 0142+610",
          "NGC 663",
          "OCISM 80",
          "OCl 333",
          "OCl 333.0",
          "[KPR2004b] 22",
          "[KPS2012] MWSC 0139"
        ],
        "searchKey": "ocism80|c0142610|ocl3330|kps2012mwsc0139|kpr2004b22|ocl333|ngc663",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ROSE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 1.772388888888889,
        "dec": 61.21194444444445,
        "fluxV": 7.1,
        "sizeMajor": 18.0,
        "sizeMinor": 18.0,
        "sizeAngle": null,
        "simbadName": "NGC 663",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  },
  {
    "id": "978959210084958209",
    "title": "r/astrophotography",
    "color": "ORANGE",
    "credit": "https://www.reddit.com/r/astrophotography",
    "imgURL": "/list_icons/reddit.jpg",
    "type": "PUBLIC_LIST",
    "objects": [
      {
        "id": "944241943363649537",
        "name": "Jupiter",
        "names": [
          "NAME Jupiter"
        ],
        "searchKey": "jupiter|jupiter",
        "solarSystemKey": "jupiter barycenter",
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Jupiter_New_Horizons.jpg/435px-Jupiter_New_Horizons.jpg",
        "ra": null,
        "dec": null,
        "fluxV": -1.99,
        "sizeMajor": 0.5795,
        "sizeMinor": 0.5795,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "National Aeronautics and Space Administration",
        "description": "Jupiter is the fifth planet from the Sun and the largest in the Solar System. It is a gas giant with a mass more than two and a half times that of all the other planets in the Solar System combined, and slightly less than one one-thousandth the mass of the Sun. Jupiter orbits the Sun at a distance of 5.20 AU (778.5 Gm) with an orbital period of 11.86 years. Jupiter is the third brightest natural object in the Earth's night sky after the Moon and Venus, and it has been observed since prehistoric times. It was named after Jupiter, the chief deity of ancient Roman religion.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Jupiter"
      },
      {
        "id": "944241943867162625",
        "name": "Moon",
        "names": [
          "NAME Moon",
          "NAME Luna"
        ],
        "searchKey": "moon|moon|luna",
        "solarSystemKey": "moon",
        "cometKey": null,
        "celestrakKey": null,
        "color": "GRAY",
        "type": "SOLAR_SYSTEM_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/FullMoon2010.jpg/631px-FullMoon2010.jpg",
        "ra": null,
        "dec": null,
        "fluxV": -6.68,
        "sizeMajor": 33.78,
        "sizeMinor": 33.78,
        "sizeAngle": null,
        "simbadName": null,
        "imgCredit": "Gregory H. Revera",
        "description": "The Moon is Earth's only natural satellite. It orbits at an average distance of 384,400 km (238,900 mi), about 30 times Earth's diameter. The Moon always presents the same side to Earth, because gravitational pull has locked its rotation to the planet. This results in the lunar day of 29.5 Earth days matching the lunar month. The Moon's gravitational pull \u2013 and to a lesser extent the Sun's \u2013 are the main drivers of the tides.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Moon"
      },
      {
        "id": "944241952512442369",
        "name": "Andromeda Galaxy",
        "names": [
          "PLX 124.00",
          "PPM 43228",
          "HD 3969",
          "BD+40 148",
          "AG+40 60",
          "PLX 124",
          "2C 56",
          "DA 21",
          "GIN 801",
          "IRAS F00400+4059",
          "IRAS 00400+4059",
          "IRC +40013",
          "K79 1C",
          "LEDA 2557",
          "M 31",
          "MCG+07-02-016",
          "NAME Andromeda",
          "NAME Andromeda Galaxy",
          "NGC 224",
          "RAFGL 104",
          "UGC 454",
          "XSS J00425+4102",
          "Z 535-17",
          "Z 0040.0+4100",
          "[DGW65] 4",
          "2MASX J00424433+4116074",
          "[M98c] 004000.1+405943",
          "[VV2000c] J004244.3+411610",
          "[VV2003c] J004244.3+411610",
          "[VV2006] J004244.3+411610",
          "[VV98c] J004245.1+411622",
          "UZC J004244.3+411608",
          "[VV2010] J004244.3+411610",
          "NAME And Nebula",
          "NAME Andromeda Nebula",
          "2MAXI J0043+412",
          "3FGL J0042.5+4117",
          "2FGL J0042.5+4114",
          "4FGL J0043.2+4114"
        ],
        "searchKey": "da21|dgw654|bd40148|2maxij0043412|2fglj004254114|hd3969|irasf004004059|z53517|m31|xssj004254102|iras004004059|vv2000cj0042443411610|4fglj004324114|andromeda|vv2006j0042443411610|plx12400|mcg0702016|ag4060|rafgl104|uzcj0042443411608|leda2557|ugc454|plx124|2c56|2masxj004244334116074|m98c0040001405943|irc40013|andromedanebula|andromedagalaxy|vv2010j0042443411610|vv2003cj0042443411610|z004004100|andnebula|3fglj004254117|ppm43228|k791c|andromedagalaxy|gin801|ngc224|vv98cj0042451411622",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "ORANGE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/M31_09-01-2011_%28cropped%29.jpg/435px-M31_09-01-2011_%28cropped%29.jpg",
        "ra": 0.7123138888888888,
        "dec": 41.26875,
        "fluxV": 3.44,
        "sizeMajor": 199.53,
        "sizeMinor": 70.79,
        "sizeAngle": 35.0,
        "simbadName": "M 31",
        "imgCredit": "Torben Hansen",
        "description": "The Andromeda Galaxy is a barred spiral galaxy and is the nearest major galaxy to the Milky Way. It was originally named the Andromeda Nebula and is cataloged as Messier 31, M31, and NGC 224. Andromeda has a diameter of about 46.56 kiloparsecs (152,000 light-years) and is approximately 765 kpc (2.5 million light-years) from Earth. The galaxy's name stems from the area of Earth's sky in which it appears, the constellation of Andromeda, which itself is named after the princess who was the wife of Perseus in Greek mythology.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Andromeda_Galaxy"
      },
      {
        "id": "944241955830530049",
        "name": "Orion Nebula",
        "names": [
          "3C 145",
          "4C -05.21",
          "CTA 37",
          "LBN 209.13-19.35",
          "LBN 974",
          "M 42",
          "MSH 05-0-11",
          "Mills 05+0A",
          "NAME Great Orion Nebula",
          "NAME Ori Nebula",
          "NAME Orion Nebula",
          "NGC 1976",
          "NRL 6",
          "PKS 0532-054",
          "PKS 0532-05",
          "XSS J05351-0519",
          "[DGW65] 26",
          "[PT56] 6",
          "[WCO2009] J053517-052326",
          "GAL 209.01-19.4",
          "[KPS2012] MWSC 0582"
        ],
        "searchKey": "msh05011|orionnebula|pt566|4c0521|3c145|pks0532054|pks053205|ngc1976|dgw6526|lbn974|cta37|gal20901194|kps2012mwsc0582|wco2009j053517052326|lbn209131935|greatorionnebula|m42|xssj053510519|orinebula|orionnebula|nrl6|mills050a",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "AMBER",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Orion_Nebula_-_Hubble_2006_mosaic_18000.jpg/450px-Orion_Nebula_-_Hubble_2006_mosaic_18000.jpg",
        "ra": 5.588,
        "dec": -5.390000000000001,
        "fluxV": null,
        "sizeMajor": 66.0,
        "sizeMinor": 66.0,
        "sizeAngle": 90.0,
        "simbadName": "M 42",
        "imgCredit": "NASA, ESA, M. Robberto",
        "description": "The Orion Nebula (also known as Messier 42, M42, or NGC 1976) is a diffuse nebula situated in the Milky Way, being south of Orion's Belt in the constellation of Orion, and is known as the middle \"star\" in the \"sword\" of Orion. It is one of the brightest nebulae and is visible to the naked eye in the night sky with apparent magnitude 4.0. It is 1,344 \u00b1 20 light-years (412.1 \u00b1 6.1 pc) away and is the closest region of massive star formation to Earth. The M42 nebula is estimated to be 24 light-years across (so its apparent size from Earth is approximately 1 degree). It has a mass of about 2,000 times that of the Sun. Older texts frequently refer to the Orion Nebula as the Great Nebula in Orion or the Great Orion Nebula.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Orion_Nebula"
      },
      {
        "id": "944241958223970305",
        "name": "Rosette Nebula",
        "names": [
          "GPA 206.29-2.35",
          "Zel 0629+050",
          "MSH 06+0-08",
          "[DGW65] 37",
          "ASB 27",
          "3C 163",
          "DA 211",
          "Cul 0629+049",
          "[GS55] 97",
          "LBN 949",
          "LBN 206.39-01.87",
          "[S50] 29",
          "LBN 948",
          "CTB 21",
          "SH 2-275",
          "GRS G206.20 -01.80",
          "GRS G206.40 -02.40",
          "W 16",
          "NAME Rosette Nebula",
          "NAME Skull Nebula"
        ],
        "searchKey": "sh2275|s5029|rosettenebula|lbn948|3c163|asb27|msh06008|zel0629050|rosettenebula|lbn206390187|gpa20629235|grsg206400240|da211|ctb21|w16|grsg206200180|gs5597|dgw6537|skullnebula|lbn949|cul0629049",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "STONE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Rosette_Nebula_Narrowband_SHO_focal_length_384mm_Stephan_Hamel.jpg/435px-Rosette_Nebula_Narrowband_SHO_focal_length_384mm_Stephan_Hamel.jpg",
        "ra": 6.540766666666666,
        "dec": 4.793638888888888,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Rosette Nebula",
        "imgCredit": "Stephan Hamel",
        "description": "The Rosette Nebula (also known as Caldwell 49) is an H II region located near one end of a giant molecular cloud in the Monoceros region of the Milky Way Galaxy. The open cluster NGC 2244 (Caldwell 50) is closely associated with the nebulosity, the stars of the cluster having been formed from the nebula's matter.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Rosette_Nebula"
      },
      {
        "id": "944241965995786241",
        "name": "Pleiades",
        "names": [
          "[KC2019] Theia 369",
          "C 0344+239",
          "H 0346+24",
          "M 45",
          "NAME Pleiades",
          "NAME Seven Sisters",
          "OCl 421.0",
          "[KPR2004b] 47",
          "Cl Melotte 22",
          "[KPS2012] MWSC 0305"
        ],
        "searchKey": "pleiades|clmelotte22|kpr2004b47|c0344239|m45|pleiades|kc2019theia369|ocl4210|h034624|sevensisters|kps2012mwsc0305",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PINK",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 3.773388888888889,
        "dec": 24.11388888888889,
        "fluxV": null,
        "sizeMajor": 76.86,
        "sizeMinor": 76.86,
        "sizeAngle": null,
        "simbadName": "Cl Melotte 22",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241971144065025",
        "name": "Heart Nebula",
        "names": [
          "GAL 134.2+00.8",
          "W 4",
          "NAME Heart Nebula"
        ],
        "searchKey": "gal1342008|heartnebula|heartnebula|w4",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.545,
        "dec": 61.45,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Heart Nebula",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944241973560868865",
        "name": "Veil Nebula",
        "names": [
          "LBN 191",
          "LBN 074.53-08.42",
          "NAME Cirrus Nebula",
          "NAME Filamentary Nebula",
          "NGC 6960",
          "NAME Veil Nebula"
        ],
        "searchKey": "veilnebula|veilnebula|cirrusnebula|lbn191|filamentarynebula|lbn074530842|ngc6960",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SKY",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.76055555555556,
        "dec": 30.70833333333333,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 6960",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "944242005918744577",
        "name": "Bode's Galaxy",
        "names": [
          "RFC J0955+6903",
          "TGSSADR J095533.1+690354",
          "Gaia DR3 1070470609404512512",
          "HD 85534",
          "BD+69 543",
          "[LB2005] NGC 3031 X1",
          "2E 2195",
          "HIJASS J0955+69A",
          "IERS B0951+693",
          "IRAS F09514+6918",
          "IRAS 09514+6918",
          "K79 28A",
          "KPG 218a",
          "LEDA 28630",
          "M 81",
          "MCG+12-10-010",
          "NAME M 81*",
          "NGC 3031",
          "RBS 808",
          "RORF 0951+693",
          "RX J095532+69038",
          "SPB 115",
          "TC 714",
          "Gaia DR1 1070470605109644672",
          "UGC 5318",
          "Z 333-7",
          "[B93] 13",
          "[BAZ94] 3",
          "[DML87] 630",
          "[VDD93] 99",
          "2MASX J09553318+6903549",
          "1ES 0951+69.3",
          "1RXP J095532.8+690354",
          "1RXS J095534.7+690338",
          "2E 0951.4+6918",
          "EXO 0951.3+6917",
          "ICRF J095533.1+690355",
          "RX J0955.5+6903",
          "RX J095533.4+690353",
          "Z 0951.4+6918",
          "[CHM2007] HDC 552 J095533.18+6903549  [CHM2007] LDC 842 J095533.18+6903549",
          "[M98c] 095127.6+691813",
          "[VV2000c] J095533.2+690355",
          "[VV2003c] J095533.2+690355",
          "[VV2006] J095533.2+690355",
          "[VV98c] J095533.2+690355",
          "[ZEH2003] RX J0955.5+6903 1",
          "UZC J095535.5+690352",
          "PSCz Q09514+6918",
          "[VV2010] J095533.2+690355",
          "87GB 095125.0+691806",
          "GB6 B0951+6918",
          "NVSS J095533+690355",
          "WN B0951.4+6918",
          "2XMM J095533.1+690355",
          "SDSS J095533.16+690355.1",
          "[BAG2012] 149.0083+69.0811",
          "XMMSL1 J095534.3+690350",
          "SDSS J095533.14+690355.2",
          "BWE 0951+6918",
          "RGB J0955+690",
          "[CAB95] IRAS F09514+6918",
          "[NKB95] N3031",
          "NAME Bode's Galaxy",
          "SWIFT J0955.5+6907",
          "SWIFT J0955.9+6905",
          "Gaia DR2 1070470609404512512"
        ],
        "searchKey": "rgbj0955690|m81|2masxj095533186903549|mcg1210010|vv2006j0955332690355|2e095146918|iras095146918|2e2195|kpg218a|swiftj095596905|m81|rbs808|z3337|z095146918|vv2003cj0955332690355|psczq095146918|baz943|ugc5318|rorf0951693|wnb095146918|vv2000cj0955332690355|uzcj0955355690352|rfcj09556903|87gb0951250691806|zeh2003rxj0955569031|nvssj095533690355|nkb95n3031|iersb0951693|tgssadrj0955331690354|dml87630|vdd9399|vv98cj0955332690355|gaiadr21070470609404512512|rxj095556903|cab95irasf095146918|vv2010j0955332690355|exo095136917|swiftj095556907|gb6b09516918|leda28630|m98c0951276691813|bwe09516918|bd69543|rxj0955334690353|1rxsj0955347690338|sdssj095533166903551|sdssj095533146903552|k7928a|xmmsl1j0955343690350|gaiadr31070470609404512512|b9313|hijassj095569a|hd85534|rxj09553269038|tc714|bodesgalaxy|gaiadr11070470605109644672|2xmmj0955331690355|chm2007hdc552j095533186903549chm2007ldc842j095533186903549|bag20121490083690811|irasf095146918|lb2005ngc3031x1|ngc3031|spb115|1rxpj0955328690354|1es0951693|icrfj0955331690355|bodesgalaxy",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "TEAL",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Messier_81_HST.jpg/450px-Messier_81_HST.jpg",
        "ra": 9.925881293236,
        "dec": 69.06529514038,
        "fluxV": 6.94,
        "sizeMajor": 21.38,
        "sizeMinor": 10.23,
        "sizeAngle": 157.0,
        "simbadName": "M 81",
        "imgCredit": "NASA, ESA and the Hubble Heritage Team",
        "description": "Messier 81 (also known as NGC 3031 or Bode's Galaxy) is a grand design spiral galaxy about 12 million light-years away in the constellation Ursa Major. It has a D25 isophotal diameter of 29.44 kiloparsecs (96,000 light-years). Because of its relative proximity to the Milky Way galaxy, large size, and active galactic nucleus (which harbors a 70 million M\u2609 supermassive black hole), Messier 81 has been studied extensively by professional astronomers. The galaxy's large size and relatively high brightness also makes it a popular target for amateur astronomers. In late February 2022, astronomers reported that M81 may be the source of FRB 20200120E, a repeating fast radio burst.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Messier_81"
      },
      {
        "id": "944242351239495681",
        "name": "Pinwheel Galaxy",
        "names": [
          "APG 26",
          "IRAS F14012+5434",
          "IRAS 14013+5435",
          "K73 610",
          "LEDA 50063",
          "M 101",
          "MCG+09-23-028",
          "NAME Pinwheel",
          "NGC 5457",
          "SPB 243",
          "TC 302",
          "UGC 8981",
          "VV 344a",
          "VV 456",
          "VV 344",
          "Z 272-21",
          "2MASX J14031258+5420555",
          "Z 1401.5+5435",
          "[CHM2007] HDC 853 J140312.58+5420555  [CHM2007] LDC 842 J140312.58+5420555  [M98c] 140126.6+543525",
          "UZC J140312.5+542056",
          "PSCz Q14013+5435",
          "SDSS J140312.52+542056.2"
        ],
        "searchKey": "vv456|iras140135435|pinwheelgalaxy|leda50063|z140155435|irasf140125434|ugc8981|vv344a|uzcj1403125542056|m101|chm2007hdc853j140312585420555chm2007ldc842j140312585420555m98c1401266543525|k73610|spb243|sdssj140312525420562|pinwheel|tc302|z27221|2masxj140312585420555|mcg0923028|psczq140135435|ngc5457|apg26|vv344",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "SLATE",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/M101_hires_STScI-PRC2006-10a.jpg/435px-M101_hires_STScI-PRC2006-10a.jpg",
        "ra": 14.05349527777778,
        "dec": 54.34875,
        "fluxV": 7.86,
        "sizeMajor": 21.88,
        "sizeMinor": 20.89,
        "sizeAngle": null,
        "simbadName": "M 101",
        "imgCredit": "European Space Agency & NASA",
        "description": "The Pinwheel Galaxy (also known as Messier 101, M101 or NGC 5457) is a face-on spiral galaxy 21 million light-years (6.4 megaparsecs) from Earth in the constellation Ursa Major. It was discovered by Pierre M\u00e9chain in 1781 and was communicated that year to Charles Messier, who verified its position for inclusion in the Messier Catalogue as one of its final entries.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Pinwheel_Galaxy"
      },
      {
        "id": "944242358486532097",
        "name": "Triangulum Galaxy",
        "names": [
          "1AXG J013351+3039",
          "2E 409",
          "2E 0131.0+3024",
          "HIJASS J0133+30",
          "LEDA 5818",
          "M 33",
          "MCG+05-04-069",
          "NAME Triangulum Galaxy",
          "NAME Triangulum Pinwheel",
          "NGC 598",
          "RBS 214",
          "RX J013351+30399",
          "TC 906",
          "UGC 1117",
          "Z 0131.0+3024",
          "Z 502-110",
          "2MASX J01335090+3039357",
          "1ES 0131+30.3",
          "1RXS J013350.9+303932",
          "[CHM2007] LDC 160 J013350.90+3039357  [M98c] 013101.7+302415",
          "UZC J013351.1+303922"
        ],
        "searchKey": "z502110|1es0131303|2masxj013350903039357|chm2007ldc160j013350903039357m98c0131017302415|hijassj013330|m33|1axgj0133513039|triangulumgalaxy|triangulumgalaxy|rbs214|leda5818|ugc1117|triangulumpinwheel|uzcj0133511303922|tc906|1rxsj0133509303932|rxj01335130399|2e013103024|z013103024|2e409|mcg0504069|ngc598",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "INDIGO",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/VST_snaps_a_very_detailed_view_of_the_Triangulum_Galaxy.jpg/375px-VST_snaps_a_very_detailed_view_of_the_Triangulum_Galaxy.jpg",
        "ra": 1.564137937478667,
        "dec": 30.66017511198,
        "fluxV": 5.72,
        "sizeMajor": 60.26,
        "sizeMinor": 35.48,
        "sizeAngle": 22.0,
        "simbadName": "M 33",
        "imgCredit": "ESO",
        "description": "The Triangulum Galaxy is a spiral galaxy 2.73 million light-years (ly) from Earth in the constellation Triangulum. It is catalogued as Messier 33 or NGC (New General Catalogue) 598. With the D25 isophotal diameter of 18.74 kiloparsecs (61,100 light-years), the Triangulum Galaxy is the third-largest member of the Local Group of galaxies, behind the Andromeda Galaxy and the Milky Way.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Triangulum_Galaxy"
      },
      {
        "id": "944242361449775105",
        "name": "Leo Triplet",
        "names": [
          "APG 317",
          "NAME Leo Triplet"
        ],
        "searchKey": "leotriplet|leotriplet|apg317",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "LIME",
        "type": "STAR_OBJECT",
        "imgURL": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c7/LeoTripletHunterWilson.jpg/450px-LeoTripletHunterWilson.jpg",
        "ra": 11.31666666666667,
        "dec": 13.2,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Leo Triplet",
        "imgCredit": "Hewholooks",
        "description": "The Leo Triplet (also known as the M66 Group) is a small group of galaxies about 35 million light-years away in the constellation Leo. This galaxy group consists of the spiral galaxies M65, M66, and NGC 3628.",
        "descriptionCredit": "https://en.wikipedia.org/wiki/Leo_Triplet"
      },
      {
        "id": "949161339607580673",
        "name": "Crescent Nebula",
        "names": [
          "LBN 203",
          "LBN 075.51+02.29",
          "NGC 6888",
          "[T70] 32",
          "NAME Crescent Nebula"
        ],
        "searchKey": "lbn203|crescentnebula|t7032|crescentnebula|lbn075510229|ngc6888",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 20.20194444444444,
        "dec": 38.355,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NGC 6888",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950079201617838082",
        "name": "Galactic Center",
        "names": [
          "GAL 000.0+00.0",
          "HESS J1745-290",
          "NAME Gal Centre",
          "VER J1745-290",
          "NAME Galactic Centre",
          "NAME Gal Center",
          "NAME Galactic Center",
          "TeV J1745-290"
        ],
        "searchKey": "galacticcentre|galcenter|galacticcenter|verj1745290|hessj1745290|galacticcenter|galcentre|gal0000000|tevj1745290",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "PURPLE",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 17.76100059166667,
        "dec": -29.00611111111111,
        "fluxV": null,
        "sizeMajor": null,
        "sizeMinor": null,
        "sizeAngle": null,
        "simbadName": "NAME Galactic Center",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      },
      {
        "id": "950092878411661314",
        "name": "IC 1848",
        "names": [
          "LISC 0863",
          "C 0247+602",
          "CTB 11",
          "DA 84",
          "GCRV 53465",
          "IC 1848",
          "LBN 137.24+00.77",
          "LBN 667",
          "OCISM 93",
          "OCl 364",
          "OCl 364.0",
          "SH 2-199",
          "VRO 60.02.01",
          "[KPR2004b] 38",
          "Cl Collinder 32",
          "[KPS2012] MWSC 0236",
          "W 5"
        ],
        "searchKey": "ocism93|kps2012mwsc0236|kpr2004b38|c0247602|da84|lbn667|ocl3640|w5|gcrv53465|vro600201|clcollinder32|lisc0863|ic1848|ocl364|sh2199|lbn137240077|ctb11",
        "solarSystemKey": null,
        "cometKey": null,
        "celestrakKey": null,
        "color": "YELLOW",
        "type": "STAR_OBJECT",
        "imgURL": null,
        "ra": 2.857,
        "dec": 60.41888888888889,
        "fluxV": 6.5,
        "sizeMajor": 24.0,
        "sizeMinor": 24.0,
        "sizeAngle": null,
        "simbadName": "IC 1848",
        "imgCredit": null,
        "description": null,
        "descriptionCredit": null
      }
    ]
  }
];

